const initialState = {
  list: [],
  frotasList: []
}

const ApprovalBackofficeReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'APPROVALS_BACKOFFICE':
      state = initialState;
      return {
        list: [...state.list, ...action.list],
        frotasList: [...state.frotasList, ...action.frotasList]
      }
    default:
      return state;
  }
};

export default ApprovalBackofficeReducer;