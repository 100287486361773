import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
// import ArticleTitle from '../../../components/ArticleTitle/ArticleTitle';
// import Loading from '../../../components/Loading/Loading';
// import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';

import './TermoDownload.css'; 
import Button from '../../../components/Button/Button';


// let { apiEndpoint } = require('../../../config/constants');
// let { API } = require('../../config/constants');


class TermoDownload extends Component {

  constructor(props) {
    super(props);
    this.update = false;

    this.state = {
      termsAccepted: false
    }
  }

  componentDidMount() {    
    this.update = true;
    // this.props.dispatch(fetchValues(apiEndpoint() + API() + this.props.match.params.termoNews_id, get));
  }  

  changeTerms = () => {
    this.setState({
      termsAccepted: !this.state.termsAccepted
    })
  }

  acceptDownloadTerms = () => {
    localStorage.setItem(`downloadAccepted`, 'accepted');
    this.props.history.push('/termos-legais');
  }
  
  render() {
      return (
        <div className="row vw-imprint">
          <div className="col-12">
            <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a>  / Termo de Uso - Download`} />
            <PageTitle title="Termo de Uso - Download"/>
            {/* <PageSubTitle  text="(Atualizado em 15/02/2019)"/>  */}
            <PageSubTitle  text="Os textos, imagens, áudios, documentos e vídeos, disponibilizados para download na VW News BR têm finalidade puramente informativa, podendo ser utilizados em editoriais em mídias sociais, editoriais de Influencers na Internet, editoriais jornalísticos, bem como em editoriais internos de empresas para seus colaboradores, não podendo ser utilizados para fins comerciais. "/> 
              
          </div>
          <div className="col-12">            
          <PageSubTitle  text="É vedado o uso dos textos, imagens, áudios, documentos e vídeos disponibilizados para download na VW News BR de forma que contrarie a realidade para a qual foram concebidos. O uso deverá ser em conformidade com o presente Termo de Uso, a legislação vigente, a moral e os bons costumes. "/>
          </div>
          <div className="col-12 "> 
              <PageSubTitle  text="Se aplicam a este Termo de Uso as disposições dos Termos e Condições Gerais de Uso da VW News BR  no que não conflitarem."/>           
          </div>
          <div className="col-12"> 
            <form>
              <div className="row newsletterFormCheckbox__container">
                <div className="col form-check">
                  <input type="checkbox" className="form-check-input  alinhamento-check" id="canReceiveEmails" 
                    value={this.state.termsAccepted} onChange={e=> this.changeTerms(e)}></input>
                  <label className="form-check-terms form-check-label
                    offset-1 
                    offset-sm-2 
                    offset-md-1 
                    col-11 
                    col-sm-10 
                    col-md-11
                    " 
                    for="canReceiveEmails">
                      <PageSubTitle  text="Declaro que estou ciente e concordo com o Termo de Uso acima e os Termos e Condições Gerais de Uso da VW News BR."/>           
                    </label>
                </div>
                <div className='col-12'>
                  <Button disabled={!this.state.termsAccepted} text='Aceitar Termos' hideIcon='true' onClick={e => this.acceptDownloadTerms(e)}/>
                </div>
              </div>
            </form>
          </div>
          
        </div>
      )
    }   
}

// Add this function:
function mapStateToProps(state) {
  return {
    TermoDownload: state.CarReducer.TermoDownload,
  };
}

export default connect(mapStateToProps)(TermoDownload);

