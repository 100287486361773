import { fetchValuesOptions } from '../../shared/http-request-util';
let { loginAPI } = require('../../config/constants');

export default class LoginComponentService {

  authenticate(props) {
    return new Promise((res, rej) => {
      let url = loginAPI()
      let options = {
        method: 'GET',
        headers: {
          'authorization': props.token,
          'deviceid': (localStorage.getItem("deviceID") ? localStorage.getItem("deviceID") : undefined)
        }
      };
      function callback(body) {
        if (body) res(body)
        else rej();
      }
      fetchValuesOptions(url, options, callback)
    });
  }

  isUserDataValid(userData) {
    let isValid = true;
    
    if (!userData) {
      isValid = false;
    } else if (parseInt(userData.role) < 10) {
      isValid = true;
    } else if (!userData.email) {
      isValid = false;
    } else if (!userData.rg) {
      isValid = false;
    } else if (!userData.cpf) {
      isValid = false;
    }
    return isValid;
  }
}
