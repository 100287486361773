const initialState = {
  pagePhotos: {},
  latestPhotos: [],

}

const MediaGalleryDetailsReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LIST_LATEST_PHOTOS_BY_ALBUM':
      return {
        latestPhotos  : [...state.latestPhotos, ...action.latestPhotos],
        pagePhotos    : action.pages,
      }  
    default:
      return state;
  }
};

export default MediaGalleryDetailsReducer;