import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './CookiesAlert.css'; 
import PageSubTitle from '../PageSubTitle/PageSubTitle';

class CookiesAlert extends Component {

  constructor(props) {
    super(props);

    this.state = {
        deviceType: 'iPhone'
    }

  }

    hideCookiesAlert = () => {
        this.setState({ showInstallMessage: false });
        localStorage.setItem('acceptCookies', 'true');
    }
    render() {
        if(localStorage.getItem('acceptCookies')) return null
        return (      
            <div className={`cookiesalert-container `} onClick={e => this.hideCookiesAlert()}>
                <div className='content'>
                    <button onClick={e=> this.hideCookiesAlert() } className='close closeModal'>&times;</button>
                    <PageSubTitle text='Política de cookies'/>
                    Para otimizar sua experiência durante a navegação, fazemos uso de cookies. Ao continuar no site, 
                    consideramos que você está de acordo com nossa <Link to='/termos-legais/Cookies'>Política de cookies</Link>
                </div>
            </div>
        );    
    }
    }

export default CookiesAlert;