import React, { Component } from 'react';
import './LoanRequestStep2.css'; 
import TextBody from '../../../components/TextBody/TextBody';
import ComponentsCar from '../../../components/componentsCar/ComponentsCar'

class LoanRequestStep2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      update:false,
      loanCars: props.loanCars,
      selectedCars: {
        option1: '',
        option2: ''
      }
    }
  }

  componentDidMount() {
    console.log(this.props.loanCars)
    setTimeout(() => {
      this.setState({
        update: true
      });
    }, 200);
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.loanCars)
    this.setState({
      loanCars: nextProps.loanCars
    });
  }

  selectLoanCar = (e, option) => {
    if(e) {
      this.state.selectedCars[option] = (e)
      let disabledOption = (option == 'option1') ? 'carOption-2' : 'carOption-1'
      this.state.loanCars.map((item)  => {
        if(item.id == e) {
          item['disabled-' + disabledOption] = true
        } else {
          item['disabled-carOption-1'] = false
          item['disabled-carOption-2'] = false
        }
      })
      this.setState({}, ()  => {
        this.props.selectLoanCar(this.state.selectedCars)
      })
    } 
  }

  render() {
    return ( 
      <div className='loanRequestStep2'> 
        <TextBody text="Selecionar os dados do veículo a ser solicitado abaixo"/>
        <ComponentsCar componentTitle='Primeira opção para empréstimo de veículo' isSlider={true} cars={this.state.loanCars}  showRadioButton={true} selectLoanCar={e => this.selectLoanCar(e, 'option1')} selectedSecondCar={ this.state.selectedCars.option2} familySlider="carOption-1" />
        <ComponentsCar componentTitle='Segunda opção para empréstimo de veículo' isSlider={true} cars={this.state.loanCars}  showRadioButton={true} selectLoanCar={e => this.selectLoanCar(e, 'option2')}  selectedFirstCar={ this.state.selectedCars.option1} familySlider="carOption-2" />
      </div>
    )
  }
}

export default LoanRequestStep2;