import React, { Component } from 'react';

//Components
import ShortImagem from '../../ShortImage/ShortImage';


import './TwitterComponentItem.css'; 
import NewsComponentDate from '../../NewsComponent/NewsComponentDate/NewsComponentDate';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import Slider from "react-slick";
import TwitterComponentTags from '../TwitterComponentTags/TwitterComponentTags';
import Button from '../../Button/Button';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}> 
      <img src={require('./img/arrow-next.png')}/>
    </div>
  );
}

function PrevArrow(props) { 
  const { className, style, onClick } = props;
  return (
    <div  className={className} onClick={onClick}>
      <img src={require('./img/arrow-prev.png')}/>
    </div>
  );
}


class TwitterComponentItem extends Component {

  constructor(props) {
    super(props)

    
    this.state = {
      axis: 'x',
    }
  }    

  goTweet(link) {
    window.location.href = link
  }

  render() {

    var items = this.props.newsItems.map((news, index) => {
      let label = "";
      if (news.news_association != null && news.news_association.text_enum != null) {
        label = news.news_association.text_enum;  
      }
      return( 
        <div className='row vw-social-twitter' >
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row icons-sociais-1">
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 img-twitter">
                <ShortImagem mediaLow={news.media} media={news.media} mediaMedium={news.media}/>
              </div>
              <div className="col-8 col-sm-10 col-md-10 col-lg-10 " onClick={this.goTweet.bind(this,news.link )}>
                <div className='newsbody-split' >
                  <HTMLEllipsis
                   unsafeHTML={news.description}
                   maxLine='3'
                   ellipsis='...'
                   basedOn='letters'
                  />
                </div>
                <TwitterComponentTags className='tags-txt-social' tags={news.tags} />

                <div className="w-100"></div>
                <div className='dates'>
                <div className='newsdate-component-before'>
                  <NewsComponentDate date={news.updated_at} label={label} />
                </div>
                <div className='newsdate-component-after' >
                  <NewsComponentDate date={news.updated_at} label={label} />
                </div>
                </div>

                {/* <div className="row icons-sociais ">

                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 margin-icon-1" onClick={this.goTweet.bind(this,news.link )}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <ShortImagem media={news.icons.like} mediaMedium={news.icons.like} mediaLow={news.icons.like}  />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 margin-icon">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <ShortImagem media={news.icons.reply} mediaMedium={news.icons.reply} mediaLow={news.icons.reply}  />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4  col-lg-4 margin-icon">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <ShortImagem media={news.icons.retweet} mediaMedium={news.icons.retweet} mediaLow={news.icons.retweet} />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
           
      )
    });

    var settings = {
      dots: !this.props.isVertical,
      draggable: !this.props.isVertical,
      arrows: !this.props.isVertical ? true : false,
      infinite: this.props.newsItems.length > 2 ? true : false,
      speed: 500,
      vertical: this.props.isVertical,
      slidesToShow: !this.props.isVertical ? 3 : 9,
      slidesToScroll: !this.props.isVertical ? 3 : 1,
      verticalSwiping: false,
      touchThreshold: 40,
      centerMode: false,
      variableWidth: false,
      nextArrow: <NextArrow className='vw-next-arrow'/>,
      prevArrow: <PrevArrow className='vw-prev-arrow'/>,
      responsive: [{
        breakpoint: 768,
        settings: { 
          vertical: true,
          draggable: false,
          arrows: false
        }
      },{
        breakpoint: 544,
        settings: { 
          vertical: false,
          rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 2,
          slidesPerRow: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }],
      beforeChange: (current, next) => {
        console.log('beforeChange')
      },
      afterChange: current => { 
       console.log('afterChange')
      }
    };

    return ( 
      
      <div>
      <div className="newsItem-container row img "> 
      {this.props.isVertical
        ? (
          <div>
            {items}
          </div>
        ) : (
        <Slider {...settings}>
          {items}
        </Slider>
        )
      
      }
      </div> 
      <a href="https://twitter.com/vwbrasil"> 
        <Button text={this.props.btnTxt} hideIcon={ this.props.hideIcon } className="bt-twitter"/>
      </a>
    </div>

    ); 
  }
}

export default TwitterComponentItem;
