import React, { Component } from 'react';
import Root from './Root/Root';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class AppCore extends Component{
  constructor(props) {
    super(props);
  }

  render(){
    return   (
      <div>
        <BrowserRouter>
          <Provider store={this.props.store}>
            <Root/>
          </Provider>
        </BrowserRouter>            
      </div>
    );
  }
}

export default AppCore;