const initialState = {
  empresa: {},
  news: [],
  gallery: []
}

const EmpresaReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'EMPRESA_HOME':
      state = initialState;
      return {
        empresa: {...state.empresa, ...action.empresa},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default EmpresaReducer;