import React, { Component } from 'react';
import './ApprovalBackofficeComponent.css'
import ApprovalCard from '../ApprovalCard/ApprovalCard';
import { Link } from 'react-router-dom';
import { fetchValuesOptions } from '../../../shared/http-request-util';
import PageSubTitle from '../../PageSubTitle/PageSubTitle';
import Loading from '../../Loading/Loading';
import { withRouter } from 'react-router-dom';
import AlertModal from '../../AlertModal/AlertModal';

let { listApprovalsAPI, listRejection } = require('../../../config/constants');

class ApprovalBackofficeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isApprovingLoan: false
    }
    
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.user = JSON.parse(localStorage.getItem('user-data')).user;
    } else {
      props.history.push('/login');
    }
  }

  sendToParent = (e, index) => {
    this.props.goDetails(index)
  }

  _approvalAction = (e, type) => {
    let typeMsg = 'aprovar', isApproveModal = true
    if(type == 'reject') {
      typeMsg = 'rejeitar'
      isApproveModal = false
    }
    let alertMsg = `Tem certeza que deseja ${typeMsg} essa solicitação?`
   
    this.setState({
      loanToInteract: e,
      modalTitle: 'Alerta!',
      modalMessage: alertMsg,
      isApproveModal: isApproveModal,
      isRejectModal: !isApproveModal,
      parentClass: ''
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  _modalDecision = (e) => {
    console.log('modal decision', e)
    if(e) {
      this.setState({
        showModal: false
      }, () => {
          if(!this.state.isRejectModal) {
            this.approveLoan(this.state.loanToInteract)
          } else {
            this.rejectLoan(this.state.loanToInteract)
          }
      })
    }
  }

  approveLoan = (e) => {
      let selectedLoan = this.props.approvalList.filter((item) => item.id == e)[0]
      let body = {
        idloan: e,
        carId: selectedLoan.car_frotum_id,
        clientTier: selectedLoan.user.tier,
        startDate: selectedLoan.startDate,
        endDate: selectedLoan.endDate
      }
      console.log(body)
      this.setState({
        isApprovingLoan: true
      }, () => {
        let url = listApprovalsAPI()
        let options = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': JSON.parse(localStorage.getItem('user-data')).token
          },
          body: JSON.stringify(body)
        };
        let _that = this
        function callback(response) {
          _that.props.refreshList()
          _that.setState({
            isApprovingLoan: false
          }, () => {
            _that.props.history.push('/aprovacao')
          })
        }
        fetchValuesOptions(url, options, callback)
      });
  }

  rejectLoan = (e) => {
    console.log('repprove', e)
    let selectedLoan = this.props.approvalList.filter((item) => item.id == e)[0]
    let body = {
      idloan: e,
      carId: selectedLoan.car_frotum_id,
      clientTier: selectedLoan.user.tier,
      startDate: selectedLoan.startDate,
      endDate: selectedLoan.endDate
    }
    console.log(body)
    this.setState({
      isApprovingLoan: true
    }, () => {
      let url = listRejection()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
        _that.props.refreshList()
        _that.setState({
          isCancelingLoan: false
        }, () => {
          _that.props.history.push('/aprovacao')
        })
      }
      fetchValuesOptions(url, options, callback)
    })
  }

  _getCarName = (car_frotum_id) => {
    return this.props.frotas.filter((item) => item.id == car_frotum_id)[0].car.title
  }

  render() {
    return (
      <div className='vw-approval-backoffice mt-3'>
        { this.props.approvalList.length < 1 ? 
          <div className="row loan-data pb-4">
            <div className='col-12 col-sm-6 col-md-5 col-lg-4 checklistEmpty mt-4 mb-4 ml-4'>
            <PageSubTitle text="Nenhum item encontrado" />
            </div>
          </div>
        : 
          (this.user.role != 2 && this.user.role != 3 && !this.props.isAdmin) ? 
            this.props.approvalList.map((item, index) => {
              let formatDate = function (date) {
                date = new Date(date).toLocaleDateString();
                let aux = date.split('/');
                return aux[1] + '/' + aux[0] + '/' + aux[2].substring(2, 4);
              }
              let getStatus = function (status) {
                let label;
                switch (status) {
                  case 1:
                    return " Aguardando Aprovação (Backoffice)";
                    break;
                  case 2:
                      return " Aguardando Aprovação (Gerente 1)";
                      break;
                  case 3:
                      return " Aguardando Aprovação (Gerente 2)";
                      break;
                  case 4:
                      return " Aprovado";
                      break;
                  case 5:
                      return " Reprovado (Backoffice)";
                      break;
                  case 6:
                      return " Reprovado (Gerente)";
                      break;
                  case 7:
                      return " Veículo emprestado";
                      break;
                  case 8:
                      return " Veículo devolvido, checklist pendente";
                      break;
                  case 9:
                      return " Processo finalizado";
                    break;
                  default:
                    break;
                }
                return label;
              }

              return (
                <Link to={`/aprovacao/${item.id}`} target="_self" className="row loan-data pb-4">
                  <div className="col-3 col-md-2 text-center">
                    <p className='approval-tier'>Tier {item.user.tier}</p>
                  </div>
                  <div className="col-9 col-md-3">
                    <p className='loan-number-title mb-0'>Solicitação Número:
                      <span className='loan-number mt-1'> {item.id}</span>
                    </p>
                    <p className='loan-date'>De: {formatDate(item.startDate)} até {formatDate(item.endDate)}</p>
                  </div>
                  <div className="col-5 col-md-2">
                    <p className='loan-user-title ml-3 ml-md-0'>Solicitante:
                      <span className='loan-user'>  {item.user.name}</span>
                    </p>
                    <p className='loan-user-title ml-3 ml-md-0'>Mídia:
                      <span className='loan-user'> {item.user.midia_name}</span>
                    </p>
                  </div>
                  <div className="col-7 col-md-3">
                    <p className='loan-user-title'> Modelo do veículo:
                      <span className='loan-user'>  {item.option1.title}</span>
                    </p>
                    <p className='loan-user-title'>Status:
                      <span className='loan-user'>  {getStatus(item.status)}</span>
                    </p>
                  </div>
                  <div className="col-12 col-md-2 text-right">
                    <p className='btn-go-approval mb-0'>
                      Criado em {formatDate(item.created_at)}
                    </p>
                    <p className='w-100 btn-no-icon text-right mb-0 mr-0 pr-0'>
                      <i className={`icon brand-icon arrow-foward-blue has-text mr-0`}></i>
                    </p>
                  </div>
                </Link>
              )
            })
          : 
            <div class="row loan-data pb-4 manager-approval ">
              { this.props.approvalList.map((item, index) => {
                return(
                  item.car_frotum_id ? 

                      <div className='col-12 col-md-4 mb-4 mt-4'>
                        <ApprovalCard 
                          id={item.id}
                          showApproveReproveButton={true}
                          approveAction={e => this._approvalAction(e, 'approve')} 
                          rejectAction={e => this._approvalAction(e, 'reject')} 
                          model={this._getCarName(item.car_frotum_id)} 
                          subtitleManagerCard={{'userName': item.user.name, 'midiaName': item.user.midia_name }} 
                          itemsApprovalCard={[{'name': 'Pauta', 'value': item.description}]} />
                      </div>

                    : null 
                  )
                })
              }
            </div>
        }

          <AlertModal
            isDecisionModal={ true }
            configModalCallback={e => this._modalDecision(e)}
            title1={ this.state.modalTitle}
            messageHTML={ this.state.modalMessage}
            showModal={this.state.showModal}
          />

        { this.state.isApprovingLoan ? <Loading/> : null }
      </div>
    )
  }
}
export default withRouter(ApprovalBackofficeComponent);