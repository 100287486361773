/*
 * action types
 */

export const LIST_LATEST_PHOTOS = 'LIST_LATEST_PHOTOS'

/*
 * action creators
 */
export function getLatestPhotos(result) {
  return { 
    type: LIST_LATEST_PHOTOS, 
    latestPhotos: result.bucket_media
  }
}