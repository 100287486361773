import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getChecklistForm, sendChecklistForm } from './ChecklistForm.actions';
import { fetchValuesOptions, fetchValuesAuth } from '../../../shared/http-request-util';
import InputMask from 'react-input-mask';

import './ChecklistForm.css'; 
import { apiEndpoint } from '../../../config/constants';
import Loading from '../../../components/Loading/Loading';
import ReactGA from 'react-ga';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import RadioMultiline from '../../../components/RadioMultiline/RadioMultiline';
import Button from '../../../components/Button/Button';
import VWFileUpload from '../../../components/VWFileUpload/VWFileUpload'
import { withRouter } from 'react-router-dom';

import AlertModal from '../../../components/AlertModal/AlertModal';

let { listChecklistAPI } = require('../../../config/constants');

class ChecklistForm extends Component {

  constructor(props){
    super(props);

    this.checklistItems = [{
      id: '1',
      name: 'Motor',
      text: 'Nível do óleo ou vazamentos',
      checked: false
    },{
      id: '2',
      name: 'Climatização',
      text: 'Verificar o funcionamento do sistema',
      checked: false
    },{
      id: '3',
      name: 'Suspensão',
      text: 'Verificar o conjunto de molas, amortecedores e barulhos',
      checked: false
    },{
      id: '4',
      name: 'Direção',
      text: 'Alinhamento e balanceamento',
      checked: false
    },{
      id: '5',
      name: 'Freios',
      text: 'Checar pastilhas, discos, ruídos, pressão do pedal e eficiência',
      checked: false
    },{
      id: '6',
      name: 'Parte elétrica',
      text: 'Iluminação interna, lâmpadas de seta, luzes de emergência no painel, regulagem e funcionamento dos faróis e lantenas',
      checked: false
    },{
      id: '7',
      name: 'Pneus',
      text: 'Checar rodas amassadas, raladas, pressão e estado dos pneus',
      checked: false
    },{
      id: '8',
      name: 'Equipamentos obrigatórios',
      text: 'Checar extintor de incêndio, buzina, cintos de segurança, triângulo, estepe dentre outros',
      checked: false
    },{
      id: '9',
      name: 'Documentação',
      text: 'Licenciamento e contrato de comodato',
      checked: false
    }]
    //

    let inputFields =  {
      'next-review': '',
      'km': '',
      'note': ''
    }

    this.state ={
      textItems: {...inputFields},
      radioItems: [...this.checklistItems],
      filesItems: [],
      filledInputs: [],
      fieldsError: [],
    }


    this.update=true;
  }
  
  componentDidMount(){
    ReactGA.pageview('/checklist-form');
    this.update=true;
  }

  _checkDates = (input) => {
    let days = 7;
    if (!this.state.textItems[input]) return false
    let date = this.state.textItems[input].replace(/_/g, '')
    try {
      let newDate = date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0]
      let today = new Date() 
      let validDate = new Date(newDate) >= today.setTime(today.getTime() - (1 * 24 * 60 * 60 * 1000)); 
      return validDate
    } catch (e) {
      console.log('erro data', e)
      return false
    }
  }

  blockLetterE = (e) => {
    if(e.keyCode === 69) e.preventDefault()
  }
  changeInput = (value, input) => {
    if(this.refs[input]) {
      this.state.textItems[input] = value
      if(this.refs[input].value.length > 0) {
        let inputsFilled = this.state.filledInputs
        if(inputsFilled.indexOf(input) < 0) {
          inputsFilled.push(input)
          this.setState({
            filledInputs: [...inputsFilled]
          }, () => {
            this.setState({
              isValid: this.isValidChecklistForm()
            })
          })
        }

        if(input == 'next-review' || input == 'date-return') {
          let date = this.state.textItems[input].replace(/_/g, '')
          let error = []
          if (date.length > 9) {
            if (!this._checkDates(input)) {
              error.push(input)
              this.setState({
                fieldsError: [...error]
              }, () => {
                console.log('next-review')
                return
              })
            } else {
              this.state.fieldsError.splice(this.state.fieldsError.indexOf(input), 1)
              this.setState({})
            }
          }
        }
      } else {
        let inputsFilled = this.state.filledInputs
        inputsFilled.splice(this.state.filledInputs.indexOf(input), 1)
        this.setState({
          filledInputs: [...inputsFilled]
        }, () => {
          this.setState({
            isValid: this.isValidChecklistForm()
          })
        })
      }
    }
  }

  _onChangeRadio = (e, index) => {
    this.state.radioItems[index].checked = !this.state.radioItems[index].checked
    this.state.isValid = this.isValidChecklistForm()
    this.setState({})
  }

  isValidChecklistForm = () => {
    let validRadios = this.state.radioItems.filter((item) => item.checked === true)
    if(this.state.textItems[ 'km'] && this._checkDates('next-review') && this._checkDates('date-return') && validRadios.length === this.state.radioItems.length) return true
    return false
  }

  _sendChecklistForm = () => {
    if(!this.isValidChecklistForm()) return
    this.setState({
      isSendingChecklist: true
    }, () => {
      let stringDate = this.state.textItems['next-review'].split('/')[2] + '/' + (this.state.textItems['next-review'].split('/')[1]) + '/' + this.state.textItems['next-review'].split('/')[0]
    let dateConverted = new Date(stringDate)
    let stringDateReturn = this.state.textItems['date-return'].split('/')[2] + '/' + (this.state.textItems['date-return'].split('/')[1]) + '/' + this.state.textItems['date-return'].split('/')[0]
    let dateReturnConverted = new Date(stringDateReturn)
    let checklistToSend = {
      loanId: this.props.loanId,
      checklist: this.state.radioItems.map((item) => item.id),
      nextReviewDate: dateConverted.toISOString(),
      returnDate: dateReturnConverted.toISOString(),
      notes: this.state.textItems['note'],
      km: this.state.textItems['km'],
      files: this.state.filesItems
    }
    console.log('sendchecklist', checklistToSend)

    let url = apiEndpoint() + 'checklist/create'
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': JSON.parse(localStorage.getItem('user-data')).token
      },
      body: JSON.stringify(checklistToSend)
    };
    
    var _that = this
    function callback(response) {
      // window.location.href = '/checklist'
      _that.setState({
        isSendingChecklist: false,
        showModal: true
      })
    }
    fetchValuesOptions(url, options, callback)
    })
  }

  handleModalCallback = (e) => {
    this.props.closeDetails()
  }

  _updateFileItems = (fileItems) => {
    this.setState({
      filesItems: fileItems.map((item) => item.name)
    })
  }

  isFileUploading = (e) => {
    this.setState({
      isFileUploading: true
    })
  }

  render() {
   
    if (this.update) {    
      return (      
        <div className="vw-checklistForm">
          <PageSubTitle text="Preencha os campos abaixo para fazer o checklist" />      
          <div className="row vw-floating-label mb-4">
            <div className="col-12 mb-4 m-md-0 col-md-4">
              <div className={`form-label-group ${(this.state.fieldsError.indexOf('next-review') > -1 ? ' input-error ' : ' ')}`}>
                <InputMask
                  className={`form-control ${this.state.filledInputs.indexOf('next-review') > -1 ? 'input-filled' :  ''}`}
                  placeholder='Próxima revisão'
                  onChange={e => this.changeInput(e.target.value, 'next-review')}
                  mask={"**/**/****"}
                  type="text" id="next-review" ref="next-review">
                </InputMask>
                <label htmlFor='next-review'>
                  Próxima revisão
                </label>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className='ml-md-2 form-label-group'>
                  <input type="number" onKeyDown={e => this.blockLetterE(e)} onChange={e => this.changeInput(e.target.value, 'km')} className={`form-control  ${this.state.filledInputs.indexOf('km') > -1 ? 'input-filled' :  ''} ${(this.state.fieldsError.indexOf('km') > -1  ? 'input-error' : '')} `} name="km" id="km" ref='km' maxLength="80"/>
                  <label htmlFor='km'>
                  km
                  </label>
              </div>
            </div>
            <div className="col-12 mb-4 m-md-0 col-md-4">
              <div className={`form-label-group ${(this.state.fieldsError.indexOf('date-return') > -1 ? ' input-error ' : ' ')}`}>
                <InputMask
                  className={`form-control ${this.state.filledInputs.indexOf('date-return') > -1 ? 'input-filled' :  ''}`}
                  placeholder='Data da devolução'
                  onChange={e => this.changeInput(e.target.value, 'date-return')}
                  mask={"**/**/****"}
                  type="text" id="date-return" ref="date-return">
                </InputMask>
                <label htmlFor='date-return'>
                  Data da devolução
                </label>
              </div>
            </div>
          </div>
          <div className="row vw-floating-label mt-4 mb-4">
            <div className='col-12 mt-4 pb-1' >
              <PageSubTitle className='pb-2' text="Selecione os itens do check list" /> 
            </div>     
            {this.checklistItems.map((item, index) => {
              return(
                <div key={`radio-checklist-${index}`} className='col-12 pb-2 pt-2 radio-car-item radio-active'>
                  <RadioMultiline isCheckbox='checkbox' id={item.id} value={this.state.radioItems[index].checked} reference={`radioRef-${item.id}`} name={item.name} radioType={`checklistItem`} text={item.text} onChangeRadio={e => this._onChangeRadio(e, index)}/>
                </div>
              )
            })}
          </div>

          <div className="row vw-floating-label mt-4 mb-4">
            <div className='col-12 mt-4 pb-1' >
              <PageSubTitle className='pb-2' text="Upload de imagens" /> 
            </div> 
            <div className='col-12 mt-4 pb-1 vw-fileuploader' >  
              <VWFileUpload isFileUploading={e => this.isFileUploading(e)} allowFileUploading={this.state.isFileUploading} allowMultiple={true} showPreview={true} uploadText={'Adicionar Foto'} updateFileItems={e => this._updateFileItems(e)}/>
            </div>
          </div>

          <div className="row vw-floating-label mt-4 mb-4">
            <div className='col-12 mt-4 pb-1' >
              <PageSubTitle className='pb-2' text="Observações" /> 
            </div>     
            <div className='col-12 mt-4 mb-4 vw-label'>
              <div className='form-label-group mb-4'>
                <textarea className="form-control" id="justifyBlocked" ref='note' placeholder="Digite aqui…" onChange={ e => this.changeInput(e.target.value, 'note')} required></textarea>
              </div>
            </div>
          </div>

          <div className='row pt-4 mb-4'>
            <div className='col-12 text-center mt-4 mb-4'>
              <Button hideIcon={true} text='Enviar' disabled={ !this.isValidChecklistForm() } onClick={e => this._sendChecklistForm(e) }/>
            </div>
          </div>

          <AlertModal
            title1='Checklist Enviado!'
            message={'Checklist enviado com sucesso!'}
            configModalCallback={e => this.handleModalCallback(e) }
            showModal={this.state.showModal}
          />

          {this.state.isSendingChecklist ? <Loading /> : ''}
        </div>
      );    
    } else {
        return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  return {
    checklist: state.ChecklistFormReducer.checklist
  }
}

// export default connect(mapStateToProps)(ChecklistForm);

export default withRouter(ChecklistForm);
