import React, { Component } from 'react';

import './PageTitle.css'; 

class PageTitle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      className: props.className
    }
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1 || desc.indexOf('T-Crss') > -1) {
      let tCrossString = '&#8209';
      return desc.replace(/-/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    return (      
      <div className={`page-title ${this.state.className} `}>
        <div dangerouslySetInnerHTML={{__html: this._convertDescription(this.props.title)}}></div>
      </div>
    );    
  }
}

export default PageTitle;
