/*
 * action types
 */

export const LIST_NEWS = 'LIST_NEWS'

/*
 * action creators
 */
export function listNews(result) {
  return { 
    type: LIST_NEWS, 
    page: result.pages,
    news: result.news    
  }
}