import React, { Component } from 'react';

import './TextArea.css';


class TextArea extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <textarea className="textArea" cols={this.props.cols} maxlength={this.props.maxlength} 
            placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}>
            
            </textarea>
        );
    }
}

export default TextArea;