import React, { Component } from 'react';

import './ContactRow.css'; 

import Contact from "../Contact/Contact";
import PageTitle from '../PageTitle/PageTitle';

class ContactRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      contactList: this.props.contactList
    }
  }

  render() {
    return (
      <div>
        <div className="row contactRow__container">
        <div className="col-12 contactRow__title">
        <PageTitle className="vw-font-20px" title={this.state.title}/>
        </div>
          {this.state.contactList.map((contact) =>{
            return (<Contact key={contact.id}
              image={contact.image} 
              name={contact.name}
              role={contact.role}
              email={contact.email}
              phone={contact.phone}
              languages={contact.languages} />)
          })
        }
        </div>
      </div>
    );    
  }
}

export default ContactRow;
