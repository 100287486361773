import React, { Component } from 'react'
import PubSub from 'pubsub-js'
import { Link } from 'react-router-dom';
import './Footer.css'
import PageTitle from '../PageTitle/PageTitle';
import TextBody from '../TextBody/TextBody'

class Footer extends Component {
  constructor() {
    super()
    this.token = "";
    this.update = false;
    this.state = {
      img1: require("./img/twitter.png"),
      img2: require("./img/instagram.png"),
      img3: require("./img/facebook.png"),
      img4: require("./img/youtube.png"),
    }
  }

  _receiveMenu(msg, data) {
    this.update = true;
    this.setState({menu: data});
  }

  componentDidMount() {
    this.token = PubSub.subscribe('SHOW_FOOTER', this._receiveMenu.bind(this));
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);    
  }

  render() {
    if (this.update) {
      return (
        <div className="vw-footer">
          <div className={"container vw-bodys " + (window.location.pathname == '/' || window.location.hash == '#/' ? 'container-fluid' : '')}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 col-sm-4 col-md-2 mt-md-4">
                  <Link to={`/cars/`} target="_self">
                    <PageTitle className="vw-font-12px vw-no-padding" title="Carros"/>
                  </Link>
                  <Link to="/cars/models" target="_self">
                    <TextBody className="vw-font-12px" text="Modelos"/>
                  </Link>
                  <Link to="/cars/concepts" target="_self">
                    <TextBody className="vw-font-12px" text="Conceitos"/>
                  </Link>
                  <Link to="/cars/classics" target="_self">
                    <TextBody className="vw-font-12px" text="Clássicos"/>
                  </Link>
                </div>
                <div className="col-6 col-sm-4 col-md-2 mt-md-4">
                  <Link to={`/company/`} target="_self">
                    <PageTitle className="vw-font-12px vw-no-padding" title="Empresa"/>
                  </Link>
                  {this.state.menu.session.map((empresa, index) => {
                    return (
                      <Link key={`${empresa}-${index}`} to={`/company/${empresa.id}`} target="_self">
                        <TextBody className="vw-font-12px" text={empresa.title} />    
                      </Link>
                    )
                  })}
                </div>
                <div className="col-6 col-sm-4 col-md-2 mt-4 mt-sm-0 mt-md-4">
                  <Link to={`/technology/`} target="_self">
                    <PageTitle className="vw-font-12px vw-no-padding" title="Tecnologia"/>
                  </Link>
                  {this.state.menu.technology.map((technology, index) => {
                    return (
                      <Link key={`${technology}-${index}`} to={`/technology/${technology.id}`} target="_self">
                        <TextBody className="vw-font-12px" text={technology.title} />    
                      </Link>
                    )
                  })}
                </div>
                <div className="col-6 col-sm-4 col-md-2 mt-4 ">
                  <Link to={`/mobilidade-eletrica/`} target="_self">
                    <PageTitle className="vw-font-12px vw-no-padding" title="Mobilidade Elétrica"/>
                  </Link>
                  {this.state.menu.emobility.map((emobility, index) => {
                    return (
                      <Link key={`${emobility}-${index}`} to={`/mobilidade-eletrica/${emobility.id}`} target="_self">
                        <TextBody className="vw-font-12px" text={emobility.title} />    
                      </Link>
                    )
                  })}
                </div>                
                <div className="col-8 col-sm-5 col-md-4 mt-4">
                  <Link to={`/media-center`} target="_self">
                    <PageTitle className="vw-font-12px vw-no-padding" title="Media Center"/>
                  </Link>
                  <Link to={`/news`} target="_self">
                    <TextBody className="vw-font-12px" text="News"/>
                  </Link>
                  <Link to={`/media-gallery`} target="_self">
                    <TextBody className="vw-font-12px" text="Galeria"/>
                  </Link>
                  <Link to={`/contacts`} target="_self">
                    <TextBody className="vw-font-12px" text="Contatos"/>
                  </Link>
                  <br></br>
                  <div>
                  <a href="https://twitter.com/vwbrasil" target="_blank">
                    <img  src={this.state.img1} className="img-icon"></img>
                  </a>
                  <a href="https://www.instagram.com/vwbrasil/" target="_blank">
                    <img  src={this.state.img2} className="img-icon"></img>
                  </a>
                  <a href="https://www.facebook.com/volkswagendobrasil" target="_blank">
                   <img  src={this.state.img3 } className="img-icon"></img>
                  </a>
                  <a href="https://www.youtube.com/volkswagendobrasil" target="_blank">
                   <img  src={this.state.img4} className="img-icon"></img>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>  
      );      
    } else {
      return (<div></div>);
    }
  }
}


export default Footer;