

/*
 * action types
 */

export const CHECKLIST_HOME = 'CHECKLIST_HOME'

/*
 * action creators
 */
export function getChecklist(result) {
  console.log(result)
  return { 
    type: CHECKLIST_HOME, 
    list: result.list
  }
}
