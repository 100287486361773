/*
 * action types
 */

export const LIST_MENU = 'LIST_MENU'

/*
 * action creators
 */
export function getMenu(result) {  
  return { 
    type: LIST_MENU, 
    menu: result.menu,
  } 
}  