import { ENVIRONMENTS, PLATFORMS } from "./environment.common";
import { ENVS } from "./environment";

export function getPlatform() {
  return ENVS.PLATFORM;
}

export function apiEndpoint() {
  switch (ENVS.ENVIRONMENT) {
    case ENVIRONMENTS.LOCAL:
      return "https://newsroom-cms-br-ds.itd.vw.com.br/v1/api/"
    case ENVIRONMENTS.DEV:
      return "https://newsroom-cms-br-ds.itd.vw.com.br/v1/api/"
    case ENVIRONMENTS.BETA:
        return "https://newsroom-cms-br-qs.itd.vw.com.br/v1/api/"
    case ENVIRONMENTS.PROD:
      return "https://newsroom-cms-br.itd.vw.com.br/v1/api/"
    default:
      return "https://newsroom-cms-br.itd.vw.com.br/v1/api/"
  }
}

export function publicUrlEndpoint() {
  switch (ENVS.ENVIRONMENT) {
    case ENVIRONMENTS.LOCAL:
      return "https://newsroom-br-ds.itd.vw.com.br"
    case ENVIRONMENTS.DEV:
      return "https://newsroom-br-ds.itd.vw.com.br"
    case ENVIRONMENTS.BETA:
      return "https://newsroom-br-qs.itd.vw.com.br"
    case ENVIRONMENTS.PROD:
      return "https://www.vwnews.com.br"
    default:
      return "https://www.vwnews.com.br"
  }
}

export function urlEndpoint() {
  switch (ENVS.ENVIRONMENT) {
    case ENVIRONMENTS.LOCAL:
      return "https://newsroom-cms-br-ds.itd.vw.com.br"
    case ENVIRONMENTS.DEV:
      return "https://newsroom-cms-br-ds.itd.vw.com.br"
    case ENVIRONMENTS.BETA:
      return "https://newsroom-cms-br-qs.itd.vw.com.br"
    case ENVIRONMENTS.PROD:
      return "https://www.vwnews.com.br"
    default:
      return "https://www.vwnews.com.br"
  }
}

export function analyticsFlavor() {
  switch (ENVS.ENVIRONMENT) {
    case ENVIRONMENTS.LOCAL:
    case ENVIRONMENTS.DEV:
    case ENVIRONMENTS.BETA:
      return "UA-134726392-2 "
    case ENVIRONMENTS.PROD:
    default:
      return "UA-134726392-1 "
  }
}

export function identityKitOption() {
  if (ENVS.PLATFORM === PLATFORMS.WEB) {
    switch (ENVS.ENVIRONMENT) {
      case ENVIRONMENTS.LOCAL: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "http://localhost:3000/login",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/",
        "logoutCallBack": "http://localhost:3000/home"
      }
      case ENVIRONMENTS.DEV: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "https://newsroom-br-ds.itd.vw.com.br/login",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/",
        "logoutCallBack": "https://newsroom-br-ds.itd.vw.com.br/home"
      }
      case ENVIRONMENTS.BETA: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "https://newsroom-br-qs.itd.vw.com.br/login",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/",
        "logoutCallBack": "https://newsroom-br-qs.itd.vw.com.br/home"
      }
      case ENVIRONMENTS.PROD: 
      default: return {
        "clientId": "951cc9b6-3721-4d66-973e-4311c9394f06@apps_vw-dilab_com",
        "clientSecret": "5cfd228fbb053de8cca98f5916918a7e6049613c77b5164547b92cd09e256dd5",
        "urlCallback": "https://www.vwnews.com.br/login",
        "urlVwId": "https://identity.na.vwgroup.io/oidc/v1/",
        "logoutCallBack": "https://www.vwnews.com.br/home"
      }
    }
  }
  if (ENVS.PLATFORM === PLATFORMS.MOBILE) {
    switch (ENVS.ENVIRONMENT) {
      case ENVIRONMENTS.LOCAL: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "http://localhost:3000/login",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/"
      }
      case ENVIRONMENTS.DEV: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "newsroom.app.scheme://",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/"
      }
      case ENVIRONMENTS.BETA: return {
        "clientId": "4cc46241-83b0-4555-93e9-e5b62ddfd8ea@apps_vw-dilab_com",
        "clientSecret": "71440e0b87640ea24c3a4f2dab28b6d05441b14871286caf3d97cc0bd3c82037",
        "urlCallback": "newsroom.app.scheme://",
        "urlVwId": "https://identity-sandbox.vwgroup.io/oidc/v1/"
      }
      case ENVIRONMENTS.PROD:
      default: return {
        "clientId": "951cc9b6-3721-4d66-973e-4311c9394f06@apps_vw-dilab_com",
        "clientSecret": "5cfd228fbb053de8cca98f5916918a7e6049613c77b5164547b92cd09e256dd5",
        "urlCallback": "newsroom.app.scheme://",
        "urlVwId": "https://identity.na.vwgroup.io/oidc/v1/"
      }
    }
  }
}


// ------- LOGIN -------- //
export function loginAPI() {
  return apiEndpoint() + "login/";
}

// ------- USER DATA -------- //
export function usersAPI() {
  return apiEndpoint() + "users/";
}

// ------- CARS --------- //
export function listCarsAPI() {
  return "cars/json/";
}

export function carDetailAPI() {
  return "cars/json/car/";
}

export function carVersionAPI(carID, versionID) {
  return "cars/json/car/" + carID + "/version/" + versionID;
}

// ----- EMPRESA -------- //
export function empresaHomeAPI() {
  return "sessions/json/";
}

export function empresaLevel1(id) {
  return "sessions/json/session/" + id
}

export function empresaLevel2(id, subID) {
  return "sessions/json/session/" + id + "/subsession/" + subID;
}

// ------ TECHNOLOGY ----- //

export function technologyHomeAPI() {
  return "technology/json/";
}

export function tecnologiaLevel1(id) {
  return "technology/json/technology/" + id
}

export function tecnologiaLevel2(id, subID) {
  return "technology/json/technology/" + id + "/subtech/" + subID;
}

// ------- Mobilidade Eletrica -------- //
export function emobilityHomeAPI() {
  return "mobilidade-eletrica/json/";
}

export function emobilityLevel1(id) {
  return "mobilidade-eletrica/json/mobilidade-eletrica/" + id
}

export function emobilityLevel2(id, subID) {
  return "mobilidade-eletrica/json/mobilidade-eletrica/" + id + "/subMob/" + subID;
}

// ------ HOME ----- //
export function homeAPI() {
  return "home/json/";
}

// ------ News ----- //
export function newsAPI(page, limit) {
  return "news/json?page=" + page + "&limit=" + limit;
}

export function newsDetailAPI(id) {
  return "news/json/news/" + id;
}

export function newsBySection(page, limit, textEnum, idLevel1, idLevel2) {
  //news/json/filter?limit=10&page=1&textEnum=Carros&association_id=1&association_item_id=1
  if (idLevel1 === undefined) {
    return "news/json/filter?limit=" + limit + "&page=" + page + "&textEnum=" + textEnum;
  } else {
    if (idLevel2 === undefined) {
      return "news/json/filter?limit=" + limit + "&page=" + page + "&textEnum=" + textEnum + "&association_id=" + idLevel1;
    } else {
      return "news/json/filter?limit=" + limit + "&page=" + page + "&textEnum=" + textEnum + "&association_id=" + idLevel1 + "&association_item_id=" + idLevel2;
    }
  }
}


// ------ Contacts ---- //
export function listContactsAPI() {
  return "contacts/json/";
}

// ---- Media Center ---- //
export function galleryLatestPhotos(page, limit) {
  return "buckets/json/photos?page=" + page + "&limit=" + limit;
}

export function galleryLatestVideos(page, limit) {
  return "buckets/json/videos?page=" + page + "&limit=" + limit;
}

export function galleryLatesAlbums(page, limit) {
  return "buckets/json?page=" + page + "&limit=" + limit;
}

export function getPhotosByAlbym(album_id, page, limit) {
  return "buckets/json/bucket/" + album_id + "?page=" + page + "&limit=" + limit;
}

// ------ Lending ---- //
export function approvalsAPI() {
  return apiEndpoint() + "lendingcontract/pending-for-approval";
}

export function listApprovalsAPI() {
  return apiEndpoint() + "lendingcontract/approve";
}

export function listRejection() {
  return apiEndpoint() + "lendingcontract/reject";
}

export function listCancelLoanAPI() {
  return apiEndpoint() + "lendingcontract/list-comodato";
}

export function cancelLoanAPI() {
  return apiEndpoint() + "lendingcontract/cancel";
}

export function listChecklistAPI() {
  return apiEndpoint() + "lendingcontract/checklist";
}

export function listPickupCarAPI() {
  return apiEndpoint() + "lendingcontract/aviability";
}

export function checkinPickupCarAPI() {
  return apiEndpoint() + "lendingcontract/checkin";
}

export function checkoutPickupCarAPI() {
  return apiEndpoint() + "lendingcontract/checkout";
}

export function eventsListAPI() {
  return apiEndpoint() + "events";
}

export function eventsAcceptAPI() {
  return apiEndpoint() + "events/accept";
}

export function eventsRejectAPI() {
  return apiEndpoint() + "events/refuse";
}

export function getByCPFAPI() {
  return apiEndpoint() + "users";
}

export function getLogoutUrl(token) {
  let urlCallBack = identityKitOption().logoutCallBack;
  let VWURLAuth = identityKitOption().urlVwId;
  return VWURLAuth + "logout?id_token_hint=" + token + "&post_logout_redirect_uri=" + urlCallBack;
}