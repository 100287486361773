const initialState = {
  technology: {},
  news: [],
  gallery: []
}

const TecnologiaReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'TECNOLOGIA_HOME':
      state = initialState;
      return {
        technology: {...state.technology, ...action.technology},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default TecnologiaReducer;