import React, { Component } from 'react';
import './LoanRequest.css';
import { connect } from 'react-redux';
import { getLoanCars, sendLoanCars} from './LoanRequest.actions';
import { fetchValues, fetchValuesOptions} from '../../shared/http-request-util';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import ReactGA from 'react-ga';
import StepWizard from '../../components/StepWizard/StepWizard';
import LoanRequestStep1 from './LoanRequestStep1/LoanStep1';
import LoanRequestStep2 from './LoanRequestStep2/LoanStep2';
import LoanRequestStep3 from './LoanRequestStep3/LoanStep3';
import AlertModal from '../../components/AlertModal/AlertModal';

let { apiEndpoint } = require('../../config/constants');

class LoanRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      update: false,
      activeStep: 0,
      stepShow1: 'vw-display-none',
      stepShow2: 'vw-display-none',
      stepShow3: 'vw-display-none',
      nextStep: 'btn-secondary',
      stepText: 'Prosseguir',
      driver: {
        selectedDriver: '',
        otherCPF: '',
        otherCNH: '',
        imageCNH: '',
        isValidate: false
      },
      selectedCar: {
        option1: '',
        option2: ''
      },
      userdata: {
        address: '',
        email: '',
        phone: ''
      },
      loanDate: {
        startDate: '',
        endDate: '',
        acceptTerms: false,
        loanAboutRequest:'',
      },
      loanCars: [{ "id": 1, "title": "teste", "subtitle": "teste", "isHighlight": true, "highlight_position": 35, "categoryCar": "Sedan", "media": "https://cdn.na.vwapps.io/assets/a2acc312-5aaa-41c7-bad8-868ed07cfe1d/89088d4d-8c31-4e7c-b860-992abddddb19.png", "mediaSize": "1.0 MB", "mediaSizeLow": "338 KB", "mediaSizeMedium": "619 KB", "mediaThumbSize": null, "mediaThumbSizeLow": null, "mediaThumbSizeMedium": null, "showMenu": true, "description": "", "created_at": "2019-02-14T19:59:20.000Z", "updated_at": "2019-03-15T16:29:59.000Z", "car_category_id": 2 }, { "id": 2, "title": "RamiresCar", "subtitle": "Ramires", "isHighlight": true, "highlight_position": 3, "categoryCar": "Station Wagon", "media": "https://cdn.na.vwapps.io/assets/a2acc312-5aaa-41c7-bad8-868ed07cfe1d/baa01fe9-97c7-4d6a-a542-4c0276cf6668.png", "mediaSize": "1.0 MB", "mediaSizeLow": "338 KB", "mediaSizeMedium": "619 KB", "mediaThumbSize": null, "mediaThumbSizeLow": null, "mediaThumbSizeMedium": null, "showMenu": false, "description": "", "created_at": "2019-02-19T13:07:03.000Z", "updated_at": "2019-03-15T16:29:59.000Z", "car_category_id": 2 }]
    }
  }

  componentDidMount() {
    ReactGA.pageview('/solicita-comodato');

    let userData = window.localStorage.getItem('user-data') ? JSON.parse(window.localStorage.getItem('user-data')) : { address: '', email: '', phone: ''}
    let loanCarsEndpoint = 'car_frota/json/cars_frota'
    this.props.dispatch(fetchValues(apiEndpoint() + loanCarsEndpoint, getLoanCars));

    setTimeout(() => {
      this.setState({
        update: true,
        stepShow1: '',
        loanCars: this.props.loanCars ? this.props.loanCars : this.state.loanCars,
        nextStep: 'btn-secondary',
        userdata : {
          address : userData.user.address + ' - ' + userData.user.city + '/' + userData.user.state + '/' + userData.user.country,
          email : userData.user.email,
          phone :  userData.user.phone1
        }
      });
    }, 200);
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    let userData = window.localStorage.getItem('user-data') ? JSON.parse(window.localStorage.getItem('user-data')) : { address: '', email: '', phone: ''}
    console.log(userData)
    this.setState({
      loanCars: nextProps.loanCars ? nextProps.loanCars : this.state.loanCars,
      userdata: userData.user
    })
  }

  updateCNHPicture = () => {
    if(!this.state.driver.imageCNH) return
    let user
    if(window.localStorage.getItem('user-data')) {
      user = JSON.parse(window.localStorage.getItem('user-data'))
      user.user['cnhPicture'] = this.state.driver.imageCNH
    }
    window.localStorage.setItem('user-data', JSON.stringify(user)) 
  }

  updateDriverValues = (driver) => {
    if (driver.isValidate) {
      this.setState({
        driver: driver,
        nextStep: ''
      }, ()=> {
        this.updateCNHPicture()
      })
    } else {
      this.setState({
        driver: driver,
        nextStep: 'btn-secondary'
      })
    }
  }

  updateLoanDateValues = (loanDate) =>{
    console.log(loanDate)
    if (loanDate.isValidate) {
      this.setState({
        loanDate: loanDate,
        nextStep: ''
      })
    } else {
     
      let test = (!loanDate.startDate || !loanDate.endDate || !loanDate.acceptTerms) 
      console.log(test)
      this.setState({
        loanDate: loanDate,
        nextStep: (!loanDate.startDate || !loanDate.endDate || !loanDate.acceptTerms) ? 'btn-secondary' : ''
      }, () => {
        console.log(this.state)
      })
    }
  }

  selectLoanCar = (selectedCar) => {
    if (selectedCar.option1 && selectedCar.option2) {
      this.setState({
        selectedCar: selectedCar,
        nextStep: ''
      })
    }
  }


  blockStep2 = () => {
    if (this.state.activeStep == 0) {
      if (this.state.driver.isValidate) {
        return false;
      } else {
        return true;
      }
    } else if (this.state.activeStep == 1) {
      if (!this.state.selectedCar.option1 && !this.state.selectedCar.option2) return true
    } else if (this.state.activeStep == 2) {
      if (!this.state.loanDate.startDate || !this.state.loanDate.endDate || !this.state.loanDate.acceptTerms) return true
    }
  }

  goStep2 = () => {
    console.log('GO TO REQUEST', this.state.activeStep)
    if(this.state.activeStep ==1){
      this.setState({
        activeStep: this.state.activeStep + 1,
        nextStep: 'btn-secondary',
        stepText: 'Enviar'

      });
    }else{
      this.setState({
        activeStep: this.state.activeStep + 1,
        nextStep: 'btn-secondary'
      }, () => {
        if(this.state.activeStep == 3) {
          let data = JSON.stringify(this.state.userdata) + JSON.stringify(this.state.driver) + JSON.stringify(this.state.selectedCar) + JSON.stringify(this.state.loanDate)
          console.log(`Solicitação enviada  ${data}`)
          // window.location.href = '/solicita-comodato'
          this.sendLoanRequest()
        }
      });
    }
  }

  sendLoanRequest = () => {
    console.log(this.state.driver.imageCNH[0])
    let body = {
      "isOwner": (this.state.driver.selectedDriver === 'me'),
      "cpf": this.state.driver.otherCPF,
      "cnh": this.state.driver.otherCNH,
      "rg" : this.state.driver.otherRG, 
      "option1": this.state.selectedCar.option1,
      "option2":  this.state.selectedCar.option2,
      "startDate": new Date(this.state.loanDate.startDate.split('/')[1] + '/' + this.state.loanDate.startDate.split('/')[0] + '/' + this.state.loanDate.startDate.split('/')[2]).toISOString(),
      "endDate":  new Date(this.state.loanDate.endDate.split('/')[1] + '/' + this.state.loanDate.endDate.split('/')[0] + '/' + this.state.loanDate.endDate.split('/')[2]).toISOString(),
      "description": this.state.loanDate.loanAboutRequest
    }
    body["cnhPicture"]  = this.state.driver.imageCNH
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': JSON.parse(localStorage.getItem('user-data')).token
      },
      body: JSON.stringify(body)
    };
    this.setState({
      update: false
    }, () => {
      fetch(`${apiEndpoint()}lendingcontract/request`, { method: 'post', headers: options.headers, body: JSON.stringify(body) })
      .then(
        response => response.json(),
        error => alert('Error')
      )
      .then(result => {
        // if(result.success) {
          let resultMSG = (result.success) ? 'Sua solicitação de empréstimo de veículo foi enviada com sucesso! Aguarde nosso contato.' : 'Não foi possível envia a sua solicitação'
          let postResult = (result.success) ? true : false
          this.setState({
            update: true
          }, ()=> {
            this.setState({
              showModal: true,
              alertMessage: resultMSG,
              postResult: postResult
            })
          })  
        // }
      })  
    })  
  }

  handleModalCallback = () => {
    if(this.state.postResult) {
      this.props.history.push('/')
    }
  }

  render() {
    if (this.state.update) {
      return (
        <div className='vw-loanrequest mb-4'>
          <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / <a href='/home-comodato'>Empréstimo de Veículos</a>" />
          <div className="row mb-4">
            <div className="col-12 col-md-7">
              <PageTitle title="Solicitação de Veículos" />
            </div>
            <div className="col-12 col-md-3">
              {/* <StepWizard activeStep={this.state.activeStep} /> */}
            </div>
          </div>

          <div id="step1" className={`margin-bottom-50 ${this.state.activeStep == 0 ? '' : 'vw-display-none'}`}>
            <LoanRequestStep1 updateDriverValues={this.updateDriverValues} userData={this.state.userdata} />
          </div>

          <div id="step2" className={`margin-bottom-50 ${this.state.activeStep == 1 ? '' : 'vw-display-none'}`}>
            <LoanRequestStep2 loanCars={this.state.loanCars} selectLoanCar={this.selectLoanCar} />
          </div>

          <div id="step3" className={`margin-bottom-50 ${this.state.activeStep == 2 ? '' : 'vw-display-none'}`}>
            <LoanRequestStep3 updateLoanDateValues={this.updateLoanDateValues} userData={this.state.loanDate} />
          </div>

          <div className="row">
            <div className="col-12 text-left mb-4">
              <Button text={this.state.stepText} secondary={this.state.nextStep} onClick={this.goStep2} disabled={this.blockStep2()} hideIcon={true} />
            </div>
          </div>
          <AlertModal
            title1='Solicitação Enviada!'
            message={this.state.alertMessage}
            configModalCallback={ this.handleModalCallback }
            parentClass='loanRequest-holder'
            showModal={this.state.showModal}
          />
        </div>
      );
    } else {
      return (
        <Loading />
      )
    }

  }
}

function mapStateToProps(state) {
  return {
    loanCars: state.LoanRequestReducer.loanCars
  };
}

export default connect(mapStateToProps)(LoanRequest);
