/*
 * action types
 */

export const CONTATOS_HOME = 'CONTATOS_HOME'

/*
 * action creators
 */
export function getContacts(result) {
  return { 
    type: CONTATOS_HOME, 
    contacts: result
  }
}
