import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import './Speak.css';
import bg1 from "./img/imprensa@2x.png";
import bg2 from "./img/image2.png";
import bg3 from "./img/image1.png";
import bg4 from "./img/bg3@3x.png";
import bg5 from "./img/image4.png";
import bg6 from "./img/image3.png";
import PageTitle from "../PageTitle/PageTitle";

class Speak extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {mailto:xxxx@email.com

  //   }
  // }

  _sendMail = () => {
    var link = "mailto:propostas@volkswagen.com.br";
    window.location.href = link;
  }

  render() {

    return (
      <div className='Speak-container col-12'>
        <MediaQuery maxWidth={567}>
          {(matches) => {
            if (matches) {
              return <div className="row bgH" >
                <div className="col">
                  <PageTitle title="Contatos" />
                </div>
                <div className="w-100"></div>
                <br></br>
                <div className=" col-12 col-sm-12 align-self-center">
                  <Link to="/contacts">
                    <img src={bg4}></img>
                  </Link>
                </div>
                <div className="w-100"></div>
                <br></br>
                <div className=" col-12 col-sm-12  align-self-center">
                  <img src={bg5} onClick={this._sendMail}></img>
                </div>
                <div className="w-100"></div>
                <br></br>
                <div className="col-12 col-sm-12 align-self-center">
                  <Link to="/newsletter">
                    <img src={bg6}></img>
                  </Link>
                </div>
              </div>;
            } else {
              return <div className="row vw-speak" >
                <div className={"container  vw-bodys  " + (window.location.pathname == '/' || window.location.hash == '#/' ? 'container-fluid' : '')}>
                  <div className="col car-title">
                    <PageTitle title="Contatos" />
                  </div>
                  <div className="w-100"></div>

                  <div className="row justify-content-center">
                    <div className="col-12 offset-1 col-sm-4 offset-sm-0 col-md-4 col-lg-3 align-self-center">
                      <Link to="/contacts">
                        <img src={bg1}></img>
                      </Link>
                    </div>
                    <div className="col-12 offset-1 col-sm-4 offset-sm-0 col-md-4 col-lg-3 align-self-center">
                      <img src={bg2} onClick={this._sendMail}></img>
                    </div>
                    <div className="col-12 offset-1 col-sm-4 offset-sm-0 col-md-4 col-lg-3 align-self-center">
                      <Link to="/newsletter">
                        <img src={bg3}></img>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>;
            }
          }}
        </MediaQuery>
      </div>







    );
  }
}

export default Speak;