import React, { Component } from 'react';

import './PageSubTitle.css'; 

class PageSubTitle extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (      
      <div className={`vw-page-subtitle ${this.props.className}`}>
        {this.props.text}
      </div>
    );    
  }
}

export default PageSubTitle;
