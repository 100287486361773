import React, { Component } from 'react';
import './LoanRequestStep3.css';
import { Card, CardBody, CardTitle } from 'reactstrap';
import TextBody from '../../../components/TextBody/TextBody';
import ReactGA from 'react-ga';
import InputMask from 'react-input-mask';
import Imprint from '../../Termos/Imprint/Imprint';
import { Modal, ModalBody } from 'reactstrap';
import  TextArea  from '../../../components/TextArea/TextArea';


class LoanRequestStep3 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      update: false,
      loanDate: {
        startDate: '',
        endDate: '',
        acceptTerms: false,
        isValidate: false,
        loanAboutRequest: ''
      },
      termsIsOpen: false,
      fieldsError: [],
      wrongDateMessage: ''
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        update: true
      });
    }, 200);
  }

  inputIsFilled = (input) => {
    return this.state.loanDate[input].length > 0 ? 'input-filled' : ''
  }

  _clickCheckLabel = (e, input) => {
    if(this.state.loanDate.loanAboutRequest.length > 10 &&
        !this._checkDaysInterval(this.state.loanDate.startDate, this.state.loanDate.endDate) && 
        !this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate)) {
          this.changeInput(e, input)
        }
  }

  changeInput = (e, input) => {
    console.log('entrada', e)
    this.state.loanDate[input] = e
    var error = []
    this.setState({}, () => {
      if (input != 'acceptTerms' && input != 'loanAboutRequest') {
        let date = this.state.loanDate[input].replace(/_/g, '')
        if (date.length > 9) {
          if (!this._checkDates(input)) {
            let errorMsg 
            if(input == 'startDate') {
              errorMsg = 'Data início inválida'
            }
            error.push(input)
            this.setState({
              fieldsError: [...error],
              wrongStartDateMessage: errorMsg
            }, () => {
              console.log(this.state.loanDate.startDate, this.state.loanDate.endDate, this._checkDaysInterval(this.state.loanDate.startDate, this.state.loanDate.endDate))
              return
            })
          } else {
            if(input == 'startDate') {
              let date = new Date().toISOString().split('T')[0]
              let newDate = date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
              let errorMsg = 'Data início superior a 60 dias'
              console.log(newDate, this._checkDaysInterval(newDate, this.state.loanDate.startDate, 60))
              if(this._checkDaysInterval(newDate, this.state.loanDate.startDate, 60)) {
                error.push('startDate')
                this.setState({
                  fieldsError: [...error],
                  wrongStartDateMessage: errorMsg,
                  isValidate: false,
                  wrongStartDate: true
                })
              } else {
                this.state.fieldsError.splice(this.state.fieldsError.indexOf('startDate'), 1)
                if(!this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate) || this._checkDaysInterval(newDate, this.state.loanDate.startDate, 7)) {
                  error.push('endDate')
                  this.state.fieldsError = [...error]
                  this.state.wrongEndDate = true
                  this.state.wrongDateMessage = this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate) ? 'Data fim inferior a data início' : 'Período superior a 7 dias'
                }
                this.setState({
                  isValidate: true
                })
                console.log('else no error')
              }
              
            }
            console.log(this.state.fieldsError)
            let errorEnd = []
            if(this.state.fieldsError.indexOf('startDate') > -1) {
              errorEnd.push('startDate')
            }
            if(input == 'endDate' && this.state.loanDate.endDate) {
              this.state.fieldsError.splice(this.state.fieldsError.indexOf(input), 1)
              console.log(errorEnd)
              this.setState({}, ()=> {
                let errorMsg = this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate) ? 'Data fim inferior a data início' : 'Período superior a 7 dias'
                if(this._checkDaysInterval(this.state.loanDate.startDate, this.state.loanDate.endDate) || this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate)) {
                  errorEnd.push('endDate')
                  this.setState({
                    fieldsError: [...errorEnd],
                    wrongEndDate: true,
                    wrongDateMessage: errorMsg,
                    isValidate: false
                  })
                } else {
                  console.log(this.state.fieldsError)
                  this.state.fieldsError.splice(this.state.fieldsError.indexOf('endDate'), 1)
                  this.setState({
                    fieldsError: [...errorEnd],
                    isValidate: this.state.fieldsError.indexOf('startDate') > -1 ? false : true
                  })
                }
                console.log(this.state.loanDate.startDate, this.state.loanDate.endDate, this._checkDaysInterval(this.state.loanDate.startDate, this.state.loanDate.endDate))
              })
            }
          }
        }
      } else {
        if(input == 'loanAboutRequest' && e.length < 10 || this.state.fieldsError.length > 0) {
           this.state.loanDate.acceptTerms = false
        }
        this.setState({}, () => {

          let isValidate = (this.state.loanDate.acceptTerms == true && this.state.loanDate.loanAboutRequest.length > 10) ? true : false

          this.setState({
            isValidate: isValidate
          }, () => {
            this.props.updateLoanDateValues(this.state.loanDate)
          })

        })
      }
    })
  }

  _openLegalTerms = () => {
    this.setState({
      termsIsOpen: !this.state.termsIsOpen
    })
  }

  _checkDaysInterval = (start, end, daysInterval) => {
    let validDate, days = daysInterval ? daysInterval : 7;
    let startTest = new Date(start);
    let dateStart = startTest =! 'InvalidDate' ? startTest : new Date(start.split('/')[2] + '/' + (start.split('/')[1]) + '/' + start.split('/')[0]);
    let date = end.replace(/_/g, '')
    let dateEnd = date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0]
    let res = dateStart.setTime(dateStart.getTime() + (days * 24 * 60 * 60 * 1000)); 
    validDate = new Date(dateEnd) > new Date(res)
    return validDate
  }

  _checkEndDate = (start, end) => {
    if(!end) return true
    let validDate;
    let startTest = new Date(start);
    let dateStart = startTest =! 'InvalidDate' ? startTest : new Date(start.split('/')[2] + '/' + (start.split('/')[1]) + '/' + start.split('/')[0]);
    let date = end.replace(/_/g, '')
    let dateEnd = new Date(date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0])
    validDate = dateStart.getTime() > dateEnd.getTime()
    return validDate
  }

  _checkDates = (input) => {
    let days = 7;
    if (!this.state.loanDate[input]) return false
    let date = this.state.loanDate[input].replace(/_/g, '')
    try {
      let newDate = date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0]
      let today = new Date() 
      let validDate = new Date(newDate) >= today.setTime(today.getTime() - (1 * 24 * 60 * 60 * 1000)); 
      return validDate
    } catch (e) {
      console.log('erro data', e)
      return false
    }
  }

  render() {
    return (
      <div className='vw-loanRequestStep3'>
        <div className="row">
          <div className='col-12 mt-4 mb-4'>
            <TextBody text="Para finalizar sua solicitação preencha os dados abaixo" />
          </div>
        </div>
        <div className={`row mt-4 mb-4 vw-floating-label`}>
          <div className="col-6 col-md-3">
            <div className={`form-label-group ${(this.state.fieldsError.indexOf('startDate') > -1 ? ' input-error ' : ' ')}`}>
              <InputMask
                className={`form-control ${this.inputIsFilled('startDate')}`}
                placeholder='Data início'
                onChange={e => this.changeInput(e.target.value, 'startDate')}
                value={this.state.startDate}
                mask={"**/**/****"}
                type="text" id="loan-startDate" ref="loan-startDate">
              </InputMask>
              <label htmlFor='loan-startDate'>
                Data de Início:
                    </label>
            </div>
            {this.state.wrongStartDate && this.state.fieldsError.indexOf('startDate') > -1 ? <p className='input-error-msg'>{this.state.wrongStartDateMessage}</p> : null }
          </div>
          <div className="col-6 col-md-3">
            <div className={`form-label-group ${(this.state.fieldsError.indexOf('endDate') > -1 ? ' input-error ' : ' ')}`}>
              <InputMask
                className={`form-control ${this.inputIsFilled('endDate')} `}
                placeholder='Data Fim'
                onChange={e => this.changeInput(e.target.value, 'endDate')}
                value={this.state.endDate}
                mask={"**/**/****"}
                type="text" id="loan-endDate" ref="loan-endDate">
              </InputMask>
              <label htmlFor='loan-endDate'>
                Data Fim:
              </label>
            </div>
            {this.state.wrongEndDate && this.state.fieldsError.indexOf('endDate') > -1 ? <p className='input-error-msg'>{this.state.wrongDateMessage}</p> : null }
          </div>
          <div className="col-12 col-md-12">
            <div>
              <br></br>
              <p><b>Por favor nos explique sua necessidade para este carro*:</b></p>
              <TextArea cols={"5"} maxlength={"400"} 
                        placeholder={"Digite aqui suas necessidades..."} value={this.state.loanDate.loanAboutRequest}
                        onChange={e => this.changeInput(e.target.value, 'loanAboutRequest')}></TextArea>
              <p className='disclaimer-textarea'>*Quantidade mínima: 10 caracteres</p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-12">
            <Card>
              <CardBody className={`${(this.state.fieldsError.length > 0 || this.state.loanDate.loanAboutRequest.length < 10 || !this._checkDates('startDate') || !this._checkDates('endDate') ? ' disabled ': '  ')} pl-0`}>
                <div class="divInputCheck" onClick={e => this._clickCheckLabel(!this.state.loanDate.acceptTerms, 'acceptTerms')} >
                  <input disabled={this.state.fieldsError.length > 0 || this.state.loanDate.loanAboutRequest.length < 10 || this._checkDaysInterval(this.state.loanDate.startDate, this.state.loanDate.endDate) || this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate)} className='inputCheckForm' type="checkbox" checked={this.state.loanDate.acceptTerms} value={this.state.loanDate.acceptTerms} />
                  <label className='card-title'>Aceitar os termos e condições de uso</label>
                </div>
              </CardBody>
              <p className='card-value'><span className='card-txt-value'>Ao aceitar os termos você esta concordando com as políticas de uso da Volkswagen Brasil.</span></p>
              <div>
                <button className="btnAtualizar" onClick={e => this._openLegalTerms(e)}>Visualizar <span>  -></span></button>
              </div>
            </Card>
          </div>
        </div>

        <Modal size="lg" isOpen={this.state.termsIsOpen} toggle={this._openLegalTerms}>
          <ModalBody className={`confirmationModal TermsModal`}>
            <button className="close closeModal" onClick={this._openLegalTerms}>&times;</button>
            <div className='content'>
              <Imprint hideBreadcrumb={true} />
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default LoanRequestStep3;