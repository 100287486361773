const initialState = {
  emobility: {},
  news: [],
  gallery: []
}

const EMobilityReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'EMOBILITY_HOME':
      state = initialState;
      return {
        emobility: {...state.emobility, ...action.emobility},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default EMobilityReducer;