import React, { Component } from 'react';
import { connect } from 'react-redux';
import sendCartValues, { getCartList, getCartSize } from './Cart.actions';
import PubSub from 'pubsub-js'
import { generatePDF } from '../../components/HtmlToPdf/HtmlToPdf';


import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import ReactGA from 'react-ga';

import './Cart.css'; 
import NewsComponentDate from '../../components/NewsComponent/NewsComponentDate/NewsComponentDate';
import NewsComponentTitle from '../../components/NewsComponent/NewsComponentTitle/NewsComponentTitle';
import ShortImage from '../../components/ShortImage/ShortImage';
import Button from '../../components/Button/Button';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import HighlightMedia from '../../components/HighlightMedia/HighlightMedia';

let { apiEndpoint } = require('../../config/constants');
let { galleryLatestPhotos } = require('../../config/constants');


class Cart extends Component {

  constructor(props) {
    super(props);
    this.update = false;  
    
    this.state = {
      cartList: props.cartList,
      openDetails: '',
      modalConfirmationOpenDownload: false,
      emailValue: ''
    }

    this.objectToDownload = []
  }

  componentDidMount() {   
    ReactGA.pageview('/carrinho');
    this.update = true; 
    let cartData = localStorage.getItem('favorites') ? localStorage.getItem('favorites') : [];
    this.props.dispatch(getCartList(cartData));
    
  }  

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps)
    this.setState({
      cartList: nextProps.cartList
    }, () => {
      this.mountDownloadObj()
      //get download size
      this.getCartSize()
    })
  }

  getCartSize() {
    let headers = { 'Content-Type':'application/json' }
    let body = {
      email: '',
      cart: this.objectToDownload
    }
    fetch(`${apiEndpoint()}cartfiles/size`, { method: 'post', headers: headers, body: JSON.stringify(body) })
    .then(
      response => response.json(),
      error => alert('Error')
    )
    .then(result => {
      console.log(result)
      this.setState({
        cartSize: result.size
      })
    })    
  }

  mountDownloadObj() {
    this.state.cartList.forEach((item) => {
      console.log(item)
      let obj = {
        'title': item.name,
        'list': item.contentToDownload.length < 1 ? [item.imageLink] : item.contentToDownload
      }
      this.objectToDownload.push(obj)
    })
  }

  isMobile() {
    if(document.getElementById("root").offsetWidth < 768) {
      return true
    } else {
      return false
    }
  }

  _renderImage(media) {
    console.log(media)
    if(!media) return null;
    return(
      
        <HighlightMedia 
          media={media}
          mediaLow={media}
          mediaMedium={media}
          modalOpen={this.state.modalOpen}
          activeMedia={this.state.activeMedia}
          isAutoPlay={false}
          pDownload={true}
          toggleModal={this.toggleModal.bind(this)}/>                

    )
  }

  toggleModal() {
    console.log('modal CLOSE/OPEN')
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  _renderText(date, label, title) {
    return(
      <div>
        { date && label ? <NewsComponentDate date={ date } label={ label }/> : null }
        <NewsComponentTitle title={ title }/>
      </div>
    )
  }

  _buildCheckAmnount(index, object, label, items) {
    return(
      <div className="form-check">
        <input className="form-check-input" defaultChecked onChange={e => this._objectsToDownload(object, 'checkbox', `defaultCheck1${index}`, items) } type="checkbox" name={`defaultCheck1${index}`} ref={`defaultCheck1${index}`} id={`defaultCheck1${index}`}/>
        <label className="form-check-label" htmlFor={`defaultCheck1${index}`}>
          { `${label}(${object.length}) `} 
        </label>
      </div>
    )
  }
  _renderMediaAmount(index, object) {
    // console.log(object)
    if((object.mediaAmount == 0 || !object.mediaAmount || !object.mediaAmount) && object.contentToDownload.length == 0) return null;
    let images, attach, videos
    if(object.contentToDownload.length > 0) {
      images = object.contentToDownload.filter((image)=> image.indexOf('.jpg') > -1 ||  image.indexOf('.jpeg') > -1 || image.indexOf('.png') > -1 )
      attach = object.contentToDownload.filter((image)=> image.indexOf('.pdf') > -1 || image.indexOf('PDF') > -1)
      videos = object.contentToDownload.filter((image)=> image.indexOf('.mp4') > -1)
    }

    return(
      <div>
        { images.length > 0 ?
          this._buildCheckAmnount(index, images, 'Imagens', object) 
        : null } 
        { videos.length > 0 ?
          this._buildCheckAmnount('video'+ index, videos, 'Videos', object) 
        : null } 
        { attach.length > 0 ?
          this._buildCheckAmnount('attach'+ index, attach, 'Anexos', object) 
        : null } 
      </div>
    )
  }

  _renderMediaQuality(index, object) {
    if((object.mediaAmount == 0 || !object.mediaAmount) && (object.type == 'Video' || object.type == 'Anexo')) return null;
    return(
      <div>
        <div className="form-check">
          <input className="form-check-input" onChange={e => this._objectsToDownload(object, 'radio', `checkNormalSize${index}`) } ref={`checkNormalSize${index}`} name={`radioMediaSize${index}`} defaultChecked type="radio" value="normal" id={`checkNormalSize${index}`}/>
          <label className="form-check-label" htmlFor={`checkNormalSize${index}`}>
            Grande
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" onChange={e => this._objectsToDownload(object,  'radio', `checkMediumSize${index}`) } ref={`checkMediumSize${index}`} name={`radioMediaSize${index}`} type="radio" value="medium" id={`checkMediumSize${index}`}/>
          <label className="form-check-label" htmlFor={`checkMediumSize${index}`}>
            Média
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" onChange={e => this._objectsToDownload(object, 'radio', `checkLowSize${index}`) } ref={`checkLowSize${index}`} name={`radioMediaSize${index}`} type="radio" value="low" id={`checkLowSize${index}`}/>
          <label className="form-check-label" htmlFor={`checkLowSize${index}`}>
            Pequena
          </label>
        </div>
      </div>
    )
  }

  _renderEmptyCart() {
    return(
      <div>
        <div className='col-12 mb-4'>Favoritos está vazio</div>
        <div className='col-12 text-center'>
          <Button onClick={ this._goBack.bind(this)} text='Voltar' hideIcon='true'></Button>
        </div>
      </div>
    )
  }

  _renderAlreadySendedCart() {
    if(!this.state.emailValue) return
    return(
      <div className='col-12'>
        <div className='col-12 mb-4'>Dentro de alguns minutos você receberá o link para download no seu email.</div>
        <div className='col-12 text-center mt-5 mb-5'>
          <Button onClick={ this._goBackHome.bind(this)} text='Voltar para página inicial' hideIcon='true'></Button>
        </div>
      </div>
    )
  }

  _deleteObject(object) {
    if(window.confirm('Apagar esse objeto dos favoritos?')) {
      var savedFav = JSON.parse(window.localStorage.getItem('favorites'))
      if(!savedFav) return
      // var isSaved = savedFav.filter((item) => item.imageLink == imageLink)[0]
      var isSaved
      if(object.type != 'Imagem' && object.type != 'Video' && object.type != 'Anexo') {
        isSaved = savedFav.filter((item) => item.name == object.name)[0]
      } else {
        isSaved = savedFav.filter((item) => item.imageLink == object.imageLink)[0]
      }
      if(!isSaved) return
      savedFav.splice(savedFav.indexOf(isSaved), 1)
      this.objectToDownload.splice(savedFav.indexOf(isSaved), 1)
      window.localStorage.setItem('favorites', JSON.stringify(savedFav))
      this.setState({
        cartList: savedFav,
        modalOpen: false
      })
    }
  }

  _deleteAllObjects() {
    if(window.confirm('Apagar todos objetos dos favoritos?')) {
      var savedFav = JSON.parse(window.localStorage.getItem('favorites'))
      if(!savedFav) return
      savedFav = []
      window.localStorage.removeItem('favorites')
      this.objectToDownload = []
      this.setState({
        cartList: savedFav,
        modalOpen: false
      })
    }
  }

  openMoreObjects(object, index) {
    console.log('openMoreObjects', object)
    let activeObj = this.state.cartList.filter((itemCart) => itemCart.name === object.name && itemCart.imageLink === object.imageLink)[0]
    this.setState({
      activeIndex: index,
      activeObj: activeObj,
      openDetails: 'openDetails'
    })
  }

  openDetails(object) {
    console.log('opendetails', object)
    if(this.state.openDetails === 'openDetails') {
      console.log('state opendetails')
      if(this.state.activeObj.name === object.name && this.state.activeObj.imageLink === object.imageLink) {
        console.log('name e imagelink')
        return 'openDetails'
      }
    }

    return false
  }

  _closeDetails() {
    this.setState({
      openDetails: ''
    })
  }

  _objectsToDownload = (object, type, ref, items) => {
    console.log('is equal', items)
    let name = items ? items.name : object.name
    this.objectToDownload.map((item) => {
      if(item.title == name) {
       
        if(type == 'radio') {
          item.list = item.list.map((images) => {
            if(images.indexOf('pdf') < 0 && images.indexOf('PDF') < 0 && images.indexOf('mp4') < 0) {
              
              let replaceValue = (this.refs[ref].value != 'normal') ? '_' + this.refs[ref].value : ''
              if(images.indexOf('low') > -1) {
                console.log('is low', replaceValue)
                console.log(images)
                return images.replace('_low', replaceValue)
              } 
              if(images.indexOf('medium') > -1) {
                console.log('is medium', replaceValue, images)
                console.log(images)
                return images.replace('_medium', replaceValue)
              } 
              if(images.indexOf('medium') < 0 && images.indexOf('low') < 0) {
                console.log('is normal', replaceValue)
                let imageName = images.substring(images.lastIndexOf('/'), images.lastIndexOf('.'))
                console.log(imageName)
                return images.replace(imageName, imageName + replaceValue)
              }
            } else {
              return images
            }
          })
        } else if(type == 'checkbox') {
          if(!this.refs[ref].checked) {
            item.list = item.list.filter((images) => {
              return images.indexOf('pdf') > -1 
            })
            if(this.refs[ref].id.indexOf('attach') < 0 && this.refs[ref].id.indexOf('video') < 0) {
              this._enableDisableImageSize(ref, true)
            }
          } else {
            item.list = this.state.cartList.filter((originalItem) => {
              return originalItem.name == item.title 
            })[0].contentToDownload
            if(this.refs[ref].id.indexOf('attach') < 0 && this.refs[ref].id.indexOf('video') < 0) {
              this._enableDisableImageSize(ref, false)
            }
          }
        }
      }
    })

    this.getCartSize()
  }

  _enableDisableImageSize(radio, toggle) {
    //disbled image size check
    let radioSize = radio.split('defaultCheck1')[1]
    this.refs['checkNormalSize' + radioSize].disabled = toggle
    this.refs['checkMediumSize' + radioSize].disabled = toggle
    this.refs['checkLowSize' + radioSize].disabled = toggle
  }

  _goBack() {
    PubSub.publish('HIDE_CART_PREVIEW', 'close')
    window.history.back()
  }
  _goBackHome() {
    PubSub.publish('HIDE_CART_PREVIEW', 'close')
    // window.location.href = '/'
    this.props.history.push('/')
  }

  _checkEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)) {
        return true;
    } else {
        return false;
    }
  }

  _getPDFUrl = (pdfObj) => {
    // 
    this.setState({
      loadingPDFURL: false
    }, ()=> {
      alert(JSON.stringify(pdfObj))
    })
  }

  _convertPDFLinks = () => {
    return new Promise((resolve, reject) => {
      this.objectToDownload.forEach((cartList) => {
        cartList.list.map((item) => {
          if(item.indexOf('/PDF') > -1) {
            generatePDF(item, cartList.title, true, this._getPDFUrl)
            this.setState({
              loadingPDFURL: true
            })
          }
        })
      })
    })
  }

  toggleConfirmationModalDownload = (email) => {
    this.setState({
      emailValue: !this._checkEmail(email) ? '' : email,
      modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
    }, () => {
      if(this._checkEmail(email)) {
        this.sendObjectToDownload()
        // this._convertPDFLinks().then(() => {
        //   // this.sendObjectToDownload()
        // })
      }
    })
  }

  downloadCart() {
    this.setState({
      modalConfirmationOpenDownload: true
    })
  }

  sendObjectToDownload () {
    let body = {
      email: this.state.emailValue,
      cart: this.objectToDownload
    }
    this.props.dispatch(sendCartValues(`${apiEndpoint()}cartfiles`, body))

    this.setState({
      alreadySent: true
    }, ()=> {
      localStorage.setItem('favorites', [])
    })
  }

  showCartItem = (object, e) => {
    if(object.type === 'Imagem') {
      this.setState({
        modalOpen: true,
        activeMedia: object.imageLink
      })
    } else {
      this.props.history.push(object.contentLink)
    }
  }

  _renderMobileCartDetail = () => {
    if(this.state.activeObj) {
      return (<div className={`objectDetails ${this.state.openDetails}`}>
      <div className='row mb-4'>
        {  this.state.activeObj.type != 'Imagem' ?
          <div className='col-12 border-bottom'>
            <p className='details-title'>Media</p>
            { this._renderMediaAmount(this.state.activeIndex, this.state.activeObj)}
          </div>
        : null }
        { this.state.activeObj.imageLink.indexOf('png') > -1 ||  this.state.activeObj.imageLink.indexOf('jpg') > -1 || this.state.activeObj.imageLink.indexOf('jpeg') > -1 || this.state.activeObj.imageLink.indexOf('JPG') > -1 ? 
          <div className='col-12'>
            <p className='details-title mt-4'>Quality</p>
            { this._renderMediaQuality(this.state.activeIndex, this.state.activeObj)}
          </div>
        : null }
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <Button onClick={ this._closeDetails.bind(this)} text='Salvar'></Button>
        </div>
      </div>
    </div>)
    }
    
  }

  render() {
    if (this.update) {      
      return (  
        <div> 
            <div className="row mb-5">
              <div className="col-12">
                <Breadcrumb text="<a href='/'>Newsroom</a> / Favoritos" />
                <PageTitle title="Favoritos"/>             
              </div >

              {!this.state.alreadySent ? 
              <div className='col-12'>
                
                { !this.isMobile() ? 
                  <table className="cart-table ">
                    <thead>
                      <tr>
                        <th>Conteúdo</th>
                        <th>Tipo</th>
                        <th>Media</th>
                        <th>Qualidade</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.cartList.length > 0 ? 
                          this.state.cartList.map((object, index) => {
                            return(
                              <tr key={`object-${object.name}`}>
                                <td className='cart-col-object' onClick={e => this.showCartItem(object, e)}>
                                  <div className='row'>
                                    <div className='col-4 col-image'>{ this._renderImage(object.imageLink) }</div>
                                    <div className='col-8'>{ this._renderText(null, null, object.name) }</div>
                                  </div>
                                </td>

                                <td className='cart-col-type'> 
                                  { object.type === 'Press Release' ? 'Notícia' : object.type }
                                </td>

                                <td className='cart-col-media'>
                                  { this._renderMediaAmount(index, object)}
                                </td>

                                <td className='cart-col-quality'>
                                  { this._renderMediaQuality(index, object)}
                                </td>

                                <td className='cart-col-delete'>
                                  <a className="cart-contents--footer-action" onClick={ this._deleteObject.bind(this, object)} title="Clear cart">
                                    <i className="icon brand-icon is-remove is-1x is-middle has-text"></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          }) 
                      : 
                        <tr>
                          <td className='empty-cart' colSpan='5'>
                            { this._renderEmptyCart() }
                          </td>
                        </tr>
                      }
                      
                    </tbody>
                  </table>

                : 
                  <div className='cart-content-mobile'>
                    <div className='row'>
                      
                        <div className={'col-12 object-title ' + (this.state.cartList.length < 1 ? 'd-none' : '' )}>
                          Conteúdos
                        </div>
                        { this.state.cartList.length > 0 ? 
                          this.state.cartList.map((object, index) => {
                            return(
                              <div className='row row-content'>
                                <div className='col-4 col-image'  onClick={e => this.showCartItem(object, e)}>
                                  { this._renderImage(object.imageLink) }
                                </div>
                                <div className='col-8' onClick={e => this.showCartItem(object, e)}>
                                  { this._renderText(null, null, object.name) }
                                </div>
                                <div className='col-12 text-right'>
                                    {(object.type != 'Video' && object.type != 'Anexo') ? 
                                      <a className="cart-contents--footer-action" onClick={ this.openMoreObjects.bind(this, object, index)} title="Ver Mais">
                                        <i className="icon brand-icon is-more-objects is-1x is-middle"></i>
                                      </a>
                                    : null }
                                    
                                    <a className="cart-contents--footer-action" onClick={ this._deleteObject.bind(this, object)} title="Limpar favoritos">
                                      <i className="icon brand-icon is-remove is-1x is-middle"></i>
                                    </a>
                                </div>
                              </div>
                            )
                          })
                    
                        :
                          <div className='w-100'>
                            { this._renderEmptyCart() }
                          </div>
                        }
                      
                    </div>
                  </div>
                }

                <div className={"cart-contents--footer " + (this.state.cartList.length < 1 ? 'd-none' : '' )}>
                  <div className="cart-contents--footer-actions">
                    <div className="cart-contents--footer-actions-left">
                      <a className="cart-contents--footer-action is-navigating-back" href="javascript:history.back()" title="Voltar" rel="noopener">
                        <i className="icon brand-icon is-go-back is-1x is-middle has-text"></i>Voltar
                      </a>
                      <a className="cart-contents--footer-action" onClick={this._deleteAllObjects.bind(this)} title="Limpar favoritos">
                        <i className="icon brand-icon is-remove is-1x is-middle has-text"></i>Limpar favoritos
                      </a>
                    </div>
                    <div className="cart-contents--footer-actions-right">
                      <div className="cart-contents--size">Tamanho do arquivo: {this.state.cartSize}
                        <button className="btn-primary" onClick={this.downloadCart.bind(this)} href="#">
                          <i className="icon brand-icon is-download is-1x has-text"></i>
                          <span>Download</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  <div className="cart-contents--legend">
                    <div className="cart-contents--legend-media-qualities">
                      <b>Qualidade da imagem:</b>
                      <br className="visible-xs"/> pequena = aprox. 1024 x 683, média = aprox. 1795 x 1197, grande = aprox. 4096 x 2731
                    </div>
                    <div className="cart-contents--legend-media-qualities">
                      <b>Qualidade do Video:</b>
                      <br className="visible-xs"/> médio = MP4, high = MOV
                    </div>
                  </div>
                </div>
              </div>
            : 
              this._renderAlreadySendedCart()
            }  
          </div>  

          { this._renderMobileCartDetail() }

          <ConfirmationModal className="img-download-bt"
              isOpen={ this.state.modalConfirmationOpenDownload}
              toggle={e => this.toggleConfirmationModalDownload(e)}
              title={'Download dos favoritos'}
              downloadButtonMessage={'Solicitar link para download'}
              body={'Insira seu email no campo abaixo. Enviaremos um email com o link para download do material.'}
              type='download-cart'
          />   

          { this.state.loadingPDFURL ? <Loading /> : null }
        </div>
      );       
    } else {
      return (
        <Loading />
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    cartList: state.CartReducer.cartList
  };
}

export default connect(mapStateToProps)(Cart);