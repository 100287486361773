import React, { Component } from 'react';
import './ApprovalBackofficeDetail.css'
import { Modal, ModalBody } from 'reactstrap';
import ApprovalCard from '../ApprovalCard/ApprovalCard';
import RadioMultiline from '../../RadioMultiline/RadioMultiline';
import InputMask from 'react-input-mask';
import Button from '../../Button/Button';
import { fetchValuesOptions, fetchValuesAuth } from '../../../shared/http-request-util';
import Loading from '../../Loading/Loading';
import { withRouter } from 'react-router-dom';
import AlertModal from '../../AlertModal/AlertModal';
import  TextArea  from '../../../components/TextArea/TextArea';

let { listApprovation, listApprovalsAPI, listRejection } = require('../../../config/constants');

class ApprovalBackofficeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDateFleetInfo: '',
      loanDate: {
        startDate: '',
        endDate: '',
      },
      status : 0,
      fieldsError: [],
      filledInputs: [],
      agent: {
        tier: ''
      },
      email_agent : '',
      description : '',
      frotaInput: '',
      carFrotumId: props.approvalItem.car_frotum_id,
      frotasList: props.frotasList ? props.frotasList : [],
      cnhPicture: props.approvalItem.cnhPicture ? props.approvalItem.cnhPicture : ''
    }
    this.agent = {};
    this.approvalData = [];
    this.subjectData = {
      title: 'Pauta',
      data: []
    };
    this.isBlocked = false;
    this.selecedCars = []

    this.vehicles = []

    this.selectedData = {
      title: '',
      data: []
    }
  }

  componentWillReceiveProps(nextProps) {
    let data = nextProps.approvalItem.filter((item) => item.id == nextProps.selectedLoan)[0];
    this.state.loanDate.startDate = this._convertData(data.startDate)
    this.state.loanDate.endDate = this._convertData(data.endDate)
    this.setState({
      loanId: data.id,
      loanData: data,
      description : [{ 'name': '', 'value': data.description }],
      status : data.status,
      email_agent : data.email_agent,
      agent: {
        tier: data.user.tier ? data.user.tier : '',
      },
      carFrotumId: data.car_frotum_id,
      cnhPicture: data.cnhPicture ? data.cnhPicture : ''
    })
    this.agent = {
      name: data.user.name,
      tier: data.user.tier,
      midiaType: data.user.midia_type
    }
    this.approvalData = [
      {
        title: 'Dados Pessoais',
        data: [{
          name: "RG",
          value: data.user.rg,
        }, {
          name: "CNH",
          value: data.user.cnh
        }, {
          name: "CPF",
          value: data.user.cpf
        }, {
          name: "Nome da Mídia",
          value: data.user.midia_name
        }]
      }, {
        title: 'Localização',
        data: [{
          name: "País",
          value: data.user.country,
        }, {
          name: "Estado",
          value: data.user.state
        }, {
          name: "Cidade",
          value: data.user.city
        }, {
          name: "Endereço",
          value: data.user.address
        }]
      }, {
        title: 'Contato',
        data: [{
          name: "E-mail",
          value: data.user.email
        }, {
          name: "Telefone",
          value: data.user.phone1
        }, {
          name: "Telefone",
          value: data.user.phone2
        }]
      }
    ];
    this.description = [{ 'name': '', 'value': data.description }]
    this.isBlocked = !data.user.isAllowed;
    this.deniedReason = data.user.deniedReason;
    this.selecedCars = [{
      id: data.option1.id,
      name: data.option1.title,
      itens: data.option1.car_frota
    }, {
      id: data.option2.id,
      name: data.option2.title,
      itens: data.option2.car_frota
    }];
  }

  _convertData = (pDate, toISODate) => {
    if (!pDate) return
    var date = new Date(pDate);
    if (!toISODate) {
      var day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate()
      var month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      return (day + '/' + month + '/' + date.getFullYear());
    } else {
      return date.toISOString()
    }
  }

  _checkEndDate = (start, end) => {
    if (!end) return true
    let validDate;
    let startTest = new Date(start);
    let dateStart = startTest = ! 'InvalidDate' ? startTest : new Date(start.split('/')[2] + '/' + (start.split('/')[1]) + '/' + start.split('/')[0]);
    let date = end.replace(/_/g, '')
    let dateEnd = new Date(date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0])
    validDate = dateStart.getTime() > dateEnd.getTime()
    return validDate
  }

  _checkDates = (input) => {
    let days;
    if (!this.state.loanDate[input]) return false
    let date = this.state.loanDate[input].replace(/_/g, '')
    try {
      let newDate = date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0]
      let today;
      if (input == 'startDate') {
        today = new Date()
        days = 1
      } else {
        today = new Date(this.state.loanDate.startDate.split('/')[2] + '/' + (this.state.loanDate.startDate.split('/')[1]) + '/' + this.state.loanDate.startDate.split('/')[0])
        days = 0
      }
      let validDate = new Date(newDate) >= today.setTime(today.getTime() - (days * 24 * 60 * 60 * 1000));
      return validDate
    } catch (e) {
      console.log('erro data', e)
      return false
    }
  }

  inputIsFilled = (input) => {
    return this.state.loanDate[input].length > 0 ? 'input-filled' : ''
  }

  changeInput = (e, input) => {

    if (input === 'frota') {
      let vehicle = this.vehicles[e];
      console.log(vehicle)
      this.selectedData = {
        title: vehicle.car.title,
        data: [
          [{
            name: "Periodo",
            value: this.state.loanDate.startDate + ' até ' + this.state.loanDate.endDate,
          }, {
            name: "Status Agenda",
            value: this.getStatus(this.state.loanData.status)
          }],
          [{
            name: "Versão",
            value: vehicle.car_version.title
          }, {
            name: "Ano",
            value: vehicle.model_year
          }],
          [{
            name: "Cor",
            value: vehicle.color
          }, {
            name: "Frota",
            value: vehicle.frota
          }],
          [{
            name: "Localização",
            value: vehicle.vehicle_location
          }],
          [{
            name: 'Considerações',
            value: vehicle.vehicle_status
          }]
        ]
      }


      let carFrotumDetail_1 = this.state.loanData.option1.car_frota.filter((item) => item.frota == vehicle.frota)
      let carFrotumDetail_2 = [...this.state.loanData.option2.car_frota.filter((item) => item.frota == vehicle.frota)]
      let car_frotum_id;

      if (carFrotumDetail_1.length > 0) {
        car_frotum_id = carFrotumDetail_1[0].id
      }

      if (carFrotumDetail_2.length > 0) {
        car_frotum_id = carFrotumDetail_2[0].id
      }

      this.state.carFrotumId = car_frotum_id
    }

    if (input == 'frotaInput') {
      this.state[input] = e
      if (e.length > 0) {
        let filled = [input]
        this.state.filledInputs = [...filled]
      } else {
        this.state.filledInputs.splice(this.state.filledInputs.indexOf(input), 1)
      }
    } else {
      this.state.loanDate[input] = e
    }

    if (input == 'email_agent') {
      this.state.email_agent = e;
    }
    if (input == 'description') {
      this.state.description = [{ 'name': '', 'value': e }];
    }
    var error = []
    this.setState({}, () => {
      if (input == 'startDate' || input == 'endDate') {
        let date = this.state.loanDate[input].replace(/_/g, '')
        if (date.length > 9) {
          if (!this._checkDates(input)) {
            error.push(input)
            this.setState({
              fieldsError: [...error]
            }, () => {
              return
            })
          } else {
            this.state.fieldsError.splice(this.state.fieldsError.indexOf(input), 1)

            if (this.state.loanDate.endDate) {
              this.setState({}, () => {
                if (this._checkEndDate(this.state.loanDate.startDate, this.state.loanDate.endDate)) {
                  error.push('endDate')
                  this.setState({
                    fieldsError: [...error],
                    isValidate: false
                  })
                } else {
                  this.state.fieldsError.splice(this.state.fieldsError.indexOf('endDate'), 1)
                  this.setState({
                    isValidate: true
                  })
                }
                console.log(this.state.loanDate.startDate, this.state.loanDate.endDate)
              })
            }
            // this.setState({})
          }
        }
      }
    })
  }

  _onChangeRadio = (e) => {
    this.selectedData = {
      title: '',
      data: []
    }
    this.state.showDateFleetInfo = `info-${e.target.value}`
    this.setState({})
  }

  _createFrotaOptions(itens) {
    this.vehicles = itens;
    return itens.map((each, index) => (<option key={each.frota} value={index}>{each.frota}</option>));
  }

  _onSelectTier = (e) => {
    this.state.agent.tier = e.target.value
    this.setState({})
  }

  getStatus = (status) => {
    let label;
    switch (status) {
      case 1:
        label = "Aguardando aprovação";
        break;
      case 2:
        label = "Aguardando aprovação";
        break;
      case 3:
        label = "Aguardando aprovação";
        break;
      case 4:
        label = "Aprovado";
        break;
      case 5:
        label = "Reprovado";
        break;
      case 6:
        label = "Reprovado";
        break;
      case 7:
        label = "Em comodato";
        break;
      case 8:
        label = "Devolvido";
        break;
      case 9:
        label = "Encerrado";
        break;
      default:
        break;
    }
    return label;
  }

  _convertToIsoDate = (date) => {
    let newDate = new Date((date.split('/')[2] + '/' + (date.split('/')[1]) + '/' + date.split('/')[0]))
    if (newDate && newDate != 'Invalid Date') {
      return newDate.toISOString()
    } else {
      return date
    }
  }

  _approvalAction = (e, type) => {
    let typeMsg = 'aprovar', isApproveModal = true
    if (type == 'reject') {
      typeMsg = 'rejeitar'
      isApproveModal = false
    }
    let alertMsg = `Tem certeza que deseja ${typeMsg} essa solicitação?`

    this.setState({
      loanToInteract: e,
      modalTitle: 'Alerta!',
      modalMessage: alertMsg,
      isApproveModal: isApproveModal,
      isRejectModal: !isApproveModal,
      parentClass: ''
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  _modalDecision = (e) => {
    console.log('modal decision', e)
    if (e) {
      this.setState({
        showModal: false
      }, () => {
        if (!this.state.isRejectModal) {
          this._goApprovee(this.state.loanToInteract)
        } else {
          this._goRepprovee(this.state.loanToInteract)
        }
      })
    }
  }

  _goApprovee = (e) => {
    debugger;
    this.setState({
      isLoading: true
    }, () => {
      let body = {
        idloan: this.state.loanId,
        carId: this.state.carFrotumId,
        clientTier: this.state.agent.tier,
        startDate: this._convertToIsoDate(this.state.loanDate.startDate),
        endDate: this._convertToIsoDate(this.state.loanDate.endDate),
        email_agent : this.state.email_agent,
        description : this.state.description[0].value,
      }
      console.log("ramires");
      console.log(body)
      let url = listApprovalsAPI()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
        _that.props.refreshList()
        _that.setState({
          isLoading: false
        }, () => {
          _that.props.history.push('/aprovacao')
        })
      }
      fetchValuesOptions(url, options, callback)
    })

  }

  _goRepprovee = (e) => {
    console.log('repprove', e)
    this.setState({
      isLoading: true
    }, () => {
      let body = {
        idloan: this.state.loanId,
        carId: '',
        clientTier: this.state.agent.tier,
        startDate: this._convertToIsoDate(this.state.loanDate.startDate),
        endDate: this._convertToIsoDate(this.state.loanDate.endDate)
      }
      let url = listRejection()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
        _that.props.refreshList()
        _that.setState({
          isLoading: false
        }, () => {
          _that.props.history.push('/aprovacao')
        })
      }
      fetchValuesOptions(url, options, callback)
    })
  }

  _getCarDetails = () => {
    console.log('GET FROTA DETAILS', this.state.frotaInput, this.props.frotasList)
    let vehicle = this.props.frotasList.filter((item) => item.frota == this.state.frotaInput)[0];
    console.log(vehicle)
    if (vehicle) {
      this.selectedData = {
        title: vehicle.car.title,
        data: [
          [{
            name: "Periodo",
            value: this.state.loanDate.startDate + ' até ' + this.state.loanDate.endDate,
          }, {
            name: "Status Agenda",
            value: this.getStatus(this.state.loanData.status)
          }],
          [{
            name: "Versão",
            value: vehicle.car_version.title
          }, {
            name: "Ano",
            value: vehicle.model_year
          }],
          [{
            name: "Cor",
            value: vehicle.color
          }, {
            name: "Frota",
            value: vehicle.frota
          }],
          [{
            name: "Localização",
            value: vehicle.vehicle_location
          }],
          [{
            name: 'Considerações',
            value: vehicle.vehicle_status
          }]
        ]
      }
      this.state.fieldsError.splice(this.state.fieldsError.indexOf('frotaInput'), 1)

      this.state.carFrotumId = vehicle.id

    } else {
      this.selectedData = {
        title: '',
        data: []
      }
      var error = []
      error.push('frotaInput')
      this.state.fieldsError = [...error]
    }

    this.setState({})
  }

  _changeBlockDriver = (e) => {
    console.log(e.target.value)
    this.setState({
      removeUserBLock: e.target.value == 0
    })
  }

  toggleModalCNH = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }
  _renderDateFleetInfo = (itens) => {
    return (
      <div className={`row mt-4 mb-2 vw-floating-label`}>
        <div className="col-12 col-lg-3 mb-4 mb-lg-0">
          <div className={`form-label-group ${(this.state.fieldsError.indexOf('startDate') > -1 ? ' input-error ' : ' ')}`}>
            <InputMask
              className={`form-control ${this.inputIsFilled('startDate')}`}
              placeholder='Data início'
              onChange={e => this.changeInput(e.target.value, 'startDate')}
              value={this.state.loanDate.startDate}
              mask={"**/**/****"}
              type="text" id="loan-startDate" ref="loan-startDate">
            </InputMask>
            <label htmlFor='loan-startDate'>
              Data de Início:
                  </label>
          </div>
        </div>
        <div className="col-12 col-lg-3 mb-4 mb-lg-0">
          <div className={`form-label-group ${(this.state.fieldsError.indexOf('endDate') > -1 ? ' input-error ' : ' ')}`}>
            <InputMask
              className={`form-control ${this.inputIsFilled('endDate')}`}
              placeholder='Data fim'
              onChange={e => this.changeInput(e.target.value, 'endDate')}
              value={this.state.loanDate.endDate}
              mask={"**/**/****"}
              type="text" id="loan-endDate" ref="loan-endDate">
            </InputMask>
            <label htmlFor='loan-endDate'>
              Data de Fim:
            </label>
          </div>
          {this.state.fieldsError.indexOf('endDate') > -1 ? <p className='input-error-msg'>Data fim inferior a data início</p> : null}
        </div>
        <div className="col-12 col-lg-3 mb-4 mb-lg-0">
          {(itens && itens.length && itens.length > 0) ?
            <div className="form-label-group mb-4">
              <select onChange={e => this.changeInput(e.target.value, 'frota')} className={`form-control  ${this.state.filledInputs.indexOf('frota') > -1 ? 'input-filled' : ''} ${(this.state.fieldsError.indexOf('frota') > -1 ? 'input-error' : '')} `} name="frota" id="frota" ref='frota'>
                <option value='-1'>Selecione a Frota</option>
                {this._createFrotaOptions(itens)}
              </select>
            </div>
            :
            <div className='row'>
              <div className=" mb-4 col-9 ">
                <div className={`form-label-group ${(this.state.fieldsError.indexOf('frotaInput') > -1 ? 'input-error' : '')}`} >
                  <input type="text" value={this.state.frotaInput} onChange={e => this.changeInput(e.target.value, 'frotaInput')} className={`form-control  ${this.state.filledInputs.indexOf('frotaInput') > -1 ? 'input-filled' : ''} `} name="frota" id="frota" ref='frota' maxLength="80" />
                  <label htmlFor='frota'>Frota*</label>
                </div>
                {this.state.fieldsError.indexOf('frotaInput') > -1 ? <p className='input-error-msg'>Não encontramos registros desse veículo</p> : null}
              </div>
              <div className='col-3 mt-2'>
                <Button disabled={this.state.frotaInput.length < 6} text='Buscar' hideIcon='true' onClick={e => this._getCarDetails(e)} />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='vw-approval-backoffice mt-3'>
        <div class="row loan-data pb-4">
          <p className='col-12'>Dados do agente:</p>
          <div className="col-4 col-lg-2 vw-floating-label">
            <div className="form-label-group mb-4">
              <select value={this.state.agent.tier} className={`form-control  `} name="agentTier" id="agentTier" ref="agentTier" onChange={e => this._onSelectTier(e)}>
                <option value="1">Tier 1</option>
                <option value="2">Tier 2</option>
                <option value="3">Tier 3</option>
              </select>
            </div>
          </div>
          <div className="col-8 col-lg-10">
            <p className='loan-number-title mb-0'>{this.agent.name}</p>
            <p className='loan-date'>{this.agent.midiaType}</p>
          </div>
        </div>
        <div class="row loan-data pb-4">
          {this.approvalData.map((item) => {
            return (
              <div className='col-lg-4 col-12 mb-2 mb-lg-0'>
                <ApprovalCard title={item.title} itemsApprovalCard={item.data} />
              </div>
            )
          })}
        </div>
        {this.state.cnhPicture ?
          <div class="row loan-data pb-4">
            <div className="col-12">
              <a className='openModalCNH' href='#' onClick={e => this.toggleModalCNH(e)}>Ver Imagem CNH</a>
            </div>
          </div>
          : null}
        <div class="row loan-data pb-4">
          <div className="col-12">
            <ApprovalCard title={this.subjectData.title} itemsApprovalCard={this.state.description} isEdit={true} changeInput={ e=> this.changeInput(e, 'description')} disabled={this.state.status == 4 ? true : false}/>
          </div>
        </div>

        {this.isBlocked ?
          <div className='row loan-data mt-4 mb-4'>
            <div className='col-12'>
              <p className='loan-number-title mb-4'>
                Bloqueado:
                <span className='loan-user'> {this.deniedReason}</span>
              </p>
            </div>
            <div className='col-12 mt-2 mb-3'>
              <p className='loan-user'>
                Agente condutor bloqueado, deseja desbloquear?
              </p>
              <input onChange={e => this._changeBlockDriver(e)} type="radio" id="blockDriver-0" name="blockDriver" value='0' className='inputRadioForm'></input>
              <label className='ml-4 mr-4' htmlFor='blockDriver-0'>Sim</label>
              <input onChange={e => this._changeBlockDriver(e)} type="radio" id="blockDriver-1" name="blockDriver" value='1' className='inputRadioForm ml-4'></input>
              <label className='ml-4' htmlFor='blockDriver-1'>Não</label>
            </div>
            {this.state.removeUserBLock ?
              <div className='col-12 mt-4 mb-4 vw-label'>
                <p className='loan-user'>
                  Justificativa:
                  </p>
                <div className='form-label-group mb-4'>
                  <textarea class="form-control" id="justifyBlocked" placeholder="Digite aqui…" required></textarea>
                </div>
              </div>
              : null}
          </div>
          : null}


        <div className='row mt-4'>
          <div className="col-12 col-md-12">
            <div>
              <br></br>
              <p><b>Mensagem de aprovação do empréstimo do veículo a ser enviada ao Agente:</b></p>
              <TextArea cols={"5"} maxlength={"2000"} onChange={e => this.changeInput(e.target.value, 'email_agent')}
                placeholder={"Digite a mensagem..."} value={this.state.email_agent} disabled={this.state.status == 4 ? true : false}></TextArea>
              <p className='disclaimer-textarea'>*Quantidade mínima: 10 caracteres</p>
            </div>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-12'>
            <p className='loan-user mb-2 font-weight-bold'>
              Modelo solicitado:
            </p>
          </div>
          {this.selecedCars.map((car, index) => {
            let text = (index == 0) ? 'Primeira opção agente.' : 'Segunda opção agente.'
            let activeClass = (!this.state.showDateFleetInfo || this.state.showDateFleetInfo == `info-${car.id}`) ? 'radio-active' : ''
            return (
              <div className={`col-12 radio-car-item ${activeClass}`}>
                <RadioMultiline id={car.id} value={car.id} name={car.name} radioType='carOption' text={text} onChangeRadio={e => this._onChangeRadio(e)} />
                {this.state.showDateFleetInfo == `info-${car.id}` ?
                  this._renderDateFleetInfo(car.itens)
                  : null}
              </div>
            )
          })
          }
          <div className={`col-12 pt-2 mt-4 radio-car-item  mb-4 ${(!this.state.showDateFleetInfo || this.state.showDateFleetInfo == 'info-9999999') ? 'radio-active' : ''}`}>
            <RadioMultiline id='carOption-3' value='9999999' name='Outra opção de Veículo' radioType='carOption' text='Selecione essa opção para definir outra opção como modelo' onChangeRadio={e => this._onChangeRadio(e)} />
            {this.state.showDateFleetInfo == `info-9999999` ?
              this._renderDateFleetInfo()
              : null}
          </div>
        </div>

        {this.selectedData.data.length > 0 ?
          <div className='row mt-4'>
            <div className='col-12'>
              <p className='loan-user mb-4 font-weight-bold'>
                Dados do Veículo Selecionado.
              </p>
            </div>
            <div className='col-12 agent-details-wrapper'>
              <p className='detailResume-title mb-4'>{this.selectedData.title}</p>
              <div className='row'>
                {this.selectedData.data.map((item, index) => {
                  return (
                    <div className={`col-12 col-lg-3 ${(index == (this.selectedData.data.length - 1)) ? 'col-12 col-lg-12 mt-4' : ''}`}>
                      {item.map((text) => {
                        return (<p className='detailResume-item'>
                          {text.name ? text.name : ''}:
                                  <span className='detailResume-item-value'> {text.value}</span>
                        </p>)
                      })
                      }
                    </div>
                  )
                })
                }
              </div>
            </div>
          </div>
          : null}

        <div className='row pt-4 mb-4'>
          <div className='col-6 col-lg-3 offset-lg-3 mt-4 mb-4'>
            <Button onClick={e => this._approvalAction(e, 'reject')} hideIcon={true} disabled={this.selectedData.data.length < 1} text='Reprovar' />
          </div>
          <div className='col-6 col-lg-3 mt-4 mb-4'>
            <Button onClick={e => this._approvalAction(e, 'approve')} hideIcon={true} disabled={this.selectedData.data.length < 1} text='Aprovar' />
          </div>
        </div>
        {!this.state.isLoading ? '' : <Loading />}

        <Modal size="lg" isOpen={this.state.modalOpen} toggle={e => this.toggleModalCNH(e)}>
          <ModalBody className={` cnhPreviewModal`}>
            <button className="close closeModal" onClick={e => this.toggleModalCNH(e)}>&times;</button>
            <img src={this.state.cnhPicture} />
          </ModalBody>
        </Modal>

        <AlertModal
          isDecisionModal={true}
          configModalCallback={e => this._modalDecision(e)}
          title1={this.state.modalTitle}
          messageHTML={this.state.modalMessage}
          showModal={this.state.showModal}
        />

      </div>
    )
  }
}
export default withRouter(ApprovalBackofficeDetail);