import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'

import './TextBody.css'; 

class TextBody extends Component {

  constructor(props) {
    super(props)
    this.state = {
      className: props.className
    }
  }    

  render() {
    return (      
      <div className={`vw-text-body ${this.state.className} `}>
        <div dangerouslySetInnerHTML={{__html: this.props.text }}></div>
      </div>
    );    
  }
}

export default TextBody;
