import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getContacts } from './Contacts.actions';
import { fetchValues } from '../../shared/http-request-util';

import './Contacts.css'; 
import ContactRow from '../../components/ContactRow/ContactRow'
import PageTitle from '../../components/PageTitle/PageTitle';
import TextBody from '../../components/TextBody/TextBody';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { apiEndpoint, listContactsAPI } from '../../config/constants';
import Loading from '../../components/Loading/Loading';
import Speak from '../../components/Speak/Speak';
import ReactGA from 'react-ga';

class Contacts extends Component {

  constructor(props){
    super(props);
    this.update=false;
  }
  
  componentDidMount(){
    ReactGA.pageview('/contacts');
    this.props.dispatch(fetchValues(apiEndpoint() + listContactsAPI(), getContacts));
    this.update=true;
  }

  render() {
    if (this.update) {    
      return (      
        <div className="vw-contact">
          <div className="row">
              <Breadcrumb text="<a href='/'>Newsroom</a> / Contatos" />
              <PageTitle title="Contatos" />            
          </div>
          {
            this.props.contacts.map(contact => {
              return (
                <ContactRow 
                  title={contact.title}
                  contactList={contact.subcontacts}
                  />
              );
            })
          }  
           <Speak/>    
        </div>
      );    
    } else {
        return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  return {
    contacts: state.ContactsReducer.contacts
  }
}

export default connect(mapStateToProps)(Contacts);
