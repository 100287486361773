import React, { Component } from 'react'
import './Social.css'
import PageTitle from '../PageTitle/PageTitle';
import TextBody from '../TextBody/TextBody'
import YoutubeComponent from '../YoutubeComponent/YoutubeComponent';
import TwitterComponent from '../TwitterComponent/TwitterComponent';
import Slider from "react-slick";

class Social extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render() {
    return (
      <div className="vw-social">
          <PageTitle className="vw-font-30px vw-no-padding title-social" title="Social"/>
          <TwitterComponent/>
          <YoutubeComponent/>
      </div>

    );
  }
}


export default Social;