const initialState = {
  loanCars: [],
  sendedCar: {}
}

const LoanRequestReducer = (state = initialState, action) => {
  switch(action.type) {        
    case 'LIST_LOANCARS':
      return {
        loanCars  : [...action.loanCars],
      }
      case 'SEND_LOANCARS': 
        return {
          sendedCar  : [...state.sendedCar, ...action.sendedCar],
        }
    default:
      return state;
  }
};

export default LoanRequestReducer;