const initialState = {
  emobility: {},
  news: [],
  gallery: []
}

const SubEMobilityReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'EMOBILITY_LEVEL1_DETAIL':
      state = initialState;
      return {
        emobility: {...state.emobility, ...action.emobility},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    case 'EMOBILITY_LEVEL2_DETAIL':
      state = initialState;
      return {
        emobility: {...state.emobility, ...action.emobility},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }      
    default:
      return state;
  }
    
};

export default SubEMobilityReducer;