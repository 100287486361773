import React, { Component } from 'react';

import './PWAAlert.css'; 

class PWAAlert extends Component {

  constructor(props) {
    super(props);

    this.state = {
        showInstallMessage: false,
        deviceType: 'iPhone'
    }

  }

  componentDidMount() {
    this._isIOS()
  }

_isIOS = () => {
    // Detecta se o dispositivo está no iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = () => {
        return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    // Verifica se deve exibir notificação popup de instalação:
    if (isIos() && !isInStandaloneMode()) {
        const deviceType = () => {
            if(/ipad/.test( userAgent )) {
                return 'iPad';
            } else {
                return 'iPhone'
            }
        }
        this.setState({ showInstallMessage: true, deviceType: deviceType()});
    }
}

hidePWAAlert = () => {
    this.setState({ showInstallMessage: false });
    localStorage.setItem('iosPWA', 'true');
}
  render() {
    if(!this.state.showInstallMessage) return null
    if(localStorage.getItem('iosPWA')) return null
    return (      
        <div className={`pwaalert-container `} onClick={e => this.hidePWAAlert()}>
            <div className='content popover bs-popover-top'>
                {`Deseja instalar o aplicativo no seu ${this.state.deviceType}? Clique no ícone abaixo
                e escolha a opção 'Tela de Início'`} 
                <span class="arrow"></span>
            </div>
           
        </div>
    );    
  }
}

export default PWAAlert;