import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Components
import NewsComponentTitle from '../NewsComponentTitle/NewsComponentTitle';

import './NewsComponentItem.css'; 
import NewsComponentDate from '../NewsComponentDate/NewsComponentDate';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import Slider from "react-slick";
import NextArrow from '../../Arrow/NextArrow'
import PrevArrow from '../../Arrow/PrevArrow'
import HighlightMedia from '../../HighlightMedia/HighlightMedia';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

class NewsComponentItem extends Component { 

  constructor(props) {
    super(props)
    this.state = {
      axis: 'x',
    }
  }    

  _convertDate(pDate) {
    var date = new Date(pDate);
    var day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate()
    var month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
    return (day + '/' + month + '/' +  date.getFullYear());
  }

  _convertLabel(news) {
    let label = "";
    if (news.news_associations != undefined && news.news_associations.length > 0) {    
      label = news.news_associations[0].text_enum;  
      if (label.indexOf("Carros") > -1) {
        label = "Carros";
      } else if (label.indexOf("Empresa") > -1) {
        label = "Empresas";
      } else if (label.indexOf("E-Mobilities") > -1) {
          label = "Mobilidade Elétrica";
      } else if (label.indexOf("Technologies") > -1) {
          label = "Tecnologia";
      }  


    }
    return label;
  }

  _createLink(destaque) {
    
    switch (destaque.category_type) {
      case 'session': {
        if (destaque.id != 1) {
          return "/company/" + destaque.id;
        } else {
          return "/company";
        }        
      }
      case 'sub_session': {
        if (destaque.session_id != undefined) {
          return "/company/" + destaque.session_id + "/" + destaque.id;
        }
      }
      case 'car': {
        if (destaque.id != 1) {
          return "/cars/car/" + destaque.id;
        } else {
          return "/cars/models";
        }        
      }
      case 'car_version': {
        if (destaque.car_id != undefined) {
          return "/cars/version/" + destaque.car_id + "/" + destaque.id;
        }
      }
      case 'technology': {
        if (destaque.id != 1) {
          return "/technology/" + destaque.id;
        } else {
          return "/technology";
        }        
      }
      case 'subtechnology': {        
        if (destaque.technology_id != undefined) {
          return "/technology/" + destaque.technology_id + "/" + destaque.id;
        }
      }
      case 'mobilidade-eletrica': {
        if (destaque.id != 1) {
          return "/mobilidade-eletrica/" + destaque.id;
        } else {
          return "/mobilidade-eletrica";
        }        
      }
      case 'submobilidade-eletrica': {
        if (destaque.emobility_id != undefined) {
          return "/mobilidade-eletrica/" + destaque.emobility_id + "/" + destaque.id;
        }
      }
      case 'news': {
        return "/news/" + destaque.id;
      }
      case 'gallery': {
        return "/media-gallery/album/" + destaque.bucket_id;
      }
      default:        
        break;
    }
    //return "/cars/car/3";
  }  

  render() {        
    var items = this.props.newsItems.map((news, index) => { 
      let label = this._convertLabel(news);
      let updatedAt = (news.news_date && news.news_date.indexOf('null') < 0) ? `${news.news_date}` : this._convertDate(news.created_at)
      let newsLink = "";
      if (news.category_type) {
        newsLink =  this.props.LinkYoutube ? news.media : this._createLink(news);
      } else {
        newsLink =  this.props.LinkYoutube ? news.media : `/news/${news.id} `
      }
      
      let newsTarget = this.props.LinkYoutube ? '_blank' : `_self`

      return(  
        <div className={"newscomponent-item col-12 col-md-12 col-lg-12"}>
          <Link to= {newsLink} target={newsTarget}>
          <div className='row'>
            <div className='col-sm-12 col-md-5 col-12 col-image'>
              <HighlightMedia media={ news.media } mediaMedium= { news.mediaMed }  mediaLow= { news.mediaLow } videoYT_id={news._id} youTubeVideo={this.props.LinkYoutube} isAutoPlay={ false } preventPlay={ true }/>
            </div>
            <div className='col-sm-12 col-md-7 col-12 col-data'>
                <div className='newsdate-component-before'>
                  <NewsComponentDate date={ updatedAt } label={ label }/>
                </div>
                  <NewsComponentTitle title={ news.title }/>
                <div className='newsdate-component-after' >
                  <NewsComponentDate date={ updatedAt } label={ label }/>
                </div>

                <div className='newsbody-split'>  
                <ResponsiveEllipsis
                  className='newsbody-split-txt'
                  text={ news.subtitle }
                  maxLine='2'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                />  
                </div>

              { !this.props.hideShowMore ? 
                <div className="vw-read-more">
                  { this.props.isReadMore ? 'Ver Todas' : 'Leia Mais' }
                  <i className="icon brand-icon is-read-more"></i>
                </div>
              : null }

            </div>
          </div>
          </Link>
        </div>        
      )
    });

    var settings = {
      dots: !this.props.isVertical,
      draggable: !this.props.isVertical,
      arrows: !this.props.isVertical ? true : false,
      infinite: this.props.newsItems.length > 3 ? true : false,
      speed: 500,
      vertical: this.props.isVertical,
      slidesToShow: !this.props.isVertical ? 3 : 7,
      slidesToScroll: !this.props.isVertical ? 3 : 1,
      verticalSwiping: false,
      touchThreshold: 40,
      centerMode: false,
      variableWidth: false,
      nextArrow: <NextArrow className='vw-next-arrow NextA'/>,
      prevArrow: <PrevArrow className='vw-prev-arrow'/>,
      responsive: [{
        breakpoint: 768,
        settings: { 
          vertical: true,
          draggable: false,
          arrows: false
        }
      },{
        breakpoint: 544,
        settings: { 
          vertical: false,
          rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 3,
          slidesPerRow: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }],
      beforeChange: (current, next) => {
        console.log('beforeChange')
      },
      afterChange: current => { 
       console.log('afterChange')
      }
    };

    return (  
      
      <div>
        <div className="newsItem-container row img"> 
       
        {this.props.isVertical ? (
            <div> {items} </div>
          ) : (
          <Slider {...settings}>
            {items}
          </Slider>
          )
        
        }
        </div>

      

      </div>
    ); 
  }
}

export default NewsComponentItem;
