import React, { Component } from 'react';
import { connect } from "react-redux";

import PubSub from 'pubsub-js'

import './popOverButton.css';
import { Link } from 'react-router-dom';

import { Popover, PopoverHeader, PopoverBody, ListGroup, ListGroupItem } from 'reactstrap';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { generatePDF } from '../HtmlToPdf/HtmlToPdf';
import ReactGA from 'react-ga';

import iconefacebook from './img/icone-facebook.svg';
import iconetwitter from './img/icone-twitter.svg';
import iconewhatsapp from './img/whats.svg';
import iconeemail from './img/mail.svg';
import iconelinkedin from './img/linkedin.svg';
import iconeinstagram from './img/instagram.svg';
import iconefoto from './img/icone-foto.svg';
import btdownloadnormal from './img/bt-download-normal.svg';
import iconDownload from './img/bt-download-hover.svg';
import iconecompartilhar from './img/bt-compartilhar-hover.svg';
import iconeFavorito from './img/bt-favorito-normal.svg';
import iconeFavoritoHover from './img/bt-favorito-hover.svg';
import Loading from '../Loading/Loading';
import ShortImage from '../ShortImage/ShortImage';
import NewsComponentDate from '../NewsComponent/NewsComponentDate/NewsComponentDate';
import NewsComponentTitle from '../NewsComponent/NewsComponentTitle/NewsComponentTitle';
import HighlightMedia from '../HighlightMedia/HighlightMedia';
import Button from '../Button/Button';
import { withRouter } from 'react-router-dom';
let { getPlatform } = require('../../config/constants');

let { identityKitOption, apiEndpoint } = require('../../config/constants');

// ************************************************************************************************************
// Props required:
// 
// General:
// popOverType= (image || imageSize || social)
// containerName= string (must be the ID of the div containing the popOver. Must be unique!)
// 
// 'social':
// twitterLink= string
// twitterText= string
// facebookLink= string
// 
// 'image':
// imageLink = string
// imageDownloadName= string
// 
// 'imageSize'
// imageSizeSmall = string 
// imageSizeMedium = string
// imageSizeLarge = string
// ************************************************************************************************************

class PopOverButtton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popOverOpen: false,
      modalConfirmationOpenDownload: false,
      modalConfirmationOpenShare: false,
      confirmationTitleDownload: "Termo de Uso - Download",
      confirmationSubTitleDownload: "Os textos, imagens, áudios, documentos e vídeos, disponibilizados para download na VW News BR têm finalidade puramente informativa, podendo ser utilizados em editoriais em mídias sociais, editoriais de Influencers na Internet, editoriais jornalísticos, bem como em editoriais internos de empresas para seus colaboradores, não podendo ser utilizados para fins comerciais.",
      confirmationBodyDownload: "É vedado o uso dos textos, imagens, áudios, documentos e vídeos disponibilizados para download na VW News BR de forma que contrarie a realidade para a qual foram concebidos. O uso deverá ser em conformidade com o presente Termo de Uso, a legislação vigente, a moral e os bons costumes. \nSe aplicam a este Termo de Uso as disposições dos Termos e Condições Gerais de Uso da VW News BR no que não conflitarem.",
      confirmationConfirmDownload: "Download",
      confirmationCancelDownload: "Cancelar",
      confirmationTitleShare: "Data Transmission",
      confirmationSubTitleShare: "If you would like to share contents in social networks, you can activate the corresponding functions here.",
      confirmationBodyShare: "By activating, you give your consent to the transmission of data to the operators of the social networks. Upon activation, an initial transmission of data to third parties immediately occurs. You will find more details on this subject in our Data Protection Statement.",
      confirmationConfirmShare: "Transmit data",
      confirmationCancelShare: "Do not transmit data",
      cartItems: (window.localStorage.getItem('favorites')) ? JSON.parse(window.localStorage.getItem('favorites')) : []
    }

    this.favText = 'Ver favoritos'
    this.isLoading = false
  }

  componentDidMount() {
    PubSub.subscribe('HIDE_CART_PREVIEW', this.hideCartPreview)
  }

  hideCartPreview = () => {
    console.log('teste')
    this.setState({
      popOverOpen: false
    })
  }

  toggleConfirmationModalDownload(value) {
    if (localStorage.getItem('downloadAccepted') === 'accepted') {
      switch (value) {
        case 'low': {
          window.open(
            this.props.mediaLow,
            '_blank'
          );
          ReactGA.event({
            category: 'Image',
            action: 'Download Image Low',
            label: this.props.GAText + ' | ' + this.props.mediaLow
          });
          break;
        }
        case 'medium': {
          window.open(
            this.props.mediaMedium,
            '_blank'
          );
          ReactGA.event({
            category: 'Image',
            action: 'Download Image Medium',
            label: this.props.GAText + ' | ' + this.props.mediaMedium
          });
          break;
        }
        case 'large': {
          window.open(
            this.props.media,
            '_blank'
          );
          ReactGA.event({
            category: 'Image',
            action: 'Download Image High',
            label: this.props.GAText + ' | ' + this.props.media
          });
          break;
        }
        default:
          break;
      }
      this.setState({
        popOverOpen: false,
      });
    } else {
      this.selectedValue = value;
      this.setState({
        popOverOpen: false,
        modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
      });
    }
  }

  callDownload(imageLink) {
    if(!imageLink) return
    let downloadURL = apiEndpoint() + 'medias_api/download-file/' + imageLink.split('/')[5]
    return new Promise((res, rej) => {
      if(getPlatform() === 'WEB') {
        window.open(
          downloadURL,
          '_blank'
        ); 
      } else {

        if(window.app && window.app.downloadFile) {
          window.app.downloadFile(downloadURL)
        } else {
          console.log('MOBILE ELSE', window.app)
        }
      }

      res()
    })
  }

  toggleConfirmationModalDownloadVideo(e) {
    if(e) {
      this.props.toggleModal(e, false, true)
      e.stopPropagation()
    }
    if (localStorage.getItem('downloadAccepted') === 'accepted') {
        let imageLink = this.props.imageLink ? this.props.imageLink : this.props.media

        this.callDownload(imageLink).then(() => {
          ReactGA.event({
            category: 'Video',
            action: "download Video. Name: " + imageLink,
          });
          this.setState({
            popOverOpen: false,
            modalConfirmationOpenDownload: false
          }, () => {
            this.isLoading = false
          });
        })
       
        
      // }
    } else {
      this.setState({
        popOverOpen: false,
        modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
      });
    }

  }

  toggleConfirmationModalDownload2() {
    if(this.props.media.indexOf('.mp4') > -1) {
      this.toggleConfirmationModalDownloadVideo()
      return
    }
    if (localStorage.getItem('downloadAccepted') === 'accepted') {
      switch (this.selectedValue) {
        case 'low': {
          window.open(
            this.props.mediaLow,
            '_blank'
          );
          ReactGA.event({
            category: 'Download Image',
            action: 'Download Image Low',
            label: this.props.GAText + ' | ' + this.props.mediaLow
          });
          break;
        }
        case 'medium': {
          window.open(
            this.props.mediaMedium,
            '_blank'
          );
          ReactGA.event({
            category: 'Download Image',
            action: 'Download Image Medium',
            label: this.props.GAText + ' | ' + this.props.mediaMedium
          });
          break;
        }
        case 'large': {
          window.open(
            this.props.media,
            '_blank'
          );
          ReactGA.event({
            category: 'Download Image',
            action: 'Download Image High',
            label: this.props.GAText + ' | ' + this.props.media
          });
          break;
        }
      }
    }
    this.setState({
      popOverOpen: false,
      modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
    });
  }


  openSocialLink(link) {
    window.open(
      link,
      '_blank'
    );
    this.setState({
      popOverOpen: false,
    });
  }

  modalConfirmationDecision() {
    if (this.props.imageLink.indexOf('.mp4') > -1) {
      this.toggleConfirmationModalDownloadVideo()
    } else {
      this.toggleConfirmationModalDownloadAll()
    }
  }

  toggleConfirmationModalDownloadAll() {
    if (localStorage.getItem('downloadAccepted') === 'accepted') {
      this.isLoading = true
      generatePDF(this.props.pdfULR, this.props.pdfName);

      /*
        window.open(
            this.props.imageLink,
            '_blank'
          );
          */
      this.setState({
        popOverOpen: false,
        modalConfirmationOpenDownload: false
      }, () => {
        this.isLoading = false
      });

    } else {
      this.setState({
        popOverOpen: false,
        modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
      });
    }
  }

  togglePopOver(isFavorite) {
    if (isFavorite === true && !this.props.isCartPreview) {
      this.toggleFav();
      return
    }
    this.setState({
      popOverOpen: !this.state.popOverOpen,
    })
  }

  getImagesFromContent() {
    let medias = []

    if (this.props.pdfURL) {
      medias.push(this.props.pdfURL)
    }
    if (this.props.contentToDownload.media) {
      medias.push(this.props.contentToDownload.media)
    }
    if (this.props.contentToDownload.pdfMedia) {
      medias.push(this.props.contentToDownload.pdfMedia)
    }

    if (this.props.contentToDownload.templates) {
      this.props.contentToDownload.templates.map((template) => {
        if (template.text.indexOf('png') > -1 || template.text.indexOf('jpg') > -1 || template.text.indexOf('jpeg') > -1 || template.text.indexOf('mp4') > -1) {
          medias.push(template.text)
        }
      })
    }

    return medias
  }

  toggleFav() {
    console.log('if', this.props.imageDownloadName)
    var savedFav = window.localStorage.getItem('favorites') ? JSON.parse(window.localStorage.getItem('favorites')) : []
    let dataFav = []
    let itemToSave = {
      'name': this.props.imageDownloadName,
      'contentLink': this.props.contentLink,
      'imageLink': this.props.imageLink,
      'contentToDownload': this.getImagesFromContent(),
      'type': this.props.type,
      'mediaAmount': this.props.mediaAmount
    }
    if (!savedFav) {
      dataFav.push(itemToSave)
    } else {
      var isSaved
      if (this.props.type != 'Imagem' && this.props.type != 'Video' && this.props.type != 'Anexo') {
        isSaved = savedFav.filter((item) => item.name == itemToSave.name && item.imageLink == this.props.imageLink)[0]
      } else {
        isSaved = savedFav.filter((item) => item.imageLink == this.props.imageLink)[0]
      }
      // var isSaved = savedFav.filter((item) => item.imageLink == this.props.imageLink)[0]
      // alert(isSaved)
      if (!isSaved) {
        dataFav = [itemToSave, ...savedFav]
      } else {
        this.favText = 'Já adicionado'
        dataFav = [...savedFav]
      }
    }
    window.localStorage.setItem('favorites', JSON.stringify(dataFav))

    this.setState({
      popOverOpen: !this.state.popOverOpen
    }, () => {
      if (this.props.popOverPosition) {
        setTimeout(() => {
          this.setState({
            popOverOpen: !this.state.popOverOpen
          })
        }, 3000)
      }
    })
  }

  renderPopoverBody = () => {
    switch (this.props.popOverType) {
      case 'cart':
        return this._renderCartPreview(this.props.isCartPreview)
      case 'loggedin':
        return this._renderLoggedIn(this.props.isCartPreview)
      default:
        return this._renderButtons()
    }
  }

  _renderButtons() {
    if (this.props.popOverType != 'social') {
      return (
        <ListGroup>
          <ListGroupItem tag="button" className="popOverButtons" onClick={this.toggleConfirmationModalDownloadAll.bind(this)}>
            <a href='#' className="buttonIcon">
              <div className="buttonIcon">
                <div id={`${this.props.containerName}button`} className="btdownload"></div>
              </div>
            </a>
          </ListGroupItem>

        </ListGroup>
      )
    } else {
      //get whatsapp link
      let link = this.props.facebookLink.substring(37, this.props.facebookLink.length);
      const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
      let url = "mailto:?subject=Compartilhamento de conteúdo do VW News&amp;body=Acesse o link para saber mais sobre o VW News: " + link;      
      return (
        <ListGroup>
          <ListGroupItem tag="button" className="popOverButtons" onClick={this.openSocialLink.bind(this, linkedinLink)}>
            <a href='#' className="buttonIcon" >
              <img src={iconelinkedin} />
            </a>
          </ListGroupItem>
          <ListGroupItem tag="button" className="popOverButtons" onClick={this.openSocialLink.bind(this, this.props.twitterLink)}>
            <a href='#' className="buttonIcon" >
              <img src={iconetwitter} />
            </a>
          </ListGroupItem>
          <ListGroupItem tag="button" className="popOverButtons" onClick={this.openSocialLink.bind(this, "https://api.whatsapp.com/send?preview_url='true'&text=" + link)}>
            <a href='#' className="buttonIcon" >
              <img src={iconewhatsapp} />
            </a>
          </ListGroupItem>          
          <ListGroupItem tag="button" className="popOverButtons">
            <a href={`mailto:?body=Acesse o link para saber mais sobre o VW News: <br /><br />${link}`} className="buttonIcon" >
              <img src={iconeemail} />
            </a>
          </ListGroupItem>          
          <ListGroupItem tag="button" className="popOverButtons" onClick={this.openSocialLink.bind(this, this.props.facebookLink)}>
            <a href='#' className="buttonIcon" >
              <img src={iconefacebook} />
            </a>
          </ListGroupItem>
        </ListGroup>
      )
    }
  }

  _goToCart(isPreview) {
    if (!isPreview) return
    if (isPreview) {
      // window.location.href = '/carrinho'
      this.props.history.push('/carrinho')
    }

  }

  _renderTriggerButton() {
    switch (this.props.popOverType) {
      case 'social':
        return (<img id={`${this.props.containerName}button`} src={iconecompartilhar} />)
      case 'cart':
        if (!this.props.isCartPreview) {
          var savedFav = window.localStorage.getItem('favorites') ? JSON.parse(window.localStorage.getItem('favorites')) : []
          var isSaved = savedFav ? savedFav.filter((item) => item.imageLink == this.props.imageLink)[0] : null;
        }
        return (
          <div onClick={this._goToCart.bind(this, false)}>
            {(isSaved && !this.props.isCartPreview) ? <span className="cart-mark"></span> : null}
            <div id={`${this.props.containerName}button`} className='cartButtonImage'></div>
            {/* <img  src={iconeFavoritoHover} /> */}
          </div>
        )
      case 'loggedin':
        return (
          <label id={`${this.props.containerName}button`} className='btn-header-login logged'>
            <i className="icon brand-icon login-icon-hover is-middle"></i>
          </label>
        )
      default:
        return (<img id={`${this.props.containerName}button`} src={iconDownload} />)
    }
  }

  _deleteObject(imageLink) {
    var savedFav = JSON.parse(window.localStorage.getItem('favorites'))
    if (!savedFav) return
    var isSaved = savedFav.filter((item) => item.imageLink == imageLink)[0]
    if (!isSaved) return
    savedFav.splice(savedFav.indexOf(isSaved), 1)
    window.localStorage.setItem('favorites', JSON.stringify(savedFav))
    this.setState({
      cartItems: savedFav
    })
  }

  goLogout = () => {

    let userData = JSON.parse(localStorage.getItem('user-data'));
    let token = userData.token;

    let urlCallBack = identityKitOption().logoutCallBack;
    let VWURLAuth = identityKitOption().urlVwId;


    // https://identity-sandbox.vwgroup.io/oidc/v1/logout?&state=<state>&id_token_hint=<id-token>&post_logout_redirect_uri=<redirect_uri>"
    let logoutUrl = VWURLAuth + "logout?id_token_hint=" + token + "&post_logout_redirect_uri=" + urlCallBack;
    console.log(logoutUrl);

    localStorage.removeItem('user-data');
    window.location.href = logoutUrl;
  }

  _renderCartPreview(isPreview) {
    var cartItems = window.localStorage.getItem('favorites') ? JSON.parse(window.localStorage.getItem('favorites')) : []
    if (isPreview) {
      return (
        <div className='cart-preview'>
          {cartItems.length > 0 ?
            <div>
              {cartItems.map((object, index) => {
                return (
                  (index < 5) ?
                    <div>
                      <div className='row'>
                        <div className='col-4 col-preview col-image'>
                          <HighlightMedia
                            media={object.imageLink}
                            mediaLow={object.imageLink}
                            mediaMedium={object.imageLink}
                            pDownload={true} />
                        </div>
                        <div className='col-6 col-sm-7 col-preview'>
                          {object.date && object.label ? <NewsComponentDate date={object.date} label={object.label} /> : null}
                          <NewsComponentTitle title={object.name} />
                        </div>
                        <div className='col-1 col-preview'>
                          <a className="cart-contents--footer-action" onClick={this._deleteObject.bind(this, object.imageLink)} title="Clear cart">
                            <i className="icon brand-icon is-remove is-1x is-middle has-text"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    :
                    null
                )
              })
              }
              <div className='row'>
                <div className='col-12 text-center'>
                  {cartItems.length > 5 ? <div><i className='icon brand-icon is-more-objects'></i></div> : null}
                  <Button text='Ir para o Favoritos' iconType='arrow-foward' hideIcon={true} onClick={this._goToCart.bind(this)} />
                  {/* <button className="btn-primary" onClick={this._goToCart.bind(this)} href="#">
                    Ir para o Carrinho
                  </button> */}
                </div>
              </div>
            </div>
            :
            <div className='row empty-cart-text'>
              <div className='col-12'>
                Nenhum item na lista de favoritos
                </div>
            </div>
          }
        </div>
      )
    } else {
      return (
        <ListGroup>
          <ListGroupItem tag="button" className="popOverButtons popOverCart" onClick={this._goToCart.bind(this, true)}>
            <p className='text-fav'>{this.favText}</p>
          </ListGroupItem>
        </ListGroup>
      )
    }
  }

  _renderLoggedIn = () => {
    return (
      <ListGroup>
        <ListGroupItem tag="button" className="imageSize">
          <Link to='/registry' onClick={e => this.togglePopOver(e)}>
            <span className="imageSize__size">
              Perfil
            </span>
            <i class="icon brand-icon arrow-foward has-text"></i>
          </Link>
        </ListGroupItem>
        <ListGroupItem tag="button" className="imageSize">
          <Link to='/home-comodato' onClick={e => this.togglePopOver(e)}>
            <span className="imageSize__size">
              Solicitação de Veículo
            </span>
            <i class="icon brand-icon arrow-foward has-text"></i>
          </Link>
        </ListGroupItem>
        {this.props.hasEvent ?
          <ListGroupItem tag="button" className="imageSize">
            <Link to='/eventos' onClick={e => this.togglePopOver(e)}>
              <span className="imageSize__size">
                Eventos
              </span>
              <i class="icon brand-icon arrow-foward has-text"></i>
            </Link>
          </ListGroupItem>
          : null}
        {/* <ListGroupItem tag="button" className="imageSize">
          <a onClick={e => this.goLogout(e)}>
            <span className="imageSize__size">
              Logout
            </span>
            <i class="icon brand-icon arrow-foward has-text"></i>
          </a>
        </ListGroupItem> */}
      </ListGroup>
    )
  }

  isMobile() {
    if (document.getElementById("root").offsetWidth < 992) {
      return true;
    }
    return false
  }

  render() {
    let cartTrigger = (this.props.popOverType === 'cart') ? (!this.props.isCartPreview) ? 'click' : 'hover' : 'hover'
    let isFavorite = (this.props.popOverType === 'cart') ? true : false
    let popoverPosition = (this.props.popOverPosition) ? this.props.popOverPosition : 'top'
    let popoverClass = (this.props.popOverType === 'loggedin') ? 'loggedinPopover' : 'roundPopover'
    return this.props.popOverType === 'imageSize'
      ? (
        <div className='holder-image-size-buttons'>
          <Popover container={this.props.containerName} placement={popoverPosition} trigger='hover' isOpen={this.state.popOverOpen} target={`${this.props.containerName}button`} toggle={this.togglePopOver.bind(this)}>
            <PopoverBody>
              { this.props.media && this.props.media.indexOf('.mp4') < 0 ?
                <ListGroup>
                    <ListGroupItem
                      onClick={this.toggleConfirmationModalDownload.bind(this, 'low')}
                      tag="button"
                      className="imageSize">
                      <span className="imageDownloadIcon" src={iconefoto} />
                      <span className="imageSize__size">
                        Pequena  &nbsp;
                        </span>
                      <span className="imageSize__value">
                        ({this.props.mediaSizeLow})
                        </span>
                    </ListGroupItem>
                    <ListGroupItem
                      onClick={this.toggleConfirmationModalDownload.bind(this, 'medium')}
                      tag="button"
                      className="imageSize">
                      <span className="imageDownloadIcon" src={iconefoto} />
                      <span className="imageSize__size">
                        Média &nbsp;
                        </span>
                      <span className="imageSize__value">
                        ({this.props.mediaSizeMedium})
                        </span>
                    </ListGroupItem>
                    <ListGroupItem
                      onClick={this.toggleConfirmationModalDownload.bind(this, 'large')}
                      tag="button"
                      className="imageSize">
                      <span className="imageDownloadIcon" src={iconefoto} />
                      <span className="imageSize__size">
                        Grande &nbsp;
                        </span>
                      <span className="imageSize__value">
                        ({this.props.mediaSize})
                        </span>
                    </ListGroupItem>
                  </ListGroup>
                : 
                  <ListGroup>
                    <ListGroupItem
                      onClick={e => this.toggleConfirmationModalDownloadVideo(e)}
                      tag="button"
                      className="imageSize">
                      <span className="imageDownloadIcon" src={iconefoto} />
                      <span className="imageSize__size">
                        MP4 &nbsp;
                        </span>
                      <span className="imageSize__value">
                        ({this.props.mediaSize})
                        </span>
                    </ListGroupItem>
                  </ListGroup>
                }
            </PopoverBody>
          </Popover>

          {/* <img id={`${this.props.containerName}button`} className="btdownload" src={btdownloadnormal}/> */}


          <div id={`${this.props.containerName}button`} className="btdownload"></div>

          <ConfirmationModal className="img-download-bt"
            isOpen={this.state.modalConfirmationOpenDownload}
            title={this.state.confirmationTitleDownload}
            subTitle={this.state.confirmationSubTitleDownload}
            body={this.state.confirmationBodyDownload}
            type='download'
            toggle={this.toggleConfirmationModalDownload2.bind(this)}
            confirmMessage={this.state.confirmationConfirmDownload}
            cancelMessage={this.state.confirmationCancelDownload}
          />
        </div>
      ) : (
        <div>


          <Popover className={popoverClass} container={this.props.containerName} placement={popoverPosition} trigger={cartTrigger} isOpen={this.state.popOverOpen} target={`${this.props.containerName}button`} toggle={this.togglePopOver.bind(this, isFavorite)}>
            <PopoverBody>
              {
                this.renderPopoverBody()
              }
            </PopoverBody>
          </Popover>


          {

            this._renderTriggerButton()

          }
          <ConfirmationModal
            isOpen={this.state.modalConfirmationOpenDownload}
            title={this.state.confirmationTitleDownload}
            subTitle={this.state.confirmationSubTitleDownload}
            body={this.state.confirmationBodyDownload}
            type='download'
            toggle={this.modalConfirmationDecision.bind(this)}
            confirmMessage={this.state.confirmationConfirmDownload}
            cancelMessage={this.state.confirmationCancelDownload}
          />
          {this.isLoading ?
            <Loading size='fullscreen' />
            :
            null
          }
        </div>
      );
  }
}

export default withRouter(PopOverButtton);
