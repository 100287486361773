const initialState = {
  cartList: []
}

const CartReducer = (state = initialState, action) => {
  console.log('state', state, action)
  switch(action.type) {        
    case 'CART_LIST':
      return {
        cartList  : [...state.cartList, ...action.cartList],
      }
    default:
      return state;
  }
};

export default CartReducer;