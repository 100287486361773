const initialState = {
  videosYT: []
}

const YoutubeReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'YOUTUBE_DETAIL':
      state = initialState;
      return {
        videosYT: [...state.videosYT, ...action.videosYT],
      }
    default:
      return state;
  }
};

export default YoutubeReducer;