import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PageTitle from '../PageTitle/PageTitle'
import PageSubTitle from '../PageSubTitle/PageSubTitle'
import TextBody from '../TextBody/TextBody'
import TitleCar from '../TitleCar/TitleCar'
import Button from '../Button/Button'

import './ConfirmationModal.css'; 

import placeholder from './assets/logo-vw@2x.jpg'
import imageIcon from './assets/icone-foto.svg'
import twitter from './assets/icone-twitter.svg'
import facebook from './assets/icone-facebook.svg'
import youtube from './assets/icone-youtube.svg'
import instagram from './assets/icone-instagram.svg'

import downloadImage from './assets/bt-download-normal.svg'
import shareImage from './assets/bt-compartilhar-normal.svg'

class ConfirmationModal extends Component {

  constructor(props) {
    super(props);    
    // console.log("props recebidos" + JSON.stringify(props));
    this.state = {
      isOpen: this.props.isOpen,
      title: this.props.title,
      subTitle: this.props.subTitle,
      body: this.props.body,
      type: this.props.type,
      toggle: this.props.toggle,
      closeAll: this.props.closeAll,
      confirmMessage: this.props.confirmMessage,
      cancelMessage: this.props.cancelMessage,
      image: (this.props.type === 'download' || this.props.type === 'download-cart') ? downloadImage : shareImage,
      accept: (this.props.type === 'download' || this.props.type === 'download-cart') ? false : true,
      emailValue: ''
    };
  }

  toggleAll() {
    console.log(`${this.state.type}Accepted`);
    localStorage.setItem(`${this.state.type}Accepted`, 'accepted');
    this.props.toggle();
  }

  checkBox() {
    this.setState({
      accept: !this.state.accept
    })
  }

  _checkEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)) {
        return true;
    } else {
        return false;
    }
  }

  emailChange(value) {
    this.setState({
      emailValue: value
    })
    console.log('email change')
  }

  closeCartModal() {
    if(this._checkEmail(this.state.emailValue)) {
      this.props.toggle(this.state.emailValue)
    }
  }
  componentWillUnmount(){}

  render() {
    return (
        <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalBody className={`confirmationModal ${this.state.type}`}>
        <button className="close closeModal" onClick={this.props.toggle}>&times;</button>
        <div className="container-fluid" >
          <div className="row">
            <div className="col-3 d-none d-sm-block">
              <img className="legalImage" src={this.state.image}/>
            </div>
            <div className="col-12 col-sm-9">
              <PageTitle title={this.state.title}/>
              <PageSubTitle text={this.state.subTitle}/>
              <TextBody text={this.state.body}/>
              {this.state.type === 'download-cart' ?
                <div className='form-group'>
                  <input type="text" className="form-control" id="emailDownload" value={this.state.emailValue} onChange={e => this.emailChange(e.target.value)} placeholder='Seu email *'></input>
                </div>
              : null }
              {this.state.type === 'download'
                ? (
                  <div className="row agreeBox">
                  <div className="col cc">
                    <input type="checkbox" checked={this.state.accept} onChange={this.checkBox.bind(this)} className="agreeBox__input" id="agreeAndDownload"></input>
                    <label className="offset-1 agreeBox__label" 
                      htmlFor="agreeAndDownload">
                      <TextBody text="Declaro que estou ciente e concordo com o Termo de Uso acima." />
                    </label>
                  </div>
                </div>
                )
                : null}
              
                {this.state.type != 'download-cart' ?
                  <div className="row">
                      <div className="col-auto button__container">
                      <Button onClick={this.toggleAll.bind(this)} disabled={!this.state.accept} text={this.state.confirmMessage} className="agreeBox__confirmButton"/>
                    </div>
                    <div className="col-auto button__container">
                      <Button onClick={this.props.toggle} className="agreeBox__cancelButton" text={this.state.cancelMessage}/>
                    </div>
                  </div>
                : 
                  <div className="row">
                    <div className="col-auto button__container">
                      <Button onClick={this.closeCartModal.bind(this)} className="agreeBox__cancelButton" hideIcon={true} text={this.props.downloadButtonMessage}/>
                    </div>
                  </div>
                }
              
            </div>
          </div>
        </div>
        </ModalBody>
      </Modal>
    );    
  }
}

export default ConfirmationModal;
