import React, { Component } from 'react';
import './StepWizard.css';


class StepWizard extends Component {

  constructor(props) {
    super(props);
  }


  _selectMenuItem(item, index, e) {
    e.preventDefault()
    
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps', nextProps)
  }

  componentDidMount() {
    console.log('componentDidMount')
  }

  componentWillUnmount() {
    console.log('componentWillUnmount')
  }

  render() {
    const { onClick } = this.props;
    return (
        <div className="vw-stepwizard mt-3">
            <div className="stepwizard-row setup-panel">
                <div className="stepwizard-step col-xs-3"> 
                    <a href="#" className="btn-circle" disabled={this.props.activeStep !== 0}>1</a>
                    <span>Condutor</span>
                </div>
                <div className="stepwizard-step col-xs-3"> 
                    <a href="#" className="btn-circle" disabled={this.props.activeStep !== 1}>2</a>
                    <span>Veículo</span>
                </div>
                <div className="stepwizard-step col-xs-3"> 
                    <a href="#" className=" btn-circle" disabled={this.props.activeStep !== 2}>3</a>
                    <span>Agenda</span>
                </div>
            </div>
        </div>
    );    
  }
}

export default StepWizard;
