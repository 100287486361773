
/*
 * action types
 */

export const LIST_SEARCH = 'LIST_SEARCH'

/*
 * action creators
 */
export function searchResult(result) {  
  console.log('RESULT COMPLETE', result)
  let newResult = { 
      headers: result.headers,
      searchResult: result.search,
      total: result.total
  }
  console.log('newResult', newResult)
  return { 
    type: LIST_SEARCH, 
    search: newResult
  } 
}  
