/*
 * action types
 */

export const EMPRESA_HOME = 'EMPRESA_HOME'

/*
 * action creators
 */
export function getEmpresa(result) {
  return { 
    type: EMPRESA_HOME, 
    empresa: result.session[0],
    news: result.news,
    gallery: result.gallery
  }
}
