import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLatestPhotos } from './MediaGalleryDetails.actions';
import { fetchValues } from '../../../shared/http-request-util';


import Speak from '../../../components/Speak/Speak';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageTitle from '../../../components/PageTitle/PageTitle';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import Button from '../../../components/Button/Button';
import Loading from '../../../components/Loading/Loading';

import ReactGA from 'react-ga';


import './MediaGalleryDetails.css';

let { apiEndpoint } = require('../../../config/constants');
let { getPhotosByAlbym } = require('../../../config/constants');

class MediaGalleryDetails extends Component {

  constructor(props) {
    super(props);
    this.qtdePagination = 9;
    this.positionPhotos = 1;
    this.limitPhotos = this.qtdePagination;    
  }

  componentDidMount() {    
    //ReactGA.pageview('/media-gallery');
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + getPhotosByAlbym(this.props.match.params.album_id, this.positionPhotos, this.limitPhotos), getLatestPhotos));  
  }

  _callMore = (shortTitle) => {
    this.positionPhotos += 1;
    this.props.dispatch(fetchValues(apiEndpoint() + getPhotosByAlbym(this.props.match.params.album_id, this.positionPhotos, this.limitPhotos), getLatestPhotos));      
  }

  _renderButton(shortTitle, page, position) {
    if (page != position) {
      return (          
        <Button onClick={() => this._callMore(shortTitle) } text={`Ver mais ${shortTitle}`}/>
      )
    }
    
  }

  _renderImages(shortTitle, gallery) {
    if (shortTitle === "Álbuns") {
      return (
        gallery.map(function (item) {
          return (
            <div class="col-sm-6 col-md-4">
              <HighlightMedia showPopOverButtons={true}  
              imageName={shortTitle}
              media={ item.bucket_media[0].media } mediaMedium= { item.bucket_media[0].mediaMed } mediaLow = { item.bucket_media[0].mediaLow } 
              mediaSize={ item.bucket_media[0].mediaSize } mediaSizeMedium= { item.bucket_media[0].mediaSizeMedium } mediaSizeLow = { item.bucket_media[0].mediaSizeLow } 
              isAutoPlay={ false } />
            </div>  
          )
        })
      );
    } else {
      return (
        gallery.map(function (item) {
          return (
            <div class="col-sm-6 col-md-4">
              <HighlightMedia showPopOverButtons={true} 
               imageName={shortTitle} 
              media={ item.media } mediaMedium= { item.mediaMed } mediaLow = { item.mediaLow }
              mediaSize={ item.mediaSize } mediaSizeMedium= { item.mediaSizeMedium } mediaSizeLow = { item.mediaSizeLow } 
              isAutoPlay={ false } />
            </div>  
          )
        })
      );
    }
  }

  _renderGallery(title, shortTitle, gallery, page, position) {
    if (gallery != undefined && gallery.length > 0) {
      return (
        <div className="vw-gallery-center">
          <div className="row car-margin">
            <div className="col-12">
            <PageTitle className="vw-font-20px vw-no-padding" title={title}/>
            </div>
          </div>
          <div class="row ">
            { this._renderImages(shortTitle, gallery) }
            <div className="w-100">&nbsp;</div>
            <br />
            {this._renderButton(shortTitle, page, position)}
          </div>
        </div>      
      )        
    } else {
      return (<div></div>);
    }

  }

  render() {
    if (this.update) {
      return (      
        <div className="row media-center-gallery-1 ">
            <div className="col-11 col-sm-12 media-center-gallery">
                <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/media-center'>Media Center</a>  / <a href='/media-gallery'>Galeria</a> / Álbum" />
                <PageTitle title="Álbum" />
            </div>
          <div className=" col-9 gallery-component">
            { this._renderGallery("Fotos e Videos","Fotos", this.props.latestPhotos, this.props.pagePhotos, this.positionPhotos) }
          </div>  
          <div className="speak-media">
           <Speak/>
          </div>    
        </div>
      );      
    } else {
      return (
        <Loading />
      )
    }
  } 
}

// Add this function:
function mapStateToProps(state) {
  return {
    latestPhotos: state.MediaGalleryDetailsReducer.latestPhotos,
    pagePhotos: state.MediaGalleryDetailsReducer.pagePhotos,
  };
}

export default connect(mapStateToProps)(MediaGalleryDetails);