/*
 * action types
 */

export const TECNOLOGIA_HOME = 'TECNOLOGIA_HOME'

/*
 * action creators
 */
export function getTechnology(result) {
  return { 
    type: TECNOLOGIA_HOME, 
    technology: result.technology[0],
    news: result.news,
    gallery: result.gallery
  }
}