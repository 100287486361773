import React, { Component } from 'react'
import './GalleryItem.css'
import NextArrow from '../../Arrow/NextArrow'
import PrevArrow from '../../Arrow/PrevArrow'
import Slider from "react-slick";
import PageTitle from '../../PageTitle/PageTitle';
import Button from '../../Button/Button';
import HighlightMedia from '../../HighlightMedia/HighlightMedia';
import {withRouter} from 'react-router-dom'

class GalleryItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      axis: 'x',
      imagesItems: props.gallery
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      imagesItems: nextProps.gallery
    })
  }

  _goToGallery = (e) => {
    this.props.history.push('/media-gallery')
  }

  render() {
    
  if (this.state.imagesItems != null && this.state.imagesItems.length > 0) {
    var items = this.state.imagesItems.map((item, index) => {
      return(     
        <div key={'key'+index} className="col-md-12 col-sm-6 col-12">
        <HighlightMedia showPopOverButtons={ true } 
          imageName={this.props.title}
          media={item.media} mediaMedium= { item.mediaMed } mediaLow = {item.mediaLow} 
          mediaSize={item.mediaSize} mediaSizeMedium= { item.mediaSizeMedium } mediaSizeLow = {item.mediaSizeLow} 
          GAText={this.props.title}
          isAutoPlay={false}/>
          {/* <img className="d-block w-100 img-gallery" src={item.media}></img> */}
        </div>
      )
    });
    
    var settings = {
      dots: true,
      arrows: true,
      infinite: this.state.imagesItems.length > 9 ? true : false,
      speed: 500,
      slidesToShow: 1,
      touchThreshold: 40,
      centerMode: false,
      variableWidth: false,
      rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 3,
      slidesPerRow: 3,
      nextArrow: <NextArrow className='vw-next-arrow'/>,
      prevArrow: <PrevArrow className='vw-prev-arrow galleryItem-prev'/>,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 2,
            infinite: this.state.imagesItems.length > 6 ? true : false,
          }
        },{
          breakpoint: 544,
          settings: {
            slidesPerRow: 1,
            infinite: this.state.imagesItems.length > 3 ? true : false,
          }
        },
      ],
      beforeChange: (current, next) => {
        console.log('beforeChange')
      },
      afterChange: current => { 
       console.log('afterChange')
      }
    };

    return (  
      <div className={"gallery-container " + (this.props.whiteBG ? 'whiteBG' : null)}>
        <div className="CarouselGallery "> 
          {/* <p className="CarouselGallery-title">{ this.props.title}</p> */}
          <div className="row">
            <div className="col">            
              <PageTitle title={ this.props.title}/>
            </div>
          </div>
          <div className='row'>
            <Slider {...settings}>
              {items}
            </Slider>
          </div>
          <Button text='Ir para a Galeria' onClick={e => this._goToGallery(e)} whiteBtn={this.props.whiteBG}/>
        </div>  
      </div>
      );          
    } else {
      return (<div></div>);
    } 
  }
}


export default withRouter(GalleryItem);

