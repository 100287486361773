/*
 * action types
 */

export const EMPRESA_LEVEL1_DETAIL = 'EMPRESA_LEVEL1_DETAIL'
export const EMPRESA_LEVEL2_DETAIL = 'EMPRESA_LEVEL2_DETAIL'

/*
 * action creators
 */
export function getEmpresa(result) {
  return { 
    type: EMPRESA_LEVEL1_DETAIL, 
    company: result.session,
    news: result.news,
    gallery: result.gallery
  }
}

export function getSubEmpresa(result) {
  return { 
    type: EMPRESA_LEVEL2_DETAIL, 
    company: result.subsession,
    news: result.news,
    gallery: result.gallery
  }
}

