import React, { Component } from 'react';
import './Newsletter.css'; 
import NewsletterForm from '../../components/NewsletterForm/NewsletterForm';
import TextBody from '../../components/TextBody/TextBody';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import Speak from '../../components/Speak/Speak';
import Button from '../../components/Button/Button';
import ReactGA from 'react-ga';

class Newsletter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      update:false
    }
  }

  componentDidMount() {
    ReactGA.pageview('/newsletter');
    setTimeout(() => {
      this.setState({
        update: true
      });
    }, 200);
  }

  render() {
    if(this.state.update) { 
      return ( 
        <div>
          <div className="row vw-newsletter">
          <div className="col-12">
            <Breadcrumb text="<a href='/'>Newsroom</a> / Newsletter" />
            <PageTitle title="Cadastre-se"/>
            <TextBody text="O VW News BR é uma plataforma aberta que oferece a todos os usuários opções de pesquisas abrangentes. <br>
              Se você gostaria de receber por e-mail os comunicados e novidades divulgadas pela área de Relações com a Imprensa, inscreva-se abaixo:"/>
          </div>
          <div  className="col-12">
          <NewsletterForm/>
          </div>
          {/* <div  className="col-12">
            <Button text='Assinar a newsletter' hideIcon={ true }/>
          </div> */}
          </div>
          
          <Speak/>
        </div>
      ); 
    } else {
      return(
        <Loading/>
      )
    }

  }
}

export default Newsletter;
