import React, { Component } from 'react';
import Speak from '../../components/Speak/Speak';

import Search from '../../components/Search/Search';
import './SearchPage.css'; 

class SearchPage extends Component {

    constructor(props) {
      super(props);

      this.state = {
        querySearch: ''
      }
    }

    componentDidMount() {
      if(this.props.location.search.split('search=')[1]) {
        this.setState({
          querySearch: encodeURIComponent(new URLSearchParams(this.props.location.search).get("search"))
        })
      }
    }

    render() {
        return(

            <div className="row ">
                <div className="col-12">
                    <Search isAutoComplete={false} querySearch={ this.state.querySearch}/>
                </div>
                 <div className="col-12">
                    <div className='container'>
                      <Speak/>
                    </div>
                 </div>
            </div>

        );
    }

}

export default SearchPage;