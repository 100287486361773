/*
 * action types
 */

export const NEWS_DETAIL = 'NEWS_DETAIL'

/*
 * action creators
 */
export function getNewsDetail(result) {
  return { 
    type: NEWS_DETAIL, 
    news: result,
  }
}
