import React, { Component } from 'react';
import Logo from './img/volkswagen_logo.png';
import PubSub from 'pubsub-js'
import { fetchValues } from '../../shared/http-request-util';
import iconeFavorito from './img/bt-favorito-hover.svg';
import './Header.css';
import PopOverButton from '../PopOverButton/popOverButton';
import { Link } from 'react-router-dom';
import Search from '../Search/Search';

let { getPlatform } = require('../../config/constants');

class Headers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchVisible: '',
      headerColor: '',
      txtSearch: '',
      fieldsError: [],
      isValid: false,
      cartHasItems: localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites')) : '',
      isLoggedIn: false
    };
  }

  componentDidMount() {
    //check if user is logged in
    let userData = JSON.parse(localStorage.getItem('user-data'));
    if (userData && userData.user && userData.user.loggedIn) {
      this.setState({
        isLoggedIn: true
      })
    }

    this.token = PubSub.subscribe('HAS_EVENT', this.userHasEvent.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    let userData = JSON.parse(localStorage.getItem('user-data'));
    if (userData && userData.user && userData.user.loggedIn) {
      this.setState({
        isLoggedIn: true
      })
    }
  }

  userHasEvent = () => {
    this.setState({
      hasEvent: true
    })
  }

  showSearchField = (e) => {
    e.preventDefault();
    this.setState({
      searchVisible: 'is-visible',
      overlay: 'overlay',
    });
    // document.getElementById("txtSearch").value = '';
    // document.getElementById("txtSearch").focus();
  }

  txtSearch = () => {
    // PubSub.publish('SHOW_SEARCH', 'open'); vw-display-none 
    var error = []
    if (this.refs.txtSearch) {
      alert('Por favor, preencher o campo para realizar a pesquisa');
      this.isValid = false
      error.push('txtSearch')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      this.isValid = true
      return (
        <div>
          <a href='/SearchPage'></a>
        </div>
      )
    }

  }

  isMobile() {
    var userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i)!= -1 ){
      console.log('userAgent mobile', userAgent);  
      return true;
    }

    return false; 
  }
        
  hideSearchField = (e) => {
    e.preventDefault();
    this.setState({
      searchVisible: '',
      overlay: '',
    });
  }

  showSideMenu = () => {
    PubSub.publish('SHOW_HIDE_MENU', 'open');
  }

  render() { 
    return (
      <div className={`vw-header`}>
        <Search isAutocomplete={true} showSearch={this.state.searchVisible} onClickAutocomplete={e => this.hideSearchField(e)}/>
        {/* <div className={`vw-header-search ${this.state.searchVisible}`}>
          <div className="vw-header-search-field">
          <form>
            <input type="text" id="txtSearch" name="txtSearch" ref='txtSearch' onChange={e => this.findAnwser(e.target.value)} className={"vw-header-search-input form-control "  + (this.state.fieldsError.indexOf('txtSearch') > -1  ? 'input-error' : '') } autoComplete="off"  maxLength="60"/>
            <div className="vw-header-search-icon">
              { !this.refs.txtSearch ?
                <a href='#'>
                  <i className="icon brand-icon is-search is-middle " ></i>
                </a>
                :
                <a href='/SearchPage' >
                <i className="icon brand-icon is-search is-middle" ></i>
                </a>
              }

            </div>   
            </form>        
          </div>          
        </div> */}
        <div className="vw-header-logo">
          <ul className="nav flex-column"> 
            <li className="nav-item">
              <Link className="nav-link active" to="/"> 
                <img alt="Volkswagen Newsroom" width="40" height="40" src={Logo} />
              </Link>
            </li>
          </ul>
        </div>
        <ul className="vw-header-icons">
            { !this.state.isLoggedIn ?
              <li className="vw-header-icons-item">
                <label className='btn-header-login'>
                  <Link to="/login">              
                    <i className="icon brand-icon login-icon is-middle"></i>
                  </Link>
                </label>
              </li>
            : 
              <li className="vw-header-icons-item loggedin-button">
                <div id="headerLoggedButtonContainer" className="buttonContainer">
                  <PopOverButton 
                      popOverType="loggedin"
                      containerName="headerLoggedButtonContainer"
                      isLoggedMenu={true}
                      hasEvent={this.state.hasEvent}
                    />
                </div>
              </li>
            }

          <li className="vw-header-icons-item ">
            <label className='btn-header-search'>
              <a href="#" onClick={this.showSearchField}>
                <i className="icon brand-icon is-search is-middle"></i>
              </a>
            </label>
          </li>
          <li className="vw-header-icons-item vw-header-icons-item-fav">
            <div id="headerCartButtonContainer" className="buttonContainer">
              <PopOverButton 
                  popOverType="cart"
                  containerName="headerCartButtonContainer"
                  isCartPreview={true}
                />
                {this.state.cartHasItems.length > 0 ? <span className='cart-has-item'></span> : null }
            </div>
          </li>
          
          <li className="vw-header-icons-item">
            <label>
              <span onClick={this.showSideMenu} >
                <i className="icon brand-icon is-menu-opener is-middle">
                </i>
              </span>
            </label>
          </li>
        </ul>
        <div className={`${this.state.overlay}`} onClick={e => this.hideSearchField(e)}></div>
      </div>
    );    
  }
}

export default Headers;
