import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ComponentsCar.css';

import TitleCar from '../TitleCar/TitleCar';
import PageTitle from '../PageTitle/PageTitle';
import Slider from "react-slick";
import NextArrow from '../Arrow/NextArrow'
import PrevArrow from '../Arrow/PrevArrow'
import { withRouter } from 'react-router-dom';
import { timingSafeEqual } from 'crypto';

class ComponentsCar extends Component {


  constructor(props) {
    super(props);
    this.state = {
      axis: 'x',
      cars: props.cars,
      selectLoanCarValue: '',
      selectLoanCarValue_Validation: '',
      disabledCarLinkClass: ''
    }
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      cars: nextProps.cars
    })
    this.disabledCarLink()
  }

  _goCarDetails = (elem) => {
    if(elem.currentTarget.classList.value.indexOf('car-link-disabled') > -1) {
      // elem.stopPropagation()
      // elem.preventDefault()
      return false
    }
    let carId = elem.currentTarget.dataset.carid
    if(this.props.showRadioButton) {
      if(this.props.selectLoanCar) {
        if(!this.props.selectedFirstCar && this.props.familySlider == 'carOption-2') return
        let otherSlider = this.props.familySlider == 'carOption-1' ? 'carOption-2' : 'carOption-1'
        this.setState({
          selectLoanCarValue: carId.substring(carId.lastIndexOf('-')).replace('-', ''),
          disabledCarLinkClass: `linkCar-${otherSlider}-${carId.substring(carId.lastIndexOf('-')).replace('-', '')}`
        }, () => {
            // this.selectRadioCar(carId.substring(carId.lastIndexOf('-')).replace('-', ''))
            this.props.selectLoanCar(this.state.selectLoanCarValue)
            this.setState({
              selectLoanCarValue_Validation: `checkCar-${this.props.familySlider}-${this.state.selectLoanCarValue}`
            }, () => {
              console.log(this.state.disabledCarLinkClass)
            })
        })
      }
    } else {
      this.props.history.push(`/cars/car/${carId.substring(carId.lastIndexOf('-')).replace('-', '')}`)
      // window.location.href = 
    }
  }

  selectRadioCar = () => {
    if(this.state.selectLoanCarValue) {
      let radioRef = `checkCar-${this.props.familySlider}-${this.state.selectLoanCarValue}`
      if(!this.refs[radioRef]) return
      if(!this.props.selectedFirstCar && this.props.familySlider == 'carOption-2') return
      this.props.selectLoanCar(this.state.selectLoanCarValue)
      this.setState({
        selectLoanCarValue_Validation: radioRef
      })
    }
    
  }

  disabledCarLink = () => {
    let sliderRef = this.props.familySlider == 'carOption-1' ? this.props.selectedSecondCar : this.props.selectedFirstCar
    let linkRef = `linkCar-${this.props.familySlider}-${sliderRef}`
    if(!this.refs[linkRef]) return
    // this.refs[linkRef].classList.add('car-link-disabled')
    this.setState({
      disabledCarLinkClass: linkRef
    })
  }

  buildItemsList() {
    const categories = [];
    const filteredCars = this.state.cars.reduce((carsSoFar, { id, categoryCar, title, mediaThumb }) => {
      if (!carsSoFar[categoryCar]) carsSoFar[categoryCar] = [];
      var objCar = { "name": title, "image": mediaThumb, "id": id };
      carsSoFar[categoryCar].push(objCar);
      if (categories.indexOf(categoryCar) < 0) categories.push(categoryCar)
      return carsSoFar;
    }, {});
    
    var itemsList = categories.map((categoryCar) => {
      return (
        <div>
          <div className="row car-margin">
            <div className="col">
              <TitleCar title={categoryCar} />
            </div>
          </div>
          <div className="row ">
          
            {filteredCars[categoryCar].map((car) => {
              return (
                <div className="col-sm-6 col-md-4 text-center">
                  <Link className='car-link' to={`/cars/car/${car.id}`}>
                    <img src={car.image}></img>
                    <PageTitle className="vw-font-20px vw-no-padding" title={car.name} />
                    {this.props.showRadioButton ? <input type="radio" id="checkCar" name="carSelect" value={car.id} className='inputRadioForm'></input> : null}
                  </Link>
                </div>
              )
            })
            }
              
          </div>
        </div>
      )
    }
    )
    return itemsList;
  }

  render() {
    if (this.state.cars != null && this.state.cars.length > 0) {
      var items = this.state.cars.map((car) => {
        let item = `checkCar-${this.props.familySlider}-${car.id}`
        let isChecked = ''
        if(item == this.state.selectLoanCarValue_Validation) {
          isChecked = 'isChecked'
        }
        let isDisabled = false
        if(car['disabled-'+this.props.familySlider]) {
          isDisabled = true
        }
        return (
          <div key={`ref-${car.id}`} className="col-sm-12 col-md-12 text-center">
            <div ref={`linkCar-${this.props.familySlider}-${car.id}`} className={`car-link ${(!this.props.selectedFirstCar && this.props.familySlider == 'carOption-2') || isDisabled ? 'car-link-disabled' : ''}`} data-carId={`checkCar-${this.props.familySlider}-${car.id}`}  onClick={this._goCarDetails}>
              <img src={car.mediaThumb}></img>
              {/* {this.props.showRadioButton ? <input disabled={true} data-carId={car.id} defaultChecked={item == this.state.selectLoanCarValue_Validation} data-familySlider={this.props.familySlider} className={`carSelectRadio inputRadioButton  ${isChecked} `} type="radio" ref={`checkCar-${this.props.familySlider}-${car.id}`}  id={`checkCar-${this.props.familySlider}-${car.id}`} name={`checkCar-${this.props.familySlider}`} value={car.id}></input> : null} */}
              {this.props.showRadioButton ? <p className={`carSelectRadio inputRadioButton  ${isChecked} `} ref={`checkCar-${this.props.familySlider}-${car.id}`}></p> : null }
              <PageTitle className="vw-font-20px vw-no-padding" title={car.title} />
            </div>
          </div>
        )
      }
      )
      var settings = {
        dots: true,
        arrows: true,
        infinite: this.state.cars.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        verticalSwiping: false,
        touchThreshold: 40,
        centerMode: false,
        variableWidth: false,
        nextArrow: <NextArrow className='vw-next-arrow ' />,
        prevArrow: <PrevArrow className='vw-prev-arrow car-prev' />,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 1,
            slidesPerRow: 1
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: this.state.cars.length > 3 ? true : false,
            rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 1,
            slidesPerRow: 1
          }
        }, {
          breakpoint: 544,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            rows: this.props.itemsPerSlide ? this.props.itemsPerSlide : 1,
            slidesPerRow: 1
          }
        }],
        beforeChange: (current, next) => {
          console.log('beforeChange')
        },
        afterChange: current => {
          console.log('afterChange')
        }
      };
      return (
        <div className="vw-car car-container">
          {this.props.isSlider ?
            <div className="row car-margin car-inside">
              <PageTitle title={this.props.componentTitle ? this.props.componentTitle : "Carros"} />
              <Slider {...settings}>
                {items}
              </Slider>
            </div>
            :
            <div>
              {this.buildItemsList()}
            </div>
          }
        </div>
      );
    } else {
      return (<div></div>)
    }
  }
}

export default withRouter(ComponentsCar);
