
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import './PickupCar.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import ApprovalCard from '../../components/ApprovalBackoffice/ApprovalCard/ApprovalCard';

import { getPickupCar } from './PickupCar.actions';
import { fetchValuesAuth, fetchValuesOptions} from '../../shared/http-request-util';
import AlertModal from '../../components/AlertModal/AlertModal';

let { listPickupCarAPI, checkinPickupCarAPI, checkoutPickupCarAPI } = require('../../config/constants');


class PickupCar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loanList: props.list
    }
    this.update = false;
  }

  componentDidMount() {
    ReactGA.pageview('/retirar');
    this.setState({
      update: true,
    })
    this.props.dispatch(fetchValuesAuth(listPickupCarAPI(), 'GET', getPickupCar));

  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, nextProps.list)

    let loanList  = []
    nextProps.list.map((item) => {
      //TODO filter by selected car ID
      let loanListObj = {};
      loanListObj['id']= item.id

      let startDateItem
      let pickupDate
      if(item.startDate) {
        startDateItem = new Date(item.startDate).toISOString().split('T')[0]
        pickupDate = startDateItem.split('-')[2] + '/' + startDateItem.split('-')[1] + '/' + startDateItem.split('-')[0]
      }
      // console.log(item)
      if(item.car_frotum) {
        loanListObj['title'] =  item.car_frotum.car.title
        loanListObj['color'] = item.car_frotum.color
        loanListObj['items'] = [{
          name: 'Placa:',
          value: item.car_frotum.placa
        },{
          name: 'Frota:',
          value: item.car_frotum.frota
        },{
          name: 'Chassi:',
          value: item.car_frotum.chassis
        },{
          name: 'Data:',
          value: pickupDate
        }]
      } else {
        loanListObj['title'] =  ''
        loanListObj['color'] = ''
        loanListObj['items'] = []
      }
      
      loanList.push(loanListObj)
    })

    this.setState({
      loanList: loanList
    })
  }

  _pickupCarAction = (e, car_frotum) => {
    let today = new Date().toISOString().split('T')[0]
    let pickupDate = today.split('-')[2] + '/' + today.split('-')[1] + '/' + today.split('-')[0]
    let alertMsg = `Confirma a retirada do veículo <strong>${car_frotum[1].value}</strong> em ${pickupDate}`
   
    this.setState({
      loanToInteract: e,
      modalTitle: 'Alerta!',
      modalMessage: alertMsg,
      isDecisionModal: true
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  _modalDecision = (e) => {
    console.log('modal decision', e)
    if(e && this.state.isDecisionModal) {
      this.setState({
        showModal: false
      }, () => {
        this._goToPickUpCar(this.state.loanToInteract)
      })
    } else {
      if(!this.state.isDecisionModal) this.props.history.push('/home-comodato')
    }
  }

  _goToPickUpCar = (e) => {
    console.log(e)
    let carToPickup = {
      idLoan: e
    }
    console.log('sendpickupcar', carToPickup)
    let _that = this
    let url = checkoutPickupCarAPI()
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': JSON.parse(localStorage.getItem('user-data')).token
      },
      body: JSON.stringify(carToPickup)
    };
    function callback(response) {
      _that.setState({
        isDecisionModal: false,
        modalTitle: 'Obrigado',
        modalMessage: 'Veículo retirado com sucesso.'
      }, () => {
        _that.setState({
          showModal: true
        })
      })
    }
    fetchValuesOptions(url, options, callback)
  }

  render() {
    if (this.state.update) {
      return (
        <div className="vw-checklist container mb-4">
          <div className='row'>
            <div className="col-12">
              <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / <a href='/home-comodato'>Empréstimo de Veículos</a>" />
              <PageTitle title="Retirar veículo" />
              <PageSubTitle text="Selecione o veículo para retirar" />
            </div>      
          </div>
          <div className='row'>
              {this.state.loanList.length > 0 ? 
                this.state.loanList.map((item) => {
                  return (
                    <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                      <div className='mb-4'>
                        <ApprovalCard
                          isChecklistCard={true}
                          model={item.title}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          userName={item.isOwner ? item.user.name: item.driverName}
                          numberLoan={item.id}
                          carColor={item.color}
                          id={item.id}
                          carItems={item.items ? item.items : []}
                          showCancelButton={true}
                          cancelButtonText={'Retirar'}
                          cancelAction={e => this._pickupCarAction(e, item.items)} />
                      </div>
                    </div>
                  )
                })
              : 
                <div className='checklistEmpty col-12 col-sm-6 col-md-5 col-lg-4 mt-4 mb-4'>
                  <div className='mb-4'>
                    <PageSubTitle text="Nenhum item encontrado" />
                  </div>
                </div>
              }
            </div>

            <AlertModal
              isDecisionModal={ this.state.isDecisionModal }
              configModalCallback={e => this._modalDecision(e)}
              title1={ this.state.modalTitle}
              messageHTML={ this.state.modalMessage}
              showModal={this.state.showModal}
            />

          </div>
      );
    } else {
      return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  console.log(state)
  return {
    list: state.PickupCarReducer.list
  }
}

export default connect(mapStateToProps)(PickupCar);
