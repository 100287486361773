import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
// import ArticleTitle from '../../../components/ArticleTitle/ArticleTitle';
// import Loading from '../../../components/Loading/Loading';
// import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';

import './Imprint.css'; 


// let { apiEndpoint } = require('../../../config/constants');
// let { ImprintTermoAPI } = require('../../config/constants');


class Imprint extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    this.update = true;
    // this.props.dispatch(fetchValues(apiEndpoint() + ImprintTermoAPI() + this.props.match.params.imprint_id, getImprint));
  }  

  
  render() {
    if (this.update) {
      return (
        // TEM QUE AJUSTAR QUANDO TIVER CONSUMO DA API
       
          <div className="row vw-imprint">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a> / ${this.props.imprint.title}`} />
              <PageTitle title={this.props.imprint.title}/>
            </div>
            <div className="col-12"> 
              <PageSubTitle  title={this.props.imprint.title}/>           
          </div>
          </div> 
        
      );       
    } else {
      return (
        <div className="row vw-imprint">
          <div className="col-12">
            { !this.props.hideBreadcrumb ?
                <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a>  / Termos e Condições Gerais de Uso `} />
                : null
            }
            <PageTitle title="Termos e Condições Gerais de Uso"/>
            <PageSubTitle  text="(Atualizado em 18/03/2019)"/> 
            <PageSubTitle  text="O presente documento “Termos e Condições Gerais de Uso”, doravante denominado “Termo de Uso”, aplica-se aos usuários, doravante denominado “Usuário”, do site www.vwnews.com.br, doravante denominado “VW News BR”, de propriedade da Volkswagen do Brasil Indústria de Veículos Automotores Ltda., doravante denominada “VWB”, com sede na Estrada Marginal da Via Anchieta, Km 23,5, na Cidade de São Bernardo do Campo, Estado de São Paulo, inscrita no CNPJ/MF sob nº 59.104.422/0001-50."/> 
          </div>
          <div className="col-12">            
          <u><PageSubTitle  text="1. Do Uso"/></u>
          </div>
          <div className="col-12 "> 
              <PageSubTitle  text="1.1 Antes de utilizar quaisquer dos serviços, informações, imagens ou dados da VW News BR, o Usuário declara que fez a leitura completa e atenta das regras deste documento e da Política de Privacidade e Proteção de Dados da VW News BR, estando plenamente ciente conferindo sua livre e expressa concordância com os termos aqui estipulados."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.2 O Usuário está ciente que para o uso de algumas funcionalidades disponíveis na VW News BR, o Usuário deverá, igualmente e concomitantemente, tomar conhecimento e aceitar os Termos de Uso Próprio e Políticas de uso. "/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.3 O presente Termo de Uso está sujeito a constante melhoria e aprimoramento. Assim, a VWB se reserva o direito de modificá-lo a qualquer momento, conforme sua finalidade e conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica, cabendo aos Usuários verificá-lo sempre que efetuar o acesso na VW News BR."/>  
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.4 Para o uso da VW News BR o Usuário não precisa realizar nenhum cadastro, salvo para o recebimento de Newsletter ou acesso à área restrita. "/>           
          </div>         
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="1.4.1 O cadastro para recebimento de Newsletter é aberto a todos os Usuários. Para tanto, o Usuário deverá fornecer informações corretas e verdadeiras, bem como, mantê-las atualizadas."/>           
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="1.4.2 O acesso à área restrita será concedido ao Usuário previamente autorizado pela VWB. A VWB tem total discricionariedade quanto à aprovação do acesso do Usuário à área restrita, não sendo obrigada a revelar a motivação da sua aprovação ou recusa."/>           
          </div>
          {/* <div className="col-12 offset-1"> 
              <PageSubTitle  text="1.4.3 Para o cadastro na VW News BR o Usuário deverá atentar para a Política de Privacidade e Proteção de Dados, e aos respectivos Termos de Uso Próprio disponíveis na VW News BR. "/>           
          </div> */}
          <div className="col-12"> 
              <u><PageSubTitle  text="2. Do Conteúdo da VW News BR"/></u>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.1 A VW News BR é um site direcionado, principalmente, ao Usuário, profissional da Imprensa e aos entusiastas da marca Volkswagen."/>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.2 A VW News BR disponibiliza ao Usuário informações institucionais, técnicas, imagens, vídeos e Press Releases sobre a VWB e sobre o grupo Volkswagen."/>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.3 A VWB envida seus melhores esforços para manter os dados e informações disponíveis na VW News BR atualizados e assertivos, contudo, não garante a infalibilidade dos mesmos.  "/>         
          </div>
          {/* <div className="col-12 offset-1"> 
              <PageSubTitle  text="2.3.1 A VWB não se responsabiliza por quaisquer perdas e danos de qualquer natureza em virtude de qualquer utilidade que o Usuário possa ter dado às opiniões, dados ou informações disponíveis na VW News BR."/>        
          </div> */}
          <div className="col-12"> 
              <PageSubTitle  text="2.4 Toda a informação e material, incluindo, mas não se limitando a textos, imagens, documentos ou vídeos, disponibilizados na VW News BR em forma de Press Release tem finalidade puramente informativa. "/>         
          </div> 
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="2.4.1 O material da Press Release poderá ser utilizado em editoriais em mídias sociais, editoriais de Influencers na Internet, editoriais jornalísticos, bem como em editoriais internos de empresas para seus colaboradores."/>         
          </div>
          {/* <div className="col-12"> 
              <PageSubTitle  text="2.5 A VW News BR disponibiliza ao Usuário acesso à sites de terceiros."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="2.5.1O presente Termo de Uso não se aplica aos respectivos sites de terceiros."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="2.5.2 A VWB não se responsabiliza por quaisquer perdas e danos de qualquer natureza causado pelo conteúdo disponibilizado por terceiros."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.6 Toda a informação e material, incluindo, mas não se limitando a, textos, imagens, documentos ou vídeos, disponibilizados na VW News BR em forma de Press Release tem finalidade puramente informativa. "/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="2.6.1 O material da Press Release poderá ser utilizado em editoriais em mídias sociais, editoriais de Influencers na Internet, editoriais jornalísticos, bem como em editoriais internos de empresas para seus colaboradores."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.7 O Usuário se compromete a não:"/>         
          </div>
          <div className="col-12 offset-1 alinhamento"> 
              <li><span>corromper ou burlar sistema ou medida de segurança da VWB;"</span></li>    
              <li><span>danificar, alterar a estrutura lógica ou física e a integridade ou o funcionamento de sistemas, servidores ou redes da VWB ou redes àquelas conectadas, através do uso de equipamentos ou sistemas;</span></li>
              <li><span>utilizar imagens, áudios ou vídeos disponibilizados pela VW News BR para fins comerciais;</span></li>
              <li><span>incorporar a VW News BR ou parte desta em outro site, seja privado ou comercial. </span></li>
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.8 O Usuário concorda em usar a VW News BR de acordo com o presente Termo de Uso, respectivos Termos de Uso Próprio e Políticas de uso."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="2.9 O Usuário se compromete a usar os recursos e informações da VW News BR em conformidade com a legislação vigente, a moral e os bons costumes."/>         
          </div> */}
          <div className="col-12"> 
              <u><PageSubTitle  text="3. Responsabilidades da VWB"/></u>       
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="3.1 A VW News BR e suas funcionalidades são apresentadas ao Usuário na maneira como estão disponíveis, podendo passar por constantes aprimoramentos e atualizações, obrigando-se a VWB a:"/>         
          </div>

          <div className="col-12 offset-1 alinhamento"> 
              <li><span>Preservar a sua funcionalidade, com links não quebrados e utilizando layout que respeita a usabilidade e navegabilidade, sempre que possível;</span></li>    
              <li><span>Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas; e</span></li>
              <li><span>Proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas.</span></li>
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="3.2 A VWB envida seus esforços para a manutenção da disponibilidade contínua e permanente da VW News BR. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.2.1 Se isso ocorrer, a VWB fará o que estiver ao seu alcance para restabelecer o acesso à VW News BR o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiros, dos quais depende para ficar online."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.2.2 Eventuais procedimentos de manutenção que acarretem a indisponibilidade da VW News BR por longos períodos serão informados por meio dos canais oficiais de comunicação, como e-mails, perfis oficiais em mídias sociais ou telefone de atendimento."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="3.3 O Usuário não possui qualquer direito para exigir a disponibilidade da VWB tampouco poderá pleitear indenização ou reparação de danos no caso de a VW News BR permanecer fora do ar, independente da motivação."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="3.4 A VWB reserva-se ao direito de alterar o presente Termo de Uso e demais regras e critérios estabelecidos, serviços prestados, campos adicionais de cadastro, e inserção de dados, e tudo aquilo que julgar necessário para o correto funcionamento da VW News BR e seu negócio, não cabendo nenhum aviso prévio aos Usuários."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="3.5 A VWB pode de acordo com seus objetivos de negócio modificar ou descontinuar (temporária ou permanentemente) a distribuição ou a atualização da VW News BR."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="3.6 A VWB não se responsabiliza:"/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.6.1 Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos Usuários e sejam resultantes direta ou indiretamente do uso regular da VW News BR."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.6.2 Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de hackers, falhas no sistema, no servidor ou na conexão à internet, inclusive por ações de softwares que possam, de algum modo, danificar ativos físicos ou lógicos ou a conexão dos Usuários em decorrência do acesso, utilização ou navegação na VW News BR, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos neste."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.6.3 Pela navegação dos Usuários nos links externos disponibilizados na VW News BR, sendo dever do Usuário a leitura dos Termos de Uso e Política de Privacidade do recurso acessado e agir conforme o determinado."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.6.4 Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle text="3.6.5 Pelas atitudes tomadas pelos Usuário com base, direta ou indiretamente, na VW News BR, suas regras, informações, dados, pesquisas, relatórios, regulamentos, opiniões, sugestões e outros, concordando o Usuário que a utilizará com bom senso, baseado na moral e bons costumes."/>         
          </div>

          <div className="col-12"> 
              <u><PageSubTitle  text="4. Responsabilidades dos Usuários"/></u>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="4.1  Os Usuários são responsáveis e se obrigam a:"/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.1 Utilizar com retidão e de forma ética a VW News BR, respeitando as condições que regem a sua utilização e finalidade."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.2 Não utilizar as informações disponibilizadas na VW News BR para finalidades comerciais."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.3 Fornecer dados cadastrais corretos, completos e atualizados, além de informar canal de contato apto a ser acionado pela VWB para o melhor cumprimento dos serviços. Se a qualquer momento for constatado que o Usuário forneceu dados falsos ou não condizentes com a realidade, a VWB se reserva ao direito de suspender ou cancelar seu cadastro, sem prejuízo de adotar as medidas que entender cabíveis."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.4 Dispor de dispositivos e equipamentos compatíveis, serviço de conexão à Internet com antivírus e firewall habilitados, softwares devidamente atualizados."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.5 Adotar medidas em seus dispositivos tecnológicos para evitar o acesso físico e lógico por terceiros não autorizados, tais como utilização de senha e/ou biometria."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.6 Deixar seus sistemas anti-spam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da VWB, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados. A VWB não envia e-mails ou outros tipos de comunicação com links externos, desta forma, caso o Usuário receba alguma mensagem desse tipo, deve estar ciente dos riscos ao clicar no link, pois pode ser tentativa de fraude conhecida como phishing."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.7 Respeitar todos os direitos de propriedade intelectual de titularidade da VWB, incluindo todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma ali disponíveis. "/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.8 Não acessar áreas de programação da VW News BR, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.9 Não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades da VW News BR."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="4.1.10 Não utilizar softwares para automatização de acessos no VW News BR, com exceção das bibliotecas disponibilizadas pela VWB, bem como softwares de mineração ou coleta automatizada de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="4.2 As funcionalidades que compõem a VW News BR são oferecidas como ferramentas para apoiar os serviços da VWB, não conferindo ao Usuário nenhum direito sobre a VW News BR ou sobre as estruturas tecnológicas que as sustentam."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="4.3 A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da VW News BR em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais, como reconhecimento de culpa ou de qualquer infração pela VWB a direito de terceiro."/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="4.4 Na incidência de danos à VWB ou a terceiros, o Usuário responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo a exclusão da VWB, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-a livre de prejuízos e ônus."/>         
          </div>

          <div className="col-12"> 
              <u><PageSubTitle  text="5. Da Propriedade Intelectual"/></u>      
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="5.1 O Usuário garante que irá respeitar toda a propriedade intelectual, incluindo, mas não se limitando a direitos autorais morais e patrimoniais, marcas, patentes, nomes comerciais, nomes de domínio, títulos de estabelecimento, segredos industriais e comerciais da VWB e de terceiros disponibilizados na VW News BR."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="5.1.1 Não é concedido ao Usuário qualquer espécie de cessão ou autorização de uso de qualquer marca, nome, patente da VWB ou de terceiros disponibilizados na VW News BR."/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="5.1.2 O respeito, pelo Usuário, à propriedade intelectual da VWB e de terceiros inclui, mas não se limita, à imagens, músicas e vídeos disponibilizados na VW News BR. "/>         
          </div>

          <div className="col-12"> 
              <PageSubTitle  text="5.2 É vedado que o Usuário utilize imagens, músicas e vídeos disponibilizados na VW News BR de forma que contrarie a realidade para a qual foram concebidos. "/>         
          </div>
          
          <div className="col-12"> 
              <u><PageSubTitle  text="6. Da Limitação de Responsabilidade"/></u>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="6.1 A responsabilidade da VWB está limitada aos danos diretos decorrentes da execução ou inexecução do presente Termo de Uso nos casos de dolo ou culpa grave."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="6.2 Sob nenhuma circunstância a VWB, seus executivos, diretores, funcionários ou representantes legais serão responsáveis por danos ou prejuízo de toda natureza decorrentes de erros ou omissões do conteúdo ou uso de qualquer conteúdo da VW News BR, bem como da execução ou inexecução do presente Termo de Uso.   "/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="6.3 A VWB seus executivos, diretores, funcionários ou representantes legais não serão responsáveis por qualquer dano ou prejuízo de toda natureza decorrente da falta de disponibilidade ou de continuidade da VW News BR."/>         
          </div>

          <div className="col-12"> 
              <u><PageSubTitle  text="7. Atendimento aos Usuários"/></u>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="7.1 O atendimento aos Usuários poderá ser realizado por meio dos seguintes Canais de Atendimento, de segunda à sexta, das8 horas às18 horas:"/>         
          </div>
          <div className="col-12 offset-1 alinhamento"> 
              <li><span>Telefone: 11 4347- 5754</span></li>    
              <li><span>Email: imprensa.volkswagen@volkswagen.com.br</span></li>
          </div>

          <div className="col-12"> 
              <u><PageSubTitle  text="8. Disposições Gerais"/></u>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="8.1 Este Termo de Uso substitui qualquer Termo anterior, escrito ou oral, com relação a todas as questões aqui mencionadas."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="8.2 A tolerância de descumprimento de qualquer das cláusulas deste Termo de Uso não implicará em renúncia ou novação das obrigações aqui descritas."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="8.3 Se qualquer condição do presente Termo de Uso for considerada inválida, ilegal ou inexigível, as demais condições permanecerão em vigor."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="8.4 O presente Termo de Uso se aplica a todos os Termos de Uso Próprio e Políticas de uso disponíveis na VW News BR, naquilo que não conflitarem. "/>         
          </div>
          <div className="col-12 offset-1"> 
              <PageSubTitle  text="8.4.1 No caso de conflito entre o presente Termo de Uso e um Termo de Uso Próprio ou Política de uso prevalecerá o mais específico."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="8.5 Todos os registros e guarda de dados realizados no ambiente da VW News BR serão realizados e catalogados com base no horário oficial de Brasília."/>         
          </div>

          <div className="col-12"> 
              <u><PageSubTitle  text="9. Da Lei Aplicável e Jurisdição"/></u>        
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="9.1 O presente Termo de Uso é regido pelas leis da República Federativa do Brasil."/>         
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="9.2 Fica eleito o foro da cidade de São Paulo, por mais privilegiado que qualquer outro seja ou possa vir a ser, para dirimir quaisquer dúvidas ou conflitos oriundos do presente Termo de Uso.        "/>         
          </div>

        </div>
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    imprint: state.CarReducer.imprint,
  };
}

export default connect(mapStateToProps)(Imprint);

