import React, { Component } from 'react';

import './ArticleTitle.css'; 

class ArticleTitle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      className: props.className
    }
  }

  render() {
    return (      
      <div className={`vw-article-title ${this.state.className} `}>
        {this.props.title}
      </div>
    );    
  }
}

export default ArticleTitle;
