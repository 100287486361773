/*
 * action types
 */

export const CAR_DETAIL = 'CAR_DETAIL'

/*
 * action creators
 */
export function getCar(result) {
  return { 
    type: CAR_DETAIL, 
    car: result.cars,
    news: result.news,
    gallery: result.gallery
  }
}
