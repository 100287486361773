/*
 * action types
 */

export const LOANLIST_HOME = 'LOANLIST_HOME'

/*
 * action creators
 */
export function getLoanList(result) {
  return { 
    type: LOANLIST_HOME, 
    list: result.list
  }
}
