import React, { Component } from 'react';
import './RadioMultiline.css'

class RadioMultiline extends Component{
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='vw-radio-multiline mt-3'>
        <input type={ this.props.isCheckbox ? this.props.isCheckbox : 'radio'} id={`${this.props.radioType}-${this.props.id}`} onChange={this.props.onChangeRadio} ref={this.props.reference} name={this.props.radioType} value={this.props.value} className='inputRadioForm'></input>
        <label className='ml-4 mr-4 radio-car-title' htmlFor={`${this.props.radioType}-${this.props.id}`}>{this.props.name}</label>
            {this.props.text ? 
                <p className='radio-car-desc'>
                    {this.props.text} 
                </p>
            : null }
      </div>
    )
  }
}
export default RadioMultiline;