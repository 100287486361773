import React, { Component } from 'react';

import './ShortImage.css'; 

import ExpandImage from '../ExpandImage/ExpandImage'

// ******************************************************************************************
// Props required for download:
// mediaLow = 'string'
// media = 'string'
// mediaMedium = 'string'
// imageNo = 'string'
// copyright = 'string'
// rights = 'string'
// imageName = 'string'
// imageSizeSmall = 'string'
// imageSizeMedium = 'string'
// imageSizeLarge = 'string'
// date = 'string'
// ******************************************************************************************
class ShortImage extends Component {

  constructor(props) {
    super(props);        
    const roundBorder = props.roundBorder ? 'no-border' : '';
    let pDownload = true;
    if (props.download != undefined) {
      pDownload = props.download;    
    }
    
    this.state = {
      roundBorder: roundBorder,
      download: pDownload,
    }
  }

  toggleParentModal() {
    return this.props.toggleModal()
  }

  openVideoModal(isImage, e) {
    this.refs['childVideo'].toggleModal(isImage, e)
    e.stopPropagation()
  }

  render() {    
    return (            
      <div className="short-image">
        <div className="image">
          <span className={`cropped-image ${this.state.roundBorder} `}>
            <span className="cropped-image--sizer">
              <span className="lazyloaded" data-noscript="true">
              {this.state.download ? (
                <ExpandImage 
                  ref='childVideo'
                  mediaLow={this.props.mediaLow}
                  media={this.props.media}
                  mediaMedium={this.props.mediaMedium}
                  imageNo = { this.props.imageNo }
                  copyright = { this.props.copyright }
                  rights = { this.props.rights }
                  imageName = { this.props.imageName }
                  mediaSizeLow = { this.props.mediaSizeLow }
                  mediaSizeMedium = { this.props.mediaSizeMedium }
                  mediaSize = { this.props.mediaSize }
                  date = { this.props.date }
                  showPopOverButtons={ this.props.showPopOverButtons}
                  modalOpen={this.props.modalOpen}
                  toggleModal={this.toggleParentModal.bind(this)}
                  activeMedia={ this.props.activeMedia}
                  contentLink={ this.props.contentLink}
                  GAText={this.props.GAText}
                  pdfURL={ this.props.pdfURL}
                />
              ) : (
                <img  src={this.props.mediaLow} /> 
              )
            }
              </span>                
            </span>              
          </span>
        </div>
      </div>
    );    
  }
}

export default ShortImage;
