import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listNews } from './NewsComponent.actions';
import { fetchValues } from '../../shared/http-request-util';


//Components
import PageTitle from '../PageTitle/PageTitle';
import NewsComponentItem from './NewsComponentItem/NewsComponentItem';
import Button from '../Button/Button';

import './NewsComponent.css'; 


let { apiEndpoint } = require('../../config/constants');
let { newsAPI } = require('../../config/constants');
let { newsBySection } = require('../../config/constants');

class NewsComponent extends Component { 

  constructor(props) {
    super(props);
    this.limit = 10;
  }    

  _createTitle() {    
    return(
      <div className="row">
        <div className="col">            
          <PageTitle title="News"/>
        </div>
      </div>
    )
  }

  _increaseLimit() {
    this.limit += 10;
    return this.limit;
  }

  _callMore() {
    this._refresh(this._increaseLimit());
  }

  _goToNews() {
    window.location = "/news";
  }

  _renderButton() {
    if (this.props.page != 1) {
      if (this.load && this.props.isVertical) {
        return (
          <Button onClick={ this._callMore.bind(this)} text='Ver Mais News'/>
        )  
      } else {
        return (          
          <Button onClick={ this._goToNews } text='Ver todas as News'/>
        )
      }
    }
  }
  
  componentDidMount() {    
    //If is null, then load from database
    if (!this.props.news) {
      this.load = true;
      this._refresh(this.limit);
    } else {
      setTimeout(() => {
        this.setState({})
      }, 500)
    }  
  }  

  _refresh(limit) {
    //Check which endpoint to call
    if (this.props.textEnum != undefined && this.props.isVertical) {
      if (this.props.idLevel1 != undefined) {
        if (this.props.idLevel2 != undefined) {
          this.props.dispatch(fetchValues(apiEndpoint() + newsBySection(1,limit, this.props.textEnum, this.props.idLevel1, this.props.idLevel2), listNews));              
        } else {
          this.props.dispatch(fetchValues(apiEndpoint() + newsBySection(1,limit, this.props.textEnum, this.props.idLevel1), listNews));
        }
      } else {
        this.props.dispatch(fetchValues(apiEndpoint() + newsBySection(1,limit, this.props.textEnum), listNews));
      }      
    } else {        
      this.props.dispatch(fetchValues(apiEndpoint() + newsAPI(1, limit), listNews));  
    }
  }

  render() {
    if (this.props.news != null && this.props.news.length > 0) {
      return (      
        <div className={'newscomponent-container ' + (this.props.isVertical ? 'isVertical' : 'isSlider')}>
          { 
            this.props.hideTitle ? null : this._createTitle() 
          }
          <NewsComponentItem 
            newsItems={ this.props.news } 
            itemsPerSlide={ this.props.itemsPerSlide } 
            isVertical={ this.props.isVertical }
            isReadMore={ this.props.isReadMore }        
            />
          {this._renderButton()}            
        </div>
      );      
    } else {
      return (<div></div>);
    }

  }
}

// Add this function:
function mappropsToProps(props) {
  console.log(props)
  if (props.NewsComponentReducer.news != null && props.NewsComponentReducer.news.length > 0) {
    return {
      page: props.NewsComponentReducer.page,
      news: props.NewsComponentReducer.news
    };  
  } 

}

export default connect(mappropsToProps)(NewsComponent);
//export default NewsComponent;
