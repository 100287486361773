const initialState = {
  tweets: []
}

const TwitterReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'TWITTER_DETAIL':
      state = initialState;
      return {
        tweets: [...state.tweets, ...action.tweets],
      }
    default:
      return state;
  }
};

export default TwitterReducer;