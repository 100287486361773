import fetch from 'cross-fetch'

/*
* Fetch Values
*/
export default function sendValues(endpoint, body) {
  let headers = { 'Content-Type':'application/json' } 
  console.log(endpoint, headers, body)
  return function (dispatch) {
    return fetch(`${endpoint}`, { method: 'post', headers: headers, body: JSON.stringify(body) })
    .then(
      response => response.json(),
      error => alert('Error')
    )
    .then(result => {
     console.log(' resultado newsletter', result)
    })    
  }
}
