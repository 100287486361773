/*
 * action types
 */

export const VERSION_DETAIL = 'VERSION_DETAIL'

/*
 * action creators
 */
export function getVersion(result) {
  return { 
    type: VERSION_DETAIL, 
    version: result.version,
    news: result.news,
    gallery: result.gallery
  }
}
