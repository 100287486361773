/*
 * action types
 */
export const HOME_CONTENT = 'HOME_CONTENT'

/*
 * action creators
 */
export function getHome(result) {
  return { 
    type: HOME_CONTENT, 
    news: result.news,
    gallery: result.gallery,
    destaque: result.destaque,
    cars: result.cars
  }
}