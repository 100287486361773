import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVersion } from './Version.actions';
import { fetchValues } from '../../../shared/http-request-util';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
//import ShortImage from '../../components/ShortImage/ShortImage';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import iconetecnico from './img/tecnico@3x.png';

import iconedonwload from './img/donwload@3x.png';

//import GalleryItem from '../../../components/Gallery/GalleryItem/GalleryItem';
import './Version.css'; 
import ShortImage from '../../../components/ShortImage/ShortImage';
import iconefacebook from './img/icone-facebook.svg';
import iconetwitter from './img/icone-twitter.svg';
import iconefoto from './img/icone-foto.svg';
import btdownloadnormal from './img/bt-download-normal.svg';
import iconDownload from './img/bt-download-hover.svg';
import iconecompartilhar from './img/bt-compartilhar-hover.svg';

import PopOverButtton from '../../../components/PopOverButton/popOverButton'


let { apiEndpoint } = require('../../../config/constants');
let { carVersionAPI } = require('../../../config/constants');
let { urlEndpoint } = require('../../../config/constants');


class VersionPDF extends Component {

  constructor(props) {
    super(props);
    this.update = false;



    var medias=[
      {
      media: this.props.version.media,
      mediaLow: this.props.version.mediaLow,
      mediaMed: this.props.version.mediaMed,
      }
    ]

    this.state = {
      medias: medias,
    }

  }

  componentDidMount() {    
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + carVersionAPI(this.props.match.params.car_id,this.props.match.params.version_id), getVersion));
  }   

  
  _getPDFName = () => {
    if (this.props.version.pdfMedia) {
      let pdf = this.props.version.pdfMedia.split('/');
      return pdf[pdf.length - 1];
    }
  }

  _renderPDF = () => {
    if (this.props.version.pdfMedia) {
      return (
        <div className="col-12"> 
          <br />
          <div className="row bg-interno ">
            <div className="col-2 col-sm-2 col-md-1 align-self-center">
              <img src={iconetecnico }></img>
            </div>
            <div className="col-6 col-sm-8 col-md-9 align-self-center"> 
              <PageSubTitle text={ this._getPDFName() }/>
            </div>                  
            <div className="col-2 col-sm-1 offset-1 align-self-center iconedonwload"> 
            <a href={this.props.version.pdfMedia} download={this.props.version.title} target="_blank">
              {/* <img src={iconedonwload }></img> */}
              <div className="icondownloadVersion"></div>
            </a>
            </div>
          </div>
      </div>
      )
    }
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {


    if (this.update) {
      // console.log('this.props.version',this.props.version);
      return (      
        <div className="vw-carro-interno">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/cars/models'>Carros</a> / ${this.props.version.title} `} />
              <PageTitle title={this.props.version.title}/>
              <HighlightMedia media={this.props.version.media} mediaMedium={this.props.version.mediaMed} mediaLow={this.props.version.mediaLow} isAutoPlay={true}/>
            </div>            
            </div>
            <div className="row">
            <div className="col-12">            
              <PageSubTitle text={this.props.version.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">            
              <TextBody text={this._convertDescription(this.props.version.description)}/>
            </div>
            <br/>
            <Template GAText={ this.props.version.title } templates={this.props.version.templates} pdf={ true } />
            <NewsComponent isVertical={true} textEnum="Carros" idLevel1={ this.props.match.params.car_id } idLevel2={ this.props.match.params.version_id }/>   
          </div> 
        </div>
      );       
    } else {
      return (<Loading></Loading>);
    }
   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    version: state.VersionReducer.version,
    news: state.VersionReducer.news,
    gallery: state.VersionReducer.gallery
  };
}

export default connect(mapStateToProps)(VersionPDF);
