import React, { Component } from 'react';
import RegistryComponent from '../../components/Registry/RegistryComponent';

class Registry extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div class="row">
                <div class="col-12 RegistryComponent">
                    <RegistryComponent />
                </div>
            </div>

        )
    }
}

export default Registry;