/*
 * action types
 */

export const LIST_CARS = 'LIST_CARS'

/*
 * action creators
 */
export function listCars(result) {
  return { 
    type: LIST_CARS, 
    cars: result.cars,
    news: result.news,
    gallery: result.gallery
  }
}