import React, { Component } from 'react'
import './Gallery.css'
import GalleryItem from './GalleryItem/GalleryItem';

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      axis: 'x',
      imagesItems: props.gallery
    }

  }

  render() {
    
  if (this.state.imagesItems != null && this.state.imagesItems.length > 0) {

    return (  
      <div className={"gallery-full " + (this.props.whiteBG ? 'white-bg' : '' )}>
        <div className='container'>
          <div className="row">
            <div className="col-12"> 
              <GalleryItem title={ this.props.title } gallery={this.props.gallery} whiteBG={this.props.whiteBG}/> 
            </div>
          </div>
        </div>
      </div>    
      );          
    } else {
      return (<div></div>);
    } 
  }
}


export default Gallery;

