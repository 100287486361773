
import { fetchValuesOptions } from '../../shared/http-request-util';

export default class VwIdentityKit {

  /**
   * @param {options} object - { clientId, urlVwid, urlCallback }
   */
  constructor(options) {
    this.clientId = options.clientId;
    this.clientSecret = options.clientSecret;
    this.urlCallback = options.urlCallback; 
    this.urlVwId = options.urlVwId;
  }

  /**
   * A method to login with VW Identity Kit
   */
  mountRedirectUrl() {
    return this.urlVwId + 'authorize?&client_id=' + this.clientId +
      '&scope=openid&response_type=code&redirect_uri=' + this.urlCallback + '&state=1234567890';//&prompt=login
  }

  /**
   * A method to get email from code returned by VW Identity Kit
   * @param {code} string - code returned from redirect url
   */
  getInfoFromCode(code) {
    let id_token;
    return new Promise((res, rej) => {
      this.getIdTokenByCode(code).then((result) => {
        id_token = result;
        return this.getEmailFromIdToken(id_token)
      }).then((email) => {
        let info = {
          token: id_token,
          email: email
        }
        res(info)
      }).catch((err) => rej(err))
    })
  }

  getIdTokenByCode(code) {
    let credentials = Buffer.from(this.clientId + ':' + this.clientSecret).toString("base64");
    let url = `${this.urlVwId}token?code=${code}&client_id=${this.clientId}&redirect_uri=${this.urlCallback}&grant_type=authorization_code`;
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${credentials}`
      }
    };
    return new Promise((res, rej) => {      
      function callback(body) {
        if (body && body.id_token) res(body.id_token)
        else rej();
      }
      fetchValuesOptions(url, options, callback)
    });
  }

  getEmailFromIdToken(token) {
    return new Promise((res) => {
      function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
      };
      let decoded = parseJwt(token)
      res(decoded.email);
    });
  }
}
