import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLatestPhotos } from './MediaCenter.actions';
import { fetchValues } from '../../shared/http-request-util';


import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Gallery from '../../components/Gallery/Gallery';
import Speak from '../../components/Speak/Speak';
import Loading from '../../components/Loading/Loading';
import ReactGA from 'react-ga';

import './MediaCenter.css'; 

let { apiEndpoint } = require('../../config/constants');
let { galleryLatestPhotos } = require('../../config/constants');


class MediaCenter extends Component {

  constructor(props) {
    super(props);
    this.update = false;    
  }

  componentDidMount() {   
    ReactGA.pageview('/media-center');
    this.update = true; 
    this.props.dispatch(fetchValues(apiEndpoint() + galleryLatestPhotos(1, 9), getLatestPhotos));  
  }  

  render() {
    if (this.update) {      
      return (      
        <div className="vw-media-C">
          <div className="row">
            <div className="col-12">
            <div className="col-12">
              <Breadcrumb text="<a href='/'>Newsroom</a> / Media Center" />
              <PageTitle title="Media center"/>            
              <PageSubTitle text="Aqui você encontra todas as novidades, fotos e vídeos divulgados pela Volkswagen do Brasil."/>
            </div >
            <NewsComponent isVertical={false}/>
            <Gallery title='Últimas Fotos' gallery={this.props.latestPhotos}/>
            <Speak/>  
            </div>
          </div> 
        </div>
      );       
    } else {
      return (
        <Loading />
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  //alert(JSON.stringify(state.MediaGalleryReducer));
  return {
    latestPhotos: state.MediaCenterReducer.latestPhotos
  };
}

export default connect(mapStateToProps)(MediaCenter);