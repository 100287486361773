import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

// import PubSub from 'pubsub-js'
import { searchResult } from './Search.actions';
import { fetchValues } from '../../shared/http-request-util';
import { connect } from 'react-redux';
import './SearchComponent.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import NewsComponentItem from '../NewsComponent/NewsComponentItem/NewsComponentItem';
let { apiEndpoint } = require('../../config/constants');
// let { homeAPI } = require('../../config/constants');

class SearchComponent extends Component {

  constructor(props) {
    super(props);
    this.token = "";
    this.update = false;
    this.state = {
      isAutocomplete: true,
      buscaTeste: '0',
      titleSearchResult: 'Todos os Resultados',
      txtSearchpage: this.refs.txtSearchpage,
      valueSearchPage: '',
      searchResult: [],
      searchHeaders: ['listAll'],
      searchResultAutocomplete: [],
      fieldsError: [],
      isValid: false,
      showSearch: this.props.showSearch,
      activeTab: 0,
      isLoading: false,
      typing: false,
    };

    this.isValid = false
    this.fieldsError = []
  }

  componentWillReceiveProps(nextProps) {
    if(this.state.searchResult.length < 1) {
      if(nextProps.querySearch || this.state.valueSearchPage) this.findAnwser({target: { value: this.state.valueSearchPage ? this.state.valueSearchPage : nextProps.querySearch}}, 'false')
      this.state.valueSearchPage = !this.state.valueSearchPage ? decodeURIComponent(nextProps.querySearch).split("+").join(" ") : this.state.valueSearchPage
      this.state.showAutocomplete = false
    }

    if(nextProps.isAutocomplete) {
      this.state.isAutocomplete = true
      this.setState({
        showSearch:nextProps.showSearch
      })
    }
    let newSearchResultAutocomplete = [], savedImageNames = [{title: '', type: ''}]
    if(nextProps.search.searchResult.listAll){
      nextProps.search.searchResult.listAll.forEach((searchResultItem) => { 
        let newObj = {type: searchResultItem.category_type, title: searchResultItem.item.title}
        let containsObj = savedImageNames.filter((mappedObj) => {
          return mappedObj.type == searchResultItem.category_type && mappedObj.title == searchResultItem.item.title
        })
          if(containsObj.length < 1) {
            savedImageNames.push(newObj)
            newSearchResultAutocomplete.push(searchResultItem)
            return searchResultItem
          }
      })
    }

    let newSearchResult = !this.state.showAutocomplete ? (nextProps.search ? nextProps.search.searchResult : {listAll: ""}) : newSearchResultAutocomplete

    if(newSearchResult != this.state.searchResult) {
      this.state.searchResult = newSearchResult
      this.setState({

        isAutocomplete: nextProps.querySearch ? false : nextProps.isAutocomplete,
        // searchResult: newSearchResult,
        searchResultAutocomplete: newSearchResultAutocomplete,
        searchHeaders: nextProps.search ? nextProps.search.headers : ['listAll']
      }, () => {
        if(nextProps.isAutocomplete) {
          if(this.refs.txtSearch) this.refs.txtSearch.focus()
        }
  
        if(this.refs.txtSearchpage) this.refs.txtSearchpage.focus()
        this.update = true;
      });
      

      if(this.state.valueSearchPage.length <= 2) {
        this.setState({
          searchHeaders:['listAll'],
          searchResult: {listAll: ""}
        })
      }

    } else {

      // if(this.refs.txtSearchpage) this.refs.txtSearchpage.focus()
      this.setState({
        searchHeaders: nextProps.search ? nextProps.search.headers : ['listAll'],
        // searchResult: nextProps.search.searchResult,
        searchResultAutocomplete:  nextProps.search ?  newSearchResultAutocomplete : []
      }, () => {
        if(nextProps.isAutocomplete) {
          if(this.refs.txtSearch) this.refs.txtSearch.focus()
        }
  
        if(this.refs.txtSearchpage) this.refs.txtSearchpage.focus()
        this.update = true;
      });
      // this.update = true;
    }
    
    // this.update = true;
  }

  componentDidMount() {
    // console.log('componentDidMount', this.props.location.search.length)
    if(this.props.location.pathname.indexOf('SearchPage') > -1 &&
    (this.props.location.search.indexOf('=') == -1 || this.props.location.search.length < 11)) this.props.history.push('/')
    if (this.props.search) {
      // if(this.props.isAutocomplete) this.update = true;
      if (!this.props.search.searchResult.length > 0) {
        this.setState({
          searchResult: {headers: "", searchResult: "", total: ""}
        })
        this.update = true;
        // this.findAnwser({target: { value: 'aaaa'}})
      } else {
        this.update = true;
      }

    }
  }

  componentWillUnmount() {
    // PubSub.unsubscribe(this.token);    
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  _validSearch = () => {
    if(this.refs.txtSearchpage){
    
    
    }
  }
  _xSearch = () => {
  
      return (
        <div>
          <label className="xsearch-input">
            <a href="#" className="xsearch-input" >
              <i className="icon brand-icon is-close-layer"></i>
            </a>
          </label>
        </div>
      )
  }

  findAnwser = async (e, quickSearch) => {
    if(e.target) e = e.target.value
    if(e.length > 2) {
      this.setState({ isLoading: true });
      let searchAPI = `elastic/elastic/findBy?search=${e}&quickSearch=${quickSearch}`
      await this.props.dispatch(fetchValues(`${apiEndpoint()}${searchAPI}`, searchResult))
      this.setState({
        typing: false,
        isLoading: false
      })
    } else {
      this.setState({
        showAutocomplete: false
      })
    }
  }

  changeTxtSearchPage(e) {
    // if(!e.target.value) return
    window.history.replaceState(null, '', '?search=' + e.target.value)
    this.setState({
      valueSearchPage: e.target.value,
      showAutocomplete: e.target.value.length > 2 ? true : false,
      typing: true,
    }, () => {
      this.refs.txtSearchpage.focus()
      if(this.state.valueSearchPage.length > 2 && this.state.valueSearchPage.trim().length > 2) {
        this.findAnwser(this.state.valueSearchPage, 'false')
      }
    })
  }

  handleKeyPress(target) {
    if((target.type === 'submit' || target.charCode==13) && this.refs.txtSearchpage.value !== '' && this.refs.txtSearchpage.value.trim().length > 2){      
      let value = this.state.valueSearchPage
      if(this.refs.txtSearch) value = this.refs.txtSearch.value
      if(this.refs.txtSearchpage) value = this.refs.txtSearchpage.value;
      //  window.location.href = `/SearchPage?search=${value}`
       this.props.history.push(`/SearchPage?search=${value}`)
      target.preventDefault();
      this.setState({
        showSearch:false
      })

      if(this.props.onClickAutocomplete) {
        this.props.onClickAutocomplete(target)
      }

      return false;
    } 
  //  target.preventDefault()
  }

  _goToSearchPage = () => {
    if(this.refs.txtSearch.value && this.refs.txtSearch.value.trim().length > 2 && this.refs.txtSearch.value != '') {
      this.props.history.push(`/SearchPage?search=${this.refs.txtSearch.value}`)
    }
  }

  emptySearchField = (e) => {
    this.refs.txtSearchpage.value = ''
    this.refs.txtSearchpage.focus()
    console.log(e.currentTarget)
  }

  autocompleteClick = (e, link) => {
    if(this.props.onClickAutocomplete) {
      this.setState({
        showSearch:false
      }, () => {
        this.props.history.push(`/SearchPage?search=${this.refs.txtSearch.value}`)
        this.props.onClickAutocomplete(e)
      })
    } else {
      this.props.history.push(link)
      window.location.reload()
    }
  }

  _convertTabName(tabId) {
    let tabName;
    switch(tabId) {
      case 'session':
        tabName = 'Sessões'
        break
      case 'listAll':
        tabName = 'Todos os resultados'
        break
      case 'car_version':
        tabName = 'Versão do carro'
        break
      case 'car':
        tabName = 'Carro'
        break
      case 'technology':
        tabName = 'Tecnologia'
        break
      case 'subtechnology':
        tabName = 'Tecnologia'
        break
      case 'mobilidade-eletrica':
        tabName = 'Mobilidade Elétrica'
        break
      case 'submobilidade-eletrica':
        tabName = 'Mobilidade Elétrica'
        break
      case 'company':
        tabName = 'Empresa'
        break
      case 'subcompany':
        tabName = 'Empresa'
        break
      case 'news':
        tabName = 'Noticias'
        break
      default:
        tabName = 'Imagens'
        break
    }

    return tabName;
  }


  buildSearchResults() {
    let tabLength = this.state.searchResult.listAll ? this.state.searchResult.listAll.length : ''
      return(
        <div className="col-12 offset-md-1">
          <div className='wrapper-tabs'>
            <div className='container'>
              <Nav tabs>
              { this.state.searchHeaders ?
                this.state.searchHeaders.map((result, index) => {
                  return(
                    <NavItem>
                      <NavLink className={classnames({ active: this.state.activeTab === index })} onClick={() => { this.toggleTab(index); }}>
                        <span className='nav-link-item'>{this._convertTabName(result)} ({this.state.searchResult[result] ? this.state.searchResult[result].length : '0'})</span>
                      </NavLink>
                    </NavItem>
                  )
                })
              : 
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === 0 })} onClick={() => { this.toggleTab(0); }}>
                    <span className='nav-link-item'>{this._convertTabName('listAll')} (0)</span>
                  </NavLink>
                </NavItem>
              }
              </Nav>
            </div>
          </div>
          <div className='wrapper-tabs-content'>
            <div className='container'>
              <TabContent activeTab={this.state.activeTab}>
                {this.state.searchHeaders ?
                  this.state.searchHeaders.map((header, index) => {
                    return(
                      this.state.searchResult.listAll ? 
                        this.state.searchResult.listAll.length > 0 ?
                          <TabPane tabId={index}>
                            {this.state.typing ? (
                              <span>Buscando resultados...</span>
                            ) : (
                              <>
                                {this.state.searchResult[header].map((itemSearch, i) => {
                                  let objToMountItem = {...itemSearch.item, ...itemSearch.item.object}
                                  objToMountItem.media =   itemSearch.item.media         
                                    return(
                                      <NewsComponentItem
                                        newsItems={ [objToMountItem] }
                                        isVertical={ true }
                                        isReadMore={ true }        
                                      />
                                    )
                                  })
                                }
                              </>
                            )}
                          </TabPane>
                        : <TabPane tabId={index}> Não foram encontrados resultados</TabPane>
                      : null
                    )
                  })
                :
                  null
                }
              </TabContent>
            </div>
          </div>
        </div>
      )
  }

  render() {
    if (!this.update) {
     
      return (        
       <div>
        
       </div>
        
      );          
    } else {
      let resultsLength = this.state.searchResult.listAll ? this.state.searchResult.listAll.length : ''
      return (
      !this.state.isAutocomplete ?
        
        <div>
          <div className="row vw-searchComponent">
            <div className="col-12 vw-listaSearch ">
              <div className='container'>
                <div className="col-12 offset-md-1 ">
                  { resultsLength > 1 ?
                    <PageTitle className="tituloResult" title={`${resultsLength}` + " Resultados"}/>
                    :
                    <PageTitle className="tituloResult" title="Resultado"/>
                  }
                </div>
                <div className="col-12 offset-md-1">
                  <div className="vw-header-search-component col-12 col-sm-11">
                    <form ref='form-searchPage' encType="text/plain" onSubmit={e => this.handleKeyPress(e)}>
                      <input type="search" id="txtSearchpage" name="search" ref='txtSearchpage' onChange={ e => this.changeTxtSearchPage(e)} value={ this.state.valueSearchPage} placeholder="Buscar" className={"vw-header-search-input" + (this.state.fieldsError.indexOf('txtSearchpage') > -1  ? 'input-error' : '') } autoComplete="off"  maxLength="60">
                      </input>
                      <label className="search-input">
                        <a onClick={e => this.emptySearchField(e)}>
                          <i className="icon brand-icon is-clear-input "></i>
                        </a>
                      </label>  
                    
                    </form>

                    { this.state.showAutocomplete && this.state.searchResultAutocomplete ? 
                        this.state.searchResultAutocomplete.length > 0 ? 
                          <div className='vw-search-autocomplete popover'>
                          
                            <ul className='list bs-popover-bottom'>
                            <span className="arrow"></span>
                              { this.state.searchResultAutocomplete.map((searchResult) => { 
                                  return (
                                    <li className='list-item'><Link onClick={e => this.autocompleteClick(e, `/SearchPage?search=${searchResult.item.title}`)} to={`/SearchPage?search=${searchResult.item.title}`} className='item'> {searchResult.item.title}</Link></li> 
                                  )
                                })
                              }
                            </ul>
                          </div> 
                        : null   
                      : null }
                  </div>     
                </div>
              </div>
            </div>
    
            {this.state.isLoading ? <span style={{ marginTop: '20px' }}>Buscando resultados...</span> : this.buildSearchResults()}
            
          </div>
        </div>
        : 
      
          <div className={`vw-header-search ${this.state.showSearch}`}>
            <div className="vw-header-search-field">
            <form ref='form-searchPageHeader' onSubmit={this._goToSearchPage} encType="text/plain">
              <input type="search" id="txtSearch" name="search" ref='txtSearch' onChange={e => this.findAnwser(e, 'true', this)} onKeyPress={e => this.handleKeyPress(e)} className={"vw-header-search-input form-control "  + (this.state.fieldsError.indexOf('txtSearch') > -1  ? 'input-error' : '') } autoComplete="off"  maxLength="60"/>
                <div className="vw-header-search-icon">
                  <button >
                    <i className="icon brand-icon is-search is-middle " ></i>
                  </button>
                </div>   
              </form>  
              { this.state.searchResultAutocomplete ? 
                        this.state.searchResultAutocomplete.length > 0 ?  
                  <div className='vw-search-autocomplete popover'>
                  
                    <ul className='list bs-popover-bottom'>
                    <span className="arrow"></span>
                      { this.state.searchResultAutocomplete.map((searchResult) => { 
                          return (
                            <li className='list-item'><Link onClick={e => this.autocompleteClick(e)} to={`/SearchPage?search=${searchResult.item.title}`} className='item'> {searchResult.item.title}</Link></li> 
                          )
                        })
                      }
                    </ul>
                  </div> 
                 : null   
              : null }
            </div>          
          </div>

      );
    }

  }
}


// Add this function:
function mappropsToProps(props) {
  if (props.SearchReducer.search != null) {
  //   if(props.SearchReducer.search.searchResult.listAll) {
      return {
        search: props.SearchReducer.search
      };  
    // }
  } 

}



export default connect(mappropsToProps)(withRouter(SearchComponent));