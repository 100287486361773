const initialState = {
  company: {},
  news: [],
  gallery: []
}

const SubEmpresaReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'EMPRESA_LEVEL1_DETAIL':
      state = initialState;
      return {
        company: {...state.company, ...action.company},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    case 'EMPRESA_LEVEL2_DETAIL':
      state = initialState;
      return {
        company: {...state.company, ...action.company},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }      
    default:
      return state;
  }
    
};

export default SubEmpresaReducer;