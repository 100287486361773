import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './HomeCardBoard.css';


class HomeCardBoard extends Component {
    constructor(props) {
        super(props);
    }

    returnImage(image) {
        switch (image) {
            case 'plus':
                return (<img className="imgBoard" src={require("./images/plus-2@2x.png")} />)
                break;
            case 'approve':
                return (<img className="imgBoard" src={require("./images/approve-6@2x.png")} />)
                break;
            case 'car':
                return (<img className="imgBoard" src={require("./images/car-2@2x.png")} />)
                break;
            case 'checklist':
                return (<img className="imgBoard" src={require("./images/checklist-7@2x.png")} />)
                break;
            case 'cancel':
                return (<img className="imgBoard" src={require("./images/cancel-2@2x.png")} />)
                break;
            case 'profile':
                return (<img className="imgBoard" src={require("./images/profile-loggedin@2x.png")} />)
                break;
            case 'events':
                return (<img className="imgBoard" src={require("./images/eventos-loggedin@2x.png")} />)
                break;
            case 'logout':
                return (<img className="imgBoard" src={require("./images/logout-loggedin@2x.png")} />)
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="col-12 col-md-3 margin-bottom-30">

                <div className="boardCard" onClick={e => this.logout(this.props.title)}>
                    <Link to={{ pathname: this.props.url, isAdmin: this.props.isAdmin }}>
                        {this.returnImage(this.props.image)}

                        <div className="divSpan">
                            <span className="titleBoard">{this.props.title}</span>
                        </div>

                        <div className="divSpan">
                            <span className="subTitleBoard">{this.props.subTitle}</span>
                        </div>
                    </Link>
                </div >
            </div >
        );
    }

    logout(title) {
        if (title == 'Logout') {
            localStorage.removeItem('user-data');
            window.location.href = this.props.urlLogout;
        }
    }
}

export default HomeCardBoard;