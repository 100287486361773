import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, NavLink, Redirect, Link } from 'react-router-dom';

//Components
import SideMenus from '../SideMenu/SideMenus';
import Headers from '../Header/Headers';
import Footer from '../../components/Footer/Footer';
import { handleLogin } from '../../components/LoginComponent/LoginComponent';

//Pages
import Registry from '../../pages/Registry/Registry';

import Home from '../../pages/Home/Home';

import Empresa from '../../pages/Empresa/Empresa';
import SubEmpresa from '../../pages/Empresa/SubEmpresa/SubEmpresa';

import Tecnologia from '../../pages/Tecnologia/Tecnologia';
import SubTecnologia from '../../pages/Tecnologia/SubTecnologia/SubTecnologia';

import EMobility from '../../pages/EMobility/EMobility';
import SubEMobility from '../../pages/EMobility/SubEMobility/SubEmobility';

import News from '../../pages/News/News';
import NewsDetail from '../../pages/News/NewsDetail/NewsDetail';
import NewsDetailPDF from '../../pages/News/NewsDetail/NewsDetailPDF';
import MediaCenter from '../../pages/MediaCenter/MediaCenter';
//Cars
import Cars from '../../pages/Cars/Cars';
import Car from '../../pages/Cars/Car/Car';
import Version from '../../pages/Cars/Version/Version';
import VersionPDF from '../../pages/Cars/Version/VersionPDF';

import Contacts from '../../pages/Contacts/Contacts';

import MediaGallery from '../../pages/MediaGallery/MediaGallery';
import MediaGalleryDetails from '../../pages/MediaGallery/MediaGalleryDetails/MediaGalleryDetails';
import Newsletter from '../../pages/Newsletter/Newsletter';
import Termos from '../../pages/Termos/Termos';
import Imprint from '../../pages/Termos/Imprint/Imprint';
import TermoNews from '../../pages/Termos/TermoNews/TermoNews';
import TermoDownload from '../../pages/Termos/TermoDownload/TermoDownload';
import Cookies from '../../pages/Termos/Cookies/Cookies';
import Policies from '../../pages/Termos/Policies/Policies';
import SearchPage from '../../pages/SearchPage/SearchPage';
import './Root.css';
import Cart from '../../pages/Cart/Cart';
import Tabbar from '../Tabbar/Tabbar';
import Eventos from '../../pages/Eventos/Eventos'
import LoanRequest from '../../pages/LoanRequest/LoanRequest';
import ApprovalBackoffice from '../../pages/ApprovalBackoffice/ApprovalBackoffice'
import HomeComodato from '../../pages/HomeComodato/HomeComodato';
import Checklist from '../../pages/Checklist/Checklist';
import PickupCar from '../../pages/PickupCar/PickupCar';
import LoanList from '../../pages/LoanList/LoanList';
import HomeLoggedIn from '../../pages/HomeLoggedIn/HomeLoggedIn'
import PrivacyPolicy from '../../pages/Termos/PrivacyPolicy/PrivacyPolicy';

const Root = ({ store }) => (
  
  <div>
    <Route
      render={({ location }) => (            
            <div className="vw-page">              
              <Headers searchIsOn={true}/>
              <SideMenus/>  
              { 
                (window.location.pathname == '/' || window.location.hash == '#/') ? <Tabbar/> : null
              }
              <div className="row">
                <div className="col-12">
                  <div
                    className={
                      "vw-body container " + 
                      (
                        window.location.pathname == '/'  || 
                        window.location.hash == '#/' || 
                        window.location.pathname == '/home' || 
                        window.location.pathname == '/SearchPage' ? 
                          'container-fluid' 
                        : 
                          ''
                      )
                    }
                  >
                    <Switch location={location}>
                      <Route exact path="/login/" component={handleLogin} /> 
                      <Route exact path="/registry" component={Registry} /> 
                      <Route exact path="/cars/:car" component={Cars} /> 
                      <Route exact path="/cars/car/:car_id" component={Car} />
                      <Route exact path="/cars/version/:car_id/:version_id" component={Version} />
                      <Route exact path="/cars/version/:car_id/:version_id/pdf" component={VersionPDF} />
                      <Route exact path="/company" component={Empresa} /> 
                      <Route exact path="/company/:company_id" component={SubEmpresa} /> 
                      <Route exact path="/company/:company_id/:subsession_id" component={SubEmpresa} /> 
                      <Route exact path="/technology" component={Tecnologia} /> 
                      <Route exact path="/technology/:technology_id" component={SubTecnologia} /> 
                      <Route exact path="/technology/:technology_id/:subtechnology_id" component={SubTecnologia} />                     
                      <Route exact path="/mobilidade-eletrica" component={EMobility} /> 
                      <Route exact path="/mobilidade-eletrica/:emobility_id" component={SubEMobility} /> 
                      <Route exact path="/mobilidade-eletrica/:emobility_id/:subemobility_id" component={SubEMobility} /> 
                      <Route exact path="/news" component={News} />
                      <Route exact path="/news/:news_id" component={NewsDetail} />
                      <Route exact path="/news/:news_id/pdf" component={NewsDetailPDF} />
                      <Route exact path="/contacts" component={Contacts} />                    
                      <Route exact path="/media-center" component={MediaCenter} />
                      <Route exact path="/newsletter" component={Newsletter} />
                      <Route exact path="/termos-legais" component={Termos}/>
                      <Route exact path="/termos-legais/imprint" component={Imprint}/>
                      <Route exact path="/termos-legais/privacypolicy" component={PrivacyPolicy}/>
                      <Route exact path="/termos-legais/termoNews" component={TermoNews}/>
                      <Route exact path="/termos-legais/termoDownload" component={TermoDownload}/>
                      <Route exact path="/termos-legais/Cookies" component={Cookies}/>
                      <Route exact path="/termos-legais/Policies" component={Policies}/>
                      <Route exact path="/carrinho" component={Cart}/>
                      <Route exact path="/eventos" component={Eventos} />
                      <Route path="/aprovacao" component={ApprovalBackoffice}/>
                      <Route exact path="/solicita-comodato" component={LoanRequest}/>
                      <Route exact path="/home-comodato" component={HomeComodato}/>
                      <Route exact path="/checklist" component={Checklist}/>
                      <Route exact path="/retirar" component={PickupCar}/>
                      <Route exact path="/comodato-lista" component={LoanList}/>
                      <Route exact path="/area-logada" component={HomeLoggedIn}/>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/home" component={Home} />
                      <Route exact path="/media-gallery" component={MediaGallery} />
                      <Route exact path="/media-gallery/album/:album_id" component={MediaGalleryDetails} />
                      <Route exact path="/SearchPage" component={SearchPage}/>
                      <Route path="*" component={() => (<Redirect to="/home" />)} />
                    </Switch>
                  </div> 
                </div>
                <div className="col-12 vw-background-gray">
                  <div className={" container " + (window.location.pathname == '/' || window.location.hash == '#/' ? 'container-fluid' : '')}>
                    <Footer />
                  </div>
                </div>   
       

              </div>                      
            </div>            
         
      )}
      />
  </div>
)

export default Root;

