import React, { Component } from 'react';

import './HomeLoggedIn.css';
import PubSub from 'pubsub-js'

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import HomeCardBoard from '../../components/HomeCardBoard/HomeCardBoard';
import ReactGA from 'react-ga';

let { getLogoutUrl } = require('../../config/constants');


class HomeLoggedIn extends Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem('user-data')).user
        this.titleApproval = this.user.role == 2 || this.user.role == 3 ? 'Aprovação Gerencial' : 'Aprovação Backoffice'

        this.state = {
            hasEvent: false
        }
    }

    componentDidMount() {
        ReactGA.pageview('/area-logada');
        this.userHasEvent()
    }

    userHasEvent = () => {
        let events = localStorage.getItem('user-has-event')
        if(events && events == 'true') {
            this.setState({
                hasEvent: true
            })
        }
    }

    render() {
      
        let userData = JSON.parse(localStorage.getItem('user-data'));
        let token = userData.token;
        let logoutUrl = getLogoutUrl(token);
        return (
            <div className="vw-media-center">
                <div className="row vw-media-center-1">
                    <div className="col-12">
                        <div className="col-12 p-0">
                            <Breadcrumb text="<a href='/'>Newsroom</a> / Área Logada" />
                            <PageTitle title="Área Logada" />
                        </div>
                        <div className="col-12 padding-0 margin-bottom-50">

                            <div className="row">

                                <HomeCardBoard url={"/registry"} image={"profile"} title={"Perfil"} subTitle={"Acessar funções do seu perfil"}></HomeCardBoard>
                                <HomeCardBoard url={"/home-comodato"} image={"car"} title={'Solicitação de Veículo'} subTitle={"Acessar funções para solicitação de veículo"}></HomeCardBoard>
                                { this.state.hasEvent ? 
                                    <HomeCardBoard url={"/eventos"} image={"events"} title={"Eventos"} subTitle={"Acessar lista de eventos"}></HomeCardBoard>
                                : null }
                                {/* <HomeCardBoard url={""} image={"logout"} urlLogout={logoutUrl} title={"Logout"} subTitle={"Sair da sua área logada"}></HomeCardBoard> */}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeLoggedIn;
