import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmpresa } from './Empresa.actions';
import { fetchValues } from '../../shared/http-request-util';

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import ArticleTitle from '../../components/ArticleTitle/ArticleTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import TextBody from '../../components/TextBody/TextBody';
import ShortImage from '../../components/ShortImage/ShortImage';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Gallery from '../../components/Gallery/Gallery';
import Loading from '../../components/Loading/Loading';

import './Empresa.css'; 
import HighlightMedia from '../../components/HighlightMedia/HighlightMedia';
import Speak from '../../components/Speak/Speak';

import ReactGA from 'react-ga';
import PopOverButton from '../../components/PopOverButton/popOverButton';

let { urlEndpoint, publicUrlEndpoint } = require('../../config/constants');
let { apiEndpoint } = require('../../config/constants');
let { empresaHomeAPI } = require('../../config/constants');

class Empresa extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    ReactGA.pageview('/company');
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + empresaHomeAPI(), getEmpresa));
  }  

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {      
      return (  
          
        <div className="vw-empresa">
          <div className="row">
            <div className="col-12">            
              <Breadcrumb text="<a href='/'>Newsroom</a> / Empresa" />
              <PageTitle title="Empresa"/>
              <HighlightMedia showPopOverButtons={true} 
              media={ this.props.empresa.media} mediaMedium={this.props.empresa.mediaMed} mediaLow={this.props.empresa.mediaLow} 
              mediaSize={this.props.empresa.mediaSize} mediaSizeMedium= { this.props.empresa.mediaSizeMedium } mediaSizeLow = {this.props.empresa.mediaSizeLow} 
              isAutoPlay={true}/>
            </div>
            <div className="row w-100 justify-content-end newsButtons">
              
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.empresa.title} ` + ` ${publicUrlEndpoint()}` + `/company/` + `${this.props.match.params.empresa_id}`}
                    twitterText={this.props.empresa.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/company/` + `${this.props.match.params.empresa_id}`}
                  />
              </div>
            </div>
            <div className="col-12">            
              <ArticleTitle title={this.props.empresa.title}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">
              <PageSubTitle text={this.props.empresa.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-md-8">            
              <TextBody text={this._convertDescription(this.props.empresa.description)}/>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-12 col-sm-8 col-md-12">         
                  <ShortImage showPopOverButtons={true} 
                  imageName={this.props.empresa.title}
                  contentLink={'/company'} 
                    mediaMedium={this.props.empresa.sideMediaMed} media={this.props.empresa.sideMedia} mediaLow={this.props.empresa.sideMediaLow} 
                    mediaSize={this.props.empresa.sideMediaSize} mediaSizeMedium= { this.props.empresa.sideMediaSizeMedium } mediaSizeLow = {this.props.empresa.sideMediaSizeLow} 
                    roundBorder="false" 
                    />
                </div>
                <div className="vw-italic imageSubtitle col-12 col-sm-4 col-md-12 col-lg-10">
                  <TextBody className="vw-italic vw-font-12px" text={this.props.empresa.sideDescription}/>
                </div>
              </div>
            </div>
            <div className="w-100"></div>
            <div className="col">            
                <PageTitle className="vw-font-20px" title={this.props.empresa.bottomTitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">
            <HighlightMedia 
              showPopOverButtons={true} 
              media={ this.props.empresa.bottomMedia} mediaMedium={ this.props.empresa.bottomMediaMed } mediaLow={ this.props.empresa.bottomMediaLow } 
              mediaSize={this.props.empresa.bottomMediaSize} mediaSizeMedium= { this.props.empresa.bottomMediaSizeMedium } mediaSizeLow = {this.props.empresa.bottomMediaSizeLow} 
              isAutoPlay={true}/>
            </div>          
          </div>           
  
          <NewsComponent isVertical={false} news={this.props.news}/> 
  
          <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/> 
          
          <Speak/>
        </div>
      );       
    } else {
      return (<Loading></Loading>);
    }
   
  }
}
 
// Add this function:
function mapStateToProps(state) {
  return {
    empresa: state.EmpresaReducer.empresa,
    news: state.EmpresaReducer.news,
    gallery: state.EmpresaReducer.gallery
  };
}

export default connect(mapStateToProps)(Empresa);
