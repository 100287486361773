import React, { Component } from 'react';
import PubSub from 'pubsub-js'
import { Link } from 'react-router-dom';

// import Logo from './img/volkswagen_logo.png';

import './Tabbar.css';
import { Thumbs } from 'react-responsive-carousel';


class Tabbar extends Component {

  constructor(props) {
    super(props);
    this.token = "";
    this.state = {
      showMenuCSS: '',
      selectedItem: 0
    };
  }


  _selectMenuItem(item, index, e) {
    e.preventDefault()
    PubSub.publish('SELECT_TABBAR', index);

      // this.props.onSelectSlide(index)
    this.setState({
      selectedItem: index
    }, () => {
      if(document.getElementById('banner-container')) {
        if(this.state.ontop) {
          document.documentElement.scrollTop = document.getElementById('banner-container').offsetHeight +60
        }
      }
    })
  }

  isLoggedIn = () => {
    let userData = JSON.parse(localStorage.getItem('user-data'));
    if (userData && userData.user && userData.user.loggedIn) {
      return true
    } else {
      return false
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps, this.state.ontop, window.location)
    // if(!this.state.ontop) {
    //   this.setState({
    //     ontop: true
    //   });  
    // }
    // console.log(nextProps, nextProps.selectedSlide)
    // this.setState({
    //   selectedItem: nextProps.selectedSlide
    // })
  }

  componentDidMount() {

    // this.token = PubSub.subscribe('SHOW_HIDE_MENU', this.selectItem);

    if(document.getElementById('banner-container')) {
      // this.refs.navbar.top = document.getElementById('banner-container').offsetHeight
    }
    // document.getElementById('navbar').marginTop = tabbarTopMargin

    window.onscroll = () => {
      // if(this.state.ontop) return
      if(!document.getElementById('banner-container')) return
      if(window.pageYOffset > (document.getElementById('banner-container').offsetHeight + 60)) {
          this.setState({
            ontop: true
          });  
      } else {
        this.setState({
          ontop: false
        }); 
      }
    };
  }

  componentWillUnmount() {
    // PubSub.unsubscribe(this.token);    
  }

 

  render() {
    const { onClick } = this.props;
    return (
        <nav id='navbar' ref='navbar' className={`vw-tabbar ${(this.state.ontop ? 'fixed-top' : null)} ${this.isLoggedIn() ? ' is-logged-tabbar ' : '' }`}>
          <div className="vw-tabbar-content navbar" data-level="1">
            <ul className="navbar-nav"> 

              <li onClick={this._selectMenuItem.bind(this, 'newsAnchor', 0)} className={"nav-item " + (this.state.selectedItem == 0 ? 'active' : '') }>
                <a href='#' className="nav-link">
                  <label className="txt-modelos">News</label>
                </a>
              </li>

              <li onClick={this._selectMenuItem.bind(this, 'galleryAnchor', 1)} className={"nav-item " + (this.state.selectedItem == 1 ? 'active' : '') }>
                <a href='#' className="nav-link">                
                  <label >Galeria</label>
                </a>
              </li>

              <li onClick={this._selectMenuItem.bind(this, 'carAnchor', 2)} className={"nav-item " + (this.state.selectedItem == 2 ? 'active' : '') }>
                <a href='#' className="nav-link">
                  <label >Carros</label>
                </a>
              </li>

              <li onClick={this._selectMenuItem.bind(this, 'socialAnchor', 3)} className={"nav-item " + (this.state.selectedItem == 3 ? 'active' : '') }>
                <a href='#' className="nav-link">
                  <label >Social</label>
              </a>                
              </li>

              <li onClick={this._selectMenuItem.bind(this, 'speakAnchor', 4)} className={"nav-item " + (this.state.selectedItem == 4 ? 'active' : '') }>
                <a href='#'  className="nav-link">
                  <label >Contatos</label>
                </a>
              </li>
            </ul>
          </div>
        </nav>
    );    
  }
}

export default Tabbar;
