import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import './NewsComponentTitle.css'; 

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

class NewsComponentTitle extends Component {

  constructor(props) {
    super(props)
    this.state= {
      maxline: 2
    }
  }    

  render() {

    return (      
      <div className="newscomponenttitle">
        <h3 className="title">
          <ResponsiveEllipsis
            text={this.props.title}
            maxLine={this.state.maxline}
            ellipsis='...'
            trimRight
            basedOn='letters'
          />  
        </h3>   
      </div> 
    );    
  }

}

export default NewsComponentTitle;
