import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNewsDetail } from './NewsDetail.actions';
import { fetchValues, fetchValuesToken } from '../../../shared/http-request-util';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

import { Popover, PopoverHeader, PopoverBody, ListGroup, ListGroupItem } from 'reactstrap';


import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import PopOverButtton from '../../../components/PopOverButton/popOverButton'
import ReactGA from 'react-ga';

import './NewsDetail.css'; 
// import iconefacebook from './img/icone-facebook.svg';
// import iconetwitter from './img/icone-twitter.svg';
// import iconefoto from './img/icone-foto.svg';
// import btdownloadnormal from './img/bt-download-normal.svg';
// import iconDownload from './img/bt-download-hover.svg';
// import iconecompartilhar from './img/bt-compartilhar-hover.svg';



let { apiEndpoint, publicUrlEndpoint } = require('../../../config/constants');
let { newsDetailAPI } = require('../../../config/constants');
let { urlEndpoint } = require('../../../config/constants');


class NewsDetail extends Component {

  constructor(props) {
    super(props);
    this.update = false;
    

    var medias=[
      {
      media: this.props.news.media,
      mediaLow: this.props.news.mediaLow,
      mediaMed: this.props.news.mediaMed,
      }
    ]

    this.state = {
      downloadOpen:false,
      sharedOpen: false,
      medias: medias,
      active: 'true'
    }

  }


  componentDidMount() {    
    this.update = true;
    //if user has been viewing an preview from CMS, will receive the user token here
    if(this.props.location.search.indexOf('token=') != -1) {
      let token = this.props.location.search.split('token=')[1]
      this.props.dispatch(fetchValuesToken(apiEndpoint() + newsDetailAPI(this.props.match.params.news_id), 'GET', getNewsDetail, token));
    } else {
      this.props.dispatch(fetchValues(apiEndpoint() + newsDetailAPI(this.props.match.params.news_id), getNewsDetail));
    }
  }  
  

  toggleDownload(){
    this.setState({
      downloadOpen: !this.state.downloadOpen
      }
    )
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }
  
  toggleShare(){
    this.setState({
      sharedOpen: !this.state.sharedOpen,
      }
    )
  }
  render() {

    if (this.update) {
      ReactGA.pageview('/news: ' + this.props.news.title);
      return (            
        <div className="vw-newsdetail-center">
          <MetaTags>
            <title>{this.props.news.title}</title>
            <meta property="og:title" content={this.props.news.title} />
            <meta name="description" content={this.props.news.title} /> 
            <meta property="og:description" content={this.props.news.title}  /> 
            <meta property="og:image" itemprop="image" content={this.props.news.mediaLow} />
          </MetaTags>
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/news/'>News</a> / ${this.props.news.title} `} />
              <PageTitle title={this.props.news.title} />
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.news.title}
              media={this.props.news.media} mediaMedium={this.props.news.mediaMed} mediaLow={this.props.news.mediaLow} 
              mediaSize={this.props.news.mediaSize} mediaSizeMedium= { this.props.news.mediaSizeMedium } mediaSizeLow = {this.props.news.mediaSizeLow}
              GAText={'/news: ' + this.props.news.title}
              isAutoPlay={false} />
            </div>
            </div>
            <div className="row justify-content-end newsButtons">
            
              <div id="downloadBondary" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                  popOverType="image"
                  containerName="downloadBondary"
                  imageLink={this.props.news.media}
                  imageDownloadName={this.props.news.title}
                  pdfULR={`${urlEndpoint()}` + `/news/` + `${this.props.match.params.news_id}/PDF`}
                  pdfName={this.props.news.title}
                />
              </div>
              
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.news.title} ` + ` ${publicUrlEndpoint()}` + `/news/` + `${this.props.match.params.news_id}`}
                    twitterText={this.props.news.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/news/` + `${this.props.match.params.news_id}`}
                  />
              </div>

              <div id={`cartButtonContainer-${this.props.match.params.news_id}`} className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                    popOverType="cart"
                    containerName={`cartButtonContainer-${this.props.match.params.news_id}`}
                    imageLink={this.props.news.media}
                    contentToDownload={this.props.news}
                    imageDownloadName={this.props.news.title }
                    contentLink={`/news/` + `${this.props.match.params.news_id}/`}
                    pdfURL={`${urlEndpoint()}` + `/news/` + `${this.props.match.params.news_id}/PDF`}
                    type='Press Release'
                    mediaAmount={ this.props.news.templates.filter((item) => item.type == 2).length }
                  />
              </div>

            </div>
            <div className="row">
            <div className="col-12">
              <PageSubTitle text={this.props.news.subtitle} />
            </div>
            <div className="w-100"></div>
            <div className="col-12">
              <TextBody text={this._convertDescription(this.props.news.description)} /> 
            </div>
            <Template GAText={ this.props.news.title }  templates={this.props.news.templates}  contentLink={`/news/` + `${this.props.match.params.news_id}/`}/>
            <Speak />
          </div>
        </div>
      ); 
      
  } else {
      return (<Loading></Loading>);
    }
  }
}

 // Add this function:
 function mapStateToProps(state) {   
  return {
    news: state.NewsDetailReducer.news,
  };
}

export default connect(mapStateToProps)(NewsDetail);
