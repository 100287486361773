const initialState = {
  latestPhotos: []
}

const MediaCenterReducer = (state = initialState, action) => {
  switch(action.type) {        
    case 'LIST_LATEST_PHOTOS':
      return {
        latestPhotos  : [...state.latestPhotos, ...action.latestPhotos],
      }
    default:
      return state;
  }
};

export default MediaCenterReducer;