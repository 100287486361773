import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Route, BrowserRouter, Switch, NavLink, Redirect, Link } from 'react-router-dom';
import { getApprovalBackoffice } from './ApprovalBackoffice.actions';
import { fetchValuesAuth } from '../../shared/http-request-util';
import ApprovalBackofficeComponent from '../../components/ApprovalBackoffice/ApprovalBackofficeComponent/ApprovalBackofficeComponent';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import ApprovalBackofficeDetail from '../../components/ApprovalBackoffice/ApprovalBackofficeDetail/ApprovalBackofficeDetail';

let { approvalsAPI } = require('../../config/constants');

class ApprovalBackoffice extends Component {
  constructor(props) {
    super(props);
    this.update = false;
    this.state = {
      isApprovalDetail: false,
      filteredList: []
    }

    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.user = JSON.parse(localStorage.getItem('user-data')).user;
      this.pageTitle = this.user.role == 2 || this.user.role == 3 || this.props.location.isAdmin ? 'Aprovação Gerente' : 'Aprovação Backoffice'
    } else {
      localStorage.setItem('has-redirect', '/aprovacao');
      props.history.push('/login');
    }
  }

  componentDidMount() {
    ReactGA.pageview('/aprova-comodato');
    this.update = true;
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.props.dispatch(fetchValuesAuth(approvalsAPI(), 'GET', getApprovalBackoffice));
    }
  }

  refreshList = (e) => {
    this.props.dispatch(fetchValuesAuth(approvalsAPI(), 'GET', getApprovalBackoffice));
  }

  render() {
    return (
      <div class="row">
        <div className="col-12 title-approval">
          <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / <a href='/home-comodato'>Solicitação de Veículos</a> `} />
          <PageTitle title={this.pageTitle} />
          <PageSubTitle text='Lista de solicitações de empréstimo de veículos' />
        </div>
        <div className="col-12">
          <BrowserRouter>
            <Switch location={window.location}>
              <Route exact path="/aprovacao" render={(history) => {
                let approvalList = !this.props.location.isAdmin ? this.props.list : this.props.list.filter((item) => item.status == 2 || item.status == 3)
                return (
                  <ApprovalBackofficeComponent refreshList={e => this.refreshList(e)} approvalList={approvalList} frotas={this.props.frotasList} isAdmin={this.props.location.isAdmin} />
                );
              }} />

              <Route exact path={`/aprovacao/:id`} render={(history) => {
                return (
                  <ApprovalBackofficeDetail refreshList={e => this.refreshList(e)} selectedLoan={history.match.params.id} approvalItem={this.props.list} frotasList={this.props.frotasList} />
                );
              }} />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    )
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    list: state.ApprovalBackofficeReducer.list,
    frotasList: state.ApprovalBackofficeReducer.frotasList
  };
}

export default connect(mapStateToProps)(ApprovalBackoffice);