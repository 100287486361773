
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Modal, ModalBody} from 'reactstrap';

import classnames from 'classnames';
import './Eventos.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import ApprovalCard from '../../components/ApprovalBackoffice/ApprovalCard/ApprovalCard';
import Button from '../../components/Button/Button';

import { getEventos } from './Eventos.actions';
import { fetchValuesAuth, fetchValuesOptions} from '../../shared/http-request-util';
import { apiEndpoint } from '../../config/constants';
import AlertModal from '../../components/AlertModal/AlertModal';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Template from '../../components/Template/Template';

let { eventsListAPI, eventsAcceptAPI, eventsRejectAPI } = require('../../config/constants');

class Eventos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      eventosList: '',
      selectedEvent: {},
      eventToInteract: '',
      activeTab: 0
    }
    this.update = false;
    
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.user = JSON.parse(localStorage.getItem('user-data')).user;
    } else {
      props.history.push('/login');
    }
  }

  componentDidMount() {
    ReactGA.pageview('/lista-comodato');
    this.update = true;
    this.props.dispatch(fetchValuesAuth(eventsListAPI(), 'GET', getEventos));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
        eventosHeaders: nextProps.list.headers,
        eventosList: nextProps.list,
        eventosListNext: nextProps.list ? nextProps.list.filter((item) => !item.isOld) : [],
        eventosListPrev: nextProps.list ? nextProps.list.filter((item) => item.isOld) : [],
        isEmptyEvents: !nextProps.list.length > 0
    })
  }

  _approveAction = (e) => {
    let selectedEvent = this.state.selectedEvent.id ? this.state.selectedEvent : this.state.eventosList.filter((item) => item.id == e)[0]
    let selectedGuest = selectedEvent.guests.filter((guest) => guest.id == this.user.id)[0]
    let isAcceptRollBack = false, parentClass = 'alert-accept-invite', message = 'Sua participação foi confirmada com sucesso.'
    if(selectedGuest) {
      if(selectedGuest.events_guests.status == 2 || selectedGuest.events_guests.status == 3) {
        isAcceptRollBack = true
        parentClass = ''
        message = `Por favor, entre em contato pelo email <span class='small'>imprensa.volkswagen@volkswagen.com.br</span>. 
          </br></br>Obrigado. 
          </br><span class='small'>Equipe de Assuntos Corporativos e Relações com a Imprensa da Volkswagen Brasil</span>`
      } 
      this.setState({
        eventToInteract: e,
        modalTitle: '',
        modalMessage: message,
        isApproveModal: true,
        isRejectModal: false,
        parentClass: parentClass
      }, () => {
        this.setState({
          showModal: true,
          isAcceptRollBack: isAcceptRollBack
        })
      })
    }
  }

  _rejectAction = (e) => {
    this.setState({
      eventToInteract: e,
      modalTitle: 'Alerta!',
      modalMessage: 'Tem certeza que deseja recusar permanentemente este evento da sua lista?',
      isApproveModal: false,
      isRejectModal: true,
      parentClass: ''
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  _modalDecision = (e) => {
      console.log('modal decision', e)
    if(e && !this.state.isAcceptRollBack) {
      this.setState({
        isCancelingLoan: true,
        showModal: false
      }, () => {
          if(this.state.isRejectModal) {
            this._sendReject()
          } else {
              this._sendApprove()
          }
      })
    }
  }

  _sendApprove = () => {
    let body = {
        eventId: this.state.eventToInteract,
        userId: this.user.id
    }
    console.log(body)
    let url = eventsAcceptAPI()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
       _that.setState({
          isCancelingLoan: false
        }, () => {
          _that.props.dispatch(fetchValuesAuth(eventsListAPI(), 'GET', getEventos));
        })
      }
      fetchValuesOptions(url, options, callback)
  }

  _sendReject = () => {
    let body = {
        eventId: this.state.eventToInteract,
        userId: this.user.id
    }
    
    console.log(body)
      let url = eventsRejectAPI()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
       _that.setState({
          isCancelingLoan: false
        }, () => {
          _that.props.dispatch(fetchValuesAuth(eventsListAPI(), 'GET', getEventos));
        })
      }
      fetchValuesOptions(url, options, callback)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        showModal: false
      });
    }
  }

  _convertTabName(tabId) {
    let tabName;
    switch(tabId) {
      case 'prevEvents':
        tabName = 'Eventos Anteriores'
        break
      default:
        tabName = 'Próximos Eventos'
        break
    }
    return tabName;
  }

    formatDate(date){
        if(!date) return
        if(!date.split('T')) {
          let data = new Date(date);
          var dia  = data.getDate();
          if (dia< 10) {
              dia  = "0" + dia;
          }
          var mes  = data.getMonth() + 1;
          if (mes < 10) {
              mes  = "0" + mes;
          }
          var ano  = data.getFullYear();
          return dia + "/" + mes + "/" + ano;
        } else {
          let data = date.split('T')[0]
          var dia  = data.split('-')[2]
          var mes  = data.split('-')[1]
          var ano  = data.split('-')[0]
          return dia + "/" + mes + "/" + ano;
        }
    }

    formatTime(time) {
      if(!time) return
      let timeFormated = new Date(time)
      var hour  = timeFormated.getHours()
      var min  = timeFormated.getMinutes();
      if (min < 10) {
        min  = "0" + min;
      }
      return hour + ":" + min
    }

  _getEventDetail = (e) => {
    this.setState({
      isCancelingLoan: true
    }, () => {
      let url = eventsListAPI() + '/' + e
      let options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        }
      };
      let _that = this
      function callback(response) {
        _that.setState({
          isCancelingLoan: false,
          selectedEvent: response.event
        }, () => {
          _that.showDetails(e)
        })
      }
      fetchValuesOptions(url, options, callback)
    })

  }

  htmlInviteClick = (type, id, event) => {
    event.preventDefault()
    let isPrevEvent = this.state.eventosListPrev.filter((prevEv) => prevEv.id == id)
    if(isPrevEvent.length > 0) return
    if(type == 'yes') {
      this.setState({
        isRejectModal: false
      }, ()=> {
        this._approveAction(id)
      })
    } else {
      this.setState({
        isRejectModal: true
      }, ()=> {
        this._rejectAction(id)
      })
    }
  }

  showDetails = (id) => {
    this.setState({
        showModal: false
    }, () => {
        this.setState({
            modalDetailOpen: true
        }, ()=> {
          let yesBtn = document.getElementById(`ev_yes_${id}`)
          if(yesBtn) {
            yesBtn.addEventListener('click', e => this.htmlInviteClick('yes', id, e))
          }

          let noBtn = document.getElementById(`ev_no_${id}`)
          if(noBtn) {
            noBtn.addEventListener('click', e => this.htmlInviteClick('no', id, e))
          }
        })
    })
  }

  toggleModalDetail = (e) => {
    this.setState({
        modalDetailOpen: !this.state.modalDetailOpen,
        showModal: false
    })
  }

  buildEventsTabs() {
    return(
      <div className="row">
        <div className='wrapper-tabs col-12'>
            <div className='container'>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === 0 })} onClick={() => { this.toggleTab(0); }}>
                            <span className='nav-link-item'>{this._convertTabName()}</span>
                        </NavLink>
                    </NavItem>
                    { (this.state.eventosList.length > 0 && this.state.eventosListPrev.length > 0) ? 
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }}>
                                <span className='nav-link-item'>{this._convertTabName('prevEvents')}</span>
                            </NavLink>
                        </NavItem>
                        : null
                    }
                </Nav>
            </div>
        </div>
        <div className='wrapper-tabs-content col-12'>
            <div className='container'>
                { this.state.eventosList.length > 0 ? 
                  <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId={0}>
                        { this.state.eventosListNext.map((item) => {
                            return(
                              <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                                  <div className='mb-4'>
                                  <ApprovalCard
                                      parentClass='events-wrapper'
                                      isEventCard={true}
                                      title={item.title}
                                      id={item.id}
                                      showDetailsLink={true}
                                      showDetails={e => this._getEventDetail(e)}
                                      eventDate={item.startDate}
                                      approveAction={e => this._approveAction(e)} 
                                      rejectAction={e => this._rejectAction(e)} 
                                      showAcceptRecuseButton={true} 
                                      rejectText='Recusar'
                                      acceptText='Aceitar' />
                                  </div>
                              </div>
                            )
                          })
                        }
                      </TabPane>
                      <TabPane tabId={1}>
                        { this.state.eventosListPrev.map((item) => {
                          return(
                            <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                              <div className='mb-4'>
                                <ApprovalCard
                                  parentClass='events-wrapper'
                                  isEventCard={true}
                                  title={item.title}
                                  id={item.id}
                                  showDetailsLink={true}
                                  showDetails={e => this._getEventDetail(e)}
                                  eventDate={item.startDate}
                                  eventTime={new Date(item.startDate).getTime()}
                                  approveAction={e => this._approveAction(e)} 
                                  rejectAction={e => this._rejectAction(e)} 
                                  showAcceptRecuseButton={false} 
                                  rejectText='Recusar'
                                  acceptText='Aceitar' />
                              </div>
                            </div>
                          )})
                        }
                      </TabPane>
                  </TabContent>
                : 
                  <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId={0}> 
                          <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                              <div className='mb-4'>Não foram encontrados resultados</div>
                          </div>
                      </TabPane>
                  </TabContent>
            }
            </div>
        </div>
    </div>
    )
  }

  render() {
    if (this.update) {
      return (
        <div className="vw-loanlist vw-events mb-4">
          <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / Eventos" />
          <PageTitle title="Eventos" />

          <div className='row'>
                <div className='col-12'>
                    { this.buildEventsTabs() }
                </div>
          </div>

          <AlertModal
            isDecisionModal={ this.state.isRejectModal }
            configModalCallback={e => this._modalDecision(e)}
            title1={ this.state.modalTitle}
            messageHTML={ this.state.modalMessage}
            showModal={this.state.showModal}
            parentClass={this.state.parentClass}
          />

        
            <Modal size="lg" isOpen={this.state.modalDetailOpen} toggle={e => this.toggleModalDetail(e)}>
                <ModalBody className='modal-event-detail'>
                    <button className="close closeModal" onClick={e => this.toggleModalDetail(e)}>&times;</button>
                    <PageTitle title={ this.state.selectedEvent.title} />
                    <div dangerouslySetInnerHTML={{__html: this.state.selectedEvent.description}}></div>
                    <div className='row'>
                        <div className={`col-12 ${this.state.selectedEvent.endDate ? ' col-sm-6 ' : ''}`}>
                            <div className='row'>
                                <div className='col-12 mb-1 font-weight-bold'>
                                    <p>Início</p>
                                </div>
                                <div className='col-6'>
                                    <p className='loan-user-title'>
                                        <img src={require('./images/icon_data.svg')} className='icon-card-date'/>Data
                                        <p className='loan-user'> {this.formatDate(this.state.selectedEvent.startDate)}</p>
                                    </p>
                                </div>
                                <div className='col-6'>
                                    <p className='loan-user-title'>
                                        <img src={require('./images/icon_hora.svg')} className='icon-card-date'/>Hora
                                        <p className='loan-user'> {this.formatTime(this.state.selectedEvent.startDate)} hs</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {this.state.selectedEvent.endDate ? 
                            <div className='col-12 col-sm-6 mt-4 mt-sm-0'>
                                <div className='row'>
                                    <div className='col-12  mb-1 font-weight-bold'>
                                        <p>Término</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='loan-user-title'>
                                            <img src={require('./images/icon_data.svg')} className='icon-card-date'/>Data
                                            <p className='loan-user'> {this.formatDate(this.state.selectedEvent.endDate)}</p>
                                        </p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='loan-user-title'>
                                            <img src={require('./images/icon_hora.svg')} className='icon-card-date'/>Hora
                                            <p className='loan-user'> {this.formatTime(this.state.selectedEvent.endDate)} hs</p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        : null}

                        { this.state.selectedEvent.templates ? 
                            this.state.selectedEvent.templates.length > 0 ? 
                                <div className='col-12 mt-4 pl-0'>
                                    <Template templates={this.state.selectedEvent.templates} showPopOverButtons={false}/>
                                </div>
                            : null 
                        : null }
                    </div>
                </ModalBody>
            </Modal>

            { this.state.isCancelingLoan ? <Loading /> : null }
        </div>
      );
    } else {
      return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  return {
    list: state.EventosReducer.list
  }
}

export default connect(mapStateToProps)(Eventos);
