import { combineReducers } from 'redux';
import ApprovalBackofficeReducer from '../pages/ApprovalBackoffice/ApprovalBackoffice.reducer'
import CarsReducer from '../pages/Cars/Cars.reducer';
import CarReducer from '../pages/Cars/Car/Car.reducer';
import VersionReducer from '../pages/Cars/Version/Version.reducer';
import EmpresaReducer from '../pages/Empresa/Empresa.reducer';
import TecnologiaReducer from '../pages/Tecnologia/Tecnologia.reducer';
import EMobilityReducer from '../pages/EMobility/EMobility.reducer';
import HomeReducer from '../pages/Home/Home.reducer';
import ContactsReducer from '../pages/Contacts/Contacts.reducer';
import SideMenuReducer from '../components/SideMenu/SideMenus.reducer';
import SubEmpresaReducer from '../pages/Empresa/SubEmpresa/SubEmpresa.reducer'; 
import SubTecnologiaReducer from '../pages/Tecnologia/SubTecnologia/SubTecnologia.reducer';
import SubEMobilityReducer from '../pages/EMobility/SubEMobility/SubEmobility.reducer';
import NewsComponentReducer from '../components/NewsComponent/NewsComponent.reducer';
import NewsDetailReducer from '../pages/News/NewsDetail/NewsDetail.reducer';
import MediaGalleryReducer from '../pages/MediaGallery/MediaGallery.reducer';
import MediaCenterReducer from '../pages/MediaCenter/MediaCenter.reducer';
import MediaGalleryDetailsReducer from '../pages/MediaGallery/MediaGalleryDetails/MediaGalleryDetails.reducer'
import CartReducer from '../pages/Cart/Cart.reducer';
import SearchReducer from '../components/Search/Search.reducer';
import TwitterReducer from '../components/TwitterComponent/TwitterComponent.reducer';
import YoutubeReducer from '../components/YoutubeComponent/YoutubeComponent.reducer';
import LoanRequestReducer from '../pages/LoanRequest/LoanRequest.reducer';
import ChecklistReducer from '../pages/Checklist/Checklist.reducer';
import ChecklistForm  from '../pages/Checklist/ChecklistForm/ChecklistForm.reducer';
import LoanListReducer from '../pages/LoanList/LoanList.reducer';
import PickupCarReducer from '../pages/PickupCar/PickupCar.reducer';
import EventosReducer from '../pages/Eventos/Eventos.reducer';


export default combineReducers({
  ApprovalBackofficeReducer, CarsReducer, CarReducer, VersionReducer, EmpresaReducer, 
  TecnologiaReducer, EMobilityReducer, HomeReducer, ContactsReducer,
  SideMenuReducer, SubEmpresaReducer, SubTecnologiaReducer, SubEMobilityReducer, 
  NewsComponentReducer, NewsDetailReducer, MediaGalleryReducer, MediaCenterReducer,
  MediaGalleryDetailsReducer, CartReducer, SearchReducer, TwitterReducer, YoutubeReducer,
  LoanRequestReducer, ChecklistReducer, ChecklistForm, LoanListReducer, PickupCarReducer,
  EventosReducer
});
