const initialState = {
  cars: [],
  news: [],
  gallery: []
}

const CarReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LIST_CARS':
      state = initialState;
      return {
        cars: [...state.cars, ...action.cars],
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default CarReducer;