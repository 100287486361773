import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTweets } from './TwitterComponent.action';
import { fetchValues } from '../../shared/http-request-util';

import './TwitterComponent.css'; 
import TwitterComponentItem from '../TwitterComponent/TwitterComponentItem/TwitterComponentItem';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { apiEndpoint } from '../../config/constants';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

class TwitterComponent extends Component { 

  constructor(props) {
    super(props)
    
    var newsItems = [
      {
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      },{
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA   #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      },{
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA   #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      },{
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA   #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      },{
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA   #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      },{
        media: require('./img/twitter@3x.png'),
        description: 'Electrifying: The first electric car to roll off the production line in Chattanooga will be the ID. CROZZ SUV model.',
        tags: 'http://vwpress.de/f8EK30njTAA   #VWoA #electricvehicles #eMobility',
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'Carros'
        },
        icons: {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        },
      }]
    this.state = {
      newsItems: newsItems
    }
  }    

  componentDidMount() {    
    
    this.update = true;
    // this.props.dispatch(fetchValues(apiEndpoint() + 'rssfeed/twitter', getTweets));
  }  

  mapTwitterResults() {
    let mappedResultTweets = []
    this.props.tweets.map((tweet, index) => {
      if(index < 9) {
        var dateTweet = new Date(tweet.isoDate)
        let mappedResult = {
          media: require('./img/twitter@3x.png'),
        } 
        mappedResult.description = tweet.content
        mappedResult.link = tweet.link
        mappedResult.updated_at = dateTweet.toLocaleDateString('pt-BR')
        mappedResult.news_association = {
          text_enum: 'Twitter'
        }
        mappedResult.icons = {
          like: require('./img/like@3x.png'),
          reply: require('./img/reply@3x.png'),
          retweet: require('./img/retweet@3x.png'),
        }

      
        console.log('mappedResult', dateTweet.toString(), dateTweet.toLocaleDateString('pt-BR'))
        mappedResultTweets.push(mappedResult)
      }
      
    })

    return mappedResultTweets;
  }

  render() {

    return (   
      this.mapTwitterResults().length > 0 ?   
        <div className={'newscomponent-container twitter-container ' + (this.props.isVertical ? 'isVertical' : 'isSlider')}>
        
          <TwitterComponentItem
            newsItems={ this.mapTwitterResults() } 
            itemsPerSlide='3' 
            isVertical={ this.props.isVertical }
            isReadMore={ this.props.isReadMore }
            btnTxt='Ir para o Twitter da Volkswagen'
            hideIcon={true}
            hideReadMore={true}
            />
      

        </div>
      : null 
    );    
  }
}


// Add this function:
function mapStateToProps(state) {
  return {
    tweets: state.TwitterReducer.tweets
  };
}

export default connect(mapStateToProps)(TwitterComponent);
// export default TwitterComponent;
