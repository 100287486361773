/*
 * action types
 */

export const APPROVALS_BACKOFFICE = 'APPROVALS_BACKOFFICE'

/*
 * action creators
 */
export function getApprovalBackoffice(result) {
  return { 
    type: APPROVALS_BACKOFFICE, 
    list: result.list,
    frotasList: result.frotas
  }
}
