import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumb.css'; 

class Breadcrumb extends Component {

  constructor(props) {
    super(props);
  }

  _setContent = (value, link) => {
    return (
      <span><Link to={`${link}`}>{value}</Link> / </span>
    )
  }

  render() {
    //Split values
    var values = this.props.text.split(' / ');
    //get href and remove it from string
    for (let i = 0; i < values.length; i++) {
      //Check if there is href
      if (values[i].includes('href')) {
        //Remove the <anchor
        let initial = values[i].indexOf('>') + 1;
        let final = values[i].indexOf('</');
        let newValue = values[i].substring(initial, final);
        //Get the link
        let initialLink = values[i].indexOf('href') + 6;
        let finalLink = values[i].indexOf('>') - 1;
        let newLink = values[i].substring(initialLink, finalLink);
        values[i] = this._setContent(newValue, newLink);
      } else {
        values[i] = values[i]
      }
    }

    return (
      <div className="vw-breadcrumb">
        {values.map((value, index) => {
          return (
            <span>{value}</span>
          )          
        })}
      </div>
    );    
  }
}

export default Breadcrumb;
