const initialState = {
  news: [],
  gallery: [],
  destaque: [],
  cars: []
}

const HomeReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'HOME_CONTENT':
      state = initialState;
      return {
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery],
        destaque: [...state.destaque, ...action.destaque],
        cars: [...state.cars, ...action.cars]
      }
    default:
      return state;
  }
};

export default HomeReducer;