import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEMobility } from './EMobility.actions';
import { fetchValues } from '../../shared/http-request-util';

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../components/TextBody/TextBody';
import ShortImage from '../../components/ShortImage/ShortImage';
import ArticleTitle from '../../components/ArticleTitle/ArticleTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Gallery from '../../components/Gallery/Gallery';
import Loading from '../../components/Loading/Loading';
import ReactGA from 'react-ga';

import './EMobility.css'; 
import HighlightMedia from '../../components/HighlightMedia/HighlightMedia';
import Speak from '../../components/Speak/Speak';
import PopOverButton from '../../components/PopOverButton/popOverButton';

let { apiEndpoint, publicUrlEndpoint } = require('../../config/constants');
let { urlEndpoint } = require('../../config/constants');

let { emobilityHomeAPI } = require('../../config/constants');


class EMobility extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    ReactGA.pageview('/mobilidade-eletrica');
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + emobilityHomeAPI(), getEMobility));
  }    

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {      
      return (      
        <div className="vw-emobility">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text="<a href='/'>Newsroom</a> / Mobilidade Elétrica" />
              <PageTitle title="Mobilidade Elétrica"/>
              <HighlightMedia showPopOverButtons={ true } 
                imageName={this.props.emobility.title}
                media={ this.props.emobility.media} mediaMedium={ this.props.emobility.mediaMed } mediaLow={ this.props.emobility.mediaLow } 
                mediaSize={this.props.emobility.mediaSize} mediaSizeMedium= { this.props.emobility.mediaSizeMedium } mediaSizeLow = {this.props.emobility.mediaSizeLow}
                GAText='mobilidade-eletrica'
                isAutoPlay={true}/>
              <div className="row w-100 justify-content-end newsButtons emobillity-Buttons">
              
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.emobility.title} ` + ` ${publicUrlEndpoint()}` + `/mobilidade-eletrica/` + `${this.props.match.params.emobility_id}`}
                    twitterText={this.props.emobility.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/mobilidade-eletrica/` + `${this.props.match.params.emobility_id}`}
                  />
              </div>
            </div>
             <ArticleTitle title={this.props.emobility.title}/>
            </div>
          
            <div className="col-12">            
              <PageSubTitle text={this.props.emobility.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-md-8">            
              <TextBody text={this._convertDescription(this.props.emobility.description)}/>
            </div>
            <div className="col-md-4">            
              <ShortImage showPopOverButtons={ true } 
              imageName={this.props.emobility.title}
                contentLink={ '/mobilidade-eletrica'} 
                media={ this.props.emobility.sideMedia} mediaMedium={ this.props.emobility.sideMediaMed } mediaLow={ this.props.emobility.sideMediaLow } 
                mediaSize={ this.props.emobility.sideMediaSize} mediaSizeMedium={ this.props.emobility.sideMediaSizeMedium } mediaSizeLow={ this.props.emobility.sideMediaSizeLow } 
                GAText='mobilidade-eletrica'
                roundBorder="false" />
              <div className="vw-italic">
                <TextBody className="vw-italic vw-font-12px" text={this.props.emobility.sideDescription}/>
              </div>
            </div>
            <div className="w-100"></div>
            <div className="col">            
                <PageTitle className="vw-font-20px" title={this.props.emobility.bottomTitle}/>
            </div>
            <div className="w-100" />
            <div className="col-12">
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.emobility.title}
              media={ this.props.emobility.bottomMedia} mediaMedium={ this.props.emobility.bottomMediaMed } mediaLow={ this.props.emobility.bottomMediaLow } 
              mediaSize={this.props.emobility.bottomMediaSize} mediaSizeMedium= { this.props.emobility.bottomMediaSizeMedium } mediaSizeLow = {this.props.emobility.bottomMediaSizeLow}
              GAText='mobilidade-eletrica'
              isAutoPlay={true}/>
            </div>          
          </div>        
          <NewsComponent isVertical={false} news={this.props.news}/>
  
          
          <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/>  
          <Speak/>
        </div>
      );      
    } else {
      return (<Loading></Loading>);
    }
    
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    emobility: state.EMobilityReducer.emobility,
    news: state.EMobilityReducer.news,
    gallery: state.EMobilityReducer.gallery
  };
}

export default connect(mapStateToProps)(EMobility);

