import React, { Component } from 'react';
import { Button,ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PageTitle from '../PageTitle/PageTitle'
import PageSubTitle from '../PageSubTitle/PageSubTitle'
import TextBody from '../TextBody/TextBody'
import TitleCar from '../TitleCar/TitleCar'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import PopOverButton from '../PopOverButton/popOverButton'

import './ExpandImage.css'; 

import placeholder from './assets/logo-vw@2x.jpg'
import imageIcon from './assets/icone-foto.svg'
import twitter from './assets/icone-twitter.svg'
import facebook from './assets/icone-facebook.svg'
import youtube from './assets/icone-youtube.svg'
import instagram from './assets/icone-instagram.svg'
import PopOverButtton from '../PopOverButton/popOverButton';

// Props required for download:
// imageNo = 'string'
// copyright = 'string'
// rights = 'string'
// imageName = 'string'
// imageSizeSmall = 'string'
// imageSizeMedium = 'string'
// imageSizeLarge ='string'
// date = 'string'

class ExpandImage extends Component {

  isVideoDownload = false

  constructor(props) {
    super(props);    
    this.modal = React.createRef();
    this.toggleModal.bind(this);
    
      this.state = {
        modalOpen: false,
        modalConfirmationOpenDownload: false,
        modalConfirmationOpenShare: false,
        downloadPopOver: false,
        sharePopOver: false,
        confirmationTitleDownload: "Termo de Uso - Download",
        confirmationSubTitleDownload: "Os textos, imagens, áudios, documentos e vídeos, disponibilizados para download na VW News BR têm finalidade puramente informativa, podendo ser utilizados em editoriais em mídias sociais, editoriais de Influencers na Internet, editoriais jornalísticos, bem como em editoriais internos de empresas para seus colaboradores, não podendo ser utilizados para fins comerciais.",
        confirmationBodyDownload: "É vedado o uso dos textos, imagens, áudios, documentos e vídeos disponibilizados para download na VW News BR de forma que contrarie a realidade para a qual foram concebidos. O uso deverá ser em conformidade com o presente Termo de Uso, a legislação vigente, a moral e os bons costumes. \nSe aplicam a este Termo de Uso as disposições dos Termos e Condições Gerais de Uso da VW News BR no que não conflitarem.",
        confirmationConfirmDownload: "Download",
        confirmationCancelDownload: "Cancelar",
        confirmationTitleShare: "Data Transmission",
        confirmationSubTitleShare: "If you would like to share contents in social networks, you can activate the corresponding functions here.",
        confirmationBodyShare: "By activating, you give your consent to the transmission of data to the operators of the social networks. Upon activation, an initial transmission of data to third parties immediately occurs. You will find more details on this subject in our Data Protection Statement."    ,
        confirmationConfirmShare: "Transmit data",
        confirmationCancelShare: "Do not transmit data",
      };


      if(document.getElementById("root").offsetWidth < 992) {
        this.isMobile = true;
      }
  }

  componentWillReceiveProps(nextProps) {
      console.log(nextProps)
      this.setState({
        modalOpen: nextProps.modalOpen ? !this.state.modalOpen : nextProps.modalOpen
      });
  }

  toggleModal(isImage, e, isVideoDownload){
    if(isVideoDownload || !isVideoDownload && this.isVideoDownload) {
      this.isVideoDownload = isVideoDownload
      return
    }
    console.log('modal open', isImage, e, this.isMobile)
    this.closeParentModal()
    e.stopPropagation()
    if(isImage) {
      // if(this.isMobile) {
      //   this.setState({
      //     modalOpen: !this.state.modalOpen
      //   });
      // }
    } else {
      this.setState({
        modalOpen: !this.state.modalOpen
      });
    }
   
  }

  closeParentModal() {
    return this.props.toggleModal()
  }
  toggleDownload() {
    this.setState({
      downloadPopOver: !this.state.downloadPopOver
    });
  }

  toggleShare() {
    this.setState({
      sharePopOver: !this.state.sharePopOver
    })
  }

  toggleConfirmationModalDownload2() {
    switch (this.selectedValue) {
      case 'low': {
        window.location.href = this.props.mediaLow;
        break;
      }
      case 'medium': {
        window.location.href = this.props.mediaMedium;
        break;
      }
      case 'large': {
        window.location.href = this.props.media;
        break;
      }
      default:
        break;
    }
    this.setState({
      downloadPopOver: false,
      sharePopOver: false,
      modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
    }, () => console.log("model should be open" + JSON.stringify(this.state)));
  }  

  toggleConfirmationModalDownload(value) {
    this.selectedValue = value;
    this.setState({
      downloadPopOver: false,
      sharePopOver: false,
      modalConfirmationOpenDownload: !this.state.modalConfirmationOpenDownload
    }, () => console.log("model should be open" + JSON.stringify(this.state)));
  }

  toggleConfirmationModalShare() {
    this.setState({
      downloadPopOver: false,
      sharePopOver: false,
      modalConfirmationOpenShare: !this.state.modalConfirmationOpenShare
    }, () => console.log("model should be open" + JSON.stringify(this.state)));
  }

  playVideo() {
    if(this.props.preventPlay) return
    var videoName = 'video-' + this.getMediaName();
    console.log(this.refs,'videoname', videoName)
    if(this.refs[videoName]) {
        this.refs[videoName].play();
    }
    this.setState({
        hidePlayBtn: true
    })
    this.refs[videoName].setAttribute("controls","controls") 
  }

  getMediaName() {
    if(!this.props.media) return
    if(!this.props.youTubeVideo) {
        return this.props.media.substring((this.props.media.lastIndexOf('/') + 1), this.props.media.lastIndexOf('.'))
    } else {
        return this.props.videoYT_id.replace('yt:video:', '')
    }
  }

  render() {

    const closeBtn = <button className="close" onClick={this.toggleModal.bind(this, false)}>&times;</button>;
    let imgName =  this.props.media ? this.props.media.substring((this.props.media.lastIndexOf('/') + 1), this.props.media.lastIndexOf('.')) : ''
    let mediaType =  this.props.media ? (this.props.media.indexOf('mp4') < 0 ? 'Imagem' : 'Vídeo') : ''
    let popOverPosition = (!this.isMobile) ? 'top' : 'right'
    return (
      <span>
        <span className='small-image' data-noscript="true">
          { mediaType == 'Imagem' ? 
            <img  src={this.props.mediaLow ? this.props.mediaLow : this.props.media } />
          : 
            <video id={`video-${this.getMediaName()}`} ref={`video-${this.getMediaName()}`} preload="metadata">
                <source src={`${this.props.media}#t=1`} type="video/mp4"/>
            </video>
          }
         
            { (this.props.showPopOverButtons) ? 
              <div className='holder-buttons'>
                  <div id={`cartButtonContainer-${imgName}`} className="buttonContainer">
                    <PopOverButtton 
                        popOverType="cart"
                        containerName={`cartButtonContainer-${imgName}`}
                        imageLink={this.props.media}
                        contentToDownload={[]}
                        contentLink={this.props.contentLink}
                        imageDownloadName={this.props.imageName }
                        type={mediaType}
                        mediaAmount={ 0 }
                        insideImage={true}
                        popOverPosition={popOverPosition}
                      />
                  </div>
                  <div id={`downloadBondaryHover-${imgName}`} className="buttonContainer">
                      <PopOverButtton 
                          popOverType="imageSize"
                          containerName={`downloadBondaryHover-${imgName}`}
                          mediaLow = {this.props.mediaLow} 
                          mediaMedium = {this.props.mediaMedium} 
                          media = {this.props.media} 
                          imageDownloadName={imgName}
                          mediaSizeLow = {this.props.mediaSizeLow} 
                          mediaSizeMedium = {this.props.mediaSizeMedium} 
                          mediaSize = {this.props.mediaSize} 
                          GAText={this.props.GAText}
                          popOverPosition={popOverPosition}
                          toggleModal={(e, isImage, isVideoDownload) => this.toggleModal(e, isImage, isVideoDownload)}
                      />
                  </div>
                  <div className="buttonContainer ">
                    <a className="buttonIcon btn-expand" onClick={ this.toggleModal.bind(this, false)}>
                      <i className="icon brand-icon is-maximize"></i>
                    </a>
                  </div>
              </div>
            : null }
        </span>

        { (!this.props.activeMedia || this.props.activeMedia == this.props.media) ? 
          <Modal size="lg" isOpen={this.state.modalOpen} toggle={this.toggleModal.bind(this, false)}>
              <ModalHeader className="expand__imageHeader" close={closeBtn}> 
                {/* <img src={this.props.mediaLow} /> */}

                { mediaType == 'Imagem' ? 
                  <img src={this.props.mediaLow} />
                : 
                  <video id={`video-${this.getMediaName()}`} ref={`video-${this.getMediaName()}`} autoPlay controls preload="metadata">
                      <source src={`${this.props.media}#t=1`} type="video/mp4"/>
                  </video>
                }

              </ModalHeader>
              <ModalBody>
              <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-md-auto" id="dateAndType">
                      
                    </div>
                    <div className="col-12 col-md-2 expand__menuImages" id="optionRows">
                      <div className="row justify-content-center justify-content-md-end" >
                        <div className="col-2 col-md-6" id="expandImageDownloadContainer" >
                          <PopOverButton
                          popOverType= 'imageSize'
                          containerName= 'expandImageDownloadContainer'
                          mediaLow = {this.props.mediaLow} 
                          mediaMedium = {this.props.mediaMedium} 
                          media = {this.props.media} 
                          mediaSizeLow = {this.props.mediaSizeLow} 
                          mediaSizeMedium = {this.props.mediaSizeMedium} 
                          mediaSize = {this.props.mediaSize} 
                          popOverPosition='top'
                          toggleModal={(e, isImage, isVideoDownload) => this.toggleModal(e, isImage, isVideoDownload)}
                            />
                        </div>
                        <div className="col-2 col-md-6 align-end buttonContainer shareContainer" id="expandImageShareContainer">
                          <PopOverButton
                            popOverType= 'social'
                            containerName= 'expandImageShareContainer'
                            twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.imageName} ` + ` ${this.props.media}`}
                            twitterText="this.props.imageName"
                            facebookLink={`http://www.facebook.com/sharer.php?u=` + `${this.props.media}`}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                    <PageTitle
                      title={this.state.imageName}/>                
                    </div>
                  </div>
              </div>
              </ModalBody>
          </Modal>
      
        : null }

      <ConfirmationModal 
        isOpen={this.state.modalConfirmationOpenDownload}
        title={this.state.confirmationTitleDownload}
        subTitle={this.state.confirmationSubTitleDownload}
        body={this.state.confirmationBodyDownload}
        type='download'
        toggle={this.toggleConfirmationModalDownload2.bind(this)}
        closeAll={this.toggleModal.bind(this, false)}
        confirmMessage={this.state.confirmationConfirmDownload}
        cancelMessage={this.state.confirmationCancelDownload}
        />
        
      <ConfirmationModal 
        isOpen={this.state.modalConfirmationOpenShare}
        title={this.state.confirmationTitleShare}
        subTitle={this.state.confirmationSubTitleShare}
        body={this.state.confirmationBodyShare}
        type='share'
        toggle={this.toggleConfirmationModalShare.bind(this)}
        closeAll={this.toggleModal.bind(this, false)}
        confirmMessage={this.state.confirmationConfirmShare}
        cancelMessage={this.state.confirmationCancelShare}
        />



      </span>
    );    
  }
}

export default ExpandImage;
