
/*
 * action types
 */

export const YOUTUBE_DETAIL = 'YOUTUBE_DETAIL'

/*
 * action creators
 */
export function getVideosYT(result) {
  return { 
    type: YOUTUBE_DETAIL, 
    videosYT: result.items
  }
}
