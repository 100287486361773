import React, { Component } from 'react'
import './Arrow.css'

class PrevArrow extends Component {
    constructor(props) {
        super()
    }
    render(){
        const { className, style, onClick } = this.props;
        return (
            <div className={className} onClick={onClick}> 
            <div className="prevArrow">
              <img className="Parrow" src={require('./img/arrow-prev.png')}/>
            </div>
            </div>
          );
    }
}

export default PrevArrow;
