/*
 * action types
 */

export const EVENTOS_HOME = 'EVENTOS_HOME'

/*
 * action creators
 */
export function getEventos(result) {
  return { 
    type: EVENTOS_HOME, 
    list: result.list
  }
}
