import React, { Component } from 'react';

import './Button.css'; 

class Button extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { onClick } = this.props;
    return (                
        <div className='row'>
            <div className='col-12 text-center'>
                <button onClick={onClick} disabled={this.props.disabled} className={"btn-primary " + (this.props.whiteBtn ? 'btn-white' : '') + (this.props.secondary ? 'btn-secondary' : '')}>
                    {!this.props.hideIcon ? 
                        <i className="icon brand-icon is-show-more has-text"></i>
                    : null }
                    <span className="btn-txt">{ this.props.text }</span>

                    {this.props.iconType ?
                      <i className={`icon brand-icon ${this.props.iconType} has-text`}></i>
                    : null }

                </button>
            </div>
        </div>    
       
    );    
  }
}

export default Button;
