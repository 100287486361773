const initialState = {
  pagePhotos: {},
  latestPhotos: [],
  pageAlbums: {},
  latestAlbums: [],
  latestVideos: [],
  pageVideos: {},

}

const MediaGalleryReducer = (state = initialState, action) => {
  switch(action.type) {        
    case 'LIST_LATEST_PHOTOS':
      return {
        latestPhotos  : [...state.latestPhotos, ...action.latestPhotos],
        pagePhotos    : action.pagePhotos,
        latestAlbums  : state.latestAlbums,
        pageAlbums    : state.pageAlbums,
        latestVideos  : state.latestVideos,
        pageVideos    : state.pageVideos
      }
    case 'LIST_LATEST_ALBUMS':
      return {
        latestAlbums  : [...state.latestAlbums, ...action.latestAlbums],
        pageAlbums    : action.pageAlbums,
        latestPhotos  : state.latestPhotos,
        pagePhotos    : state.pagePhotos,
        latestVideos  : state.latestVideos,
        pageVideos    : state.pageVideos
      }  
    case 'LIST_LATEST_VIDEOS':
      return {
        latestAlbums  : state.latestAlbums,
        pageAlbums    : state.pageAlbums,
        latestPhotos  : state.latestPhotos,
        pagePhotos    : state.pagePhotos,
        latestVideos  : [...state.latestVideos, ...action.latestVideos],
        pageVideos    : action.pageVideos,
      }  
    default:
      return state;
  }
};

export default MediaGalleryReducer;