const initialState = {
  menu: {},
}

const SideMenuReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LIST_MENU':
      state = initialState;
      return {
        menu: {...state.menu, ...action.menu},
      }
    default:
      return state;
  }
};

export default SideMenuReducer;