import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
// import ArticleTitle from '../../../components/ArticleTitle/ArticleTitle';
// import Loading from '../../../components/Loading/Loading';
// import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';

import './TermoNews.css'; 


// let { apiEndpoint } = require('../../../config/constants');
// let { API } = require('../../config/constants');


class TermoNews extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    this.update = true;
    // this.props.dispatch(fetchValues(apiEndpoint() + API() + this.props.match.params.termoNews_id, get));
  }  

  
  render() {
    if (this.update) {
      return (
        // TEM QUE AJUSTAR QUANDO TIVER CONSUMO DA API
       
          <div className="row vw-imprint">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a> / ${this.props.termoNews.title}`} />
              <PageTitle title={this.props.imprint.title}/>
            </div>
            <div className="col-12"> 
              <PageSubTitle  title={this.props.imprint.title}/>           
          </div>
          </div> 
        
      );       
    } else {
      return (
        <div className="row vw-imprint">
          <div className="col-12">
            <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a>  / Termo de Uso - Newsletter`} />
            <PageTitle title="Termo de Uso - Newsletter"/>
            {/* <PageSubTitle  text="(Atualizado em 15/02/2019)"/>  */}
            <PageSubTitle  text="1.1 Antes de utilizar nosso serviço de Newsletter o Usuário declara ter lido e compreendido o presente Termo de Uso e os Termos e Condições Gerais de Uso da VW News BR, estando de acordo com os mesmos.             "/> 
              
          </div>
          <div className="col-12">            
          <PageSubTitle  text="1.2 A Newsletter da VW News BR tem por finalidade o envio, ao Usuário cadastrado, das principais notícias e informações disponibilizadas no site."/>
          </div>
          <div className="col-12 "> 
              <PageSubTitle  text="1.3 O cadastro para recebimento da Newsletter será feito pelo Usuário no site da VW News BR através do seu e-mail. Após o cadastro, o Usuário receberá um e-mail de confirmação e passará a receber a Newsletter."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.4 O Usuário cadastrado poderá, a qualquer momento, cancelar o seu cadastro através de link específico constante no rodapé do e-mail de Newsletter.  "/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.5 A Newsletter será enviada ao Usuário cadastrado a cada publicação de nova “News”."/>  
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.6 A responsabilidade do recebimento da Newsletter é exclusiva do Usuário cadastrado. Para tanto, deverá o mesmo manter seu cadastro atualizado e configurar sua conta de e-mail para o recebimento da Newsletter. "/>           
          </div>         
          <div className="col-12"> 
              <PageSubTitle  text="1.7 A VWB se reserva o direito de, a qualquer tempo, discricionariamente,  sem aviso prévio: alterar conteúdo da Newsletter; alterar a periodicidade de envio; modificar o formato da Newsletter e/ou cancelar o envio da Newsletter."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.8 Os dados coletados do Usuário para o cadastro serão tratados de acordo com a Política de Privacidade e Proteção de Dados da VW News BR."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="1.9 Se aplicam a este Termo de Uso as disposições dos Termos e Condições Gerais de Uso da VW News BR no que não conflitarem. "/>           
          </div>
        </div>
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    TermoNews: state.CarReducer.TermoNews,
  };
}

export default connect(mapStateToProps)(TermoNews);

