import fetch from 'cross-fetch'

/*
* Fetch Values
*/
export function fetchValues(endpoint, callback) {
  return function (dispatch) {
    return fetch(endpoint)
      .then(
        response => response.json(),
        error => console.log('Error')
      )
      .then(result => {
        dispatch(callback(result));
      })
  }
}

/*
* Fetch Values with token as paramenter
*/
export function fetchValuesToken(endpoint, method, callback, token) {
  return function (dispatch) {
    let options = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': token
      }
    };
    return fetch(endpoint, options)
      .then(
        response => response.json(),
        error => console.log('Error')
      )
      .then(result => {
        dispatch(callback(result));
      })
  }
}

/**
 * Fetch Values with options
 * @param {method} string with request method
 */
export function fetchValuesAuth(endpoint, method, callback) {
  return function (dispatch) {
    let options = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': JSON.parse(localStorage.getItem('user-data')).token
      }
    };
    return fetch(endpoint, options)
      .then(
        response => response.json(),
        error => console.log('Error')
      )
      .then(result => {
        dispatch(callback(result));
      })
  }
}

/*
* Fetch Values with options
*/
export function fetchValuesOptions(endpoint, options, callback) {
  return fetch(endpoint, options)
    .then(
      response => {
        if(response.status == 200) {
          return response.json()
        } else {
          return {error: true}
        }
      },
      error => alert('Error')
    )
    .then(result => {
      callback(result);
    })
}

/*
* Fetch Values
*/
export function fetchValuesLogout(endpoint, callback) {
 
    return fetch(endpoint)
      .then(
        response => console.log(response),
        error => console.log('Error')
      )
      .then(result => {
        debugger;
        callback();
      })
}