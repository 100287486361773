import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmpresa } from './SubEmpresa.actions';
import { getSubEmpresa } from './SubEmpresa.actions';
import { fetchValues, fetchValuesToken } from '../../../shared/http-request-util';

//Components
import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import ReactGA from 'react-ga';

import './SubEmpresa.css';
import PopOverButton from '../../../components/PopOverButton/popOverButton';

let { urlEndpoint, publicUrlEndpoint } = require('../../../config/constants');
let { apiEndpoint } = require('../../../config/constants');
let { empresaLevel1 } = require('../../../config/constants');
let { empresaLevel2 } = require('../../../config/constants');

class SubEmpresa extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    this.update = true;
    //if user has been viewing an preview from CMS, will receive the user token here
    let token = (this.props.location.search.indexOf('token=') != -1) ? this.props.location.search.split('token=')[1] : false;
    if (this.props.match.params.subsession_id) {
      if (token) {
        this.props.dispatch(fetchValuesToken(apiEndpoint() + empresaLevel2(this.props.match.params.company_id, this.props.match.params.subsession_id), 'GET', getSubEmpresa, token));
      } else {
        this.props.dispatch(fetchValues(apiEndpoint() + empresaLevel2(this.props.match.params.company_id, this.props.match.params.subsession_id), getSubEmpresa));
      }
    } else {
      if (token) {
        this.props.dispatch(fetchValuesToken(apiEndpoint() + empresaLevel1(this.props.match.params.company_id), 'GET', getEmpresa, token));
      } else {
        this.props.dispatch(fetchValues(apiEndpoint() + empresaLevel1(this.props.match.params.company_id), getEmpresa));
      }
    }
    
  }   

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {
      console.log(this.props.gallery);
      ReactGA.pageview('/company: ' + this.props.company.title);
      return (            
        <div className="vw-empresa-level1">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/company'>Empresa</a> / ${this.props.company.title} `} />
              <PageTitle title={this.props.company.title}/>
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.company.title}
              media={this.props.company.media} mediaMedium={ this.props.company.mediaMed } mediaLow={ this.props.company.mediaLow } 
              mediaSize={this.props.company.mediaSize} mediaSizeMedium= { this.props.company.mediaSizeMedium } mediaSizeLow = {this.props.company.mediaSizeLow}
              GAText={'/company: ' + this.props.company.title}
              isAutoPlay={true}/>
            </div>
            <div className="row w-100 justify-content-end newsButtons">
              
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.company.title} ` + ` ${publicUrlEndpoint()}` + `/company/` + `${this.props.match.params.company_id}`}
                    twitterText={this.props.company.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/company/` + `${this.props.match.params.company_id}`}
                  />
              </div>
            </div>
            <div className="col-12">            
              <PageSubTitle text={this.props.company.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">            
              <TextBody text={this._convertDescription(this.props.company.description)}/>
            </div>
            <Template GAText={ this.props.company.title }  templates={this.props.company.templates} />
            <NewsComponent isVertical={true} textEnum="Empresa" idLevel1={ this.props.match.params.company_id } idLevel2={ this.props.match.params.subsession_id }/>   
  
            <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/>
                 
            <Speak/>           
          </div> 
        </div>
      );       
    } else {
      return (<Loading></Loading>);
    }
   
  }
}
 // Add this function:
function mapStateToProps(state) {
  return {
    company: state.SubEmpresaReducer.company,
    news: state.SubEmpresaReducer.news,
    gallery: state.SubEmpresaReducer.gallery
  };
}

export default connect(mapStateToProps)(SubEmpresa);
