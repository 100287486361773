/*
 * action types
 */

export const LIST_LATEST_PHOTOS = 'LIST_LATEST_PHOTOS';
export const LIST_LATEST_ALBUMS = 'LIST_LATEST_ALBUMS';
export const LIST_LATEST_VIDEOS = 'LIST_LATEST_VIDEOS';

/*
 * action creators
 */
export function getLatestPhotos(result) {
  return { 
    type: LIST_LATEST_PHOTOS, 
    latestPhotos: result.bucket_media,
    pagePhotos: result.pages
  }
}

export function getLatestAlbums(result) {
  return {
    type: LIST_LATEST_ALBUMS,
    latestAlbums: result.bucket,
    pageAlbums: result.pages
  }
}

export function getLatestVideos(result) {
  return {
    type: LIST_LATEST_VIDEOS,
    latestVideos: result.bucket_media,
    pageVideos: result.pages
  }
}