import React, { Component } from 'react';
import PubSub from 'pubsub-js'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMenu } from './SideMenus.actions';
import { fetchValues } from '../../shared/http-request-util';

import Logo from './img/volkswagen_logo.png';
import './SideMenu.css';

let { apiEndpoint } = require('../../config/constants');
let { homeAPI } = require('../../config/constants');

class SideMenus extends Component {

  constructor(props) {
    super(props);
    this.token = "";
    this.update = false;
    this.activeLevel1 = ''
    this.activeLevel2 = ''
    this.timeoutLevel1 = ''
    this.timeoutLevel2 = ''
    this.state = {
      showMenuCSS: '',
      showMenuLevel2: '',
      showMenuLevel3: '',
    };
  }

  _showSideMenu = (msg, data) => {
    this.setState({
      showMenuCSS: 'show',
      overlay: 'overlay',
    });
  }

  _hideSideMenu = () => {
    this.setState({
      showMenuCSS: 'hide',
      showMenuLevel2: 'hide',
      showMenuLevel3: 'hide',
      overlay: '',
    });    
  }

  _hoverDataLevel1 = (e)=> {
    if(document.getElementById("root").offsetWidth > 992) {
      let parentElement = document.getElementById("side-menu")
      let activeLevel = e.currentTarget
      //check if element has a sibling
      if(activeLevel.children[1]) {
        if(this.timeoutLevel1) clearTimeout(this.timeoutLevel1)
        this.timeoutLevel1 = setTimeout(() => {
          let activeItems = document.querySelectorAll('.active')
          for(var i = 0; i < activeItems.length; i++) {
            activeItems[i].classList.remove('active')
          }
          activeLevel.classList.add('active')
          this.setState({
            showMenuLevel2: 'show',
          });
        }, 500)
      } else {
        if(this.timeoutLevel1) clearTimeout(this.timeoutLevel1)
        this.timeoutLevel1 = setTimeout(() => {
          let activeItems = document.querySelectorAll('.active')
          for(var i = 0; i < activeItems.length; i++) {
            activeItems[i].classList.remove('active')
          }
        }, 500)
      }
    }
  }
  
  _hoverDataLevel1Leave = (e)=> {
    if(document.getElementById("root").offsetWidth > 992) {
      this.activeLevel1 = e.currentTarget
      if(this.timeoutLevel1) clearTimeout(this.timeoutLevel1)
      this.timeoutLevel1 = setTimeout(() => {
        this.activeLevel1.classList.remove('active')
      }, 500)
    }
  }

  _hoverDataLevel2 = (e)=> {
    if(document.getElementById("root").offsetWidth > 992) {
      let activeLevel = e.currentTarget
      let parentElement = document.getElementById("side-menu")
      var parent = parentElement.querySelectorAll('.vw-side-menu-content')
      //check if element has a sibling
      if(activeLevel.children[1]) {
        if(this.timeoutLevel2) clearTimeout(this.timeoutLevel2)
          this.timeoutLevel2 = setTimeout(() => {
            for(var i = 0; i < parent.length; i++) {
              if(parent[i].dataset.level == '2') {
                let childActive = parent[i].querySelectorAll('.active')
                for(var z = 0; z < childActive.length; z++) {
                  childActive[z].classList.remove('active')
                }
              }
            }
            activeLevel.classList.add('active')
            this.setState({
              showMenuLevel3: 'show',
            });
          }, 500)
      } else {
        if(this.timeoutLevel2) clearTimeout(this.timeoutLevel2)
        this.timeoutLevel2 = setTimeout(() => {
          for(var i = 0; i < parent.length; i++) {
            if(parent[i].dataset.level == '2') {
              let childActive = parent[i].querySelectorAll('.active')
              for(var z = 0; z < childActive.length; z++) {
                childActive[z].classList.remove('active')
              }
            }
          }
        },500)
      }
    }
  }
  
  _hoverDataLevel2Leave = (e)=> {
    if(document.getElementById("root").offsetWidth > 992) {
      this.activeLevel2 = e.currentTarget
      setTimeout(() => {
        this.activeLevel2.classList.remove('active')
      }, 500)
    }
  }

  _showDataLevel2 = (page, e) => {
    if(document.getElementById("root").offsetWidth < 992) {
      let activeItems = document.querySelectorAll('.active')
      for(var i = 0; i < activeItems.length; i++) {
        activeItems[i].classList.remove('active')
      }
      
      //check if element has a sibling
      if(e.currentTarget.nextSibling) {
        e.stopPropagation()
        e.preventDefault()
        e.currentTarget.parentElement.classList.add('active')
        this.setState({
          showMenuLevel2: 'show',
        });
      } else {
        console.log('hide sidemenu')
        this._hideSideMenu()
      }

    } else {
      window.location = page
    }
  }

  _hideDataLevel2 = () => {
    this.setState({
      showMenuLevel2: 'hide'
    });    
  }  

  _showDataLevel3 = (page, e) => {
    let parentElement = document.getElementById("side-menu")
    var parent = parentElement.querySelectorAll('.vw-side-menu-content')
    if(document.getElementById("root").offsetWidth < 992) {
      for(var i = 0; i < parent.length; i++) {
        if(parent[i].dataset.level == '2') {
          let childActive = parent[i].querySelectorAll('.active')
          for(var z = 0; z < childActive.length; z++) {
            childActive[z].classList.remove('active')
          }
        }
      }
      //check if element has a sibling
      if(e.currentTarget.nextSibling) {
        e.stopPropagation()
        e.preventDefault()
        e.currentTarget.parentElement.classList.add('active')
        this.setState({
          showMenuLevel3: 'show',
        });
      } else {
        console.log('hide sidemenu')
        this._hideSideMenu()
      }
      console.log('has SUB ITEMS ?', e, e.currentTarget.nextSibling)
    } else {
      console.log('go to page NOT ?MOBILE')
      window.location = page
    }
  }

  _hideDataLevel3 = () => {
    this.setState({
      showMenuLevel3: 'hide'
    });    
  }    

  isMobile() {
    var userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i)!= -1 ){
      console.log('userAgent mobile', userAgent);  
      return true;
    }

    return false; 
  }

  _renderFirstLevel = (section, title, firstLevelLink, secondLevelLink) => {    
    if (section != null && section.length > 0) {
      let actualLink = firstLevelLink;
      if (firstLevelLink === '/cars/car/') {        
        actualLink = '/cars/models';
      }
      return (
        <div className={`vw-side-menu-content ${this.state.showMenuLevel2}`} data-level="2">
        <ul className="nav flex-column">
          <li className="nav-item title1">
            <div className="nav-link active">
              <a className="title-sidemenu">
                <label className="menu-head--title" > { title } </label> 
              </a>
              <a onClick={this._hideDataLevel2}>
                <i className="icon brand-icon is-close-layer"></i>
              </a>
            </div>
           </li>
           <li className="nav-item title2">    
           <Link to={actualLink} target="_self" className="nav-link" onClick={e => this.openPage(e)} >
              <div className="nav-align">
                <i className="is-arrow"></i>
                <label>Abrir {title}</label>
              </div>
            </Link>
          </li>
          {             
            section.map(function (car, index) {
            return (
              <li key={`${car}-${index}`} className="nav-item" onMouseEnter={ this._hoverDataLevel2} onMouseLeave={ this._hoverDataLevel2Leave}>         
                <Link to={`${firstLevelLink}${car.id}`} onClick={e => this._showDataLevel3(`${firstLevelLink}${car.id}`, e)} target="_self" className="nav-link">
                  <div className="nav-align">
                    <i className="is-arrow"></i>
                    <label>{car.title}</label>
                  </div>
                </Link>         
                { this._renderSecondLevel(car.id, car.listsub, firstLevelLink, secondLevelLink, car.title) }
              </li>
            )
          }, this)}
        </ul>          
      </div>
      )
    }
  }

  openPage = (e) => {
    if(!this.isMobile()) return
    this._hideSideMenu()
  }

  openExternalPage = (e, link) => {
    window.location = link
    e.preventDefault()
    if(!this.isMobile()) return
    this._hideSideMenu()
  }

  _renderSecondLevel = (id, versions, firstLevelLink, secondLevelLink, carTitle) => {    
    if (versions != null && versions.length > 0) {
      return (
        <div className={`vw-side-menu-content ${this.state.showMenuLevel3}`} data-level="3">
          <ul className="nav flex-column">
            <li className="nav-item title1">
              <div className="nav-link active">
                <a className="title-sidemenu" >
                  <label className="menu-head--title" > {carTitle} </label> 
                </a>
                <a onClick={this._hideDataLevel3}>
                  <i className="icon brand-icon is-close-layer"></i>
                </a>
              </div>
            </li>
            <li className="nav-item title2">                    
              <Link className="nav-link" to={`${firstLevelLink}${id}`} onClick={e => this.openPage(e)} >
                <div className="nav-align">
                  <i className="is-arrow"></i>
                  <label>Abrir {carTitle} </label>
                </div>
              </Link>
            </li>
            {versions.map((version, index) => {
              return (
                <li key={`${version}-${index}`} className="nav-item">                    
                  <Link to={`${secondLevelLink}${id}/${version.id}`} onClick={e => this.openPage(e)} target="_self" className="nav-link">
                    <div className="nav-align">
                      <i className="is-arrow"></i>
                      <label>{version.title}</label>
                    </div>
                  </Link>
                </li>  
              )
            })}
          </ul>
        </div>      
      )
    }
  }

  componentDidMount() {
    this.token = PubSub.subscribe('SHOW_HIDE_MENU', this._showSideMenu);
    this.update = true;
    console.log('didi mopunt', this.props)
    this.props.dispatch(fetchValues(apiEndpoint() + homeAPI(), getMenu));
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);    
  }

  render() {
    if (this.update) {
      PubSub.publish('SHOW_FOOTER', this.props.menu);
      return (        
        <div id='side-menu' className={`vw-side-menu fixed-top ${this.state.showMenuCSS}`}>
          <div className="vw-side-menu-content" data-level="1">
            <ul className="nav"> 
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <div className="nav-link active vw-logo" > 
                  <Link to="/">
                    <img alt="Volkswagen Newsroom" className="Volkswagen" width="67" height="67" src={Logo} />
                  </Link>
                  <label></label>
                  <a onClick={this._hideSideMenu} >
                    <i className="icon brand-icon is-close-layer" ></i>
                  </a>
                </div>                
              </li>
              {/* MODELOS */}
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <Link to='/cars/models' target="_self" className="nav-link" onClick={e => this._showDataLevel2('/cars/models', e)}>
                  <i className="icon brand-icon is-models"></i>
                  <label className="txt-modelos">Carros</label>
                </Link>
                {this._renderFirstLevel(this.props.menu.cars, 'Carros', '/cars/car/', '/cars/version/')}
              </li>
              {/* INSTITUCIONAL */}
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <Link to='/company' target="_self" className="nav-link" onClick={e =>this._showDataLevel2('/company', e)}>                
                  <i className="icon brand-icon is-company"></i>
                  <label >Empresa</label>
                </Link>
                {this._renderFirstLevel(this.props.menu.session, 'Empresa', '/company/', '/company/')}
              </li>
              {/* TECNOLOGIA */}
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <Link to='/technology' target="_self" className="nav-link" onClick={e =>this._showDataLevel2('/technology', e)}>
                  <i className="icon brand-icon is-technology"></i>
                  <label >Tecnologia</label>
                </Link>
                {this._renderFirstLevel(this.props.menu.technology, 'Tecnologia', '/technology/', '/technology/')}
              </li>
              {/* E-MOBILITY */}
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <Link to='/mobilidade-eletrica' target="_self" className="nav-link" onClick={e =>this._showDataLevel2('/mobilidade-eletrica', e)}>
                  <i className="icon brand-icon is-e-mobility"></i>
                  <label >Mobilidade Elétrica</label>
                </Link>
                {this._renderFirstLevel(this.props.menu.emobility, 'Mobilidade Elétrica', '/mobilidade-eletrica/', '/mobilidade-eletrica/')}
              </li>
              {/* MEDIA CENTER */}
              <li className="nav-item" onMouseEnter={ this._hoverDataLevel1} onMouseLeave={ this._hoverDataLevel1Leave}>
                <Link to='/media-center' target="_self" className="nav-link" onClick={e =>this._showDataLevel2('/media-center', e)}>
                    <i className="icon brand-icon is-press-service"></i>
                    <label >Media Center</label>
                </Link>
                {/* DATA LEVEL 2 */}
                <div className={`vw-side-menu-content ${this.state.showMenuLevel2}`} data-level="2">
                  <ul className="nav flex-column">
                    <li className="nav-item title1">
                      <div className="nav-link active">
                        <a className="title-sidemenu">
                          <label className="menu-head--title title2" > Media Center </label> 
                        </a>
                        <a onClick={this._hideDataLevel2}>
                          <i className="icon brand-icon is-close-layer"></i>
                        </a>
                      </div>
                     </li>
                     <li className="nav-item title2">                    
                      <Link className="nav-link" to="/media-center" onClick={e => this.openPage(e)} target="_self">
                        <div className="nav-align">
                          <i className="is-arrow"></i>
                          <label>Abrir Media Center</label>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item" onMouseEnter={ this._hoverDataLevel2} onMouseLeave={ this._hoverDataLevel2Leave}>                    
                      <Link to="/news" className="nav-link" target="_self" onClick={e => this.openPage(e)} >
                        <div className="nav-align">
                          <i className="is-arrow"></i>
                          <label>News</label>
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item" onMouseEnter={ this._hoverDataLevel2} onMouseLeave={ this._hoverDataLevel2Leave}>
                      <Link to="/media-gallery" className="nav-link" target="_self" onClick={e => this.openPage(e)} >
                        <div className="nav-align">
                          <i className="is-arrow"></i>
                          <label>Galeria</label>                     
                        </div>
                      </Link>
                    </li>
                    <li className="nav-item" onMouseEnter={ this._hoverDataLevel2} onMouseLeave={ this._hoverDataLevel2Leave}>
                      <Link to="/contacts" className="nav-link" target="_self" onClick={e => this.openPage(e)} >
                        <div className="nav-align">
                          <i className="is-arrow"></i>
                          <label>Contatos</label>                      
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>                
              </li>
                 {/* Group News */}
              {/* <li className="nav-item">
                  <a href="https://www.volkswagenag.com/en/media.html" target="_blank" className="nav-link">
                      <label >Volkswagen AG</label><br></br>
                  </a>               
              </li> */}
              {/* <li className="nav-item">
                  <a href="https://www.volkswagen-newsroom.com/en" target="_blank" className="nav-link">
                      <label >Newsroom</label>
                  </a>               
              </li> */}
                  {/* Imprensa VW */}
              {/* <li className="nav-item">
                  <a href="#" target="_blank" onClick={e => this.openExternalPage(e, 'http://www.imprensavw.com.br')} className="nav-link">
                      <label className="arquivo">Arquivo VW</label>
                  </a>               
              </li> */}
              {/* Newsletter*/}
              <li className="nav-item">
                <Link to="/newsletter" className="nav-link" onClick={e => this.openPage(e)} target="_self">
                  <label >Newsletter</label>
                </Link>              
              </li>
                  {/* Termos Legais */}
              <li className="nav-item">
                <Link to="/termos-legais" className="nav-link" onClick={e => this.openPage(e)} target="_self">
                  <label >Termos Legais</label>
                </Link>              
              </li>
             
            </ul>
          </div>
          <div className={`${this.state.overlay}`} onClick={this._hideSideMenu}></div>
        </div>
        
      );          
    } else {
      return (<div></div>);
    }

  }
}

// Add this function:
function mapStateToProps(state) {  
  return {
    menu: state.SideMenuReducer.menu,
  };
}

export default connect(mapStateToProps)(SideMenus);
