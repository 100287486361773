/*
 * action types
 */

export const CART_LIST = 'CART_LIST'
export const CART_SIZE = 'CART_SIZE'

/*
 * action creators
 */
export function getCartList(result) {
  console.log('result', result)
  let newResult = (result.length > 0) ?  JSON.parse(result) : result
  console.log('newResult', newResult)
  return { 
    type: CART_LIST, 
    cartList: newResult
  }
}

/*
* SEND Values
*/
export default function sendCartValues(endpoint, body) {
  let headers = { 'Content-Type':'application/json' } 
  console.log(endpoint, headers, body)
  return function (dispatch) {
    return fetch(`${endpoint}`, { method: 'post', headers: headers, body: JSON.stringify(body) })
    .then(
      response => response.json(),
      error => alert('Error')
    )
    .then(result => {
      console.log(' resultado favoritos', result)
      let savedCart = [{ savedCart: result }]
      dispatch(getCartList(savedCart))
    })    
  }
}


