import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import InputMask from 'react-input-mask';
import { isValid as isValidCpf } from "@fnando/cpf";
import { handleLoggedInUser } from '../../components/LoginComponent/LoginComponent';
import Button from '../../components/Button/Button';
import './RegistryComponent.css'
import { fetchValuesOptions } from '../../shared/http-request-util';
import { withRouter } from 'react-router-dom';

let { usersAPI } = require('../../config/constants');
const brStatesCities = require('./br-states-cities.json');

class RegistryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filledInputs: [],
      fieldsError: [],
      isBrasil: 0,
      cities: [],
      midiaTypeValue: '0',
      countryValue: '2',
      stateValue: '-1',
      cityValue: '-1',
      isNewsletterAccepted: false
    }
    this.isValid = false;
    this.userId = 0;
  }

  componentDidMount() {
    // this.refs.name.value = (user.name ? user.name : '');
    // this.refs.email.value = (user.email ? user.email : '');
    // this.refs.rg.value = (user.rg ? user.rg : '');
    // this.refs.cpf.value = (user.cpf ? user.cpf : '');
    // // this.refs.birthday.value = (user.birthday ? user.birthday : '');
    // this.refs.address.value = (user.address ? user.address : '');
    // this.refs.country.value = (user.country ? user.country : '');
    // this.refs.state.value = (user.state ? user.state : '');
    // this.refs.city.value = (user.city ? user.city : '');
    // this.refs.phone1.value = (user.phone1 ? user.phone1 : '');
    // this.refs.phone2.value = (user.phone2 ? user.phone2 : '');
    // this.refs.phone3.value = (user.phone3 ? user.phone3 : '');
    // this.refs.midiaName.value = (user.midiaName ? user.midiaName : '');
    // this.refs.midiaType.value =(user.midiaType ? user.midiaType : '');

    let user = JSON.parse(localStorage.getItem('user-data')).user;
    this.userId = (user.id ? user.id : '');
    this.refs.isNewsletterAccepted.value = false;

    let userItems = ['name', 'email', 'rg', 'cpf', 'address', 'country', 'state', 'city', 'phone1', 'phone2', 'phone3', 'midiaName', 'midiaType']
    let filledInput = []
    userItems.forEach((item) => {
      this.refs[item].value = ''
      if (user[item]) {
        this.refs[item].value = user[item]
        filledInput.push(item)
      }
    })
    let stateSelectedValue = brStatesCities.estados.map((item, index) => {
        if(item.nome === user['state']) return index 
      }).filter((item) => item)[0]
      let citySelectedValue;
      if(stateSelectedValue) {
        citySelectedValue = brStatesCities.estados[stateSelectedValue].cidades.map((item, index) => {
          if(item === user['city']) return index 
        }).filter((item) => {
          if(item != undefined) { 
            return item.toString()
          }
        })
      }


    this.setState({
      filledInputs: [...filledInput],
      midiaTypeValue:  user['midiaType'] ? user['midiaType'] :  '0',
      countryValue: user['country'] ? user['country'] :  '2',
      isBrasil: (user['country'] == 'Brasil') ? '1' :  '2',
      state: user['state'] ? user['state'] :  '',
      stateValue: stateSelectedValue, 
      cityValue: citySelectedValue ? citySelectedValue : ''
    }, () => {
      this.setState({
        cities: brStatesCities.estados[stateSelectedValue] ? brStatesCities.estados[stateSelectedValue].cidades : ''
      })
    })
  }

  _submitForm = (e) => {
    e.preventDefault();
    var error = []
    if (this.refs.name.value.length > 80) {
      alert("Excedeu o limite máximo de caracteres do campo Nome!");
      this.isValid = false
      error.push('name')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      if (this.refs.name.value == "" || this.refs.name.value.length < 3) {
        alert("Nome obrigatório!");
        this.isValid = false
        error.push('name')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }

    // if(this.refs.email.value.length > 80){
    //   alert( "Excedeu o limite máximo de caracteres do campo Email!" );
    //   this.isValid = false
    //   error.push('email')
    //   this.setState({
    //     fieldsError: [...error]
    //   })
    //   return false
    // }else{
    //   if(this.refs.email.value == "" || this.refs.email.value.length < 3) {
    //     alert( "Email é obrigatório!" );
    //     this.isValid = false
    //     error.push('email')
    //     this.setState({
    //       fieldsError: [...error]
    //     })
    //     return false
    //   } else {
    //     this.isValid = true
    //   }
    // }

    if (!this.refs.midiaType.value) {
      alert("Tipo da mídia é obrigatório!");
      this.isValid = false
      error.push('midiaType')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      this.isValid = true
    }

    if (this.refs.midiaName.value.length > 80) {
      alert("Excedeu o limite máximo de caracteres do campo Nome da mídia!");
      this.isValid = false
      error.push('midiaName')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      if (this.refs.midiaName.value == "" || this.refs.midiaName.value.length < 3) {
        alert("Nome da mídia é obrigatório!");
        this.isValid = false
        error.push('midiaName')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }

    if (this.refs.rg.value.length > 20) {
      alert("Excedeu o limite máximo de caracteres do campo RG!");
      this.isValid = false
      error.push('rg')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } 
    // else {
    //   if (this.refs.rg.value == "" || this.refs.rg.value.length < 3) {
    //     alert("RG é obrigatório!");
    //     this.isValid = false
    //     error.push('rg')
    //     this.setState({
    //       fieldsError: [...error]
    //     })
    //     return false
    //   } else {
    //     this.isValid = true
    //   }
    // }

    // if (!isValidCpf(this.refs.cpf.value)) {
    //   alert("CPF inválido!");
    //   this.isValid = false
    //   error.push('cpf')
    //   this.setState({
    //     fieldsError: [...error]
    //   })
    //   return false
    // } 
    // else {
    //   if (this.refs.cpf.value == "" || this.refs.cpf.value.length < 3) {
    //     alert("CPF é obrigatório!");
    //     this.isValid = false
    //     error.push('cpf')
    //     this.setState({
    //       fieldsError: [...error]
    //     })
    //     return false
    //   } else {
    //     this.isValid = true
    //   }
    // }

    // if(this.refs.birthday.value.length > 10){
    //   alert( "Excedeu o limite máximo de caracteres do campo Data de nascimento!" );
    //   this.isValid = false
    //   error.push('birthday')
    //   this.setState({
    //     fieldsError: [...error]
    //   })
    //   return false
    // }else{
    //   if(this.refs.birthday.value == "" || this.refs.birthday.value.length < 3) {
    //     alert( "Data de nascimento é obrigatório!" );
    //     this.isValid = false
    //     error.push('birthday')
    //     this.setState({
    //       fieldsError: [...error]
    //     })
    //     return false
    //   } else {
    //     this.isValid = true
    //   }
    // }

    if (this.refs.address.value.length > 80) {
      alert("Excedeu o limite máximo de caracteres do campo Endereço!");
      this.isValid = false
      error.push('address')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      if (this.refs.address.value == "" || this.refs.address.value.length < 3) {
        alert("Endereço é obrigatório!");
        this.isValid = false
        error.push('address')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }

    if (!this.refs.country.value) {
      alert("País é obrigatório!");
      this.isValid = false
      error.push('country')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      this.isValid = true
    }

    if (!this.refs.state.value) {
      alert("Estado é obrigatório!");
      this.isValid = false
      error.push('state')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      this.isValid = true
    }

    if (!this.refs.city.value) {
      alert("Cidade é obrigatório!");
      this.isValid = false
      error.push('city')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      this.isValid = true
    }

    if (this.refs.phone1.value.length > 20) {
      alert("Excedeu o limite máximo de caracteres do campo telefone primário!");
      this.isValid = false
      error.push('phone1')
      this.setState({
        fieldsError: [...error]
      })
      return false
    } else {
      if (this.refs.phone1.value == "" || this.refs.phone1.value.length < 3) {
        alert("telefone primário é obrigatório!");
        this.isValid = false
        error.push('phone1')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }

    if (this.refs.phone2.value.length > 20) {
      alert("Excedeu o limite máximo de caracteres do campo telefone secundário!");
      this.isValid = false
      error.push('modeloVeículo')
      this.setState({
        fieldsError: [...error]
      })
      return false
    }

    if (this.refs.phone3.value.length > 20) {
      alert("Excedeu o limite máximo de caracteres do campo telefone terciário!");
      this.isValid = false
      error.push('modeloVeículo')
      this.setState({
        fieldsError: [...error]
      })
      return false
    }

    // if (this.refs.isNewsletterAccepted.value === 'false') {
    //   alert("Favor aceitar o envio de newsletter para prosseguir.");
    //   this.isValid = false
    //   error.push('isNewsletterAccepted')
    //   this.setState({
    //     fieldsError: [...error]
    //   })
    //   return false
    // } else {
    //   this.isValid = true
    // }
    let deviceID = localStorage.getItem("deviceID") ? localStorage.getItem("deviceID"): undefined;
    if (!this.isValid) {
      alert("Verifique os campos preenchidos!");
    } else {
      var body = {
        id: this.userId,
        name: this.refs.name.value,
        email: this.refs.email.value,
        rg: (this.refs.rg.value ? this.refs.rg.value : null),
        cpf: (this.refs.cpf.value ? this.refs.cpf.value : null),
        // birthday: (this.refs.birthday.value ? this.refs.birthday.value : undefined),
        address: (this.refs.address.value ? this.refs.address.value : undefined),
        country: (this.refs.country.value ? 'Brasil' : 'Outros'),
        state: (this.refs.state.value ? brStatesCities.estados[parseInt(this.refs.state.value)].nome : undefined),
        city: (this.refs.city.value ? this.state.cities[parseInt(this.refs.city.value)] : undefined),
        phone1: (this.refs.phone1.value ? this.refs.phone1.value : undefined),
        phone2: (this.refs.phone2.value ? this.refs.phone2.value : undefined),
        phone3: (this.refs.phone3.value ? this.refs.phone3.value : undefined),
        midiaName: (this.refs.midiaName.value ? this.refs.midiaName.value : undefined),
        midiaType: (this.refs.midiaType.value ? this.refs.midiaType.value : undefined),
        deviceId: deviceID,
        isNewsletterAccepted: this.refs.isNewsletterAccepted.value 
      }
      let url = usersAPI()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      var _that = this
      function callback(response) {
        if (response) {
          let userData = JSON.parse(localStorage.getItem('user-data'));
          body.role = userData.user.role;
          userData.user = body;
          localStorage.setItem('user-data', JSON.stringify(userData));
          handleLoggedInUser(_that.props);
        } else {
          _that.props.history.push('/')
        }
      }
      fetchValuesOptions(url, options, callback)
    }
  }

  createStateOptions() {
    return brStatesCities.estados.map((state, index) => (<option key={state.nome} value={index}>{state.nome}</option>));
  }

  updateStates = (event) => {
    let flag = event.target.value
    let inputsFilled = this.state.filledInputs
    if (inputsFilled.indexOf('country') < 0) {
      inputsFilled.push('country')
    }
    if (this.state.filledInputs.indexOf('state') > -1) {
      inputsFilled.splice(this.state.filledInputs.indexOf('state'), 1)
    }
    if (this.state.filledInputs.indexOf('city') > -1) {
      inputsFilled.splice(this.state.filledInputs.indexOf('city'), 1)
    }
    this.setState({
      countryValue: flag,
      stateValue: '-1',
      cityValue: '-1',
      isBrasil: flag,
      filledInputs: [...inputsFilled]
    })
  }

  updateCities = (event) => {
    let index = event.target.value;
    let cities = brStatesCities.estados[index].cidades;
    let inputsFilled = this.state.filledInputs
    if (inputsFilled.indexOf('state') < 0) {
      inputsFilled.push('state')
    }
    if (this.state.filledInputs.indexOf('city') > -1) {
      inputsFilled.splice(this.state.filledInputs.indexOf('city'), 1)
    }
    this.setState({
      stateValue: index,
      cities: cities,
      cityValue: '-1',
      filledInputs: [...inputsFilled]
    })
  }

  createCitiesOptions() {
    if(!this.state.cities) return []
    return this.state.cities.map((city, index) => (<option key={city} value={index}>{city}</option>));
  }

  changeCities = (event) => {
    let index = event.target.value;
    let inputsFilled = this.state.filledInputs
    if (inputsFilled.indexOf('city') < 0) {
      inputsFilled.push('city')
      this.state.filledInputs = [...inputsFilled]
    }
    this.setState({
      cityValue: index
    })
  }

  changeMidiaType = (e) => {
    // alert(e.target.value)
    let inputsFilled = this.state.filledInputs
    let midiaTypeValue = e.target.value

    // alert(this.refs.midiaType.value)
    if (inputsFilled.indexOf('midiaType') < 0) {
      inputsFilled.push('midiaType')
    }
    this.setState({
      midiaTypeValue: midiaTypeValue,
      filledInputs: [...inputsFilled]
    }, () => {
      console.log(this.refs.midiaType.value)
      // this.refs.midiaType.value = midiaTypeValue
    })
  }

  changeCheck(event) {
    const target = event.target;
    this.refs.isNewsletterAccepted.value = target.type === 'checkbox' ? target.checked : target.value;
  }

  inputIsFilled = (input) => {
    if (this.refs[input]) console.log(this.refs[input].value)

    // return this.state.driver[input].length > 0 ? 'input-filled' : ''
  }

  changeInput = (value, input) => {
    if (this.refs[input]) {

      if (this.refs[input].value.length > 0) {
        let inputsFilled = this.state.filledInputs
        if (inputsFilled.indexOf(input) < 0) {
          inputsFilled.push(input)
          console.log('add item', inputsFilled)
          this.setState({
            filledInputs: [...inputsFilled]
          })
        }
      } else {
        let inputsFilled = this.state.filledInputs
        inputsFilled.splice(this.state.filledInputs.indexOf(input), 1)
        console.log('remove item', inputsFilled)
        this.setState({
          filledInputs: [...inputsFilled]
        })
      }
    }

    // return this.state.driver[input].length > 0 ? 'input-filled' : ''
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="title-approval">
            <PageTitle title="Complete seu cadastro" />
            <PageSubTitle text='Preencha as informações abaixo para completar seu cadastro' />
          </div>
          <form className="vw-registry-form" method="#" name="dados">
            <div className="row vw-floating-label">
              <div className="col-12">
                <div className="form-label-group mb-4">
                  <input type="text" onChange={e => this.changeInput(e.target.value, 'name')} className={`form-control  ${this.state.filledInputs.indexOf('name') > -1 ? 'input-filled' : ''} ${(this.state.fieldsError.indexOf('name') > -1 ? 'input-error' : '')} `} name="name" id="name" ref='name' maxLength="80" />
                  <label htmlFor='name'>
                    Nome
                    </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group select-item mb-4">
                  <select value={this.state.midiaTypeValue} onChange={this.changeMidiaType} className={`form-control  ${(this.state.fieldsError.indexOf('midiaType') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('midiaType') > -1 ? 'input-filled' : ''}`} name="midiaType" id="midiaType" ref="midiaType">
                    <option value="0" selected disabled>Tipo da mídia</option>
                    <option value="Rádio">Rádio</option>
                    <option value="TV">TV</option>
                    <option value="Jornal">Jornal</option>
                    <option value="Influencer">Influencer</option>
                    <option value="Site">Site</option>
                    <option value="Bloggeiro">Bloggeiro</option>
                  </select>
                  {this.state.filledInputs.indexOf('midiaType') > -1 && this.state.midiaTypeValue != '0' ?
                    <label htmlFor='midiaType' >
                      Tipo da mídia
                        </label>
                    : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <input type="text" onChange={e => this.changeInput(e.target.value, 'midiaName')} className={`form-control  ${(this.state.fieldsError.indexOf('midiaName') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('midiaName') > -1 ? 'input-filled' : ''}`} name="midiaName" id="midiaName" ref="midiaName" maxLength="80"></input>
                  <label htmlFor='midiaName'>
                    Nome da mídia
                      </label>
                </div>
              </div>
              <div className="col-12 col-md-6" hidden>
                <div className="form-label-group mb-4">
                  <input type="text" className={`form-control input-filled ' ${(this.state.fieldsError.indexOf('email') > -1 ? 'input-error' : '')}`} name="email" id="email" ref="email" maxLength="80" disabled></input>
                  <label htmlFor='email'>
                    Email do solicitante
                    </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <input type="text" onChange={e => this.changeInput(e.target.value, 'rg')} className={`form-control  ${this.state.filledInputs.indexOf('rg') > -1 ? 'input-filled' : ''} ${(this.state.fieldsError.indexOf('rg') > -1 ? 'input-error' : '')} `} name="rg" id="rg" ref='rg' maxLength="80" />
                  <label htmlFor='rg'>
                    RG
                    </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <InputMask
                    onChange={e => this.changeInput(e.target.value, 'cpf')}
                    className={`form-control  ${(this.state.fieldsError.indexOf('cpf') > -1 ? 'input-error' : '')}  ${this.state.filledInputs.indexOf('cpf') > -1 ? 'input-filled' : ''}`}
                    mask={"***.***.***-**"}
                    name="cpf" id="cpf" ref="cpf" placeholder="CPF">
                  </InputMask>
                  <label htmlFor='cpf'>
                    CPF
                    </label>
                </div>
              </div>
              {/* <div className="form-group col-12 col-md-6 newsletterForm">
                    <input type="date" className={"form-control "  + (this.state.fieldsError.indexOf('birthday') > -1 ? 'input-error' : '')} name="birthday" id="birthday" ref="birthday" placeholder="Data de nascimento" maxLength="10"></input>
                </div> */}
              <div className="col-12 col-md-6">
                <div className="form-label-group select-item  mb-4">
                  <select value={this.state.countryValue} className={`form-control  ${(this.state.fieldsError.indexOf('country') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('country') > -1 ? 'input-filled' : ''}`} name="country" id="country" ref="country" onChange={this.updateStates}>
                    <option value="2" selected disabled>Selecionar País</option>
                    <option value="1">Brasil</option>
                    <option value="0">Outros</option>
                  </select>
                  {this.state.filledInputs.indexOf('country') > -1 && this.state.country != '2' ?
                    <label htmlFor='country' >
                      Selecionar País
                        </label>
                    : null}
                </div>
              </div>
              {(this.state.isBrasil === "1") ?
                <div className="col-12 col-md-6">
                  <div className="form-label-group select-item mb-4">
                    <select value={this.state.stateValue} className={`form-control  ${(this.state.fieldsError.indexOf('state') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('state') > -1 ? 'input-filled' : ''}`} name="state" id="state" ref="state" onChange={this.updateCities}>
                      <option value="-1" selected disabled>Estado</option>
                      {this.createStateOptions()}
                    </select>
                    {this.state.filledInputs.indexOf('state') > -1 && this.state.stateValue != '-1' ?
                      <label htmlFor='state' >
                        Estado
                        </label>
                      : null}
                  </div>
                </div>
                :
                <div className="col-12 col-md-6">
                  <div className="form-label-group mb-4">
                    <input type="text" onChange={e => this.changeInput(e.target.value, 'state')} className={`form-control  ${this.state.filledInputs.indexOf('state') > -1 ? 'input-filled' : ''} ${(this.state.fieldsError.indexOf('state') > -1 ? 'input-error' : '')} `} name="state" id="state" ref="state" maxLength="80"></input>
                    <label htmlFor='state'>
                      Estado
                      </label>
                  </div>
                </div>
              }
              {(this.state.isBrasil === "1") ?
                <div className="col-12 col-md-6">
                  <div className="form-label-group select-item mb-4">
                    <select value={this.state.cityValue} className={`form-control  ${(this.state.fieldsError.indexOf('city') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('city') > -1 ? 'input-filled' : ''}`} name="city" id="city" ref="city" onChange={this.changeCities}>
                      <option value="-1" selected disabled>Cidade</option>
                      {this.createCitiesOptions()}
                    </select>
                    {this.state.filledInputs.indexOf('city') > -1 && this.state.cityValue != '-1' ?
                      <label htmlFor='city' >
                        Cidade
                        </label>
                      : null}
                  </div>
                </div>
                :
                <div className="col-12 col-md-6">
                  <div className="form-label-group mb-4">
                    <input type="text" onChange={e => this.changeInput(e.target.value, 'city')} className={`form-control  ${(this.state.fieldsError.indexOf('city') > -1 ? 'input-error' : '')} ${this.state.filledInputs.indexOf('city') > -1 ? 'input-filled' : ''}`} name="city" id="city" ref="city" maxLength="80"></input>
                    <label htmlFor='city'>
                      Cidade
                      </label>
                  </div>
                </div>
              }              
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <input type="text" onChange={e => this.changeInput(e.target.value, 'address')} className={`form-control  ${this.state.filledInputs.indexOf('address') > -1 ? 'input-filled' : ''} ${(this.state.fieldsError.indexOf('address') > -1 ? 'input-error' : '')} `} name="address" id="address" ref="address" maxLength="80"></input>
                  <label htmlFor='address'>
                    Endereço
                      </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <InputMask
                    onChange={e => this.changeInput(e.target.value, 'phone1')}
                    className={`form-control  ${(this.state.fieldsError.indexOf('phone1') > -1 ? 'input-error' : '')}  ${this.state.filledInputs.indexOf('phone1') > -1 ? 'input-filled' : ''}`}
                    mask={"(**) * ****-****"}
                    name="phone1" id="phone1" ref="phone1">
                  </InputMask>
                  <label htmlFor='phone1'>
                    Telefone para contato 1
                      </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label-group mb-4">
                  <InputMask
                    onChange={e => this.changeInput(e.target.value, 'phone2')}
                    className={`form-control  ${(this.state.fieldsError.indexOf('phone2') > -1 ? 'input-error' : '')}  ${this.state.filledInputs.indexOf('phone2') > -1 ? 'input-filled' : ''}`}
                    mask={"(**) * ****-****"}
                    name="phone2" id="phone2" ref="phone2" placeholder="">
                  </InputMask>
                  <label htmlFor='phone2'>
                    Telefone para contato 2
                      </label>
                </div>
              </div>
              <div className="col-12 col-md-6" hidden>
                <div className="form-label-group mb-4">
                  <InputMask
                    onChange={e => this.changeInput(e.target.value, 'phone3')}
                    className={`form-control  ${(this.state.fieldsError.indexOf('phone3') > -1 ? 'input-error' : '')}  ${this.state.filledInputs.indexOf('phone3') > -1 ? 'input-filled' : ''}`}
                    mask={"(**) * ****-****"}
                    name="phone3" id="phone3" ref="phone3">
                  </InputMask>
                  <label htmlFor='phone3'>
                    Telefone para contato 3
                      </label>
                </div>
              </div>
              <div className="form-group col-12 col-md-6 newsletterForm">
                <div className="vw-box-ckeckbox">
                  <input className="vw-checkbox-input inputCheckForm" type="checkbox" name="isNewsletterAccepted" id="isNewsletterAccepted" ref="isNewsletterAccepted" onChange={this.changeCheck.bind(this)}></input>
                  <span className="vw-checkbox-label">Quero receber a newsletter.</span>
                </div>
              </div>
            </div>
            <div className="row newsletterFormCheckbox__container ">
              <div className={'col-12 alert alert-success ' + (this.isValid ? 'show' : 'hide')} role="alert">Cadastro enviado com sucesso</div>
            </div>
            <div className="row">
              <div className="col-12 text-left mb-4">
                <Button text="Enviar" className={this.isValid ? 'btn-primary': 'btn-secondary'} onClick={this._submitForm.bind(this)} hideIcon={true} />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
export default withRouter(RegistryComponent);