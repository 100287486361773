const initialState = {
  news: {}
}

const NewsDetailReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'NEWS_DETAIL':
      state = initialState;
      return {
        news: action.news,
      }
    default:
      return state;
  }
    
};

export default NewsDetailReducer;