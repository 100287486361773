import VwIdentityKit from './VwIdentityKit';
import LoginComponentService from './LoginComponentService';
let { identityKitOption } = require('../../config/constants');
let { getLogoutUrl } = require('../../config/constants');

/**
 * Get redirect url to login
 */
export function handleLogin(props) {
  if (sessionStorage.getItem("isMobile") != undefined && sessionStorage.getItem("isMobile") == "true") {
    //Mobile    
    if (sessionStorage.getItem("return_from_vwid") != undefined && sessionStorage.getItem("return_from_vwid") != null) {
      sessionStorage.setItem("return_from_vwid", null);
      //it's  mobile
      const value = localStorage.getItem("vwid_url");
      localStorage.setItem("vwid_url", null);
      if (value.indexOf('code') !== -1) {
        var matches = /code=([^&#=]*)/.exec(value);
        var code = matches[1];
        let tempToken;
        new VwIdentityKit(identityKitOption()).getInfoFromCode(code)
          .then(properties => {
            tempToken = properties.token;
            return new LoginComponentService().authenticate(properties)
          }).then(userData => {
            localStorage.setItem('user-data', JSON.stringify(userData));
            if (new LoginComponentService().isUserDataValid(userData.user)) {
              handleLoggedInUser(props);
            } else {
              props.history.push('/registry')
            }
          }).catch((err) => {
            alert("Desculpe, você não tem permissão para acessar essa área");
            props.history.push('/');
            // window.location.href = getLogoutUrl(tempToken);
          })
      } else {
        //let url = new VwIdentityKit(identityKitOption()).mountRedirectUrl();
        //props.history.push(url)
        window.open(new VwIdentityKit(identityKitOption()).mountRedirectUrl(), '_blank', 'location=yes');
      }
    } else {
      console.log(new VwIdentityKit(identityKitOption()).mountRedirectUrl());
      window.open(new VwIdentityKit(identityKitOption()).mountRedirectUrl(), '_blank', 'location=yes');
    }
  } else {
    //Web
    if (window.location.search.indexOf('code') !== -1) {
      var matches = /code=([^&#=]*)/.exec(window.location.search);
      var code = matches[1];
      let tempToken;
      new VwIdentityKit(identityKitOption()).getInfoFromCode(code)
        .then(properties => {
          tempToken = properties.token;
          return new LoginComponentService().authenticate(properties)
        }).then(userData => {
          localStorage.setItem('user-data', JSON.stringify(userData));
          if (new LoginComponentService().isUserDataValid(userData.user)) {
            handleLoggedInUser(props);
          } else {
            //go to complete registry
            props.history.push('/registry')
          }
        }).catch((err) => {
          console.error(err);
          window.location.href = getLogoutUrl(tempToken);
        })
    } else {
      window.location.href = new VwIdentityKit(identityKitOption()).mountRedirectUrl();
      //props.history.push(url)
    }
  }
  return null;
}

export function handleLoggedInUser(props) {
  let userData = JSON.parse(localStorage.getItem('user-data'));
  userData.user.loggedIn = true;
  localStorage.setItem('user-data', JSON.stringify(userData));
  handleRedirect(props);
}

export function handleRedirect(props) {
  if (localStorage.getItem('has-redirect')) {
    let redirectRout = localStorage.getItem('has-redirect');
    localStorage.removeItem('has-redirect');
    props.history.push(redirectRout)
  } else {
    props.history.push('/')
  }
}