const initialState = {
  version: {},
  news: [],
  gallery: []
}

const VersionReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'VERSION_DETAIL':
      state = initialState;
      return {
        version: {...state.version, ...action.version},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default VersionReducer;