import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewsletterForm.css'; 
import TextBody from '../TextBody/TextBody';
import PageTitle from '../PageTitle/PageTitle';
import sendValues from './NewsletterActions'
import { withRouter } from 'react-router-dom';
let { apiEndpoint } = require('../../config/constants');

class NewsletterForm extends Component {

  constructor(props) {
    super(props);

    this.state ={
      newsletterBody: {
        name: '',
        surname: '',
        email: '',
        emailConfirmation: ''
      },
      fieldsError: [],
      isValid: false
    }

    this.isValid = false
    this.fieldsError = []
  }

  _checkEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)) {
        return true;
    } else {
        return false;
    }
  }

  _submitForm = () =>{
       var error = []
      if(this.refs.name.value.length > 80){
        alert( "Excedeu o limite máximo de caracteres do campo Nome!" );
        this.isValid = false
        error.push('name')
        this.setState({
          fieldsError: [...error]
        })
        return false
      }else{
        if(this.refs.name.value == "" || this.refs.name.value.length < 3) {
          alert( "Nome obrigatório!" );
          this.isValid = false
          error.push('name')
          this.setState({
            fieldsError: [...error]
          })
          return false
        } else {
          this.isValid = true
        }
      }


      if(this.refs.surname.value.length > 80){
        alert( "Excedeu o limite máximo de caracteres do campo Sobrenome!" );
        this.isValid = false
        error.push('name')
        this.setState({
          fieldsError: [...error]
        })
        return false
      }else{
      if(this.refs.surname.value == "" || this.refs.surname.value.length < 3) {
        alert( "Sobrenome obrigatório!" );
        this.isValid = false
        error.push('surname')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }

 
      if(this.refs.email.value.length > 100){
        alert( "Excedeu o limite máximo de caracteres do campo E-mail!" );
        this.isValid = false
        error.push('name')
        this.setState({
          fieldsError: [...error]
        })
        return false
      }else{   
      
        if(this.refs.email.value == "" || this.refs.email.value.length < 3) {
          alert( "Email obrigatório!" );
          this.isValid = false
          error.push('email')
          this.setState({
            fieldsError: [...error]
          })
          return false
        } else {
          if(this._checkEmail(this.refs.email.value)){
          this.isValid = true
          }  else{
              alert( "Por favor, inserir um Email válido!" );
              this.isValid = false
              return false
              
            }
        }
      }
   

    if(this._checkEmail(this.refs.email.value)){
    if(this.refs.emailConfirmation.value.length > 100){
      alert( "Excedeu o limite máximo de caracteres do campo de confirmação do e-mail!" );
      this.isValid = false
      error.push('name')
      this.setState({
        fieldsError: [...error]
      })
      return false
    }else{ 
      if(this.refs.emailConfirmation.value == ""  || this.refs.emailConfirmation.value.length < 3) {
        alert( "Obrigatório confirmar email!" );
        this.isValid = false
        error.push('emailConfirmation')
        this.setState({
          fieldsError: [...error]
        })
        return false
      } else {
        this.isValid = true
      }
    }
  }else{
    alert( "Por favor, inserir um Email válido!" );
    this.isValid = false
    return false
  }


      if(this.refs.emailConfirmation.value != this.refs.email.value) {
        alert( "Os emails são diferentes!" );
        this.isValid = false
        return false
      } else {
        this.isValid = true
      }


      // if(!this.refs.isLegallyOldEnough.value) {
      //   alert( "Você precisa ter ao menos 16 anos" );
      //   this.isValid = false
      //   return false
      // } else {
      //   this.isValid = true
      // }

      if(!this.state.acceptIsNewsletterAccepted) {
        alert( "Você precisa aceitar os termos");
        this.isValid = false
        return false
      } else {
        this.isValid = true
      }

    if(!this.isValid) {
      alert( "Verifique os campos preenchidos!" );
    } else {
      var body = {
          "name" : this.refs.name.value,
          "lastname" : this.refs.surname.value,
          "email" :  this.refs.email.value,
          "isNewsletterAccepted": this.state.acceptIsNewsletterAccepted
      }

      this.props.dispatch(sendValues(apiEndpoint() + 'newsletter/add', body))
      this.isValid = true;
      this.setState({
        isValid: true
      }, ()=> {
        setTimeout(() => {
          // window.location.href = '/'
          this.props.history.push('/')
        }, 2000)
      })
      
    }
  }

  checkBoxIsLegallyOldEnough = () => {
      this.setState({
        acceptIsLegallyOldEnough: !this.state.acceptIsLegallyOldEnough
      })
  }

  checkBoxIsNewsletterAccepted = () => {
    this.setState({
      acceptIsNewsletterAccepted: !this.state.acceptIsNewsletterAccepted
    })
}

  goToTermsPage = (e) => {
    window.location = '/termos-legais'
  }

  render() {
    return (
      <form className="vw-newsletter-form" method="#"  name="dados" onSubmit="return _enviardados()">
        <div className="row">
          <div className="col">
            <PageTitle title="Dados Pessoais" />
          </div>
        </div>
        <div className="row newsletterFormRow__container">
          <div className="form-group col-12 col-md-6 newsletterForm ">
            <input type="text" className={"form-control "  + (this.state.fieldsError.indexOf('name') > -1  ? 'input-error' : '') } name="name" id="name" ref='name' placeholder="Nome*" maxlength="80"/>
          </div>
          <div className="form-group col-12 col-md-6 newsletterForm">
            <input type="text" className={"form-control "  + (this.state.fieldsError.indexOf('surname') > -1 ? 'input-error' : '')} name="surname" id="surname" ref="surname" placeholder="Sobrenome*" maxlength="80"></input>
          </div>
          <div className="form-group col-12 col-md-6 newsletterForm">
            <input type="email" className={"form-control "  + (this.state.fieldsError.indexOf('email') > -1 ? 'input-error' : '')} name="email" id="email" ref="email" placeholder="Email*" maxlength="100"></input>
          </div>
          <div className="form-group col-12 col-md-6 newsletterForm">
            <input type="email" className={"form-control "  + (this.state.fieldsError.indexOf('emailConfirmation') > -1 ? 'input-error' : '')} name="emailConfirmation"  id="emailConfirmation" ref="emailConfirmation" placeholder="Confirmação do email*" maxlength="100"></input>
          </div>
        </div>
        <div className="row newsletterFormRow__container">
          <PageTitle title="Termos de Privacidade"/>
        </div>
        {/* <div className="row newsletterFormCheckbox__container">
          <div className="col-12 form-check">
            <input type="checkbox" value={this.state.acceptIsLegallyOldEnough} checked={this.state.acceptIsLegallyOldEnough} onChange={this.checkBoxIsLegallyOldEnough.bind(this)} className="form-check-input" ref="isLegallyOldEnough" name="isLegallyOldEnough" id="isLegallyOldEnough"></input>
            <label className="form-check-label offset-1 offset-sm-2 offset-md-1 col-11 col-sm-10 col-md-11" for="isLegallyOldEnough">
              <TextBody text={`Yes, I am at least 16 years of age and am legally entitled to consent to receiving the e-mail newsletters*. (<a href="/termos-legais" onClick=${e => this.goToTermsPage(e)}}>Ver termos</a>).`}/>
            </label>
          </div>
        </div> */}
        <div className="row newsletterFormCheckbox__container">
          <div className="col-12 form-check">
            <input type="checkbox" value={this.state.acceptIsNewsletterAccepted} checked={this.state.acceptIsNewsletterAccepted} onChange={this.checkBoxIsNewsletterAccepted.bind(this)} className="form-check-input" ref="isNewsletterAccepted" name="isNewsletterAccepted" id="isNewsletterAccepted"></input>
            <label className="form-check-label offset-1  offset-sm-2 offset-md-1 col-11 col-sm-10 col-md-11" 
              for="isNewsletterAccepted">
              <TextBody text={`Aceito os termos e condições de uso - Newsletter (<a href="/termos-legais/termoNews" onClick=${e => this.goToTermsPage(e)}}>Ver termos</a>).`} />
            </label>
          </div>
        </div>
        <div className="row newsletterFormCheckbox__container ">
          <div className={'col-12 alert alert-success ' + (this.state.isValid ? 'show' : 'hide')} role="alert">Cadastro enviado com sucesso</div>
          <div className=" col-12 col-sm-12 col-md-11">
             <div className="btn-newsletterForm align-self-center col-11 col-sm-6 col-md-4">
              <a href='#' onClick={ this._submitForm.bind(this)} className="btn btn-newsletterForm-txt btn-lg">Assinar a newsletter</a>
            </div>
          </div>
        </div>


      </form>
    );    
  }
}

// Add this function:
function mapStateToProps(state) {
 console.log(state)
}

export default connect(mapStateToProps)(withRouter(NewsletterForm));
