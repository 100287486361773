import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTechnology } from './Tecnologia.actions';
import { fetchValues } from '../../shared/http-request-util';

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../components/TextBody/TextBody';
import ShortImage from '../../components/ShortImage/ShortImage';
import ArticleTitle from '../../components/ArticleTitle/ArticleTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Gallery from '../../components/Gallery/Gallery';
import Loading from '../../components/Loading/Loading';
import Speak from '../../components/Speak/Speak';
import './Tecnologia.css'; 
import HighlightMedia from '../../components/HighlightMedia/HighlightMedia';
import ReactGA from 'react-ga';
import PopOverButton from '../../components/PopOverButton/popOverButton';

let { apiEndpoint, publicUrlEndpoint } = require('../../config/constants');
let { urlEndpoint } = require('../../config/constants');

let { technologyHomeAPI } = require('../../config/constants');


class Tecnologia extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    ReactGA.pageview('/technology');
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + technologyHomeAPI(), getTechnology));
  }  

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {
      let techPDFURL = this.props.match.params.technology_id ? '/' + this.props.match.params.technology_id : '';
      return (      
        <div className="vw-tecnologia">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text="<a href='/'>Newsroom</a> / Tecnologia" />
              <PageTitle title="Tecnologia"/>
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.technology.title}
              media={ this.props.technology.media} mediaMedium= { this.props.technology.mediaMed } mediaLow= { this.props.technology.mediaLow } 
              mediaSize={this.props.technology.mediaSize} mediaSizeMedium= { this.props.technology.mediaSizeMedium } mediaSizeLow = {this.props.technology.mediaSizeLow}
              GAText={'Tecnologia'}
              isAutoPlay={true}/>
            </div>
            <div className="row w-100 justify-content-end newsButtons">
             
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.technology.title} ` + ` ${publicUrlEndpoint()}` + `/technology/` + `${this.props.match.params.technology_id}`}
                    twitterText={this.props.technology.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/technology/` + `${this.props.match.params.technology_id}`}
                  />
              </div>
            </div>
            <div className='col-12'>
              <ArticleTitle title={this.props.technology.title}/>
            </div>
            <div className="col-12">            
              <PageSubTitle text={this.props.technology.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-md-8">            
              <TextBody text={this._convertDescription(this.props.technology.description)}/>
            </div>
            <div className="col-md-4">            
              <ShortImage showPopOverButtons={ true } 
              contentLink={ '/technology'} 
                imageName={this.props.technology.title}
                media={ this.props.technology.sideMedia} mediaMedium={ this.props.technology.sideMediaMed } mediaLow={ this.props.technology.sideMediaLow } 
                mediaSize={ this.props.technology.sideMediaSize} mediaSizeMedium={ this.props.technology.sideMediaSizeMedium } mediaSizeLow={ this.props.technology.sideMediaSizeLow } 
                GAText={'Tecnologia'}
                roundBorder="false" />
              <div className="vw-italic">
                <TextBody className="vw-italic vw-font-12px" text={this.props.technology.sideDescription}/>
              </div>
            </div>
            <div className="w-100"></div>
            <div className="col">            
                <PageTitle className="vw-font-20px" title={this.props.technology.bottomTitle}/>
            </div>
            <div className="w-100" />
            <div className="col-12">
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.technology.title}
              media={ this.props.technology.bottomMedia} mediaMedium={ this.props.technology.bottomMediaMed } mediaLow={ this.props.technology.bottomMediaLow } 
              mediaSize={this.props.technology.bottomMediaSize} mediaSizeMedium= { this.props.technology.bottomMediaSizeMedium } mediaSizeLow = {this.props.technology.bottomMediaSizeLow}
              GAText={'Tecnologia'}
              isAutoPlay={true}/>
            </div>          
          </div>        
          <NewsComponent isVertical={false} news={this.props.news}/>
  
          <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/>
          
          <Speak/>
        </div>
      );          
    } else {
      return (<Loading />);
    }

  }
}

// Add this function:
function mapStateToProps(state) {

  return {
    technology: state.TecnologiaReducer.technology,
    news: state.TecnologiaReducer.news,
    gallery: state.TecnologiaReducer.gallery
  };
}

export default connect(mapStateToProps)(Tecnologia);