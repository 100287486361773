import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
// import ArticleTitle from '../../../components/ArticleTitle/ArticleTitle';
// import Loading from '../../../components/Loading/Loading';
// import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';

import './Cookies.css'; 


// let { apiEndpoint } = require('../../../config/constants');
// let { TermoNewsTermoAPI } = require('../../config/constants');


class Cookies extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    this.update = true;
    // this.props.dispatch(fetchValues(apiEndpoint() + TermoNewsTermoAPI() + this.props.match.params.termoNews_id, gettermoNews));
  }  

  
  render() {
    if (this.update) {
      return (
        // TEM QUE AJUSTAR QUANDO TIVER CONSUMO DA API
       
          <div className="row vw-imprint">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a> / ${this.props.termoNews.title}`} />
              <PageTitle title={this.props.imprint.title}/>
            </div>
            <div className="col-12"> 
              <PageSubTitle  title={this.props.imprint.title}/>           
          </div>
          </div> 
        
      );       
    } else {
      return (
        <div className="row vw-imprint">
          <div className="col-12">
            <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a>  / Política de Cookies `} />
            <PageTitle title="Política de Cookies"/>
            {/* <PageSubTitle  text="(Atualizado em 15/02/2019)"/>  */}
            <PageSubTitle  text="Utilizamos cookies para melhorar o desempenho e a sua experiência como utilizador no nosso site."/> 
              
          </div>
          <div className="col-12">            
          <b><PageSubTitle  text="O que são cookies?"/></b>
          </div>
          <div className="col-12 "> 
              <PageSubTitle  text="Os cookies são pequenos arquivos de texto que um site, quando visitado, coloca no computador do usuário ou no seu dispositivo móvel, através do navegador de internet (browser). A colocação de cookies ajudará o site a reconhecer o seu dispositivo numa próxima visita. Usamos o termo cookies nesta política para referir todos os   arquivos que recolhem informações desta forma."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Os cookies utilizados não recolhem informação que identifica o usuário, no entanto, se já for nosso cliente poderemos monitorar as suas visitas ao site desde que, pelo menos, por uma vez, tenha iniciado a sua navegação a partir de alguma comunicação enviada por nós, por exemplo, SMS e e-mail."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Os cookies recolhem também informações genéricas, designadamente a forma como os usuários chegam e utilizam os sites ou a zona do país/países através do qual acedem ao site, etc."/>  
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Os cookies retêm apenas informação relacionada com as suas preferências."/>           
          </div>         
          <div className="col-12"> 
              <PageSubTitle  text="A qualquer momento o usuário pode, através do seu navegador de internet (browser) decidir ser notificado sobre a recepção de cookies, bem como bloquear a respectiva entrada no seu sistema."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="A recusa de uso de cookies no site, pode resultar na impossibilidade de ter acesso a algumas das suas áreas ou de receber informação personalizada."/>           
          </div>
          <div className="col-12"> 
              <b><PageSubTitle  text="Para que servem os cookies?"/></b>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Os cookies são usados para ajudar a determinar a utilidade, interesse e o número de utilizações dos sites, permitindo uma navegação mais rápida e eficiente e eliminando a necessidade de introduzir repetidamente as mesmas informações."/>           
          </div>
          <div className="col-12"> 
             <b> <PageSubTitle  text="Que tipo de cookies utilizamos?"/></b>          
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Os nossos cookies têm diferentes funções:"/>           
          </div>
          <div className="col-12 offset-1 alinhamento"> 
              <li><span><b>Cookies essenciais</b> - Alguns cookies são essenciais para acessar a áreas específicas do nosso site. Permitem a navegação no site e a utilização das suas aplicações, tal como acessar áreas seguras do site através de login. Sem estes cookies, os serviços que o exijam não podem ser prestados.</span></li>    
              <li><span><b>Cookies analíticos</b> - Utilizamos estes cookies para analisar a forma como os usuários usam o site e monitorar a performance deste. Isto permite-nos fornecer uma experiência de alta qualidade ao personalizar a nossa oferta e rapidamente identificar e corrigir quaisquer problemas que surjam. Por exemplo, usamos cookies de desempenho para saber quais as páginas mais populares, qual o método de ligação entre páginas que é mais eficaz, ou para determinar a razão de algumas páginas estarem a receber mensagens de erro. Baseado na utilização do site, podemos também utilizar estes cookies para destacar artigos ou serviços do site que pensamos ser do interesse dos usuários. Estes cookies são utilizados apenas para efeitos de criação e análise estatística, sem nunca recolher informação de carácter pessoal.</span></li>
              <li><span><b>Cookies de funcionalidade</b> - Utilizamos cookies de funcionalidade para nos permitir relembrar as preferências do usuário. Por exemplo, os cookies evitam digitar o nome do utilizador cada vez que este acede ao site. Também usamos cookies de funcionalidade para fornecer serviços avançados ao usuário, como por exemplo efetuar comentários a um artigo. Em resumo, os cookies de funcionalidade guardam as preferências do usuário relativamente à utilização do site, de forma que não seja necessário voltar a configurar o site cada vez que o visita.</span></li>
              <li><span><b>Cookies de terceiros</b> - Servem para medir o sucesso de aplicações e a eficácia da publicidade de terceiros no nosso site.</span></li>
              <li><span><b>Cookies de publicidade</b> - Servem para direcionar a publicidade em função dos interesses de cada usuário e do número de visitas que realizou, permitindo limitar o número de vezes da exibição do anúncio. Estes cookies ajudam a medir a eficácia da publicidade.</span></li>
          </div>
          <div className="col-12"> 
              <b><PageSubTitle  text="Os cookies utilizados podem ser:"/></b>        
          </div>
          <div className="col-12 offset-1 alinhamento"> 
              <li><span><b>Cookies permanentes</b> - Ficam armazenados ao nível do navegador de internet (browser) nos seus dispositivos de acesso (pc, mobile e tablet) e são utilizados sempre que o usuário faz uma nova visita ao site. Geralmente são utilizados para direcionar a navegação de acordo com os interesses do usuário, permitindo-nos prestar um serviço mais personalizado.</span></li>    
              <li><span><b>Cookies de sessão</b> - São temporários, permanecem nos cookies do seu navegador de internet (browser) até sair do site. A informação obtida permite identificar problemas e fornecer uma melhor experiência de navegação.</span></li>
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Depois de autorizar o uso de cookies, o usuário pode sempre desativar parte ou a totalidade dos nossos cookies."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text='Todos os browsers permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da seleção das definições apropriadas no respetivo navegador. Pode configurar os cookies no menu "opções" ou preferências do seu browser.'/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="Note-se que, ao desativar cookies, pode impedir que alguns serviços da web funcionem corretamente, afetando, parcial ou totalmente, a navegação no website."/>           
          </div>
          <div className="col-12"> 
              <PageSubTitle  text="O Recordamos que ao desativar os cookies, partes do nosso site podem não funcionar corretamente."/>           
          </div>
        </div>
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    Cookies: state.CarReducer.Cookies,
  };
}

export default connect(mapStateToProps)(Cookies);

