import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHome } from './Home.actions';
import { fetchValues, fetchValuesAuth } from '../../shared/http-request-util';
import PubSub from 'pubsub-js'

import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Banner from '../../components/Banner/Banner';
import Speak from '../../components/Speak/Speak';
import Social from '../../components/Social/Social';
import ComponentsCar from '../../components/componentsCar/ComponentsCar'
import Tabbar from '../../components/Tabbar/Tabbar';
import Loading from '../../components/Loading/Loading';
import NextArrow from '../../components/Arrow/NextArrow'
import PrevArrow from '../../components/Arrow/PrevArrow'
import Slider from "react-slick";
import ReactGA from 'react-ga';
import AreaLogadaComponent from '../../components/AreaLogadaComponent/AreaLogadaComponent';

import './Home.css'; 
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import PWAAlert from '../../components/PWAAlert/PWAAlert';
import CookiesAlert from '../../components/CookiesAlert/CookiesAlert';
import { getEventos } from '../Eventos/Eventos.actions';

let { apiEndpoint } = require('../../config/constants');
let { homeAPI } = require('../../config/constants');
let { eventsListAPI } = require('../../config/constants');


class Home extends Component {

  constructor(props) {
    super(props);
    this.update = false;
    this.isMobileHome = false
    this.selectedSlide = 0
     this.state ={
       selectedSlide: 0,
       isLoggedIn: false
    }
  }

  componentDidMount() {    
    ReactGA.pageview('/home');
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + homeAPI(), getHome));

    if(document.getElementById("root").offsetWidth < 992) {
      this.isMobileHome = true

      this.token = PubSub.subscribe('SELECT_TABBAR', this.goSlide.bind(this));
    }

    //check if user is logged in
    let userData = JSON.parse(localStorage.getItem('user-data'));
    if (userData && userData.user && userData.user.loggedIn) {
      //check if user has events
      this.props.dispatch(fetchValuesAuth(eventsListAPI(), 'GET', getEventos));
      this.setState({
        isLoggedIn: true
      })
    }

    window.history.pushState("", "", '/');
  }  

  componentWillReceiveProps(nextProps) {
    if(nextProps.events.length > 0) {
      PubSub.publish('HAS_EVENT', 'open');
      localStorage.setItem('user-has-event', true)
    }
  }


  goSlide(msg, data) {
    console.log('home', msg,  data)
    this.setState({
      selectedSlide: data
    })
    var amount = 0
    if(data > 0) amount = data * 100
    this.refs['homeMobile'].style.marginLeft = `-${amount}vw`
    // this.refs['slide-0'].style.visibility = 'visible'
    // this.slider.slickGoTo(slide, true)
  }

  _checkAmount(type) {
    switch(type) {
      case 'news':
        return this.props.news.length;
      case 'gallery':
        return this.props.gallery.length;
      case 'cars':
        return this.props.cars.length;
      default:
        return 3

    }
  }

  render() {

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      touchThreshold: 40,
      centerMode: false,
      // lazyLoad: 'ondemand',
      onLazyLoad: (slidesLoaded) => {
        console.log(slidesLoaded)
      },
      variableWidth: false,
      adaptiveHeight: true,
      nextArrow: <NextArrow className='vw-next-arrow'/>,
      prevArrow: <PrevArrow className='vw-prev-arrow'/>,
      beforeChange: (current, next) => {
        this.selectedSlide = next
        // this.setState({
        //   selectedSlide: next
        // })
      }
    }

    if (this.update) {
      return (      
        <div className="vw-home">
          <Banner slidesContent={this.props.destaque}/>
          { this.state.isLoggedIn ? <AreaLogadaComponent/> : '' }
          {/* <Tabbar selectedSlide={ this.state.selectedSlide } onSelectSlide={this.goSlide.bind(this)} /> */}
          {/* <nav id='navbar' className={`vw-tabbar `}>
            <div className="vw-tabbar-content navbar" data-level="1">
              <ul className="navbar-nav"> 

                <li onClick={this.goSlide.bind(this, 0)} className={"nav-item " + (this.selectedSlide == 0 ? 'active' : '') }>
                  <a href='#' className="nav-link">
                    <label className="txt-modelos">News</label>
                  </a>
                </li>

                <li onClick={this.goSlide.bind(this, 1)} className={"nav-item " + (this.selectedSlide == 1 ? 'active' : '') }>
                  <a href='#' className="nav-link">                
                    <label >Galeria</label>
                  </a>
                </li>

                <li onClick={this.goSlide.bind(this, 2)} className={"nav-item " + (this.selectedSlide == 2 ? 'active' : '') }>
                  <a href='#' className="nav-link">
                    <label >Carros</label>
                  </a>
                </li>

                <li onClick={this.goSlide.bind(this, 3)} className={"nav-item " + (this.selectedSlide == 3 ? 'active' : '') }>
                  <a href='#' className="nav-link">
                    <label >Social</label>
                  </a>
                </li>

                <li onClick={this.goSlide.bind(this, 4)} className={"nav-item " + (this.selectedSlide == 4 ? 'active' : '') }>
                  <a href='#'  className="nav-link">
                    <label >Contatos</label>
                  </a>
                </li>
              </ul>
            </div>
          </nav> */}
          { this.isMobileHome ? 
            <div id='homeMobile' ref='homeMobile' className='homeMobile'>
              {/* <Slider ref={slider => (this.slider = slider)} {...settings}> */}
                <div ref='slide-0' className={"holderSliderHome " + (this.state.selectedSlide == 0 ? 'slide-active' : '') }>
                  <NewsComponent isVertical={false} itemsPerSlide={ 3 } news={this.props.news.slice(0, 3)}/> 
                </div>
                <div ref='slide-1'  className={"holderSliderHome " + (this.state.selectedSlide == 1 ? 'slide-active' : '') }>
                <GalleryItem itemsPerSlide={3} title='Fotos e Vídeos' gallery={this.props.gallery.slice(0, 3)}/>
                </div>
                <div ref='slide-2'  className={"holderSliderHome " + (this.state.selectedSlide == 2 ? 'slide-active' : '') }>
                  <ComponentsCar itemsPerSlide={ this.props.cars.length }  isSlider={true} cars={this.props.cars}/>
                </div>
                <div ref='slide-3'  className={"holderSliderHome " + (this.state.selectedSlide == 3 ? 'slide-active' : '') }>
                  <Social/> 
                </div>
                <div ref='slide-4'  className={"holderSliderHome " + (this.state.selectedSlide == 4 ? 'slide-active' : '') }>
                  <Speak/> 
                </div>
              {/* </Slider> */}
            </div>
          : 
            <div className="row">
              {/* <div className="col-12 col-md-12">
                <AreaLogadaComponent/>
              </div> */}
              <div className="col-12 col-md-12">
              <NewsComponent itemsPerSlide={ this.isMobileHome ? 1 : null } isVertical={false} news={this.props.news}/> 
              </div>
              <div className="col-12 col-md-12">
              <GalleryItem itemsPerSlide={ this.isMobileHome ? 1 : null } title='Fotos e Vídeos' gallery={this.props.gallery}/>
              </div>
              <div>
              <ComponentsCar isSlider={true} cars={this.props.cars}/>
              </div>
              <div className="col-12 col-md-12">
                <Social/> 
              </div>
              <div>
                <Speak/> 
              </div>
            </div>
          }
          <CookiesAlert/>
          {/* <PWAAlert/> */}
        </div> 
      );         
    } else { 
      return (
      <Loading size='fullscreen'></Loading>

      );
    }  
  } 
}

// Add this function:
function mapStateToProps(state) {
  return {
    news: state.HomeReducer.news,
    gallery: state.HomeReducer.gallery,
    destaque: state.HomeReducer.destaque,
    cars: state.HomeReducer.cars,
    events: state.EventosReducer.list
  };
}

export default connect(mapStateToProps)(Home);
