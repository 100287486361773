/*
 * action types
 */

export const LIST_LATEST_PHOTOS_BY_ALBUM = 'LIST_LATEST_PHOTOS_BY_ALBUM';

/*
 * action creators
 */
export function getLatestPhotos(result) {
  return { 
    type: LIST_LATEST_PHOTOS_BY_ALBUM, 
    latestPhotos: result.bucket.bucket_associations,
    pages: result.pages
  }
}