import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVersion } from './Version.actions';
import { fetchValues, fetchValuesToken } from '../../../shared/http-request-util';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
//import ShortImage from '../../components/ShortImage/ShortImage';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import iconetecnico from './img/tecnico@3x.png';

import iconedonwload from './img/donwload@3x.png';

//import GalleryItem from '../../../components/Gallery/GalleryItem/GalleryItem';
import './Version.css'; 
import ShortImage from '../../../components/ShortImage/ShortImage';
import iconefacebook from './img/icone-facebook.svg';
import iconetwitter from './img/icone-twitter.svg';
import iconefoto from './img/icone-foto.svg';
import btdownloadnormal from './img/bt-download-normal.svg';
import iconDownload from './img/bt-download-hover.svg';
import iconecompartilhar from './img/bt-compartilhar-hover.svg';

import PopOverButtton from '../../../components/PopOverButton/popOverButton'
import ReactGA from 'react-ga';


let { apiEndpoint, publicUrlEndpoint } = require('../../../config/constants');
let { carVersionAPI } = require('../../../config/constants');
let { urlEndpoint } = require('../../../config/constants');


class Version extends Component {

  constructor(props) {
    super(props);
    this.update = false;



    var medias=[
      {
      media: this.props.version.media,
      mediaLow: this.props.version.mediaLow,
      mediaMed: this.props.version.mediaMed,
      }
    ]

    this.state = {
      medias: medias,
    }

  }

  componentDidMount() {    
    this.update = true;
    //if user has been viewing an preview from CMS, will receive the user token here
    if(this.props.location.search.indexOf('token=') != -1) {
      let token = this.props.location.search.split('token=')[1]
      this.props.dispatch(fetchValuesToken(apiEndpoint() + carVersionAPI(this.props.match.params.car_id,this.props.match.params.version_id), 'GET', getVersion, token));
    } else {
      this.props.dispatch(fetchValues(apiEndpoint() + carVersionAPI(this.props.match.params.car_id,this.props.match.params.version_id), getVersion));
    }
  }   

  
  _getPDFName = () => {
    if (this.props.version.pdfMedia) {
      let pdf = this.props.version.pdfMedia.split('/');
      return pdf[pdf.length - 1];
    }
  }

  _registerClick = () => {
    ReactGA.event({
      category: 'PDF',
      action:"download PDF from version: " + this.props.version.title
    });    

  }

  _renderPDF = () => {
    if (this.props.version.pdfMedia) {
      ReactGA.pageview('/car-version: ' + this.props.version.title);
      return (        
        <div className="col-12"> 
          <br />
          <div className="row bg-interno ">
            <div className="col-2 col-sm-2 col-md-1 align-self-center">
              <img src={iconetecnico }></img>
            </div>
            <div className="col-6 col-sm-8 col-md-9 align-self-center center-align"> 
              <PageSubTitle text={ this._getPDFName() }/>
            </div>                  
            <div className="col-2 col-sm-1 offset-1 align-self-center iconedonwload"> 
            <a href={this.props.version.pdfMedia} download={this.props.version.title} target="_blank">
              <img src={iconedonwload } onClick={() => this._registerClick() }></img>
            </a>
            </div>
          </div>
      </div>
      )
    }
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {


    if (this.update) {
      return (      
        <div className="vw-carro-interno">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/cars/models'>Carros</a> / ${this.props.version.title} `} />
              <PageTitle title={this.props.version.title}/>
              <HighlightMedia showPopOverButtons={ true } 
                imageName={this.props.version.title}
                media={this.props.version.media} mediaMedium={this.props.version.mediaMed} mediaLow={this.props.version.mediaLow} 
                mediaSize={this.props.version.mediaSize} mediaSizeMedium= { this.props.version.mediaSizeMedium } mediaSizeLow = {this.props.version.mediaSizeLow} 
                GAText={this.props.version.title}
                isAutoPlay={true}/>
            </div>
            </div>
            <div className="row justify-content-end newsButtons">                       
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.version.title} ` + ` ${publicUrlEndpoint()}` + `/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}`}
                    twitterText={this.props.news.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}`}
                  />
              </div>
              <div id="downloadBondary" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                  popOverType="image"
                  containerName="downloadBondary"
                  imageLink={this.props.version.media}
                  imageDownloadName={this.props.version.title }
                  pdfULR={`${urlEndpoint()}` + `/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}/PDF` }
                  pdfName={this.props.version.title}
                />
              </div> 
              <div id="cartButtonContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButtton 
                    popOverType="cart"
                    containerName="cartButtonContainer"
                    imageLink={this.props.version.media}
                    contentToDownload={this.props.version}
                    imageDownloadName={this.props.version.title }
                    contentLink={`/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}` }
                    pdfURL={`${urlEndpoint()}` + `/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}/PDF` }
                    type='Press Release'
                    mediaAmount={ this.props.version.templates.filter((item) => item.type == 2).length }
                  />
              </div>
            </div>
            <div className="row">
            <div className="col-12">            
              <PageSubTitle text={this.props.version.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">            
              <TextBody text={this._convertDescription(this.props.version.description)}/>
            </div>
            <div className="w-100"></div>
            { this._renderPDF() }
            <br></br>
            <Template GAText={ this.props.version.title } templates={this.props.version.templates} contentLink={`/cars/version/` + `${this.props.match.params.car_id}`+`/${this.props.match.params.version_id}` }/>
            <NewsComponent isVertical={true} textEnum="Carros" idLevel1={ this.props.match.params.car_id } idLevel2={ this.props.match.params.version_id } />   
  
            <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/>
 
            <Speak/>           
          </div> 
        </div>
      );       
    } else {
      return (<Loading></Loading>);
    }
   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    version: state.VersionReducer.version,
    news: state.VersionReducer.news,
    gallery: state.VersionReducer.gallery
  };
}

export default connect(mapStateToProps)(Version);
