const initialState = {
  car: {},
  news: [],
  gallery: []
}

const CarReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'CAR_DETAIL':
      state = initialState;
      return {
        car: {...state.car, ...action.car},
        news: [...state.news, ...action.news],
        gallery: [...state.gallery, ...action.gallery]
      }
    default:
      return state;
  }
};

export default CarReducer;