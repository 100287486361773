import React, { Component } from 'react';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import './AreaLogadaComponent.css'; 
import evento from './img/calendar.svg';
import comodato from './img/puzzle.svg';
import PageTitle from '../PageTitle/PageTitle';
import Button from '../Button/Button';
import { withRouter } from 'react-router-dom';
class AreaLogadaComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {    
    
  }  

  _gotToLoanRequest = () => {
   this.props.history.push('/area-logada')
  }


  render() {
    return (
      <div id='areaLogadaComponent' className="vw-areaLogadaComponent">
        <div className='row'>
          <div className='col-3 col-md-2'>
            {/* <div className='img-hand'></div> */}
            <img src={require('./img/puzzle.svg')} className='img-hand '/>
          </div>
          <div className='col-8 col-md-7 '>
            <PageTitle className='title-logged mt-2 pb-2' title="Bem-Vindo ao VW News."/>
            <PageSubTitle text="Neste espaço você terá acesso a área restrita e encontrará informações e serviços exclusivos."/>
          </div>
          <div className='col-5 col-md-2 offset-3 offset-md-0 mb-4 mb-md-0 d-flex align-items-center'>
            <Button text='Acessar' iconType='arrow-foward' hideIcon={true} onClick={this._gotToLoanRequest}/>
          </div>
        </div>
      </div>
    )
  }
}



export default withRouter(AreaLogadaComponent);
