

const initialState = {
  checklist: []
}

const ChecklistFormReducer = (state = initialState, action) => {
  console.log((action))
  switch(action.type) {
    case 'CHECKLIST_HOME':
      state = initialState;
      return {
        checklist: [...state.checklist, ...action.list]
      }
    default:
      return state;
  }
};

export default ChecklistFormReducer;