import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNewsDetail } from './NewsDetail.actions';
import { fetchValues } from '../../../shared/http-request-util';
import { Link } from 'react-router-dom';

import { Popover, PopoverHeader, PopoverBody, ListGroup, ListGroupItem } from 'reactstrap';


import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import PopOverButtton from '../../../components/PopOverButton/popOverButton'

import './NewsDetail.css'; 
// import iconefacebook from './img/icone-facebook.svg';
// import iconetwitter from './img/icone-twitter.svg';
// import iconefoto from './img/icone-foto.svg';
// import btdownloadnormal from './img/bt-download-normal.svg';
// import iconDownload from './img/bt-download-hover.svg';
// import iconecompartilhar from './img/bt-compartilhar-hover.svg';



let { apiEndpoint } = require('../../../config/constants');
let { newsDetailAPI } = require('../../../config/constants');
let { urlEndpoint } = require('../../../config/constants');


class NewsDetailPDF extends Component {

  constructor(props) {
    super(props);
    this.update = false;
    

    var medias=[
      {
      media: this.props.news.media,
      mediaLow: this.props.news.mediaLow,
      mediaMed: this.props.news.mediaMed,
      }
    ]

    this.state = {
      downloadOpen:false,
      sharedOpen: false,
      medias: medias,
      active: 'true'
    }

  }


  componentDidMount() {    
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + newsDetailAPI(this.props.match.params.news_id), getNewsDetail));
  }  
  

  toggleDownload(){
    this.setState({
      downloadOpen: !this.state.downloadOpen
      }
    )
  }

  

  toggleShare(){
    this.setState({
      sharedOpen: !this.state.sharedOpen,
      }
    )
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
   
    if (this.update) {

       
      // console.log("this.props.news",this.props.news); 

      return (            
        <div className="vw-newsdetail-center">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/news/'>News</a> / ${this.props.news.title} `} />
              <PageTitle title={this.props.news.title} />
              <HighlightMedia media={this.props.news.media} mediaMedium={this.props.news.mediaMed} mediaLow={this.props.news.mediaLow} isAutoPlay={true} />
            </div>
            </div>
            <div className="row">
            <div className="col-12">
              <PageSubTitle text={this.props.news.subtitle} />
            </div>
            <div className="w-100"></div>
            <div className="col-12">
              <TextBody text={this._convertDescription(this.props.news.description)} /> 
            </div>
            <Template GAText={ this.props.news.title }  templates={this.props.news.templates} pdf={ true }/>
          </div>
        </div>
      ); 
      
  } else {
      return (<Loading></Loading>);
    }
  }
}

 // Add this function:
 function mapStateToProps(state) {   
  return {
    news: state.NewsDetailReducer.news,
  };
}

export default connect(mapStateToProps)(NewsDetailPDF);
