import React, { Component } from 'react';

import './News.css'; 

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Speak from '../../components/Speak/Speak';
import ReactGA from 'react-ga';

class News extends Component {

  componentDidMount() {   
    ReactGA.pageview('/news');
  }  

  render() {
    return (      
      <div className="vw-media-center">
        <div className="row vw-media-center-1">
          <div className="col-12">
            <div className="col-12 p-0">
              <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/news'>News</a>  / Galeria" />
              <PageTitle title="News"/>
              <PageSubTitle text="Esta seção contém todas as novidades divulgadas pela Volkswagen do Brasil."/>
            </div>
            <div className="padding-0">
             <NewsComponent isVertical={true} hideTitle={true}/>  
            </div> 
          </div>
          <Speak/>
        </div>
      </div>
    );    
  }
}

export default News;
