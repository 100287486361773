import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVideosYT } from './YoutubeComponent.action';
import { fetchValues } from '../../shared/http-request-util';
import './YoutubeComponent.css'; 
import NewsComponentItem from '../NewsComponent/NewsComponentItem/NewsComponentItem';
import Button from '../Button/Button';
import { apiEndpoint } from '../../config/constants';

class YoutubeComponent extends Component { 

  constructor(props) {
    super(props)

    var newsItems = [
      {
        media: require('./img/carro2.png'),
        mediaMed: require('./img/carro2.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro3.png'),
        mediaMed: require('./img/carro3.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro4.png'),
        mediaMed: require('./img/carro4.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro2.png'),
        mediaMed: require('./img/carro2.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro3.png'),
        mediaMed: require('./img/carro3.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro4.png'),
        mediaMed: require('./img/carro4.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro2.png'),
        mediaMed: require('./img/carro2.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro3.png'),
        mediaMed: require('./img/carro3.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      },{
        media: require('./img/carro4.png'),
        mediaMed: require('./img/carro4.png'),
        updated_at:'01/02/2019',
        news_association: {
          text_enum: 'YouTube'
        },
        title: 'Golf with the genes of a race car: Advance sales of Golf GTI TCR commence',
        description: 'The latest evolution of a true motoring icon – the Golf GTI – can now be ordered: the evolu…'
      }]

      
    this.state = {
      newsItems: newsItems
    }
  }    

  componentDidMount() {    
    
    this.update = true;
    this.props.dispatch(fetchValues(apiEndpoint() + 'rssfeed/youtube', getVideosYT));
  }   

  mapYoutubeResults() {
    let mappedResultVideoYT = []
    this.props.videosYT.map((videoYT, index) => {
      if(index < 3) {
        let mappedResult = {} 
        mappedResult._id = videoYT.id
        mappedResult.title = videoYT.title
        mappedResult.description = ''
        mappedResult.link = videoYT.link
        mappedResult.media =  videoYT.link
        mappedResult.created_at = videoYT.isoDate
        mappedResult.news_associations = [{
          text_enum: 'YouTube'
        }]
      
        mappedResultVideoYT.push(mappedResult)
      }
    })
    return mappedResultVideoYT;
  }

  render() {

    return (      
      <div className={'newscomponent-container youtube-container ' + (this.props.isVertical ? 'isVertical' : 'isSlider')}>
       
        <NewsComponentItem
          newsItems={ this.mapYoutubeResults() } 
          itemsPerSlide='3' 
          isVertical={ this.props.isVertical }
          isReadMore={ this.props.isReadMore }
          hideIcon={true}
          preventPlay={false}
          hideShowMore={true}
          LinkYoutube={true}/>

          <a href="https://www.youtube.com/volkswagendobrasil" className='d-block ml-4'>
            <Button text='Ir para YouTube da Volkswagen' hideIcon={ true }/>
          </a>

      </div>
    );    
  }
}


// Add this function:
function mapStateToProps(state) {
  return {
    videosYT: state.YoutubeReducer.videosYT
  };
}

export default connect(mapStateToProps)(YoutubeComponent);
// export default YoutubeComponent;
