export const PLATFORMS = {
    WEB: "WEB",
    MOBILE: "MOBILE",
};

export const ENVIRONMENTS = {
    LOCAL: "LOCAL",
    DEV: "DEV",
    BETA: "BETA",
    PROD: "PROD",
}
