
const initialState = {
  list: []
}

const EventosReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EVENTOS_HOME':
      state = initialState;
      return {
        list: [...state.list, ...action.list]
      }
    default:
      return state;
  }
};

export default EventosReducer;