/*
 * action types
 */

export const TECNOLOGIA_LEVEL1_DETAIL = 'TECNOLOGIA_LEVEL1_DETAIL'
export const TECNOLOCIA_LEVEL2_DETAIL = 'TECNOLOCIA_LEVEL2_DETAIL'

/*
 * action creators
 */
export function getTecnologia(result) {
  return { 
    type: TECNOLOGIA_LEVEL1_DETAIL, 
    technology: result.technology,
    news: result.news,
    gallery: result.gallery
  }
}

export function getSubTecnologia(result) {
  return { 
    type: TECNOLOCIA_LEVEL2_DETAIL, 
    technology: result.subTech,
    news: result.news,
    gallery: result.gallery
  }
}

