import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listCars } from './Cars.actions';
import { fetchValues } from '../../shared/http-request-util';

import ComponentsCar from '../../components/componentsCar/ComponentsCar';
import Speak from '../../components/Speak/Speak';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import Gallery from '../../components/Gallery/Gallery';
import Loading from '../../components/Loading/Loading';
import ReactGA from 'react-ga';

import './Cars.css';

let { apiEndpoint } = require('../../config/constants');
let { listCarsAPI } = require('../../config/constants');


class Cars extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    ReactGA.pageview('/cars');
    this.update = true;
    switch(this.props.match.params.car) {
      case "models":      
        this.props.dispatch(fetchValues(apiEndpoint() + listCarsAPI() + 3, listCars));
        break;
      case "concepts":
        this.props.dispatch(fetchValues(apiEndpoint() + listCarsAPI() + 2, listCars));
        break;
      case "classics":
        this.props.dispatch(fetchValues(apiEndpoint() + listCarsAPI() + 1, listCars));
        break;
    }    
  }

  componentWillUnmount() {

  }

  render() {
    if (this.update) {      
      //Update the subtitle
      let subTitle;
      if (this.props.cars == null || this.props.cars.length <= 0) {
        subTitle = <PageSubTitle text="Nenhum carro encontrado" className="vw-page-subtitle-no-value"/>        
      } else {
        subTitle = <PageSubTitle text="Aqui você vai encontrar informações sobre os veículos Volkswagen"/>
      }
      
      return (      
        <div>
            <div className="row car">
              <div className="col-12">
                <Breadcrumb text="<a href='/'>Newsroom</a> / Carros" />
                <div className="w-100"></div>
                <PageTitle title="Carros" />
                <div className="w-100"></div>
                {subTitle}
              </div>
            </div>
          <div className="car-component">                  
            <ComponentsCar cars={this.props.cars} /> 
          </div>       
          <NewsComponent isVertical={false} news={this.props.news}/>          
          <Gallery title='Fotos e Vídeos' gallery={this.props.gallery} />           
          <Speak/> 
        </div>
      );
    } else {
      return (
        <Loading />
      )
    }
    
  } 
}

// Add this function:
function mapStateToProps(state) {
  return {
    cars: state.CarsReducer.cars,
    news: state.CarsReducer.news,
    gallery: state.CarsReducer.gallery
  };
}

export default connect(mapStateToProps)(Cars);
