import React, { Component } from 'react';

import './HomeComodato.css';

import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import HomeCardBoard from '../../components/HomeCardBoard/HomeCardBoard';
import ReactGA from 'react-ga';


class HomeComodato extends Component {

    constructor(props) {
        super(props);
        this.user = JSON.parse(localStorage.getItem('user-data')).user

        this.titleApproval = this.user.role == 2 || this.user.role == 3 ? 'Aprovação Gerencial' : 'Aprovação Backoffice'
    }

    componentDidMount() {
        ReactGA.pageview('/home-comodato');
    }


    render() {
        return (
            <div className="vw-media-center">
                <div className="row vw-media-center-1">
                    <div className="col-12">
                        <div className="col-12 p-0">
                            <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / Empréstimo de Veículos" />
                            <PageTitle title="Empréstimo de Veículos" />
                            <PageSubTitle text="Bem-Vindo a área logada, abaixo está o nosso menu de opções:" />
                        </div>
                        <div className="col-12 padding-0 margin-bottom-50">

                            <div className="row">

                                {(this.user.role == 10 || this.user.role == 1) ? <HomeCardBoard url={"/solicita-comodato"} image={"plus"} title={"Solicitar Empréstimo de Veículo"} subTitle={"Criar uma nova solicitação."}></HomeCardBoard> : ''}
                                {(this.user.role < 6  && this.user.role != 4) ? <HomeCardBoard url={"/aprovacao"} image={"approve"} title={this.titleApproval} subTitle={"Lista de solicitação de veículos para aprovação."}></HomeCardBoard> : ''}
                                {(this.user.role == 1) ? <HomeCardBoard url={"/aprovacao"} isAdmin={true} image={"approve"} title={'Aprovação Gerencial'} subTitle={"Lista de solicitação de veículos para aprovação."}></HomeCardBoard> : ''}
                                {(this.user.role < 6 && this.user.role != 4 && this.user.role != 10) ? <HomeCardBoard url={"/retirar"} image={"car"} title={"Retirar Veículo"} subTitle={"Informar a retirada do veículo."}></HomeCardBoard> : ''}
                                {(this.user.role < 6 && this.user.role != 4) ? <HomeCardBoard url={"/checklist"} image={"checklist"} title={"Checklist: Entrega e Devolução"} subTitle={"Avaliação do Carro."}></HomeCardBoard> : ''}
                                {(this.user.role < 11 &&  this.user.role != 4 || this.user.role == 1) ? <HomeCardBoard url={"/comodato-lista"} image={"cancel"} title={"Cancelar Solicitação de Empréstimo de Veículos"} subTitle={"Lista de veículos para cancelamento."}></HomeCardBoard> : ''}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeComodato;
