import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Speak from '../../components/Speak/Speak';
import './Termos.css'; 

class Termos extends Component {

    constructor(props) {
      super(props);
    }


    render(){
        return(
            <div className="row vw-termos">
                <div className="col-12">
                   <Breadcrumb text={`<a href='/'>Newsroom</a>`} />
                   <PageTitle title="Legal"/>
                </div>
                <div className="col-10 offset-0 termos-txt">
                       <ul>
                        <li>
                        <Link to="/termos-legais/imprint">
                         <span className="termos-txt-subtitulo">Termos e Condições Gerais de Uso</span>
                        </Link>
                        </li>
                        <li>
                        <Link to="/termos-legais/termoNews">
                        <span className="termos-txt-subtitulo">Termo de Uso – Newsletter</span>
                        </Link>
                        </li>
                        <li>
                        <Link to="/termos-legais/termoDownload">
                        <span className="termos-txt-subtitulo">Termo de Uso – Download</span>
                        </Link>
                        </li>
                        <li>
                        <Link to="/termos-legais/privacypolicy">
                        <span className="termos-txt-subtitulo">Política de Privacidade e Proteção de dados</span>
                        </Link>
                        {/* <a href="https://www.vw.com.br/pt/legal-info/politica-privacidade.html" target="_blank">
                        <span className="termos-txt-subtitulo">Política de Privacidade e Proteção de dados</span>
                        </a> */}
                        </li>
                        <li>
                        <a href="/termos-legais/Cookies" >
                        <span className="termos-txt-subtitulo">Política de Cookies</span>
                        </a>
                        </li>
                    </ul>
                </div>
                <Speak/>
            </div>

        );
    }

}

export default Termos;