/*
 * action types
 */

export const LIST_LOANCARS = 'LIST_LOANCARS'
export const SEND_LOANCARS = 'SEND_LOANCARS'

/*
 * action creators
 */
export function getLoanCars(result) {
  return { 
    type: LIST_LOANCARS, 
    loanCars: result.cars
  }
}

export function sendLoanCars(result) {
  if(!result.errorCode) result = { sendedLending: true }
  if(result.errorCode === 401) window.location.href = '/'
  return { 
    type: SEND_LOANCARS, 
    sendedCar: result
  }
}