import React, { Component } from 'react';
import { apiEndpoint } from '../../config/constants';
import './VWFileUpload.css'; 
// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond';


//Import the plugin code
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// Import the plugin code
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import the plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

class VWFileUpload extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      imageItems: [],
      imageSize: '',
      imageDetailText: props.imageDetailText,
      filesSaved: props.filesSaved ? props.filesSaved : [],
      fullPathS3: ''
    }

    let savedUser = window.localStorage.getItem('user-data') ? JSON.parse(window.localStorage.getItem('user-data')) : {}
    let bucket = 'https://cdn.na.vwapps.io/assets/a2acc312-5aaa-41c7-bad8-868ed07cfe1d';
    if(savedUser.user.cnhPicture) bucket = savedUser.user.cnhPicture.substring(0, savedUser.user.cnhPicture.lastIndexOf('/'))
    
    console.log(bucket)
    // Register the plugins
      registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

    let CMSURL = apiEndpoint().split('v1/api')[0] + 'medias/'
    this.state.fileUploaderOptions = {
      process: {
          url: CMSURL + 'upload-front',
          withCredentials: false,
          headers: {
            'authorization': JSON.parse(localStorage.getItem('user-data')).token
          },
          timeout: 7000,
          onload: (response) => this.onload(response),
          onerror: (response) => this.onerror(response.data),
          ondata: (formData) => {
            console.log('carregando',formData)
            return formData;
        }
      },
      restore: './',
      load: bucket + '/'
    }
  }

  componentDidMount() {
    console.log(this.props.filesSaved)
  }
  
  componentWillReceiveProps(nextProps) {
    if(!nextProps.allowFileUploading) this.state.imageSize = ''
    if(nextProps.filesSaved) {
      let imageName = nextProps.filesSaved.substring(nextProps.filesSaved.lastIndexOf('/'), nextProps.filesSaved.length)
      let savedImage = [{
        source: imageName.replace('/', ''),
        options: {
            type: 'local'
        }
      }]
      this.state.filesSaved = [...savedImage]
      this.state.savedImageFullPath = nextProps.filesSaved
    } else {
      this.state.imageItems = this.state.imageItems ? this.state.imageItems : []
    }
    this.setState({
      imageDetailText: nextProps.imageDetailText,
    })
  }

  onload(e) {
    console.log(this.state.imageItems)
    if(this.state.imageItems[0]) {
      if(this.props.allowFileUploading) {
        this.setState({
          imageSize: JSON.parse(e).size,
          imageDetailText: this.props.imageDetailText
        }, ()=> {
          this.props.updateFileItems(this.state.imageItems, e, true)
        })
        console.log('isallowed', e)
      }
      
      console.log('onload', e, this.props.allowFileUploading)
    }
  }

  onerror(err) {
    console.log('onerror', err)
  }

  ondata(e) {
    console.log('ondata', e)
  }

  handleInit = () => {
    console.log('iniciou')
    if(this.state.filesSaved) {
      // this.state.fullPathS3
      console.log('iniciou', this.state.filesSaved)
      let path 
      if(this.state.savedImageFullPath) {
        path = this.state.savedImageFullPath.substring(0, this.state.savedImageFullPath.lastIndexOf('/'))
        console.log('path',path, this.state.filesSaved, '-1')
        this.state.fileUploaderOptions.load = path
      }
      this.setState({
        imageItems: this.state.filesSaved,
        fullPathS3: path
      })
    }
  }

  generateLabel(size) {
    let label = parseFloat(size / 1000000).toFixed(3);
    if (label < 1) {
      label = (label + "").split('.')[1] + " KB";
    } else {
      label = label.substring(0, 3);
      label += " MB";
    }
    if(label != '000 KB') return label
    return ''
  }

  fileItemsReturn = (fileItems) => { 
    
    let items = fileItems.map(fileItem => fileItem.file)
    this.props.isFileUploading()
    if(items[0]) {
      this.setState({
        imageItems: items,
        imageDetailText: 'Carregando...',
        imageSize: this.state.filesSaved ? this.generateLabel(items[0].size) : ''
      }, ()=> {
        let image = { name: ''}
        if(!this.state.filesSaved) {
          this.props.updateFileItems([image])
        } else {
          this.props.updateFileItems(this.state.imageItems)
        }
      })
    } else {
      console.log('update')
      this.setState({
        imageItems: [],
        imageDetailText: 'Enviando Imagem',
        imageSize: ''
      })
    }
  }

  render() {
    return (      
      <div className={`file-upload-wrapper ${this.props.uploadStyleType}`}>
        <div className='file-upload-button'>
          <img className="imgBoard" src={require("./images/plus-2@2x.png")} />
          <p className='file-upload-text'>{ this.props.uploadText }</p>

            <p className='detail-text'>{this.state.imageDetailText} {this.state.imageSize}</p> 

        </div>
        <FilePond files={this.state.imageItems} allowMultiple={this.props.allowMultiple} server={this.state.fileUploaderOptions}  
          acceptedFileTypes={['image/*']}
          oninit={() => this.handleInit() } 
          onupdatefiles={fileItems => this.fileItemsReturn(fileItems)}
        />
      </div>  
    );    
  }
}

export default VWFileUpload;
