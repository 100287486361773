/*
 * action types
 */

export const EMOBILITY_HOME = 'EMOBILITY_HOME'

/*
 * action creators
 */
export function getEMobility(result) {
  return { 
    type: EMOBILITY_HOME, 
    emobility: result.emobility[0],
    news: result.news,
    gallery: result.gallery
  }
}
