import React, { Component } from 'react';
import './AlertModal.css'

class AlertModal extends Component {
  state = {
    open: false,
  };

  constructor(props){
    super(props);
  }


  componentWillReceiveProps(nextProps) {
    if(nextProps.showModal) {
      this.setState({ open: true });
    }
  }
  
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onAcceptModal = () => {
    this.props.configModalCallback(true);
    this.setState({ open: false });
  };

  onRejectModal = () => {
    this.props.configModalCallback(false);
    this.setState({ open: false });
  };

  onCloseModal = () => {
    //this.setState({ open: false });
    this.props.configModalCallback(true);
  };

  closeModal = () => {
    this.props.configModalCallback(false);
    this.setState({ open: false });
  }

  mountButtons() {
    if(!this.props.isDecisionModal) {
      return(
        <button onClick={this.onAcceptModal}>{'Ok'}</button>
      )
    } else {
      return (
        <div className='decision-buttons'>
          <button onClick={this.onAcceptModal}>{'Sim'}</button>
          <button onClick={this.onRejectModal}>{'Não'}</button>
        </div>
      )
    }
  }

  render() {
    return (
        this.state.open ? 
          <div className={"modal-container " + 'parent-' + this.props.parentClass}>  
            <div className={ (this.props.addClassName ? this.props.addClassName : '')  + ' content-wrapper ' + (this.props.isDecisionModal ? 'decisionModal ' : '')}>
              <h1 className="title">{this.props.title1}</h1>
              <button onClick={ this.closeModal.bind(this) } className='btn-close'/>
             
                { this.props.messageHTML ? 
                  <div className='content'>
                    <h2 dangerouslySetInnerHTML={{__html: this.props.messageHTML}}></h2>
                    { this.mountButtons() }
                  </div>
                :
                  <div className='content'>
                    <h2>{this.props.message} 
                      { this.props.question ? 
                        <span className='strong'> {this.props.question}</span>
                      : null}
                    </h2>
                    { this.mountButtons() }
                  </div>    
                }
            </div>
          </div>
        : 
          null

    );
  }
}

export default AlertModal;