import React, { Component } from 'react';

import './NewsComponentDate.css'; 

class NewsComponentDate extends Component {

  constructor(props) {
    super(props)
  }    

  render() {
    return (      
      <div className="vw-date-style">
        <p className="vw-date-info">
          <span className="vw-date-value">{ this.props.date }</span>
          { this.props.label && this.props.date ? <span>|</span> : null }
          <span className="vw-date-label">{ this.props.label }</span>
        </p>
        {/* <p className="vw-date-icons">
          <span className="vw-date-icon-fav"></span>
          <span className="vw-date-icon-share"></span>
        </p> */}
      </div>
    );    
  }
}

export default NewsComponentDate;
