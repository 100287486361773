import React, { Component } from 'react';
import ShortImagem from '../ShortImage/ShortImage';

import './HighlightMedia.css'; 
import PopOverButtton from '../PopOverButton/popOverButton';

let { urlEndpoint } = require('../../config/constants');

class HighlightMedia extends Component {

    constructor(props) {
        super(props);
        if(this.props.onRef) {
            this.props.onRef(this)
        }

        this.state = {
            hidePlayBtn: false
        }
    }

    playChildVideo(e) {
        e.stopPropagation()
        if(this.props.preventPlay) return
        this.refs['childMedia'].openVideoModal(false, e)
    }

    playVideo() {
        if(this.props.preventPlay) return
        var videoName = 'video-' + this.getMediaName();
        console.log(this.refs,'videoname', videoName)
        if(this.refs[videoName]) {
            this.refs[videoName].play();
        }
        this.setState({
            hidePlayBtn: true
        })
        this.refs[videoName].setAttribute("controls","controls") 
    }

    getMediaName() {
        if(!this.props.youTubeVideo) {
            return this.props.media.substring((this.props.media.lastIndexOf('/') + 1), this.props.media.lastIndexOf('.'))
        } else {
            return this.props.videoYT_id.replace('yt:video:', '')
        }
    }

    isMobile() {
        var userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i)!= -1 ){
          return true;
        }
    
        return false; 
    }

    checkNextSlide() {
        console.log('this.props.checkNextSlide',this.props.checkNextSlide(),(this.props.checkNextSlide))
        if(this.props.checkNextSlide) {
            this.props.checkNextSlide()
        }
    }

    toggleParentModal() {
        if(!this.props.toggleModal) return
        return this.props.toggleModal()
    }

    render() {
        let streamUL = this.props.youTubeVideo ? `https://www.youtube.com/v/${this.getMediaName()}` : `${this.props.media}`
        return(
            ((this.props.media != null && this.props.media.indexOf('mp4') > -1) || this.props.youTubeVideo) ? 
                <div className='holder-video'>
                { this.props.youTubeVideo ? 
                    <div className='rounded'>
                        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${this.getMediaName()}`}></iframe>
                    </div>
                : 
                    (this.props.isAutoPlay && !this.isMobile())  ? 
                    <video id={`video-${this.getMediaName()}`} ref={`video-${this.getMediaName()}`} autoPlay muted>
                        <source src={this.props.media}/>
                    </video>
                    :
                    <div>
                        { !this.props.hidePlayBtn ? 
                            <a ref='btn-play' className={'play-btn ' + (this.state.hidePlayBtn ? 'hidden-btn' : '')} onClick={e => this.playChildVideo(e)}></a>
                        : null }
                        {/* <video id={`video-${this.getMediaName()}`} ref={`video-${this.getMediaName()}`} preload="metadata">
                            <source src={streamUL} type="video/mp4"/>
                        </video> */}

                        <ShortImagem 
                            ref='childMedia'
                            media={streamUL} mediaMedium={streamUL} mediaLow={streamUL} 
                            mediaSize={this.props.mediaSize} mediaSizeMedium={this.props.mediaSizeMedium} mediaSizeLow={this.props.mediaSizeLow}
                            imageName={`video-${this.getMediaName()}`}
                            modalOpen={this.props.modalOpen}
                            activeMedia={ this.props.activeMedia}
                            showPopOverButtons={this.props.showPopOverButtons}
                            contentLink={ !this.props.contentLink ? window.location.pathname : this.props.contentLink }
                            pdfURL={ this.props.pdfURL }
                            GAText={this.props.GAText}
                            toggleModal={this.toggleParentModal.bind(this)}/>   

                    </div>
                }
                </div>
            : 
            <div className='holder-image'>
                <ShortImagem 
                media={this.props.media} mediaMedium={this.props.mediaMedium} mediaLow={this.props.mediaLow} 
                mediaSize={this.props.mediaSize} mediaSizeMedium={this.props.mediaSizeMedium} mediaSizeLow={this.props.mediaSizeLow}
                imageName={this.props.imageName}
                modalOpen={this.props.modalOpen}
                activeMedia={ this.props.activeMedia}
                showPopOverButtons={this.props.showPopOverButtons}
                contentLink={ !this.props.contentLink ? window.location.pathname : this.props.contentLink }
                pdfURL={ this.props.pdfURL }
                GAText={this.props.GAText}
                toggleModal={this.toggleParentModal.bind(this)}/>                
            </div>
        )
    }
}

export default HighlightMedia;