
import React, { Component } from 'react';

import './Contact.css'; 
import image from "./images/InternetHistorian.jpeg"
import emailIcon from "./images/icone-email-azul.svg"
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image,
      name: this.props.name,
      role: this.props.role,
      email: this.props.email,
      phone: this.props.phone,
      languages: this.props.languages

    }
  }

  _renderPhone() {
    if (this.state.phone != undefined && this.state.phone != "") {
      return (
        <div className="contact__item">
          Tel. {this.state.phone}
        </div>
      )
    }
  }

  _renderLanguage() {
    if (this.state.languages != undefined && this.state.languages != "") {
      return (
        <div className="contact__item">
          Languages: {this.state.languages}
        </div>
      )
    }
  }  

  render() {
    if (this.state.phone != undefined) {
    }

    return (      
      <div className="contact__container 
        col-12 col-sm-4 ">

        <div className="contact__image">
          <img src={this.state.image}></img>
          
        </div>

        <div className="contact__name">
          {this.state.name}
        </div>

        <div className="contact__item">
          {this.state.role}
        </div>

        {this._renderPhone()}

        <div className="contact__email">
          <img src={emailIcon}></img> E-Mail:  <a href={`mailto: ${this.state.email} `}>{this.state.email}</a>
        </div>

        {this._renderLanguage()}        

      </div>
    );    
  }
}

export default Contact;

