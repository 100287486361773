import React, { Component } from 'react'
import './Arrow.css'
class NextArrow extends Component {
    constructor(props) {
        super()
    }
    render(){
        const { className, style, onClick } = this.props;
        return (
            <div className={className} onClick={onClick}> 
              <div className="nextArrow">
                 <img className="Narrow" src={require('./img/arrow-next.png')}/>
              </div>
            </div>
          );
    }
}

export default NextArrow;
