export function generatePDF(url, name, hideAlert, callback) {  
      if(!hideAlert) alert('Esse processo pode levar alguns segundos:');
      fetch('https://v2018.api2pdf.com/chrome/url', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'faa790c7-6bd2-4601-9a1e-81d7bb8c784e' //Get your API key from https://portal.api2pdf.com
        },
        body: JSON.stringify({url: url, inlinePdf: true, fileName: name + '.pdf' })
      }).then(res=>res.json())    
        .then(res => {
          if(!hideAlert) {
            window.location.href = res.pdf
          } else {
            if(callback) {
              let pdfObj = {
                name: name,
                url: res.pdf
              }
              callback(pdfObj)
            }
          }
        }); 
    }
