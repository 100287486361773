

const initialState = {
  search: {
    headers: '',
    searchResult: '',
    total: ''
  },
}

const SearchReducer = (state = initialState, action) => {
  console.log(state, action)
  switch(action.type) {
    case 'LIST_SEARCH':
      state = initialState;
      return {
        search: {...state.search, ...action.search},
      }
    default:
      return state;
  }
};

export default SearchReducer;