import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import NextArrow from '../Arrow/NextArrow'
import PrevArrow from '../Arrow/PrevArrow'

import './Banner.css'; 
import HighlightMedia from '../HighlightMedia/HighlightMedia';

class Banner extends Component {

  constructor(props) {
    super(props);

    this.activeSlideIndex = 0;
    this.state = {
      slides: this.props.slidesContent,
      imageTimer: 50000,
      transitionTimer: 700,
      autoplaySpeed: 10500
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      slides: nextProps.slidesContent
    })
  }

  _createLink(destaque) {
    
    switch (destaque.category) {
      case 'session': {
        if (destaque.id_destaque != 1) {
          return "/company/" + destaque.id_destaque;
        } else {
          return "/company";
        }        
      }
      case 'sub_session': {
        if (destaque.father_id != undefined) {
          return "/company/" + destaque.father_id + "/" + destaque.id_destaque;
        }
      }
      case 'car': {
        if (destaque.id_destaque != 1) {
          return "/cars/car/" + destaque.id_destaque;
        } else {
          return "/cars/models";
        }        
      }
      case 'car_version': {
        if (destaque.father_id != undefined) {
          return "/cars/version/" + destaque.father_id + "/" + destaque.id_destaque;
        }
      }
      case 'technology': {
        if (destaque.id_destaque != 1) {
          return "/technology/" + destaque.id_destaque;
        } else {
          return "/technology";
        }        
      }
      case 'subtechnology': {
        if (destaque.father_id != undefined) {
          return "/technology/" + destaque.father_id + "/" + destaque.id_destaque;
        }
      }
      case 'emobility': {
        if (destaque.id_destaque != 1) {
          return "/mobilidade-eletrica/" + destaque.id_destaque;
        } else {
          return "/mobilidade-eletrica";
        }        
      }
      case 'subemobility': {
        if (destaque.father_id != undefined) {
          return "/mobilidade-eletrica/" + destaque.father_id + "/" + destaque.id_destaque;
        }
      }
      case 'news': {
        return "/news/" + destaque.id_destaque;
      }
      default:        
        break;
    }
    //return "/cars/car/3";
  }

  getNextSlide(activeIndex) {
    return new Promise((res, rej) => {
      if(this.state.slides.length === (activeIndex + 1)){
        res(0)
      } else {
        res(activeIndex + 1)
      }
    })
  }

  checkNextSlide(activeIndex) {
    this.getNextSlide(activeIndex).then((goTo) => {
      if(this.checkMediaType(this.state.slides[activeIndex].media) != 'video') {
        setTimeout( () => {
          if(this.sliderBanner && (this.activeSlideIndex + 1) == goTo) {
            this.sliderBanner.slickGoTo(goTo);
          } else {
            if( this.sliderBanner) {
              this.sliderBanner.slickGoTo(0);
            }
          }
        }, this.state.imageTimer);

        //Check if next slide is video and play just before it showed
        if(this.checkMediaType(this.state.slides[goTo].media) == 'video') {
          setTimeout( () => {
            this.playVideo(goTo)
          }, this.state.imageTimer - this.state.transitionTimer);
        }
      } else {
          this.sliderBanner.slickGoTo(goTo)
      }
    })
  }

  isMobile() {
    var userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i)!= -1 ){
      return true;
    }

    return false; 
}

  playVideo(activeIndex) {
    if(this.isMobile()) return
    var videoId = 'video-' + this.getMediaName(this.state.slides[activeIndex].media);
    if(document.getElementById(videoId)) {
      document.getElementById(videoId).play()
    }
  }

  stopVideo(activeIndex) {
    if(this.isMobile()) return
    if(!this.state.slides[activeIndex]) return
    var videoId = 'video-' + this.getMediaName(this.state.slides[activeIndex].media);
    if(document.getElementById(videoId)) {
      this.setState({
        autoplaySpeed: (document.getElementById(videoId).duration * 1000)
      })
      document.getElementById(videoId).play()
      document.getElementById(videoId).volume = 0;
    } else {
      this.setState({
        autoplaySpeed: 10500
      })
    }
  }

  getMediaName(media) {
    return media.substring((media.lastIndexOf('/') + 1), media.lastIndexOf('.'))
  }

  checkMediaType(mediaSrc) {
    if(mediaSrc.indexOf('.jpg') > -1 || mediaSrc.indexOf('.jpeg') > -1 || mediaSrc.indexOf('.png') > -1 || mediaSrc.indexOf('.gif') > -1) {
      return 'image';
    } else if (mediaSrc.indexOf('.mp4') > -1) {
      return 'video';
    }
  }

  createSlide() {
    return(
      this.state.slides.map((slide, index) => {
        return(
          <div key={`banner-${index}`}>
            <Link to={this._createLink(slide)}>
              <HighlightMedia 
                onRef={ref => (this.childMedia = ref)} 
                media={ slide.media } mediaMedium={slide.mediaMed} mediaLow={slide.mediaLow}
                isAutoPlay={ this.isMobile() ? false : true } 
                hidePlayBtn={ !this.isMobile() ? true : false }/>
              <p className="legend">
                <span className='legend-title'>{ slide.title } </span>
                <span className='legend-subtitle'>{ slide.subTitle } </span>
              </p>
              <div className="bg"></div>
            </Link>
          </div>
        )
      })
    )
  }
  render() {

    var settings = {
      dots: true,
      arrows: true,
      infinite: this.state.slides.length > 1 ? true : false,
      speed: this.state.transitionTimer,
      autoplay: true,
      autoplaySpeed: this.state.autoplaySpeed,
      slidesToShow: 1,
      slidesToScroll: 1,
      verticalSwiping: false,
      touchThreshold: 40,
      centerMode: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      adaptiveHeight: false,
      variableWidth: false,
      nextArrow: <NextArrow className='vw-next-arrow'/>,
      prevArrow: <PrevArrow className='vw-prev-arrow'/>,
      beforeChange: (current, next) => {
        console.log('beforeChange', current, next)
        this.stopVideo(next);
      },
      afterChange: current => { 
        console.log('afterChange', current)
      }
    };

    return (      
      <div id='banner-container' className="banner-container">
         <Slider ref={slider => (this.sliderBanner = slider)} {...settings}>
          { this.createSlide() }
        </Slider>
      </div>
    );    
  }
}

export default Banner;
