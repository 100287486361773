
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import './Checklist.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import ApprovalCard from '../../components/ApprovalBackoffice/ApprovalCard/ApprovalCard';

import { getChecklist } from './Checklist.actions';
import { fetchValuesAuth, fetchValuesOptions} from '../../shared/http-request-util';
import ChecklistForm from './ChecklistForm/ChecklistForm';

let { listChecklistAPI } = require('../../config/constants');


class Checklist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loanList: props.list
    }
    this.update = false;
  }

  componentDidMount() {
    ReactGA.pageview('/checklist');
    this.setState({
      update: true
    })
    this.props.dispatch(fetchValuesAuth(listChecklistAPI(), 'GET', getChecklist));

  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps )

    let loanList  = []
    nextProps.list.map((item) => {
      //TODO filter by selected car ID
      let loanListObj = {};
      loanListObj['id']= item.id
      // console.log(item)
      loanListObj['title'] =  item.car_frotum.car.title
      loanListObj['color'] = item.car_frotum.color
      loanListObj['items'] = [{
        name: 'Placa:',
        value: item.car_frotum.placa
      },{
        name: 'Frota:',
        value: item.car_frotum.frota
      },{
        name: 'Chassi:',
        value: item.car_frotum.chassis
      }]
      loanList.push(loanListObj)
    })

    this.setState({
      loanList: loanList
    })
  }

  _goToDetailAction = (e) => {
    this.setState({
      selectedLoanId: e,
      goToDetails: true
    })
  }

  closeDetails = (e) => {
    this.setState({
      goToDetails: false
    }, () => {
      this.props.dispatch(fetchValuesAuth(listChecklistAPI(), 'GET', getChecklist));
    })
  }
  
  render() {
    if (this.state.update) {
      return (
        <div className="vw-checklist mb-4">
          <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / <a href='/home-comodato'>Solicitação de Veículos</a> " />
          <PageTitle title="Checklist: Entrega e Devolução" />

        {!this.state.goToDetails ? 
          <div>
            <PageSubTitle text="Selecione o veículo para fazer o checklist" />
              <div className='row'>
                {this.state.loanList.length > 0 ? 
                  this.state.loanList.map((item, index) => {
                    return (
                      <div key={`card-checklist-${index}`} className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                        <div className='mb-4'>
                          <ApprovalCard
                            isChecklistCard={true}
                            model={item.title}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            userName={item.isOwner ? item.user.name: item.otherCPF}
                            numberLoan={item.id}
                            carColor={item.color}
                            id={item.id}
                            carItems={item.items}
                            showCancelButton={true}
                            cancelButtonText={'Fazer Checklist'}
                            cancelAction={e => this._goToDetailAction(e)} />
                        </div>
                      </div>
                    )
                  })
                : 
                  <div className='col-12 col-sm-6 col-md-5 col-lg-4 checklistEmpty mt-4 mb-4 ml-4'>
                    <PageSubTitle text="Nenhum item encontrado" />
                  </div>
                }
              </div>
            </div>
        : 
          <ChecklistForm closeDetails={e => this.closeDetails(e)} loanId={this.state.selectedLoanId}/>
        }

        </div>
      );
    } else {
      return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  return {
    list: state.ChecklistReducer.list
  }
}

export default connect(mapStateToProps)(Checklist);
