import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import './TwitterComponentTags.css'; 

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

class TwitterComponentTags extends Component {

  constructor(props) {
    super(props)
  }    

  render() {
    return (      
      <div className="TwitterComponenttags">
        <h3 className="title">   
          <ResponsiveEllipsis
            text={this.props.tags}
            maxLine='2'
            ellipsis='...'
            trimRight
            basedOn='letters'
          />  
        </h3>   
      </div> 
    );    
  }
}

export default TwitterComponentTags;