import React, { Component } from 'react';

import './Loading.css'; 

class Loading extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (      
      <div className={`loading-container ${this.props.size}`}>
        Loading
      </div>
    );    
  }
}

export default Loading;