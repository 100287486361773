import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLatestPhotos } from './MediaGallery.actions';
import { getLatestAlbums } from './MediaGallery.actions';
import { getLatestVideos } from './MediaGallery.actions';
import { fetchValues } from '../../shared/http-request-util';


import Speak from '../../components/Speak/Speak';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageTitle from '../../components/PageTitle/PageTitle';
import HighlightMedia from '../../components/HighlightMedia/HighlightMedia';
import ShortImagem from '../../components/ShortImage/ShortImage';
import Button from '../../components/Button/Button';

import ReactGA from 'react-ga';


import './MediaGallery.css';

let { apiEndpoint } = require('../../config/constants');
let { galleryLatestPhotos } = require('../../config/constants');
let { galleryLatesAlbums } = require('../../config/constants');
let { galleryLatestVideos } = require('../../config/constants');


class MediaGallery extends Component {

  constructor(props) {
    super(props);
    this.qtdePagination = 9;
    this.positionPhotos = 1;
    this.positionAlbums = 1;
    this.positionVideos = 1;
    this.limitPhotos = this.qtdePagination;
    this.limitAlbums = this.qtdePagination;
    this.limitVideos = this.qtdePagination;
    
  }

  componentDidMount() {    
    ReactGA.pageview('/media-gallery');
    this.props.dispatch(fetchValues(apiEndpoint() + galleryLatestPhotos(this.positionPhotos, this.limitPhotos), getLatestPhotos));  
    this.props.dispatch(fetchValues(apiEndpoint() + galleryLatesAlbums(this.positionAlbums, this.limitAlbums), getLatestAlbums));
    this.props.dispatch(fetchValues(apiEndpoint() + galleryLatestVideos(this.positionVideos, this.limitVideos), getLatestVideos));
  }

  _callMore = (shortTitle) => {
    switch (shortTitle) {
      case 'Fotos': {
        this.positionPhotos += 1;
        this.props.dispatch(fetchValues(apiEndpoint() + galleryLatestPhotos(this.positionPhotos, this.limitPhotos), getLatestPhotos));  
        break;
      }
      case 'Álbuns': {
        this.positionAlbums += 1;
        this.props.dispatch(fetchValues(apiEndpoint() + galleryLatesAlbums(this.positionAlbums, this.limitAlbums), getLatestAlbums));
        break;
      }
      case 'Vídeos': {
        this.positionVideos += 1;
        this.props.dispatch(fetchValues(apiEndpoint() + galleryLatestVideos(this.positionVideos, this.limitVideos), getLatestVideos));
        break;
      }

      default:
        break;
    }
    
  }

  _renderButton(shortTitle, page, position) {
    if (page != position) {
      return (          
        <Button onClick={() => this._callMore(shortTitle) } text={`Ver mais ${shortTitle}`}/>
      )
    }
  }

  _renderImages(shortTitle, gallery) {
    if (shortTitle === "Álbuns") {
      return (
        gallery.map(function (item) {
          return (
            <div class="col-12 col-sm-6 col-md-4">
              <Link to={`/media-gallery/album/${item.id}`} target="_self">
                <HighlightMedia 
                media={ item.bucket_media[0].media } mediaMedium= { item.bucket_media[0].mediaMed } mediaLow = { item.bucket_media[0].mediaLow } 
                isAutoPlay={ false } download = { false } /> 
             </Link>
            </div>  
          )
        })
      );
    } else {
      return (
        gallery.map(function (item) {
          return (
            <div class="col-sm-6 col-md-4">              
              <HighlightMedia showPopOverButtons={true} 
              imageName={shortTitle}
              media={ item.media } mediaMedium= { item.mediaMed } mediaLow = { item.mediaLow } 
              mediaSize={ item.mediaSize } mediaSizeMedium= { item.mediaSizeMedium } mediaSizeLow = { item.mediaSizeLow } 
              preventPlay={ item.media.indexOf('.mp4') > -1 ? false : true }
              isAutoPlay={ false } />
            </div>  
          )
        })
      );
    }
  }

  _renderGallery(title, shortTitle, gallery, page, position) {
    if (gallery != undefined && gallery.length > 0) {
      return (
        <div className="vw-gallery-center">
          <div className="row car-margin">
            <div className="col-12">
            <PageTitle className="vw-font-20px vw-no-padding" title={title}/>
            </div>
          </div>
          <div class="row ">
            { this._renderImages(shortTitle, gallery) }
            <div className="w-100">&nbsp;</div>
            <br />
            {this._renderButton(shortTitle, page, position)}
          </div>
        </div>      
      )        
    } else {
      return (<div></div>);
    }

  }

  render() {
    return (      
      <div className="row media-center-gallery-1 ">
          <div className=" col-11 col-sm-12 media-center-gallery">
              <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/media-center'>Media Center</a>  / Galeria" />
              <PageTitle title="Galeria" />
          </div>
        <div className=" col-9 gallery-component">
          { this._renderGallery("Últimas fotos","Fotos", this.props.latestPhotos, this.props.pagePhotos, this.positionPhotos) }
          { this._renderGallery("Últimos videos","Vídeos", this.props.latestVideos, this.props.pageVideos, this.positionVideos) }
          { this._renderGallery("Últimos álbuns","Álbuns", this.props.latestAlbums, this.props.pageAlbums, this.positionAlbums) }
        </div> 
        <div className="speak-media">
        <Speak/>
        </div> 
      </div>
    );    
  } 
}

// Add this function:
function mapStateToProps(state) {
  //alert(JSON.stringify(state.MediaGalleryReducer));
  return {
    latestPhotos: state.MediaGalleryReducer.latestPhotos,
    pagePhotos: state.MediaGalleryReducer.pagePhotos,
    latestAlbums: state.MediaGalleryReducer.latestAlbums,
    pageAlbums: state.MediaGalleryReducer.pageAlbums,
    latestVideos: state.MediaGalleryReducer.latestVideos,
    pageVideos: state.MediaGalleryReducer.pageVideos

  };
}

export default connect(mapStateToProps)(MediaGallery);