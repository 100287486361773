
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import './LoanList.css';
import PageTitle from '../../components/PageTitle/PageTitle';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import RadioMultiline from '../../components/RadioMultiline/RadioMultiline';
import ApprovalCard from '../../components/ApprovalBackoffice/ApprovalCard/ApprovalCard';
import Button from '../../components/Button/Button';

import { getLoanList } from './LoanList.actions';
import { fetchValuesAuth, fetchValuesOptions} from '../../shared/http-request-util';
import { apiEndpoint } from '../../config/constants';
import AlertModal from '../../components/AlertModal/AlertModal';

let { approvalsAPI, cancelLoanAPI, listCancelLoanAPI } = require('../../config/constants');

class LoanList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loanList: '',
      checkedItems: {},
      filledInputs: [],
      fieldsError: [],
      loanToDelete: ''
    }
    this.update = false;
    
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.user = JSON.parse(localStorage.getItem('user-data')).user;
    } else {
      localStorage.setItem('has-redirect', '/comodato-lista');
      props.history.push('/login');
    }
  }

  componentDidMount() {
    ReactGA.pageview('/lista-comodato');
    this.update = true;
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.props.dispatch(fetchValuesAuth(listCancelLoanAPI(), 'GET', getLoanList));
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)

    this.setState({
      loanList: nextProps.list,
      isEmptyCancel: !nextProps.list.length > 0
    })
  }

  changeInput = (value, input) => {
    if (this.refs[input]) {
      if (this.refs[input].value.length > 0) {
        let inputsFilled = this.state.filledInputs
        if (inputsFilled.indexOf(input) < 0) {
          inputsFilled.push(input)
          this.setState({
            filledInputs: [...inputsFilled]
          })
        }
      } else {
        let inputsFilled = this.state.filledInputs
        inputsFilled.splice(this.state.filledInputs.indexOf(input), 1)
        this.setState({
          filledInputs: [...inputsFilled]
        })
      }
    }

    // return this.state.driver[input].length > 0 ? 'input-filled' : ''
  }


  _cancelAction = (e) => {
    this.setState({
      loanToDelete: e
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  _deleteDecision = (e) => {
    if(e) {
      this.setState({
        isCancelingLoan: true,
        showModal: false
      }, () => {
        this._sendCancel()
      })
    }
  }
  _sendCancel = () => {
    let body = {
      id: this.state.loanToDelete
    }
    console.log(body)
      let url = cancelLoanAPI()
      let options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        },
        body: JSON.stringify(body)
      };
      let _that = this
      function callback(response) {
       _that.setState({
          isCancelingLoan: false
        }, () => {
          _that.props.dispatch(fetchValuesAuth(listCancelLoanAPI(), 'GET', getLoanList));
        })
      }
      fetchValuesOptions(url, options, callback)
  }

  getCarName = (car) => {
    if(car.modelCar) return car.modelCar
    if(!car.modelCar) {
      if(car.car_frotum) {
        return car.car_frotum.car.title
      } else {
        return car.option1.title
      }
    }
  }

  render() {
    if (this.update) {
      return (
        <div className="vw-loanlist mb-4">
          <Breadcrumb text="<a href='/'>Newsroom</a> / <a href='/area-logada'>Área Logada</a> / <a href='/home-comodato'>Solicitação de veículos</a>" />
          <PageTitle title="Lista de veículos para cancelamento" />
          <PageSubTitle 
            text={`${this.state.isEmptyCancel ? 'Não temos solicitações para cancelamento' : 'Selecione qual solicitação abaixo você deseja cancelar' } `}
          />

          <div className='row'>
            {this.state.isEmptyCancel ? 
                <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'></div> 
              : 
              this.state.loanList.map((item) => {
                  return(
                    <div className='col-12 col-sm-6 col-md-5 col-lg-4 mb-4'>
                      <div className='mb-4'>
                        <ApprovalCard
                          modelCar={this.getCarName(item)}
                          title={this.getCarName(item)}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          userName={item.isOwner ? item.user.name: item.driverName}
                          numberLoan={item.id}
                          id={item.id}
                          status={item.status}
                          showCancelButton={true}
                          cancelAction={e => this._cancelAction(e)} />
                      </div>
                    </div>
                  )
                })
              }
          </div>

          <AlertModal
            isDecisionModal={ true }
            configModalCallback={e => this._deleteDecision(e)}
            title1='Confirmação'
            message={'Tem certeza que deseja cancelar permanentemente a solicitação selecionada ?'}
            showModal={this.state.showModal}
          />

          { this.state.isCancelingLoan ? <Loading /> : null }
        </div>
      );
    } else {
      return (<Loading />)
    }
  }
}

function mapStateToProps(state) {
  return {
    list: state.LoanListReducer.list
  }
}

export default connect(mapStateToProps)(LoanList);
