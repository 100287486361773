import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTecnologia } from './SubTecnologia.actions';
import { getSubTecnologia } from './SubTecnologia.actions';
import { fetchValues, fetchValuesToken } from '../../../shared/http-request-util';

//Components
import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Template from '../../../components/Template/Template';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import ReactGA from 'react-ga';

import './SubTecnologia.css';
import PopOverButton from '../../../components/PopOverButton/popOverButton';

let { apiEndpoint } = require('../../../config/constants');
let { publicUrlEndpoint } = require('../../../config/constants');

let { tecnologiaLevel1 } = require('../../../config/constants');
let { tecnologiaLevel2 } = require('../../../config/constants');

class SubTecnologia extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    this.update = true;
    //if user has been viewing an preview from CMS, will receive the user token here
    let token = (this.props.location.search.indexOf('token=') != -1) ? this.props.location.search.split('token=')[1] : false;
    if (this.props.match.params.subtechnology_id) {
      if (token) {
        this.props.dispatch(fetchValuesToken(apiEndpoint() + tecnologiaLevel2(this.props.match.params.technology_id, this.props.match.params.subtechnology_id), 'GET', getSubTecnologia, token));
      } else {
        this.props.dispatch(fetchValues(apiEndpoint() + tecnologiaLevel2(this.props.match.params.technology_id, this.props.match.params.subtechnology_id), getSubTecnologia));
      }
    } else {
      if (token) {
        this.props.dispatch(fetchValuesToken(apiEndpoint() + tecnologiaLevel1(this.props.match.params.technology_id), 'GET', getTecnologia, token));
      } else {
        this.props.dispatch(fetchValues(apiEndpoint() + tecnologiaLevel1(this.props.match.params.technology_id), getTecnologia));
      }
    }
    
  }   

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {      
      ReactGA.pageview('/technology: ' + this.props.technology.title);
      let techPDFURL = this.props.match.params.technology_id ? '/' + this.props.match.params.technology_id : ''
      return (            
        <div className="vw-tecnologia-level1">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/technology'>Tecnologia</a> / ${this.props.technology.title} `} />
              <PageTitle title={this.props.technology.title}/>
              <HighlightMedia showPopOverButtons={ true } 
              imageName={this.props.technology.title}
              media={this.props.technology.media} mediaMedium={ this.props.technology.mediaMed } mediaLow={ this.props.technology.mediaLow } 
              mediaSize={this.props.technology.mediaSize} mediaSizeMedium= { this.props.technology.mediaSizeMedium } mediaSizeLow = {this.props.technology.mediaSizeLow}
              GAText={'/technology: ' + this.props.technology.title}
              isAutoPlay={true}/>
            </div>
            <div className="row w-100 justify-content-end newsButtons">
              
              <div id="shareContainer" className="col-2 col-lg-1 align-end buttonContainer">
                <PopOverButton 
                    popOverType="social"
                    containerName="shareContainer"
                    twitterLink={"https://twitter.com/intent/tweet?text=" + `${this.props.technology.title} ` + ` ${publicUrlEndpoint()}` + `/technology/` + `${this.props.match.params.technology_id}`}
                    twitterText={this.props.technology.title}
                    facebookLink={"http://www.facebook.com/sharer.php?u=" + `${publicUrlEndpoint()}` + `/technology/` + `${this.props.match.params.technology_id}`}
                  />
              </div>
            </div>
            <div className="col-12">            
              <PageSubTitle text={this.props.technology.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">            
              <TextBody text={this._convertDescription(this.props.technology.description)}/>
            </div>
            <Template GAText={ this.props.technology.title } templates={this.props.technology.templates} />
            <NewsComponent isVertical={true} textEnum="Technologies" idLevel1={ this.props.match.params.technology_id } idLevel2={ this.props.match.params.subtechnology_id }/>   
  
            <Gallery title='Fotos e Vídeos' gallery={this.props.gallery}/>    
            <Speak/>           
          </div> 
        </div>
      );       
    } else {
      return (<Loading></Loading>);
    }
   
  }
}
 // Add this function:
function mapStateToProps(state) {
  return {
    technology: state.SubTecnologiaReducer.technology,
    news: state.SubTecnologiaReducer.news,
    gallery: state.SubTecnologiaReducer.gallery
  };
}

export default connect(mapStateToProps)(SubTecnologia);
