import React, { Component } from 'react';
import './LoanRequestStep1.css';
import { Card, CardBody, CardTitle } from 'reactstrap';
import TextBody from '../../../components/TextBody/TextBody';
import InputMask from 'react-input-mask';
import { isValid as isValidCpf } from "@fnando/cpf";
import { fetchValuesOptions } from '../../../shared/http-request-util';
import ReactGA from 'react-ga';
import VWFileUpload from '../../../components/VWFileUpload/VWFileUpload';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import AlertModal from '../../../components/AlertModal/AlertModal';

let { getByCPFAPI } = require('../../../config/constants');

class LoanRequestStep1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      update: false,
      fieldsError: [],
      driver: {
        selectedDriver: '',
        name: '',
        otherCPF: '',
        otherCNH: '',
        otherRG : '',
        imageCNH: '',
        isValidate: false
      },
      userData: {
        address: props.userData.address,
        email: props.userData.email,
        phone: props.userData.phone
      },
      filesItems: [],
      imageDetailText: 'Carregando ',
      isUpdatingDriver: false
    }

    let savedUser = window.localStorage.getItem('user-data') ? JSON.parse(window.localStorage.getItem('user-data')) : {}
    this.bucket = 'https://cdn.na.vwapps.io/assets/a2acc312-5aaa-41c7-bad8-868ed07cfe1d';
    if(savedUser.user.cnhPicture) this.bucket = savedUser.user.cnhPicture.substring(0, savedUser.user.cnhPicture.lastIndexOf('/'))
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        update: true
      });
    }, 200);
  }

  isValidCNH = () => {
    let cnh = this.state.driver.otherCNH;
    var find = '_';
    var re = new RegExp(find, 'g');
    cnh = cnh.replace(re, '');
    return cnh.length > 10 ? true : false
  }

  _goRegistry = (e) => {
    this.props.history.push('/registry')
  }

  validateForm = () => {

    if (!this.state.driver.selectedDriver) {
      this.state.driver.isValidate = false;
    }
    
    if (this.isValidCNH() && isValidCpf(this.state.driver.otherCPF) && this.state.filesItems.length > 0 && this.state.filesItems[0].name != '') {
      this.state.driver.isValidate = true;
    } else {
      this.state.driver.isValidate = false;
    }
    if(this.state.driver.otherRG == "" || this.state.driver.otherRG == null){
      this.state.driver.isValidate = false;
    }
  }

  _getUserByCPF = () => {
    this.setState({
      isLoading: true
    }, () => {
      let url = `${getByCPFAPI()}/${this.state.driver.otherCPF}`
      let options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': JSON.parse(localStorage.getItem('user-data')).token
        }
      };
      let _that = this
      function callback(response) {
        console.log('IS VALID CPF? ---->', response)
        _that.state.isLoading = false
        if(response) {
          if(response.name) {
            _that.state.driver.name = response.name
            if(response.cnh)  _that.state.driver.otherCNH = response.cnh
            if(response.cnhPicture)  _that.state.driver.imageCNH = response.cnhPicture
            if(response.rg){
              _that.state.driver.otherRG = response.rg;
            }else{
              _that.state.driver.otherRG = "";
            }
           
          } else {
            _that.openAlertInvalidUser()
            _that.state.isInvalidUser = true;
            _that.state.driver.otherRG = "";
          }
        }
        _that.setState({}) 
      }
      console.log(url, options)
      fetchValuesOptions(url, options, callback)
    })
  }
  
  openAlertInvalidUser = (e) => {
    this.state.modalTitle = `Agente não encontrado`
    this.state.modalMessage = `Para solicitar um empréstimo de veículo é necessário ter <b>cadastrado RG e CPF no seu perfil de usuário </b>,
    caso já esteja cadastrado siga as instruções abaixo.
    <br><br>Por favor, entre em contato pelo email <span class='small'>imprensa.volkswagen@volkswagen.com.br</span>. 
    </br></br>Obrigado. 
    </br><span class='small'>Equipe de Assuntos Corporativos e Relações com a Imprensa da Volkswagen Brasil</span>`
    this.state.showModal = true
    this.setState({}) 
  }

  selectDriver = (e) => {
    this.setState({
      isUpdatingDriver: true
    }, () => {
      this.state.driver.selectedDriver = e
      let user = JSON.parse(localStorage.getItem('user-data')).user;
      if (this.state.driver.selectedDriver === 'me') {
        this.state.driver.otherCPF = (user.cpf ? user.cpf : '');
        this.state.driver.otherCNH = (user.cnh ? user.cnh : '');
        this.state.driver.otherRG =  (user.rg ? user.rg : '');
        this.state.filesSaved = '';
        this.state.driver.name = (user.name ? user.name : '');
        if(user.cnhPicture) {
          this.state.driver['imageCNH'] = user.cnhPicture 
          this.state.filesSaved = user.cnhPicture
          this.state.imageDetailText = 'Enviando Imagem '
          this.state.isFileUploading = true
        }
       
      } else {
        this.state.driver.otherCPF = '';
        this.state.driver.otherCNH = '';
        this.state.driver.otherRG = '';
        this.state.filesSaved = ''
        this.state.imageDetailText = ''
        this.state.driver['imageCNH'] = ''
        this.state.driver.name = ''
        this.state.isFileUploading = false
      }
      
      this.state.isInvalidUser = false
      this.validateForm();
      this.setState({}, () => {
        this.props.updateDriverValues(this.state.driver)
        this.setState({
          isUpdatingDriver: false
        })
      })
    })
  }

  inputIsFilled = (input) => {
    this.validateForm();
    if(input == 'imageCNH' && this.state.isLoadingImage && this.state.isFileUploading) return 'input-filled'
    return this.state.driver[input].length > 0 ? 'input-filled' : ''
  }

  changeInput = (e, input) => {
    this.state.driver[input] = e
    if(input == 'otherCPF') if(!isValidCpf(e)) this.state.driver.name = ''; this.state.isInvalidUser = false
    this.validateForm();
    this.setState({}, () => {
      let error = []
      if(this.state.driver.otherCPF.replace(/_|-|\./g, '').length > 10) {
        if(!isValidCpf(this.state.driver.otherCPF))  {
          error.push(input)
          this.setState({
            fieldsError: [...error]
          })
        } else {
          this.state.fieldsError.splice(this.state.fieldsError.indexOf(input), 1)
          this.setState({})
        }
      }
      if(isValidCpf(this.state.driver.otherCPF) && this.state.driver.selectedDriver != 'me') {
        //CALL API
        console.log('call api to validate CPF')
        this._getUserByCPF()
      } else {
        if(this.state.isValidate) this.props.updateDriverValues(this.state.driver)
      }
    })
  }

  isFileUploading = (e) => {
    this.setState({
      isFileUploading: true
    })
  }

  _updateFileItems = (fileItems, savedFile, isLoaded) => {
    if(!this.state.isFileUploading && !isLoaded) return
    let savedImageCNH
    if(savedFile) savedImageCNH = JSON.parse(savedFile).bucket + JSON.parse(savedFile).media
    console.log(fileItems[0], savedImageCNH)
    // let savedUser = window.localStorage.getItem('user') ? window.localStorage.getItem('user') : {}
    if(!fileItems) return
    this.state.driver['imageCNH'] = savedImageCNH ? savedImageCNH : this.bucket + '/' + fileItems[0].name
    if(fileItems[0].size != '') {
      console.log('selecionad')
      this.state.imageDetailText = 'Imagem selecionada '
    } else {
      console.log('enviando')
      this.state.imageDetailText = 'Enviando imagem '
      this.state.isLoadingImage = true 
    }
    this.setState({
      filesItems: fileItems
    }, () => {
      console.log(this.state.driver.imageCNH)
      this.props.updateDriverValues(this.state.driver)
    })
  }


  _alertDecision = (e) => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  render() {
    return (
      <div className='container-loanStep2'>
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Confirmar Informações</CardTitle>
              </CardBody>
              <CardBody>
                <div className="row">
                  <div className='col-12 col-md-4 mt-2 mb-2 mt-md-4 mb-md-4'>
                    <p className='card-value'>Endereço: <span className='card-txt-value'>{this.state.userData.address}</span></p>
                  </div>
                  <div className='col-12 col-md-4 mt-2 mb-2 mt-md-4 mb-md-4'>
                    <p className='card-value text-md-center'>Telefone: <span className='card-txt-value'>{this.state.userData.phone}</span></p>
                  </div>
                  <div className='col-12 col-md-4 mt-2 mb-2 mt-md-4 mb-md-4'>
                    <p className='card-value text-md-right'>E-mail: <span className='card-txt-value'>{this.state.userData.email}</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className='col align-self-end mt-4'>
                    <button onClick={e => this._goRegistry(e)} className="btnAtualizar">Atualizar <span>  -></span></button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className='col-12 mt-4 mb-4'>
            <TextBody text="Preencher as informações do condutor para prosseguir com a solitação de veículo:" />
          </div>
        </div>

        <div className="row">
          <div className='col-12 mt-4 mb-4'>
            <div className='row'>
              <div className="col-6 col-md-3">
                <div className="form-check">
                  <input onChange={e => this.selectDriver(e.target.value)} className="inputRadioForm" type="radio" id="driver-selection-me" ref="driver-selection-me" name="driver-selection" value="me" />
                  <label className="form-check-label" htmlFor='driver-selection-me' >
                    Serei o condutor
                    </label>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="form-check">
                  <input onChange={e => this.selectDriver(e.target.value)} className="inputRadioForm" type="radio" id="driver-selection-other" ref="driver-selection-other" name="driver-selection" value="other" />
                  <label className=" form-check-label" htmlFor='driver-selection-other' >
                    Outro condutor
                    </label>
                </div>
              </div>
            </div>
            <div className={`row mt-4 vw-floating-label ${!this.state.driver.selectedDriver ? 'vw-display-none ' : ''}`}>
              
              <div className="col-12 mb-4 mb-md-0 col-md-4 margin-bottom15rem" >
                <div className={`form-label-group ${(this.state.fieldsError.indexOf('otherCPF') > -1 ? ' input-error ' : ' ')}`}>
                  <InputMask
                    className={`form-control ${this.inputIsFilled('otherCPF')}`}
                    placeholder='CPF do condutor'
                    onChange={e => this.changeInput(e.target.value, 'otherCPF')}
                    value={this.state.driver.otherCPF}
                    mask={"***.***.***-**"}
                    disabled={(this.state.driver.selectedDriver === 'me')}
                    type="text" id="other-driver-cpf" ref="other-driver-cpf">
                  </InputMask>
                  <label htmlFor='other-driver-cpf'>
                    CPF do condutor
                    </label>
                </div>
                { this.state.driver.name.length > 1 &&  this.state.driver.otherCPF.length > 10 ? 
                  <p className='valid-cpf-label mt-2 ml-2'>CPF Válido: <span>{this.state.driver.name}</span></p>
                : null }
                 { this.state.driver.name.length > 1 &&  this.state.driver.otherCPF.length  == 0 ? 
                  <p className='valid-cpf-label invalid-cpf-label mt-2 ml-2'>CPF não cadastrado. <a onClick={e => this.openAlertInvalidUser()}>Ver Detalhes</a></p>
                : null }
                { this.state.isInvalidUser ? 
                  <p className='valid-cpf-label invalid-cpf-label mt-2 ml-2'>CPF não cadastrado. <a onClick={e => this.openAlertInvalidUser()}>Ver Detalhes</a></p>
                : null }
              </div>

              <div className="col-12 mb-4 mb-md-0 col-md-4 margin-bottom15rem" >
                <div className={`form-label-group ${(this.state.fieldsError.indexOf('otherRG') > -1 ? ' input-error ' : ' ')}`}>
                  <InputMask
                    className={`form-control ${this.inputIsFilled('otherRG')}`}
                    placeholder='RG do condutor'
                    onChange={e => this.changeInput(e.target.value, 'otherRG')}
                    value={this.state.driver.otherRG}
                    disabled={true}
                    type="text" id="other-driver-rg" ref="other-driver-rg">
                  </InputMask>
                  <label htmlFor='other-driver-rg'>
                    RG do condutor
                    </label>
                </div>
                { this.state.isInvalidUser ? 
                  <p className='valid-cpf-label invalid-cpf-label mt-2 ml-2'>RG não cadastrado. <a onClick={e => this.openAlertInvalidUser()}>Ver Detalhes</a></p>
                : null }
                 { this.state.driver.selectedDriver === 'me'  && this.state.driver.otherRG.length == 0? 
                  <p className='valid-cpf-label invalid-cpf-label mt-2 ml-2'>RG não cadastrado. <a onClick={e => this.openAlertInvalidUser()}>Ver Detalhes</a></p>
                : null }
                  { !this.state.isInvalidUser && this.state.driver.otherRG.length == 0 && this.state.driver.otherCNH > 0  && this.state.driver.selectedDriver === 'other'? 
                  <p className='valid-cpf-label invalid-cpf-label mt-2 ml-2'>RG não cadastrado. <a onClick={e => this.openAlertInvalidUser()}>Ver Detalhes</a></p>
                : null }
              </div>


              <div className="col-12 col-md-4 margin-bottom15rem">
                <div className="form-label-group">
                  <InputMask
                    placeholder='Número da CNH'
                    className={`form-control ${this.inputIsFilled('otherCNH')}`}
                    onChange={e => this.changeInput(e.target.value, 'otherCNH')}
                    value={this.state.driver.otherCNH}
                    type="text" id="other-driver-cnh" ref="other-driver-cnh"
                    mask={"***********"}>
                  </InputMask>
                  {/* <input placeholder='Número da CNH' className="form-control" type="text" id="other-driver-cnh" ref="other-driver-cnh" /> */}
                  <label htmlFor='other-driver-cnh'>
                    Número da CNH
                    </label>
                </div>
              </div>

              <div className={`margin-bottom15rem col-12 col-md-4 mt-md-0 vw-fileuploader ${this.inputIsFilled('imageCNH')} ${(this.state.fieldsError.indexOf('imageCNH') > -1 ? ' input-error ' : ' ')}`}>
              {!this.state.isUpdatingDriver ?
                <div>
                  <VWFileUpload 
                    filesSaved={this.state.filesSaved} 
                    allowMultiple={false} uploadStyleType={'input-field'} 
                    uploadText={'Upload para imagem da CNH'} 
                    imageDetailText={this.state.imageDetailText}
                    updateFileItems={(e, savedFileItem, isLoaded) => this._updateFileItems(e, savedFileItem, isLoaded)}
                    isFileUploading={e => this.isFileUploading(e)}
                    allowFileUploading={this.state.isFileUploading}/>
                  <p className='label-required'>* Obrigatório enviar a imagem da CNH</p>
                  </div>
                : null }
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoading ? <Loading/> : null }

        
          <AlertModal
            configModalCallback={e => this._alertDecision(e)}
            title1={ this.state.modalTitle}
            messageHTML={ this.state.modalMessage}
            showModal={this.state.showModal}
            addClassName={'transformation-20'}
          />

      </div>
    )
  }
}

export default withRouter(LoanRequestStep1);