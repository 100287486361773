import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCar } from './Car.actions';
import { fetchValues, fetchValuesToken } from '../../../shared/http-request-util';

import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import TextBody from '../../../components/TextBody/TextBody';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Speak from '../../../components/Speak/Speak';
import NewsComponent from '../../../components/NewsComponent/NewsComponent';
import Gallery from '../../../components/Gallery/Gallery';
import Loading from '../../../components/Loading/Loading';
import HighlightMedia from '../../../components/HighlightMedia/HighlightMedia';
import ReactGA from 'react-ga';

import './Car.css'; 
import ShortImage from '../../../components/ShortImage/ShortImage';


let { apiEndpoint } = require('../../../config/constants');
let { carDetailAPI } = require('../../../config/constants');


class Car extends Component {

  constructor(props) {
    super(props);
    this.update = false;
  }

  componentDidMount() {    
    
    this.update = true;
    //if user has been viewing an preview from CMS, will receive the user token here
    if(this.props.location.search.indexOf('token=') != -1) {
      let token = this.props.location.search.split('token=')[1]
      this.props.dispatch(fetchValuesToken(apiEndpoint() + carDetailAPI() + this.props.match.params.car_id, 'GET', getCar, token));
    } else {
      this.props.dispatch(fetchValues(apiEndpoint() + carDetailAPI() + this.props.match.params.car_id, getCar));
    }
  }  

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  render() {
    if (this.update) {
      ReactGA.pageview('/car-model: ' + this.props.car.title);
      return (
        <div className="vw-internal-car">
          <div className="row">
            <div className="col-12">
              <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/cars/models'>Carros</a> / ${this.props.car.title}`} />
              <PageTitle title={this.props.car.title}/>
              <HighlightMedia showPopOverButtons={ true } 
                imageName={this.props.car.title}
                media={this.props.car.media} mediaMedium={this.props.car.mediaMed} mediaLow={this.props.car.mediaLow} 
                mediaSize={this.props.car.mediaSize} mediaSizeMedium= { this.props.car.mediaSizeMedium } mediaSizeLow = {this.props.car.mediaSizeLow} 
                GAText={this.props.car.title}
                isAutoPlay={true} />
            </div>
            <div className="col-12">            
              <PageSubTitle text={this.props.car.subtitle}/>
            </div>
            <div className="w-100"></div>
            <div className="col-12">            
              <TextBody text={this._convertDescription(this.props.car.description)}/>
            </div>
            <br></br>
            <NewsComponent isVertical={true} textEnum="Carros" idLevel1={ this.props.car.id }/>   
            
            <Gallery title='Fotos e Vídeos' gallery={this.props.gallery} />
       
            <Speak/>           
          </div> 
        </div>
      );       
    } else {
      return (
        <Loading />
      )
    }   
  }
}

// Add this function:
function mapStateToProps(state) {
  return {
    car: state.CarReducer.car,
    news: state.CarReducer.news,
    gallery: state.CarReducer.gallery
  };
}

export default connect(mapStateToProps)(Car);

