const initialState = {
  contacts: []
}

const ContactsReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'CONTATOS_HOME':
      state = initialState;
      return {
        contacts: [...state.contacts, ...action.contacts]
      }
    default:
      return state;
  }
};

export default ContactsReducer;