import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle/PageSubTitle';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';




import './PrivacyPolicy.css';

const htmlContent = `<div><p> <strong>POL&Iacute;TICA DE PRIVACIDADE</strong> </p>
<p> <strong>VOLKSWAGEN</strong> </p>
<p>A presente  <strong>Pol&iacute;tica de Privacidade</strong> tem por finalidade demonstrar o compromisso da</p>
<table>
<tbody style="background-color:#fce19d">
<tr>
<td width="642">
<p> <strong>VOLKSWAGEN DO BRASIL INDUSTRIA DE VE&Iacute;CULOS AUTOMOTORES LTDA.</strong> , pessoa jur&iacute;dica de direito privado, inscrita no CNPJ sob o n&ordm;.59.104.422/0001-50, com sede na Estrada Marginal da Via Anchieta, KM 23,5 - Demarchi - CEP 09823-990 - S&atilde;o Bernardo do Campo/SP</p>
</td>
</tr>
</tbody>
</table>
<p>com a privacidade e a prote&ccedil;&atilde;o dos dados pessoais coletados de seus  <strong>USU&Aacute;RIOS,</strong> estabelecendo as regras sobre a coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e elimina&ccedil;&atilde;o dos dados coletados dentro do escopo dos servi&ccedil;os e funcionalidades da  <strong>VW</strong> , de acordo com as leis em vigor.</p>
<p>Como condi&ccedil;&atilde;o para acesso e uso das funcionalidades exclusivas da  <strong>VW</strong> , <u>o  <strong>USU&Aacute;RIO</strong>  declara ser maior de 18 (dezoito) anos e que fez a leitura completa e atenta das regras deste documento e dos  <strong>Termos de Uso</strong> , estando plenamente ciente conferindo assim sua livre e expressa concord&acirc;ncia com os termos aqui estipulados</u>. Caso n&atilde;o esteja de acordo com estas Diretivas, dever&aacute; descontinuar o seu acesso.</p>
<p>&nbsp;</p>
<ol>
<li> <strong>DADOS COLETADOS, FORMA E FINALIDADE DA COLETA</strong> 
<ul>
<li>1.1. Os dados s&atilde;o coletados quando o <strong>USU&Aacute;RIO</strong> insere ou submete voluntariamente ao acessar e interagir com as funcionalidades disponibilizadas neste site, que inclui:</li>
</ul>
</li>
</ol>
<p>&nbsp;</p>
<table width="637">
<thead>
<tr>
<td width="144">
<p> <strong>TIPOS DE DADOS</strong> </p>
</td>
<td width="199">
<p> <strong>DADOS PESSOAIS</strong> </p>
</td>
<td width="295">
<p> <strong>FINALIDADE DE USO DOS DADOS</strong> </p>
</td>
</tr>
</thead>
<tbody>
<tr>
<td rowspan="7" width="144" style="border-bottom : 0px">
<p> <strong>&nbsp;DADOS CADASTRAIS</strong> </p>
</td>
<td rowspan="9" width="199">
<p>Nome completo</p>
<p>E-mail</p>
<p>CPF</p>
<p>Data de nascimento</p>
<p>N&uacute;mero do telefone</p>
<p>Login</p>
<p>Endere&ccedil;o</p>
<p>Senha de acesso</p>
<p>N&uacute;mero da Placa de Autom&oacute;vel</p>
<p>CHASSI de Autom&oacute;vel</p>
</td>
<td width="295">
<p>Identificar o  <strong>USU&Aacute;RIO;</strong> </p>
</td>
</tr>
<tr>
<td width="295">
<p>Portabilidade dos dados cadastrais para outro Controlador do mesmo ramo de nossa atua&ccedil;&atilde;o, caso solicitado pelo  <strong>USU&Aacute;RIO</strong> .</p>
</td>
</tr>
<tr>
<td width="295">
<p>Cumprir as obriga&ccedil;&otilde;es decorrentes do uso dos servi&ccedil;os da  <strong>VOLKSWAGEN;</strong> </p>
</td>
</tr>
<tr>
<td width="295">
<p>Administrar e prestar os servi&ccedil;os obtidos por meio da  <strong>VW</strong> ;</p>
</td>
</tr>
<tr>
<td width="295">
<p>Informar sobre novidades, funcionalidades, conte&uacute;dos, not&iacute;cias e demais eventos relevantes para a manuten&ccedil;&atilde;o do relacionamento com o  <strong>USU&Aacute;RIO;</strong> </p>
</td>
</tr>
<tr>
<td width="295">
<p>Promover nossos produtos e servi&ccedil;os;</p>
</td>
</tr>
<tr>
<td width="295">
<p>Cumprimento de obriga&ccedil;&otilde;es legais e regulat&oacute;rias;</p>
</td>
</tr>
<tr>
<td width="144" style="border:0px">
<p> <strong>&nbsp;</strong> </p>
</td>
<td width="295">
<p>Atendimento via Formul&aacute;rio Eletr&ocirc;nico (E-mail);</p>
</td>
</tr>
<tr>
<td width="144" style="border:0px">
<p> <strong>&nbsp;</strong> </p>
</td>
<td width="295">
<p>Atendimento via Chat Box;</p>
</td>
</tr>
<tr>
<td rowspan="4" width="144">
<p> <strong>DADOS DE IDENTIFICA&Ccedil;&Atilde;O DIGITAL</strong> </p>
</td>
<td rowspan="4" width="199">
<p>Endere&ccedil;o IP e Porta L&oacute;gica de Origem,</p>
<p>Registros de intera&ccedil;&otilde;es com este website,</p>
<p>Telas acessadas, dispositivo (vers&atilde;o do sistema operacional, Geolocaliza&ccedil;&atilde;o,</p>
<p>Aplicativos instalados, se necess&aacute;rio),</p>
<p><em>Session ID</em></p>
<p><em>Cookies</em></p>
</td>
<td width="295">
<p>Cumprir obriga&ccedil;&atilde;o estabelecida pelo Marco Civil da Internet - Lei 12.965/2014;</p>
</td>
</tr>
<tr>
<td width="295">
<p>Identificar o  <strong>USU&Aacute;RIO;</strong> </p>
</td>
</tr>
<tr>
<td width="295">
<p>Administrar, prestar os servi&ccedil;os obtidos por meio da  <strong>VW</strong> ;</p>
</td>
</tr>
<tr>
<td width="295">
<p>Cumprimento de obriga&ccedil;&otilde;es legais e regulat&oacute;rias;</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ul>
<li>1.2. N&atilde;o somos respons&aacute;veis pela precis&atilde;o, veracidade ou falta dela nas informa&ccedil;&otilde;es que o  <strong>USU&Aacute;RIO</strong> prestar &agrave;  <strong>VOLKSWAGEN</strong> ou pela sua desatualiza&ccedil;&atilde;o, quando &eacute; de sua responsabilidade prest&aacute;-las com exatid&atilde;o ou atualiz&aacute;-las.</li>
<li>1.3. O consentimento que o  <strong>USU&Aacute;RIO</strong> nos fornece para as finalidades de uso dos dados &eacute; coletado de forma individual, clara, espec&iacute;fica e leg&iacute;tima.</li>
<li>1.4. Por meio do canal de atendimento FALE CONOSCO &lt; <a href="https://www.vw.com.br/pt/fale-conosco.html">https://www.vw.com.br/pt/fale-conosco.html</a>&gt;o  <strong>USU&Aacute;RIO</strong> poder&aacute; alterar suas concess&otilde;es de consentimento para tratamento de seus dados, conceder novas permiss&otilde;es ou retirar seu consentimento para as permiss&otilde;es atuais, sendo avisado das consequ&ecirc;ncias que a retirada de consentimento poder&aacute; causar.</li>
<li>1.5. Os dados coletados e as atividades registradas tamb&eacute;m poder&atilde;o ser compartilhados:</li>
</ul>
<ul style="margin-left: 5px;">
<li>a) Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial;</li>
<li>b) De forma autom&aacute;tica em caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o.
</ul>
<ul>
<li>1.6. A base de dados formada por meio da coleta de dados na  <strong>VW</strong> &eacute; de nossa propriedade e responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos dos nossos neg&oacute;cios e descritos nesta  <strong>Pol&iacute;tica de Privacidade</strong> .
<ul style="margin-left: 5px;">
<li>1.6.1. O <strong>USU&Aacute;RIO</strong> &eacute; correspons&aacute;vel pelo sigilo de seus dados pessoais. O compartilhamento de senhas e dados de acesso viola esta  <strong>Pol&iacute;tica de Privacidade</strong> e os  <strong>Termos de Uso</strong> da <strong>VW</strong> .</li>
<li>1.6.2. A <strong>VOLKSWAGEN </strong> n&atilde;o envia e-mail solicitando que o  <strong>USU&Aacute;RIO </strong> encaminhe seus dados cadastrais.</li>
</ul>
</li>
<li>1.7. Internamente, os dados de que coletamos s&atilde;o acessados somente por profissionais devidamente autorizados, respeitando os princ&iacute;pios de proporcionalidade, necessidade e relev&acirc;ncia para os objetivos da  <strong>VW</strong> , al&eacute;m do compromisso de confidencialidade e preserva&ccedil;&atilde;o da privacidade nos termos desta  <strong>Pol&iacute;tica de Privacidade</strong> .</li>
</ul>
</li>

<p>&nbsp;</p>
<ol start="2">
<li> <strong>COMO ARMAZENAMOS OS DADOS E REGISTROS</strong> 
<ul>
<li>2.1. Os dados coletados e os registros de atividades ser&atilde;o armazenados em ambiente seguro e controlado pelo prazo m&iacute;nimo estipulado conforme a tabela abaixo:</li>
</ul>
</li>
</ol>
<table width="643">
<thead>
<tr>
<td width="259">
<p> <strong>DADOS PESSOAIS</strong> </p>
</td>
<td width="192">
<p> <strong>PRAZO DE ARMAZENAMENTO</strong> </p>
</td>
<td width="192">
<p> <strong>FUNDAMENTO LEGAL</strong> </p>
</td>
</tr>
</thead>
<tbody>
<tr>
<td width="259">
<p> <strong>Dados cadastrais</strong> </p>
</td>
<td width="192">
<p>5 anos ap&oacute;s o t&eacute;rmino da rela&ccedil;&atilde;o</p>
</td>
<td width="192">
<p>Art. 12 e 34 do C&oacute;digo de Defesa do Consumidor</p>
</td>
</tr>
<tr>
<td width="259">
<p> <strong>Dados de identifica&ccedil;&atilde;o digital</strong> </p>
</td>
<td width="192">
<p>6 meses</p>
</td>
<td width="192">
<p>Art. 15, Marco Civil da Internet</p>
</td>
</tr>
</tbody>
</table>
<ul>
<li>2.2. Caso haja solicita&ccedil;&atilde;o do  <strong>USU&Aacute;RIO</strong> , os dados poder&atilde;o ser apagados antes desse prazo.No entanto, pode ocorrer de os dados precisarem ser mantidos por per&iacute;odo superior, por motivo de lei, ordem judicial, preven&ccedil;&atilde;o &agrave; fraude (art. 11, II, &ldquo;a&rdquo; da Lei Geral de Prote&ccedil;&atilde;o de Dados &ldquo;LGPD&rdquo;, Lei n&ordm; 13.709/2018), prote&ccedil;&atilde;o ao cr&eacute;dito (art. 7&ordm;, X, LGPD) e outros interesses leg&iacute;timos, em conformidade com o artigo 10 da LGPD. Findo o prazo e a necessidade legal, ser&atilde;o exclu&iacute;dos com uso de m&eacute;todos de descarte seguro, ou utilizados de forma anonimizada para fins estat&iacute;sticos.</li>
</ul>
<table>
<thead>
<tr>
<td width="642">
<p>2.3.&nbsp;&nbsp;&nbsp; Os dados coletados ser&atilde;o armazenados em nossos servidores localizados nos Estados Unidos, bem como em ambiente de uso de recursos ou servidores na nuvem <em>(cloud computing)</em>, o que enseja, neste &uacute;ltimo caso, transfer&ecirc;ncia ou processamento dos dados fora do Brasil, nos Estados Unidos.</p>
</td>
</tr>
</thead>
</table>
<p>&nbsp;</p>
<ol start="3">
<li> <strong>EXIBI&Ccedil;&Atilde;O, RETIFICA&Ccedil;&Atilde;O, LIMITA&Ccedil;&Atilde;O, OPOSI&Ccedil;&Atilde;O E EXCLUS&Atilde;O DE DADOS</strong> 
<ul>
<li>3.1. O <strong>USU&Aacute;RIO</strong> pode solicitar a exibi&ccedil;&atilde;o ou retifica&ccedil;&atilde;o de seus dados pessoais, por meio dos canais apontados a seguir:
<ul style="margin-left: 5px;">
<li>3.1.1. <strong>FALE CONOSCO: </strong> acess&iacute;vel atrav&eacute;s do presente  <strong><em>link</em></strong> https://www.vw.com.br/pt/fale-conosco.html</li>
<li>3.1.2. <strong>CHAT</strong> : acess&iacute;vel atrav&eacute;s do presente  <strong><em>link</em></strong> http://volkswagen.nccsesrver.com.br/Volkswagen/Chat6/Cliente/frm_login.aspx?IdArea-1&amp;idioma-0&amp;sel-HomePage</li>
<li>3.1.3. <strong>FORMUL&Aacute;RIO ELETR&Ocirc;NICO</strong> :acess&iacute;vel atrav&eacute;s do presente  <strong><em>link</em></strong> https://www.vw.com.br/pt/fale-conosco.html</li>
<li>3.1.4. <strong>CALL CENTER</strong> : atrav&eacute;s do telefone 0800 019 5775;</li>
<li>3.1.5. <strong>CORRESPOND&Ecirc;NCIA</strong> : Via Anchieta KM 23,5 &ndash; S&atilde;o Bernardo do Campo &ndash; SP &ndash; CEP 09823-901 CPI 1048</li>
</ul>
</li>
<li>3.2. Pela mesma ferramenta, o  <strong>USU&Aacute;RIO</strong> poder&aacute; tamb&eacute;m:</li>
</ul>
</li>
<ul style="margin-left: 5px;">
<li>a) requerer a limita&ccedil;&atilde;o do uso de seus dados pessoais;</li>
<li>b) manifestar sua oposi&ccedil;&atilde;o ao uso de seus dados pessoais;</li>
<li>c) solicitar a portabilidade dos dados cadastrais, em formato leg&iacute;vel, para outro Controlador do mesmo ramo de nossa atua&ccedil;&atilde;o;</li>
<li>d) solicitar a exclus&atilde;o de seus dados pessoais que coletamos, desde que eventuais Contas de Acesso tenham sido canceladas e decorrido o prazo legal m&iacute;nimo relacionado &agrave; guarda de dados.
</ul>
<ul style="margin-left: 5px;">
<li>3.2.1. Caso o  <strong>USU&Aacute;RIO </strong> retire seu consentimento para finalidades fundamentais ao regular funcionamento da  <strong>VW</strong> , os servi&ccedil;os e funcionalidades poder&atilde;o ficar indispon&iacute;veis.</li>
<li>3.2.2. Caso o  <strong>USU&Aacute;RIO</strong> n&atilde;o conceda seu consentimento para as finalidades facultativas, relacionadas ao envio de informa&ccedil;&otilde;es novidades, conte&uacute;dos, not&iacute;cias e demais eventos relevantes para a manuten&ccedil;&atilde;o do relacionamento, os servi&ccedil;os e funcionalidades da  <strong>VW</strong> continuar&atilde;o sendo disponibilizados regularmente.</li>
</ul>
</li>
</ol>
<ul>
<li>3.3. Para fins de auditoria, seguran&ccedil;a, controle de fraudes, prote&ccedil;&atilde;o ao cr&eacute;dito e preserva&ccedil;&atilde;o de direitos, poderemos permanecer com o hist&oacute;rico de registro dos dados do  <strong>USU&Aacute;RIO</strong> por prazo maior nas hip&oacute;teses que a lei ou norma regulat&oacute;ria assim estabelecer ou para preserva&ccedil;&atilde;o de direitos.</li>
</ul>
<p>&nbsp;</p>
<ol start="4">
<li> <strong>DISPOSI&Ccedil;&Otilde;ES GERAIS</strong> </li>
</ol>
<table>
<thead>
<tr>
<td width="642">
<p>4.1.&nbsp;&nbsp;&nbsp; N&atilde;o utilizamos nenhum tipo de decis&atilde;o automatizada que impacte o  <strong>USU&Aacute;RIO</strong> .</p>
</td>
</tr>
</thead>
</table>
<ul>
<li>4.2. Temos o direito de alterar o teor desta  <strong>Pol&iacute;tica de Privacidade</strong> a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente, cabendo ao  <strong>USU&Aacute;RIO</strong> verific&aacute;-la sempre que efetuar o acesso na  <strong>VW</strong> .
<ul style="margin-left: 5px;">
<li>4.2.1. Ocorrendo atualiza&ccedil;&otilde;es neste documento e que demandem nova coleta de consentimento, ser&atilde;o notificadas por meio dos contatos que nos fornece no cadastro.</li>
</ul>
</li>
<li>4.3. Em caso de qualquer d&uacute;vida com rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es constantes desta  <strong>Pol&iacute;tica de Privacidade</strong> , o  <strong>USU&Aacute;RIO</strong> poder&aacute; entrar em contato por meio dos canais de atendimento apontados a seguir, cujo hor&aacute;rio de funcionamento &eacute; de segunda &agrave; sexta, das 8h00 &agrave;s 18h00 e aos s&aacute;bados das 08h00 &agrave;s 14h00, exceto feriados nacionais:
<ul style="margin-left: 5px;">
<li>4.3.1. <strong>FALE CONOSCO: </strong> acess&iacute;vel atrav&eacute;s do presente  <strong><em>link </em></strong> https://www.vw.com.br/pt/fale-conosco.html .</li>
<li>4.3.2. <strong>CHAT</strong> : acess&iacute;vel atrav&eacute;s do presente  <strong><em>link</em></strong> http://volkswagen.nccsesrver.com.br/Volkswagen/Chat6/Cliente/frm_login.aspx?IdArea-1&amp;idioma-0&amp;sel-HomePage</li>
<li>4.3.3. <strong>FORMUL&Aacute;RIO ELETR&Ocirc;NICO</strong> :acess&iacute;vel atrav&eacute;s do presente  <strong><em>link</em></strong> https://www.vw.com.br/pt/fale-conosco.html</li>
<li>4.3.4 <strong>CALL CENTER</strong> : atrav&eacute;s do telefone 0800 019 5775;</li>
<li>4.3.5 <strong>CORRESPOND&Ecirc;NCIA</strong> : Via Anchieta KM 23,5 &ndash; S&atilde;o Bernardo do Campo &ndash; SP &ndash; CEP 09823-901 CPI 1048.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul>
<li>4.4. Os dados pessoais coletados atrav&eacute;s da  <strong>VW</strong> poder&atilde;o ser utilizados pela  <strong>VOLKSWAGEN</strong> e empresas parceiras para comunica&ccedil;&otilde;es ou outros servi&ccedil;os que venham a ser adquiridos atrav&eacute;s do uso desse site ou promo&ccedil;&otilde;es comerciais..</li>
<li>4.5. Caso empresas terceirizadas realizem o processamento de quaisquer dados que coletamos, as mesmas dever&atilde;o respeitar as condi&ccedil;&otilde;es aqui estipuladas e nossas normas de Seguran&ccedil;a da Informa&ccedil;&atilde;o, obrigatoriamente.</li>
<li>4.6. Caso alguma disposi&ccedil;&atilde;o desta  <strong>Pol&iacute;tica de Privacidade</strong> seja considerada ilegal ou ileg&iacute;tima por autoridade da localidade em que resida ou da sua conex&atilde;o &agrave; Internet, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</li>
<li>4.7. O <strong>USU&Aacute;RIO</strong> reconhece que toda comunica&ccedil;&atilde;o realizada por e-mail (aos endere&ccedil;os informados no seu cadastro), SMS, aplicativos de comunica&ccedil;&atilde;o instant&acirc;nea ou qualquer outra forma digital, virtual e digital tamb&eacute;m s&atilde;o v&aacute;lidas, eficazes e suficiente para a divulga&ccedil;&atilde;o de qualquer assunto que se refira aos servi&ccedil;os que prestamos, bem como &agrave;s condi&ccedil;&otilde;es de sua presta&ccedil;&atilde;o ou a qualquer outro assunto nele abordado, ressalvadas as disposi&ccedil;&otilde;es expressamente diversas previstas nesta  <strong>Pol&iacute;tica de Privacidade.</strong> </li>
</ul>
<p> <strong>&nbsp;</strong> </p>
<ol start="5">
<li> <strong>GLOSS&Aacute;RIO</strong> 
<ul>
<li>5.1. Para os fins deste documento, devem se considerar as seguintes defini&ccedil;&otilde;es e descri&ccedil;&otilde;es para seu melhor entendimento:
<ul style="margin-left: 5px;">
<li>5.1.1. <strong><em>Cloud Computing</em></strong> : Ou computa&ccedil;&atilde;o em nuvem, &eacute; tecnologia de virtualiza&ccedil;&atilde;o de servi&ccedil;os constru&iacute;da a partir da interliga&ccedil;&atilde;o de mais de um servidor por meio de uma rede de informa&ccedil;&atilde;o comum (p.ex. a Internet), com objetivo de reduzir custos e aumentar a disponibilidade dos servi&ccedil;os sustentados.</li>
<li>5.1.2. <strong>VW</strong> : Designa o endere&ccedil;o eletr&ocirc;nico <a href="https://www.vw.com.br/pt.html">https://www.vw.com.br/pt.html</a>e seus subdom&iacute;nios.</li>
<li>5.1.3. <strong>Conta de Acesso</strong> :Credencial de um  <strong>USU&Aacute;RIO</strong> necess&aacute;ria para utilizar ou acessar as funcionalidades exclusivas do  <strong>VW</strong> .</li>
<li>5.1.4. <strong><em>Cookies</em></strong> : Arquivos enviados pelo servidor da  <strong>VW</strong> para os dispositivos m&oacute;veis dos  <strong>USU&Aacute;RIOS</strong> , com a finalidade de identific&aacute;-los e obter dados de acesso, como p&aacute;ginas navegadas ou <em>links</em>acessados, permitindo, desta forma, personalizar a navega&ccedil;&atilde;o dos  <strong>USU&Aacute;RIOS</strong> na  <strong>VW</strong> , de acordo com o seu perfil.</li>
<li>5.1.5. <strong>IP</strong> : Abreviatura de <em>Internet Protocol</em>. &Eacute; conjunto alfanum&eacute;rico que identifica os dispositivos dos  <strong>USU&Aacute;RIOS</strong> na Internet.</li>
<li>5.1.6. <strong><em>Logs</em></strong> : Registros de atividades dos  <strong>USU&Aacute;RIOS</strong> efetuadas na <strong>VW</strong> .</li>
<li>5.1.7. <strong><em>Session ID</em></strong> : Identifica&ccedil;&atilde;o da sess&atilde;o do  <strong>USU&Aacute;RIOS</strong> quando &eacute; efetuado o acesso a  <strong>VW</strong> .</li>
<li>5.1.8. <strong>USU&Aacute;RIO</strong> : Pessoa que acessa e utiliza as funcionalidades oferecidas na  <strong>VW</strong> .</li>
</ul>
</li>
</ul>
</li>
</ol>
<p> <strong>&nbsp;</strong> </p>
<ol start="6">
<li> <strong>LEI APLIC&Aacute;VEL E JURISDI&Ccedil;&Atilde;O</strong> 
<ul>
<li>6.1. A presente Pol&iacute;tica de Privacidade ser&aacute; regida e interpretada segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o Foro da Comarca de domic&iacute;lio do  <strong>USU&Aacute;RIO </strong> para dirimir qualquer lit&iacute;gio ou controv&eacute;rsia envolvendo o presente documento, salvo ressalva espec&iacute;fica de compet&ecirc;ncia pessoal, territorial ou funcional pela legisla&ccedil;&atilde;o aplic&aacute;vel.</li>
</ul>
</li>
</ol>
<p>&nbsp;</p>
<p> <strong>Atualiza&ccedil;&atilde;o: </strong> &nbsp;16 de maio de 2019.</p>
<p> <strong>&nbsp;</strong> </p>
<p>&nbsp;</p></div>`
let template = null;

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {
        template = { __html: htmlContent };
        return (
            <div className="row vw-imprint">
                <div className="col-12">
                    {!this.props.hideBreadcrumb ?
                        <Breadcrumb text={`<a href='/'>Newsroom</a> / <a href='/termos-legais'>Termos</a>  / Política de Privacidade e Proteção de dados`} />
                        : null
                    }
                    <PageTitle title="Política de Privacidade e Proteção de dados" />
                    <PageSubTitle text="(Atualizado em 16/05/2019)" />
                </div>

                <div className="col-12">
                    <div dangerouslySetInnerHTML={template}></div>

                </div>

            </div>
        );
    }
}


export default PrivacyPolicy;

