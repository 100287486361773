import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reducers from './reducers/index';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk'
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import './index.css';
import AppCore from './components/appCore';
import * as serviceWorker from './serviceWorker';
import { analyticsFlavor } from './config/constants';

// const ANALYTICS_FLAVOR = 'UA-128764586-1';  //PROD
//const ANALYTICS_FLAVOR = 'UA-129676396-1';  //DEVELOPMENT
//const ANALYTICS_FLAVOR = 'UA-131272565-1';  //JAIR

const store = createStore(reducers,
  applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  )
);

ReactGA.initialize(analyticsFlavor(), {});

ReactDOM.render(
  <AppCore store={store} />
  , document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
