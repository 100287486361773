/*
 * action types
 */

export const EMOBILITY_LEVEL1_DETAIL = 'EMOBILITY_LEVEL1_DETAIL'
export const EMOBILITY_LEVEL2_DETAIL = 'EMOBILITY_LEVEL2_DETAIL'

/*
 * action creators
 */
export function getEMobility(result) {
  return { 
    type: EMOBILITY_LEVEL1_DETAIL, 
    emobility: result.emobility,
    news: result.news,
    gallery: result.gallery
  }
}

export function getSubEMobility(result) {
  return { 
    type: EMOBILITY_LEVEL2_DETAIL, 
    emobility: result.subMob,
    news: result.news,
    gallery: result.gallery
  }
}

