import React, { Component } from 'react';
import './ApprovalCard.css'
import Button from '../../Button/Button';
import  TextArea  from '../../../components/TextArea/TextArea';

class ApprovalCard extends Component {
  constructor(props) {
    super(props);
    
    if (localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data')).user) {
      this.user = JSON.parse(localStorage.getItem('user-data')).user;
    } else {
      props.history.push('/login');
    }
  }

  cancelAction = () => {
    this.props.cancelAction(this.props.id)
  }

  approveAction = () => {
    this.props.approveAction(this.props.id)
  }

  rejectAction = () => {
    this.props.rejectAction(this.props.id)
  }

  showDetailsAction = () => {
    this.props.showDetails(this.props.id)
  }

  returnStatus() {
    switch (this.props.status) {
      case 1:
        return " Aguardando Aprovação (Backoffice)";
        break;
      case 2:
        return " Aguardando Aprovação (Gerente 1)";
        break;
      case 3:
        return " Aguardando Aprovação (Gerente 2)";
        break;
      case 4:
        return " Aprovado";
        break;
      case 5:
        return " Reprovado (Backoffice)";
        break;
      case 6:
        return " Reprovado (Gerente)";
        break;
      case 7:
        return " Veículo emprestado";
        break;
      case 8:
        return " Veículo devolvido, checklist pendente";
        break;
      case 9:
        return " Processo finalizado";
        break;
      default:
        return this.props.status;
        break;
    }
  }

  formatDate(date) {
    if (!date) return
    let data = new Date(date);
    var dia = data.getDate();
    if (dia < 10) {
      dia = "0" + dia;
    }
    var mes = data.getMonth() + 1;
    if (mes < 10) {
      mes = "0" + mes;
    }
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }

  formatTime(time) {
    if (!time) return
    let timeFormated = new Date(time)
    var hour = timeFormated.getHours()
    var min = timeFormated.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    return hour + ":" + min
  }

  render() {
    return (
      <div className={`agent-details-wrapper ${this.props.parentClass}`}>
        <p className='loan-number-title mb-4'>{this.props.model ? this.props.model : this.props.title}</p>
        {this.props.subtitleManagerCard ?
          <p className='loan-date-resume'>
            <span>{this.props.subtitleManagerCard.userName}</span>
            <span>{this.props.subtitleManagerCard.midiaName}</span>
          </p>
          : null}
        {this.props.startDate ?
          <p className='loan-date-resume'>De {this.formatDate(this.props.startDate)} até {this.formatDate(this.props.endDate)}</p>
          : null}
        {this.props.carColor ? <p className='loan-date-resume'> {this.props.carColor} </p> : null}
        {this.props.showDetailsLink ? <p className='loan-date-resume details-link' onClick={e => this.showDetailsAction(e)}> Ver Detalhes</p> : null}
        {this.props.itemsApprovalCard ?
          this.props.itemsApprovalCard.map((item) => {
            return (
              <p className='loan-user-title'>
                {item.name ? item.name : ''}
                {this.props.isEdit ? 
                  <TextArea cols={"5"} maxlength={"2000"} onChange={e => this.props.changeInput(e.target.value, 'description')}
                  placeholder={"Digite a pauta ou o motivo..."} value={item.value} disabled={this.props.disabled}></TextArea>
                : <span className='loan-user'> {item.value}</span>}

                {/* <input onChange={e => this.props.changeInput(e.target.value, 'description')} type="text" value={item.value} maxLength="400"></input>  */}
              </p>
            )

          })
          :

          this.props.isEventCard ?
            <div className='row events-content'>
              <div className='col-5 text-center'>
                <p className='loan-user-title'>
                  <img src={require('./images/icon_data.svg')} className='icon-card-date' />Data
                    <p className='loan-user mt-3 text-center'> {this.formatDate(this.props.eventDate)}</p>
                </p>
              </div>
              <div className='col-1 text-center'>
                <span className='event-hour-split'>às</span>
              </div>
              <div className='col-5 text-center'>
                <p className='loan-user-title'>
                  <img src={require('./images/icon_hora.svg')} className='icon-card-date' />Hora
                    <p className='loan-user mt-3 text-center'> {this.formatTime(this.props.eventDate)} h</p>
                </p>
              </div>

              {this.props.showAcceptRecuseButton ?
                <div className='row row-buttons'>
                  <div className='col-6 '>
                    <Button text={this.props.rejectText ? this.props.rejectText : 'Reprovar'} hideIcon={true} onClick={this.rejectAction} disabled={this.props.status === 3}></Button>
                  </div>
                  <div className='col-6 mt-0 white-btn'>
                    <Button text={this.props.acceptText ? this.props.acceptText : 'Aprovar'} hideIcon={true} onClick={this.approveAction} disabled={this.props.status === 3}></Button>
                  </div>
                </div>
                : null}
            </div>
            :
            this.props.isChecklistCard ?

              this.props.carItems.map((item, index) => {
                return (<p key={`card-title-${index}`} className='loan-user-title'>
                  {item.name}
                  <span className='loan-user'>  {item.value}</span>
                </p>)
              })
              :
              <div>
                <p className='loan-user-title'>
                  Número Comodato:
                          <span className='loan-user'> {this.props.numberLoan}</span>
                </p>
                <p className='loan-user-title'>
                  Condutor:
                          <span className='loan-user'> {this.props.userName}</span>
                </p>
                <p className='loan-user-title'>
                  Status:
                        <span className='loan-user'>
                    {
                      this.user.role != 10 ? this.returnStatus() : ' Aguardando Aprovação'
                    }
                  </span>
                </p>
              </div>
        }

        {this.props.showCancelButton ?
          <div className='mt-4 mb-2 white-btn'>
            <Button text={`${this.props.cancelButtonText ? this.props.cancelButtonText : 'Cancelar Solicitação'}`} hideIcon={true} onClick={this.cancelAction} disabled={this.props.status === 3}></Button>
          </div>
          : null}

        {this.props.showApproveReproveButton ?
          <div className='row'>
            <div className='col-12 col-md-6 mt-4 mb-2 white-btn'>
              <Button text={this.props.rejectText ? this.props.rejectText : 'Reprovar'} hideIcon={true} onClick={this.rejectAction} disabled={this.props.status === 3}></Button>
            </div>
            <div className='col-12 col-md-6 mt-4 mb-2 '>
              <Button text={this.props.acceptText ? this.props.acceptText : 'Aprovar'} hideIcon={true} onClick={this.approveAction} disabled={this.props.status === 3}></Button>
            </div>
          </div>
          : null}

      </div>
    )
  }
}
export default ApprovalCard;