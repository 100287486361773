import React, { Component } from 'react';

import './TitleCar.css'; 

class TitleCar extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (      
      <div className="Hatch-title">
        {this.props.title}
      </div>
    );    
  }
}

export default TitleCar;