const initialState = {
  page: 0,
  news: []
}

const NewsComponentReducer = (state = initialState, action) => {
  let actionMerge = action.news ? action.news : []
  switch(action.type) {
    case 'LIST_NEWS':
      state = initialState;
      return {
        page: action.page,
        news: [...state.news, ...actionMerge]
      }
    default:
      return state;
  }
};

export default NewsComponentReducer;