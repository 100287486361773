import React, { Component } from 'react';

import TextBody from '../TextBody/TextBody';

import './Template.css'; 
import HighlightMedia from '../HighlightMedia/HighlightMedia';
import PopOverButtton from '../PopOverButton/popOverButton';

class Template extends Component {

  constructor(props) {
    super(props);
    this.templates = props.templates;
    if (this.props.pdf) {
      this.pdf = this.props.pdf
    } else {
      this.pdf = false;
    }
  }

  _convertDescription(desc) {
    if(!desc) return desc
    if(desc.indexOf('T-Cross') > -1 || desc.indexOf('T-CROSS') > -1) {
      let tCrossString = 'T' + '&#8209' + 'Cross';
      return desc.replace(/T-Cross/gmi, tCrossString)
    } else {
      return desc
    }
  }

  _renderComponent = (template) => {
    if (template.type == 1) {
      return (
        <div >            
          <TextBody text={this._convertDescription(template.text)}/>
        </div>
      )
    } else if (template.type == 2 && !this.pdf) {
      return (
        <div className='row row-media'>
          <div className='col-12'>
          <HighlightMedia showPopOverButtons={ this.props.showPopOverButtons ? true : this.props.showPopOverButtons } 
            imageName={this.props.GAText}
            media={template.text} mediaMedium={template.textMed} mediaLow={template.textLow} 
            mediaSize={template.mediaSize} mediaSizeMedium={template.mediaSizeMedium} mediaSizeLow={template.mediaSizeLow}
            GAText={ this.props.GAText }
            isAutoPlay={false}/>
          </div>
          <div className='col-12 text-right'>
            { template.text.indexOf('.mp4') > -1 ? 
              <div>
              <div id="downloadBondary" className="col-2 col-lg-1 align-end button-inside-col buttonContainer">
                <a href={template.text} target='_blank' className="buttonIcon">
                  <div className="buttonIcon">
                        <img src={require('./img/bt-download-hover.svg')} />
                    </div>
                  </a>
                </div>
                <div id={`cartButtonContainer-${template.id}`} className="col-2 col-lg-1 align-end button-inside-col  buttonContainer">
                  <PopOverButtton 
                      popOverType="cart"
                      containerName={`cartButtonContainer-${template.id}`}
                      imageLink={template.text}
                      contentToDownload={[]}
                      contentLink={this.props.contentLink}
                      imageDownloadName={this.props.GAText }
                      type={'Video'}
                      mediaAmount={ 0 }
                    />
                </div>
                </div>
            : <div></div>}
          </div>
        </div>        
      )
    }
  }
  
  render() {
    if (this.templates != undefined) {
      return (
        <div className="col-12">
          { this.templates.map((template, index)=> {
            return (
              <div key={`${template}-${index}`}>
                <div className="w-100"></div>            
                { this._renderComponent(template) }  
              </div>
            )
          },this) }
          <div className="w-100"></div>
          <br></br>   
        </div> 
      )  
    } else {
      return (<div></div>);
    }
  }
}

export default Template;
